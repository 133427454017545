export const mockInvoiceData = {
  id: 1,
  class_name: 'Invoice',

  invoiceable: {
    class_name: 'IndividualContact',
    fullName: 'John Doe',
    contact_addresses: [
      {
        city: {
          name: 'New York',
        },
        country: {
          name: 'United States',
        },
        floor: '5th Floor',
        building_name: 'Empire State Building',
      },
    ],
  },

  currency: {
    code: 'USD',
    symbol: '$',
    name: 'United States Dollar',
  },

  secondary_currency: {
    code: 'EUR',
    symbol: '€',
    name: 'Euro',
  },

  currency_conversion_rate_against_us_dollars: '1.00',
  secondary_currency_conversion_rate_against_us_dollars: '0.90',

  customer_email: 'john.doe@example.com',
  customer_name: 'John Doe',
  customer_amount: '100.00',
  discount_type: 'percentage',
  discount_type_amount: '10',
  general_note: 'Thank you for your business!',
  guid: 'INV-123456',
  invoice_date: '2025-02-20',
  invoice_items: [
    {
      name: 'Web Development Service',
      description: 'Basic website development package',
      quantity: 2,
      unit_price: '100.00',
      discount_type_amount: '2',
      tax_percentage: '5',
      total: '100.00',
    },
  ],
  invoice_number: 'INV-001',
  invoice_tax_items: [
    {
      name: 'VAT',
      rate: '5',
      tax_type: 'percentage',
      tax_type_amount: '4.50',
    },
  ],
  payment_details_note: 'Please make payment within 14 days.',
  payment_due_date: '2025-03-06',
  signature_note: 'Digitally signed for authenticity.',
  status: 'Pending',
  tax_after_discount: '4.50',
  tax_before_discount: '5.00',
  terms_and_conditions_note: 'Late payments may incur additional fees.',
  total_after_discount: '94.50',
  total_before_discount: '100.00',
  template: {},
}

export default mockInvoiceData
