export const KPIAndTargetsTemplate = [
  {
    title: 'Member',
    align: 'start',
    key: 'member',
    sortable: true,
  },
  {
    title: 'Title',
    align: 'start',
    key: 'title',
    sortable: true,
  },
  {
    title: 'Target Type',
    align: 'start',
    key: 'target_type',
    sortable: true,
  },
  {
    title: 'Target',
    align: 'start',
    key: 'target',
    sortable: true,
  },
  {
    title: 'Action',
    align: 'start',
    key: 'action_note',
    sortable: true,
  },
  {
    title: 'Start Date',
    align: 'start',
    key: 'start_date',
    sortable: true,
  },
  {
    title: 'End Date',
    align: 'start',
    key: 'end_date',
    sortable: true,
  },
  { title: 'Status', align: 'start', key: 'status', sortable: true },
  { title: 'Note', align: 'start', key: 'note', sortable: true },
  {
    title: '',
    align: 'center',
    key: 'action',
    sortable: false,
  },
]
