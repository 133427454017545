<template>
  <VaModal
    v-model="isTableConfigUpdateBool"
    size="small"
    hide-default-actions
    fixed-layout
    no-outside-dismiss
    @update:modelValue="handleToggleTableConfig"
  >
    <template #header>
      <div class="modal-form-header">
        <h1>{{ selectedSection?.name }}'s Table Headers View</h1>
        <VaIcon
          class="press-button"
          name="close"
          size="30px"
          @click="handleToggleTableConfig"
        />
      </div>
    </template>

    <div class="select-all">
      <VaCheckbox
        v-model="isAllSelected"
        :label="isAllSelected ? 'Unselect All' : 'Select All'"
        class="mb-4 select-all"
      />
    </div>

    <div class="all-fields">
      <draggable v-model="tableColumns" item-key="key" group="tableColumns">
        <template #item="{ element }">
          <div :key="element.key" class="AF-row mb-4">
            <VaIcon name="drag_handle" size="1.5rem" class="press-button" />

            <VaCheckbox
              v-model="element.selected"
              :id="element?.key"
              :label="element?.title"
            />
          </div>
        </template>
      </draggable>
    </div>
    <template #footer>
      <div class="form-modal-buttons">
        <VaButton
          preset="secondary"
          border-color="primary"
          @click="handleToggleTableConfig"
        >
          Cancel
        </VaButton>
        <VaButton
          :loading="isTableConfigUpdateLoading"
          @click="handleSubmitTableConfig"
          :disabled="isNotAllowedToSave"
        >
          Save
        </VaButton>
      </div>
    </template>
  </VaModal>
</template>
<script lang="ts" src="./script.ts" />
<style lang="scss" scoped src="./styles.scss" />
