import { FilterForm, TableColumnInterface } from '@/Interfaces/Constants'

import { StagesPresenter } from '../StagesPresenter'
import { getFormColumns } from '@/lib/utils'

export class SectionsPresenter {
  id!: number
  allow_multiple_stages_per_item!: boolean
  class_name!: string
  company_id!: number
  created_at!: string
  name!: string
  section_stages!: StagesPresenter[]
  section_type!: 'contacts' | 'tasks' | 'legal_matters' | 'leads'
  sort_order!: number
  table_columns!: TableColumnInterface[]
  form_columns!: any[]
  updated_at!: string
  color!: string
  data!: any
  listing_filters!: FilterForm
  fixed?: boolean
  type?: string

  constructor(data: SectionsPresenter) {
    Object.assign(this, data)

    if (data.section_stages) {
      this.section_stages = data.section_stages.map(
        (stage) => new StagesPresenter(stage),
      )
    }

    if (data.form_columns) {
      this.form_columns = getFormColumns(data.form_columns, data.section_type)
    }
  }
}
