import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-762a220e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "search-and-views" }
const _hoisted_2 = { class: "filter-search" }
const _hoisted_3 = { class: "filter" }
const _hoisted_4 = { class: "search" }
const _hoisted_5 = {
  key: 0,
  class: "views"
}
const _hoisted_6 = ["src"]
const _hoisted_7 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_VaIcon = _resolveComponent("VaIcon")!
  const _component_VaInput = _resolveComponent("VaInput")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_Button, {
          "button-text": "Filter",
          "text-color": '#66023c',
          "icon-before": _ctx.filterIcon,
          "custom-styles": {
            padding: '10px',
            borderRadius: '5px',
            fontSize: '12px',
            fontWeight: '500',
            backgroundColor: '#fff',
            border: '1px solid #66023c',
          },
          "hover-styles": {
            scale: '1.02',
          },
          onClick: _ctx.openFilterModal
        }, null, 8, ["icon-before", "onClick"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_VaInput, {
          modelValue: _ctx.internalSearch,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.internalSearch) = $event)),
          placeholder: "Search",
          icon: "search"
        }, {
          appendInner: _withCtx(() => [
            _createVNode(_component_VaIcon, {
              name: "search",
              color: "secondary"
            })
          ]),
          _: 1
        }, 8, ["modelValue"])
      ])
    ]),
    (_ctx.showViews)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createElementVNode("div", {
            class: _normalizeClass(["view", { active: _ctx.activeView === 'list' }]),
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.changeView('list')))
          }, [
            _createElementVNode("img", {
              src: _ctx.listViewIcon,
              alt: "list"
            }, null, 8, _hoisted_6)
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(["view", { active: _ctx.activeView === 'board' }]),
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.changeView('board')))
          }, [
            _createElementVNode("img", {
              src: _ctx.kanbanView,
              alt: "kanban"
            }, null, 8, _hoisted_7)
          ], 2)
        ]))
      : _createCommentVNode("", true),
    (_ctx.withAddButton)
      ? (_openBlock(), _createBlock(_component_Button, {
          key: 1,
          "button-text": _ctx.withAddButton?.buttonText ?? 'Add',
          "icon-after": _ctx.withAddButton?.isSecondary ? _ctx.addIcon : _ctx.whiteAddIcon,
          class: _normalizeClass(["basic-primary-button", { secondary: _ctx.withAddButton?.isSecondary }]),
          "custom-styles": {
        ...(_ctx.withAddButton?.isSecondary && {
          color: '#66023c',
          backgroundColor: '#fff',
          border: '0.5px solid #E2E8F0',
          textColor: '#66023c',
        }),
      },
          "hover-styles": {
        border: '1px solid #fff',
      },
          onClick: _ctx.withAddButton?.buttonAction
        }, null, 8, ["button-text", "icon-after", "class", "custom-styles", "onClick"]))
      : _createCommentVNode("", true)
  ]))
}