import { invoiceItemAttributesInterface } from '@/Interfaces/Services'
import { calendarNumberToTime, formatTimeSeconds } from '@/lib/utils'
import { InvoicePresenter } from '@/presenters/InvoicesPresenter'
import { defineComponent } from 'vue'

const tlaLogoImage = new URL('@/assets/images/TLA_logo.jpg', import.meta.url)
  .href
const rblcLogoImage = new URL('@/assets/images/RBLC_logo.jpg', import.meta.url)
  .href

export default defineComponent({
  name: '',
  props: {
    templateData: {
      type: Array as any,
      required: true,
    },
    form: {
      type: Object as () => InvoicePresenter,
      required: true,
    },
    subtotal: {
      type: String,
      required: true,
    },
    convertToSecondaryCurrencyTotal: {
      type: String,
      required: true,
    },
    convertToSecondaryCurrencySubTotal: {
      type: String,
      required: true,
    },
    total: {
      type: String,
      required: true,
    },
  },
  emits: [],
  data() {
    return {
      calendarNumberToTime,
      tlaLogoImage,
      rblcLogoImage,
      formatTimeSeconds,
    }
  },
  methods: {
    getImageSource(srcKey: string): string {
      console.log({ Timekeepers: this.form.timekeepers })

      return srcKey === 'logo' ? this.rblcLogoImage : this.tlaLogoImage
    },
    formatText(text: string): string {
      return text.replace(/\n/g, '<br>')
    },
    calculateTotalAmount(item: {
      quantity: number
      unit_price: number
      tax_percentage: number
      discount_type: string
      discount_type_amount: number
    }): number {
      let subtotal = item.quantity * item.unit_price
      if (item.discount_type === 'percentage') {
        subtotal -= (subtotal * item.discount_type_amount) / 100
      } else if (item.discount_type === 'amount') {
        subtotal -= item.discount_type_amount
      }
      return parseFloat(
        (subtotal + (subtotal * item.tax_percentage) / 100).toFixed(4),
      )
    },

    getFormattedQuantity(index: number): string {
      return this.calendarNumberToTime(this.form.invoice_items[index].quantity)
    },
    isCalendarItem(item: invoiceItemAttributesInterface): boolean {
      return item.billable?.class_name === 'CalendarItem'
    },
  },
  computed: {
    isDiscountItemsAmount(): boolean {
      return this.form.invoice_items?.some(
        (item) => item?.discount_type_amount > 0,
      )
    },
    city(): string {
      return this.form?.invoiceable?.contact_addresses?.[0]?.city.name || ''
    },
    country(): string {
      return this.form?.invoiceable?.contact_addresses?.[0]?.country.name || ''
    },
    floor(): string {
      return this.form?.invoiceable?.contact_addresses?.[0]?.floor || ''
    },
    building_name(): string {
      return this.form?.invoiceable?.contact_addresses?.[0]?.building_name || ''
    },
  },
})
