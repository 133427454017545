<template>
  <div class="scroll-container-wrapper for-tables">
    <v-data-table-server
      v-model:selection="selectedRows"
      :loading="loading"
      :headers="headers"
      :items="items"
      :items-length="totalItems"
      class="custom-table"
      :items-per-page="itemsPerPage"
      :items-per-page-options="[10, 25, 50]"
      @update:options="handleLoadMore"
      :fixed-header="true"
      :fixed-footer="true"
    >
      <template #[`item.member`]="{ item }">
        <div class="member-details">
          <router-link :to="`/firm/members/${item?.id}`">
            <VaAvatar color="#ECF0F1">
              {{ item?.member?.avatar_letter }}
            </VaAvatar>
          </router-link>
          <div>
            <router-link :to="`/firm/members/${item?.id}`">
              <div v-if="item?.member?.fullName">
                {{ item?.member?.fullName }}
              </div>
              <div class="member-role" v-if="item?.member?.role">
                {{ item?.member?.role }}
              </div>
            </router-link>
            <div class="member-details">
              <div class="member-info" v-if="item?.member?.company?.name">
                <div class="member-company">
                  {{ item?.member?.company?.name }}
                </div>
              </div>
              <div class="member-team-info" v-if="item?.member?.company?.guid">
                <VaIcon name="groups" class="team-icon" size="12px" />
                <div class="member-info">
                  <p class="team-label">Team member:</p>
                  <p class="team-guid">
                    {{ item?.member?.company?.guid }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>

      <template #[`item.title`]="{ item }">
        <p>{{ item?.title }}</p>
      </template>

      <template #[`item.target_type`]="{ item }">
        <p>{{ item?.target_type }}</p>
      </template>

      <template #[`item.target`]="{ item }">
        <div v-for="(target, index) in item.target" :key="index">
          <p>{{ target.name }}</p>
        </div>
      </template>

      <template #[`item.action_note`]="{ item }">
        <p>{{ item?.action_note }}</p>
      </template>

      <template #[`item.start_date`]="{ item }">
        <p>{{ item?.start_date }}</p>
      </template>

      <template #[`item.end_date`]="{ item }">
        <p>{{ item?.end_date }}</p>
      </template>

      <template #[`item.status`]="{ item }">
        <div
          class="status"
          :class="item?.status.toLowerCase().replace(/\s+/g, '_')"
        >
          {{ item?.status }}
        </div>
      </template>

      <template #[`item.note`]="{ item }">
        <v-tooltip
          v-if="isLongDescription(item?.note, 100)"
          :text="item?.note"
          location="top"
        >
          <template #activator="{ props }">
            <p v-bind="props" class="tasks-note-td">
              {{ truncateText(item?.note, 100) || 'No note available' }}
            </p>
          </template>
        </v-tooltip>

        <p v-else class="tasks-notes-td">
          {{ truncateText(item?.note, 100) || 'No note available' }}
        </p>
      </template>

      <template #[`item.action`]="{ item }">
        <td class="table-action-td">
          <va-dropdown :offset="[0, -70]" close-on-content-click>
            <template #anchor>
              <img
                :src="MoreVertIcon"
                alt="MoreVertIcon"
                class="press-button-2"
              />
            </template>
            <va-dropdown-content>
              <p class="table-action-option" @click="viewProfile(item)">
                <VaIcon name="person" /> View Profile
              </p>
            </va-dropdown-content>
          </va-dropdown>
        </td>
      </template>
    </v-data-table-server>

    <va-modal v-model="deleteDialog" title="Confirm Deletion">
      <h1>Are you sure you want to delete this item?</h1>
    </va-modal>
  </div>
</template>

<script lang="ts" src="./script.ts" />
<style lang="scss" src="./styles.scss" scoped></style>
