import { defineComponent } from 'vue'
import TabsBar from '@/components/tabsBar/index.vue'
import SharedHeader from '@/components/sharedHeader/index.vue'
import { useForm } from 'vuestic-ui'
import {
  deleteContact,
  getContactById,
  updateIndividualContact,
} from '@/services/contacts'
import ComingSoon from '@/components/ComingSoon/index.vue'
import FileUploadHelper, {
  getFormColumns,
  getOptionFullName,
  getOptionName,
  getOptionTruncateName50,
  truncateText,
  validateInput,
  validateInputEmail,
  validateSelect,
} from '@/lib/utils'
import { IndividualContactPresenter } from '@/presenters/contactPresenter'
import { useTabs } from '@/composables/useTabs'
import { Avatar } from '@/presenters/interface'
import { AddressPresenter } from '@/presenters/AddressPresenter'
import DynamicContactForm from '@/components/DynamicForms/DynamicContactForm/index.vue'
import { StagesPresenter } from '@/presenters/StagesPresenter'
import { contactFallbackForm } from '@/constants/ContactsFormFallbacks/contactFallbackForm'
import { ContactTemplatePresenter } from '@/presenters/TemplatePresenter/Contact'

const verifiedImage = new URL(
  '@/assets/icons/verified-icon.svg',
  import.meta.url,
).href

export default defineComponent({
  name: 'ContactDetailsPage',

  data() {
    const { isValid, validate, reset, resetValidation } = useForm(
      'ContactDetailsFormRef',
    )
    const tabs = [
      { id: 0, name: 'DASHBOARD', icon: 'dashboard' },
      { id: 1, name: 'CONTACT INFO', icon: 'info' },
      { id: 2, name: 'LEADS', icon: 'my_location' },
      { id: 12, name: 'LEGAL MATTERS', icon: 'inventory_2' },
      { id: 3, name: 'TASKS', icon: 'assignment' },
      { id: 4, name: 'CALENDAR', icon: 'calendar_month' },
      { id: 5, name: 'MILESTONES', icon: 'flag' },
      { id: 6, name: 'FILES', icon: 'file_copy' },
      { id: 7, name: 'FINANCIAL', icon: 'attach_money' },
      { id: 8, name: 'HEARINGS', icon: 'gavel' },
      { id: 9, name: 'ENGAGEMENT LETTER', icon: 'article' },
      { id: 10, name: 'EMAILS', icon: 'mail' },
      { id: 11, name: 'KYC DOCUMENTS', icon: 'article' },
    ]

    const { activeTabId, activeTab, changeTab } = useTabs(tabs, 1)

    return {
      validateInput,
      validateInputEmail,
      validateSelect,
      getOptionName,
      getOptionFullName,
      getOptionTruncateName50,

      isValid,
      validate,
      reset,
      resetValidation,
      verifiedImage,
      loading: false,
      loadingUpdate: false,
      tabs,
      activeTabId,
      activeTab,
      changeTab,

      formFields: [] as ContactTemplatePresenter[][],
      stagesLoading: false,
      stages: [] as StagesPresenter[],
      toggleSubmit: false,

      kyc_document: {},

      form: {
        contact_addresses: [
          {
            city: null,
            floor: '',
            zip_code: '',
            street_name: '',
            building_name: '',
            phone_number: '',
            google_maps_url: '',
          },
        ] as AddressPresenter[],
      } as IndividualContactPresenter,

      isAddressAccordion: [false],
      isDeleteModal: false,

      truncateText,
      contactFallbackForm,
    }
  },

  methods: {
    async handleToggleSubmit() {
      this.toggleSubmit = !this.toggleSubmit
    },
    //////////////////////////////////////////////////////////////////

    async handleGetContactByID() {
      this.loading = true

      const contactResponse = await getContactById(
        this.$route.params.id as string,
      )

      if (contactResponse.success) {
        this.form = contactResponse.data

        this.formFields = getFormColumns(
          this.$stores.generalData.companyConfigs
            .individual_contact_form_fields?.[0].data.fields,
          'contacts',
        ) as ContactTemplatePresenter[][]
      } else {
        this.$vaToast.init({
          message: contactResponse?.message,
          color: 'danger',
        })
      }

      this.loading = false
    },

    addEmailAddress() {
      this.form.email_addresses.push({ email_address: '', label: '' }) // Add a new email input
    },

    async handleDelete() {
      const response = await deleteContact(this.$route.params.id as string)
      if (response.success) {
        this.$router.push({ name: 'ContactsView' })
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
    },

    async handleUpdate(form: IndividualContactPresenter) {
      const {
        referred_by,
        organization_contact,
        individual_profile_picture,
        avatar,
        email_addresses,
        phone_numbers,
        contact_addresses,
        relationship_owner,
        assigned_to,
        kyc_document,
        specific_data,
        path_to,
        linked_legal_matter_ids,
        ...dynamicForm
      } = form

      this.loadingUpdate = true
      const response = await updateIndividualContact({
        id: this.$route.params?.id as string,
        body: {
          organization_contact_id: organization_contact?.id,
          relationship_owner_id: relationship_owner?.id,
          referred_by_id: referred_by?.id,
          assigned_to_id: assigned_to?.id,
          individual_profile_picture:
            this.form.individual_profile_picture ??
            new File([], 'empty.jpg', {
              type: 'image/jpeg',
            }),
          ...(!!email_addresses?.[0].email_address && {
            email_addresses,
          }),
          ...(!!phone_numbers?.[0].phone_number && { phone_numbers }),
          ...(!!contact_addresses?.[0]?.city?.id && {
            contact_addresses_attributes: contact_addresses?.map((address) => ({
              ...address,
              city_id: address.city?.id,
            })),
          }),
          linked_legal_matter_ids: linked_legal_matter_ids?.length
            ? linked_legal_matter_ids.map((matter: { id: number }) => matter.id)
            : null,
          ...dynamicForm,
        },
      })

      if (response.success) {
        this.$vaToast.init({
          message: 'Contact updates successfully!',
          color: 'success',
        })
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.loadingUpdate = false
    },

    ///////////////////////////////////////////////////////////////////

    handleRemoveImage() {
      this.form.avatar = {} as Avatar
      this.form.individual_profile_picture = new File([], 'empty.jpg', {
        type: 'image/jpeg',
      })
    },

    handleFileUpload(event: File) {
      FileUploadHelper.handleFileUpload({
        event,
        form: this.form,
        key: 'individual_profile_picture',
      })
      console.log('this.form', this.form)
    },
  },
  watch: {
    form: {
      handler(data) {
        console.log({
          form: data,
        })
      },
      deep: true,
    },
  },
  mounted() {
    this.handleGetContactByID()
    this.$stores.usersData.handleGetMembers()
    this.$stores.generalData.handleGetAllCountries()
    this.$stores.contacts.handleGetContacts()
    this.$stores.contacts.handleGetOrganizations()
    this.$stores.matter.handleGetMatters()
  },
  components: {
    TabsBar,
    SharedHeader,
    ComingSoon,
    DynamicContactForm,
  },
})
