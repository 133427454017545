import { defineStore } from 'pinia'
import { ZoneType } from '@/Interfaces/Services'
import { updateMember } from '@/services/company'
import { useAuthStore } from './auth'
import { useToast } from 'vuestic-ui/web-components'

export const useCalendarStore = defineStore('calendar', {
  state: () => ({
    type: 'Monthly',
    types: ['Monthly', 'Weekly', 'Daily'],
    value: [new Date()],
    events: [],
    colors: [
      'blue',
      'indigo',
      'deep-purple',
      'cyan',
      'green',
      'orange',
      'grey darken-1',
    ],
    titles: [
      'Meeting',
      'Holiday',
      'PTO',
      'Travel',
      'Event',
      'Birthday',
      'Conference',
      'Party',
    ],
    addNewType: '',
    searchKeyword: '',
    selectedZones: [] as ZoneType[],

    zoneLoading: false,
    authStore: useAuthStore(),
    vaToast: useToast(),
  }),
  actions: {
    async handleSaveSelectedZones(zones: ZoneType[]) {
      this.zoneLoading = true

      const calendarTimezones =
        zones.length > 0 ? zones.map((option) => option.timezone) : ['']

      const response = await updateMember(
        this.authStore.company.company_id,
        this.authStore.company.id as number,
        { calendar_timezones: calendarTimezones },
      )
      if (response.success) {
        localStorage.setItem('haqqCompany', JSON.stringify(response.data))
        this.authStore.$patch((state) => {
          state.company = response.data
        })
        this.zoneLoading = false
        return true
      } else {
        this.vaToast.init({ message: `${response.message}`, color: 'danger' })
      }

      this.zoneLoading = false
    },
  },
})
