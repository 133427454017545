import { AddUpdateLeadFormInterface } from '@/Interfaces/Services'
import {
  getOptionCurrency,
  getOptionFullName,
  getOptionName,
  getOptionTruncateName50,
  validateSelect,
} from '@/lib/utils'
import { SectionsPresenter } from '@/presenters/SectionsPresenter'
import { StagesPresenter } from '@/presenters/StagesPresenter'
import { defineComponent } from 'vue'
import { useForm } from 'vuestic-ui/web-components'
import DynamicForm from '@/components/DynamicForms/DynamicLeadForm/index.vue'
import { LeadPresenter } from '@/presenters/LeadPresenter'

export default defineComponent({
  name: '',
  props: {
    isAddLeadLoading: {
      type: Boolean,
      default: false,
    },
    isAddLeadForm: {
      type: Boolean,
      default: false,
    },
    stages: {
      type: Array as () => SectionsPresenter[],
      required: true,
    },
    section: {
      type: {} as () => StagesPresenter,
      required: true,
    },
  },
  emits: ['toggleAddLeadForm', 'handleCreateLead'],
  data() {
    const { validate } = useForm('form')
    const subSection = {} as SectionsPresenter
    return {
      validate,
      validateSelect,
      getOptionName,
      getOptionFullName,
      getOptionTruncateName50,
      getOptionCurrency,

      isAddLeadFormBool: this.isAddLeadForm,

      form: {} as AddUpdateLeadFormInterface,
      formFields: [] as AddUpdateLeadFormInterface[][],

      debounceTimeout: null as ReturnType<typeof setTimeout> | null,

      subSection,
      toggleSubmit: false,
    }
  },
  methods: {
    toggleAddLeadForm() {
      this.isAddLeadFormBool = !this.isAddLeadFormBool
      this.$emit('toggleAddLeadForm')
    },

    async handleToggleSubmit() {
      this.toggleSubmit = !this.toggleSubmit
    },

    handleCreateLead(form: LeadPresenter) {
      const {
        expected_close_date,
        section_stage,
        individual_contact,
        label,
        relationship_owner,
        organization_contact,
        currency,
        ...updatedForm
      } = form

      if (expected_close_date) {
        form.expected_close_date = new Date(expected_close_date).toISOString()
      }

      this.$emit('handleCreateLead', {
        ...updatedForm,
        currency_id: currency?.id,
        individual_contact_id: individual_contact?.id,
        label_id: label?.id,
        relationship_owner_id: relationship_owner?.id,
        organization_contact_id: organization_contact?.id,
        section_stage_id: section_stage?.id,
      })
    },
    validateProbability(value: number) {
      return value >= 0 && value <= 100
    },
  },
  watch: {
    isAddLeadForm: {
      handler(data) {
        this.isAddLeadFormBool = data
        if (data) {
          this.$stores.contacts.handleGetContacts()
          this.$stores.usersData.handleGetMembers()
          this.$stores.currency.handleGetCompanyCurrencies()
          this.$stores.contacts.handleGetOrganizations()

          this.subSection =
            this.stages.find(
              (section) => section.name === this.$route.query.tab,
            ) || this.stages[0]

          this.formFields = this.subSection.form_columns

          this.$stores.lead.sectionStages = this.subSection?.section_stages

          this.form.section_stage =
            this.subSection?.section_stages?.find(
              (stage) => stage.id === this.section?.id,
            ) || this.subSection?.section_stages[0]
        }
      },
    },
    subSection: {
      handler(data) {
        this.formFields = data.form_columns

        this.$stores.lead.sectionStages = data?.section_stages

        this.form.section_stage =
          data?.section_stages?.find(
            (stage: { id: number }) => stage.id === this.section?.id,
          ) || data?.section_stages[0]
      },
      deep: true,
    },
  },
  components: {
    DynamicForm,
  },
})
