import { defineComponent } from 'vue'
import TabsBar from '@/components/tabsBar/index.vue'
import { useTabs } from '@/composables/useTabs'
import { getVersions } from '@/services/versions'
import { VersionPresenter } from '@/presenters/VersionPresenter'

export default defineComponent({
  name: 'ChatActivityPanel',
  props: {},
  data() {
    const tabs = [
      { id: 0, name: 'CHAT' },
      { id: 1, name: 'ACTIVITY' },
    ]
    const { activeTabId, activeTab, changeTab } = useTabs(tabs, 0)

    return {
      tabs,
      activeTabId,
      activeTab,
      changeTab,

      newMessage: '',

      isChatActivityPanelOpen: false,

      versionsLoading: false,

      versions: [] as VersionPresenter[],
    }
  },
  mounted() {
    this.fetchVersions()
  },
  emits: [],
  methods: {
    handleSetTab(tab: number) {
      this.changeTab({ tab: this.tabs[tab], pushToRouter: false })
    },

    toggleChatActivityPanel() {
      this.isChatActivityPanelOpen = !this.isChatActivityPanelOpen
    },

    async changeTabHandler(tab: { title: string; name: string; id: number }) {
      if (tab.id === 1) {
        await this.fetchVersions()
      }
      this.changeTab({ tab, pushToRouter: false })
    },

    async fetchVersions() {
      this.versionsLoading = true
      const response = await getVersions(this.$route)
      if (response.success) {
        this.versions = response.data.list.sort(
          (a: VersionPresenter, b: VersionPresenter) =>
            new Date(b.created_at).getTime() - new Date(a.created_at).getTime(),
        )
      } else {
        this.versions = []
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.versionsLoading = false
    },
  },

  watch: {
    isChatActivityPanelOpen: {
      async handler(data) {
        if (data && this.activeTabId === 1) {
          await this.fetchVersions()
        }
      },
      deep: true,
      immediate: true,
    },
  },
  components: {
    TabsBar,
  },
})
