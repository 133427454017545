import { removeNullableFields } from '@/lib/utils'
// import router from '@/router'
import API from './API'
import { generalData } from '@/stores'
import { useToast } from 'vuestic-ui/web-components'

// Utility function to handle all API requests
export const apiRequest = async (
  method: 'get' | 'post' | 'put' | 'delete',
  url: string,
  data?: any,
  params?: any,
) => {
  const vaToast = useToast()
  const loadingStore = generalData()
  try {
    loadingStore.global_loading = true

    // Dynamically adjust headers for FormData
    const headers = {
      'Content-Type':
        data instanceof FormData ? 'multipart/form-data' : 'application/json',
    }

    const updatedData = method === 'put' ? data : removeNullableFields(data)
    const updatedParams =
      method === 'put' ? params : removeNullableFields(params)

    const response = await API({
      method,
      url,
      data: updatedData,
      params: updatedParams,
      headers,
    })

    return {
      success: true,
      data: response.data,
      status: response.status,
    }
  } catch (error: any) {
    console.error(
      `API ${method.toUpperCase()} request to ${url} failed:`,
      error,
    )

    const isNetworkError = !error.response
    if (isNetworkError) {
      vaToast.init({
        message: 'No internet connection. Please check your network.',
        color: 'danger',
      })
    }

    const { response } = error

    if (response?.status === 500) {
      // router.back()
    }

    return {
      success: false,
      status: response?.status || 500,
      message: response?.data?.message || 'An unknown error occurred',
    }
  } finally {
    loadingStore.global_loading = false // Reset loading indicator
  }
}
