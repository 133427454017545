<template>
  <VaModal
    v-model="isRoleModalOpenBool"
    size="medium"
    hide-default-actions
    fixed-layout
    no-outside-dismiss
    @update:modelValue="handleToggleModal"
  >
    <template #header>
      <div class="form-modal-header">
        <h1>{{ headerTitle }}</h1>
        <VaIcon
          class="press-button"
          name="close"
          size="30px"
          @click="handleToggleModal"
        />
      </div>
    </template>
    <VaForm ref="form" class="form" @submit.prevent="">
      <div class="form-column">
        <div class="form-row">
          <VaInput
            v-model="form.name"
            placeholder="Role Name"
            background="#ECF0F1"
            label="Role Name"
            class="form-row-item"
            requiredMark
            :rules="[validateInput]"
            :loading="getRoleLoading"
          />

          <VaSelect
            v-if="!isUpdateRole"
            v-model="form.company_user_roles"
            label="Assign this Role directly to:"
            background="#ECF0F1"
            placeholder="Select Members"
            clearable
            searchable
            :loading="$stores.usersData.membersLoading"
            :options="$stores.usersData.teamMembers"
            :highlight-matched-text="false"
            :text-by="getOptionFullName"
            allow-create
            :no-options-text="noOptionsText"
            @update:search="$stores.usersData.handleSearchMembers"
            class="form-row-item"
            multiple
            track-by="id"
          />
        </div>

        <div class="form-row form-row-item-right">
          <VaCheckbox v-model="isSelectAll" label="Select All" />
          <VaButton
            v-if="isUpdateRole"
            @click="handleResetCheckbox"
            size="small"
            >RESET</VaButton
          >
        </div>

        <table class="permissions-table">
          <thead>
            <tr>
              <th></th>
              <th>
                Read
                <VaIcon
                  v-if="getRoleLoading"
                  name="loop"
                  spin="counter-clockwise"
                />
              </th>
              <th>
                Create
                <VaIcon
                  v-if="getRoleLoading"
                  name="loop"
                  spin="counter-clockwise"
                />
              </th>
              <th>
                Update
                <VaIcon
                  v-if="getRoleLoading"
                  name="loop"
                  spin="counter-clockwise"
                />
              </th>
              <th>
                Delete
                <VaIcon
                  v-if="getRoleLoading"
                  name="loop"
                  spin="counter-clockwise"
                />
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(permission, key) in rolePermissions" :key="key">
              <td>{{ camelCaseToNormal(key) }}</td>
              <td>
                <VaCheckbox v-model="permission.read" />
              </td>
              <td>
                <VaCheckbox v-model="permission.create" />
              </td>
              <td>
                <VaCheckbox v-model="permission.update" />
              </td>
              <td>
                <VaCheckbox v-model="permission.delete" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </VaForm>
    <template #footer>
      <div class="form-modal-buttons">
        <VaButton
          preset="secondary"
          border-color="primary"
          @click="handleToggleModal"
        >
          Cancel
        </VaButton>
        <VaButton
          :loading="addRoleLoading || updateRoleLoading"
          @click="validate() && handleToggleSubmit()"
        >
          {{ submitButton }}
        </VaButton>
      </div>
    </template>
  </VaModal>
</template>

<script lang="ts" src="./script.ts" />
<style lang="scss" scoped src="./styles.scss" />
