<template>
  <SearchAndViews @search="searchTargetData" />

  <ActionsBar
    second-add-button="Add Target"
    @SecondButtonAction="handleToggleAddTargetModal"
  />

  <AddTargetModal
    :add-target-loading="isAddLoading"
    :is-add-target-form-open="isAddTargetModalOpen"
    @handleToggleAddTargetModal="handleToggleAddTargetModal"
    @handleCreateTarget="handleCreateTarget"
  />

  <!-- <div class="scroll-container"> -->
  <KPIAndTargetsTable
    :loading="tableLoading"
    :items-per-page="targetsItemPerPage"
    :total-items="targetTotalItems"
    :items="targets"
    :headers="getHeaders()"
    @loadMore="handlePaginationTargets"
  />
  <!-- </div> -->
</template>
<script lang="ts" src="./script.ts" />
<style lang="scss" scoped src="./styles.scss" />
