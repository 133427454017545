import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6e539202"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "add-delete-form-modal-buttons" }
const _hoisted_2 = { class: "main-container" }
const _hoisted_3 = { class: "delete-button-container" }
const _hoisted_4 = { class: "user-salary" }
const _hoisted_5 = { class: "SCF-container" }
const _hoisted_6 = { class: "billable-earning" }
const _hoisted_7 = { class: "billable-charging" }
const _hoisted_8 = { class: "action-buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VaButton = _resolveComponent("VaButton")!
  const _component_VaModal = _resolveComponent("VaModal")!
  const _component_VaSelect = _resolveComponent("VaSelect")!
  const _component_VaInput = _resolveComponent("VaInput")!
  const _component_VaForm = _resolveComponent("VaForm")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_VaModal, {
      modelValue: _ctx.isDeleteMember,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.isDeleteMember) = $event)),
      message: "Would you like to Delete?",
      size: "auto",
      "hide-default-actions": ""
    }, {
      footer: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_VaButton, {
            preset: "secondary",
            "border-color": "primary",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleToggleDeleteMember()))
          }, {
            default: _withCtx(() => [
              _createTextVNode("Cancel")
            ]),
            _: 1
          }),
          _createVNode(_component_VaButton, {
            onClick: _ctx.handleDeleteMember,
            loading: _ctx.deleteMemberLoading
          }, {
            default: _withCtx(() => [
              _createTextVNode("Delete")
            ]),
            _: 1
          }, 8, ["onClick", "loading"])
        ])
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_VaButton, {
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.handleToggleDeleteMember())),
          "icon-right": "delete"
        }, {
          default: _withCtx(() => [
            _createTextVNode(" DELETE ")
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", null, [
        _createVNode(_component_VaForm, {
          ref: "update-salary-form",
          class: "update-form",
          onSubmit: _withModifiers(_ctx.validate, ["prevent"])
        }, {
          default: _withCtx(() => [
            _createVNode(_component_VaSelect, {
              label: "Select Roles",
              placeholder: "Roles",
              modelValue: _ctx.member.roles,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.member.roles) = $event)),
              options: _ctx.rolesOption,
              "text-by": _ctx.getOptionName,
              loading: _ctx.rolesLoading,
              class: "EHR-input",
              background: "#ECF0F1",
              multiple: "",
              clearable: "",
              searchable: "",
              "track-by": "id"
            }, null, 8, ["modelValue", "options", "text-by", "loading"]),
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_VaInput, {
                label: "Basic Salary",
                modelValue: _ctx.member.basic_salary_amount,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.member.basic_salary_amount) = $event)),
                type: "number",
                min: "0",
                placeholder: "Salary",
                class: "BS-input",
                background: "#ECF0F1"
              }, null, 8, ["modelValue"]),
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_VaSelect, {
                  label: "salary currency",
                  placeholder: "Currency",
                  modelValue: _ctx.member.basic_salary_currency,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.member.basic_salary_currency) = $event)),
                  options: _ctx.$stores.currency.currencies,
                  "text-by": _ctx.getOptionCurrency,
                  loading: _ctx.$stores.currency.currencyLoading,
                  class: "SC-input",
                  background: "#ECF0F1"
                }, null, 8, ["modelValue", "options", "text-by", "loading"]),
                _createVNode(_component_VaSelect, {
                  label: "Salary Frequency",
                  placeholder: "Frequency",
                  modelValue: _ctx.member.basic_salary_frequency,
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.member.basic_salary_frequency) = $event)),
                  options: _ctx.salaryFrequencyOptions,
                  "text-by": "text",
                  "value-by": "value",
                  class: "SF-input",
                  background: "#ECF0F1"
                }, null, 8, ["modelValue", "options"])
              ])
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_VaInput, {
                label: "Earning Hourly Rate",
                modelValue: _ctx.member.billable_hourly_rate_earning_amount,
                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.member.billable_hourly_rate_earning_amount) = $event)),
                type: "number",
                min: "0",
                placeholder: "Earning Hourly Rate",
                class: "EHR-input",
                background: "#ECF0F1"
              }, null, 8, ["modelValue"]),
              _createVNode(_component_VaSelect, {
                label: "Earning Hourly Rate Currency",
                placeholder: "Currency",
                modelValue: _ctx.member.billable_hourly_rate_earning_currency,
                "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.member.billable_hourly_rate_earning_currency) = $event)),
                options: _ctx.$stores.currency.currencies,
                "text-by": _ctx.getOptionCurrency,
                loading: _ctx.$stores.currency.currencyLoading,
                class: "EHR-input",
                background: "#ECF0F1"
              }, null, 8, ["modelValue", "options", "text-by", "loading"])
            ]),
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_component_VaInput, {
                label: "Charging Hourly Rate",
                modelValue: _ctx.member.billable_hourly_rate_charging_amount,
                "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.member.billable_hourly_rate_charging_amount) = $event)),
                type: "number",
                min: "0",
                placeholder: "Charging Hourly Rate",
                class: "CHR-input",
                background: "#ECF0F1"
              }, null, 8, ["modelValue"]),
              _createVNode(_component_VaSelect, {
                label: "Charging Hourly Rate Currency",
                placeholder: "Currency",
                modelValue: _ctx.member.billable_hourly_rate_charging_currency,
                "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.member.billable_hourly_rate_charging_currency) = $event)),
                options: _ctx.$stores.currency.currencies,
                "text-by": _ctx.getOptionCurrency,
                loading: _ctx.$stores.currency.currencyLoading,
                class: "CHR-input",
                background: "#ECF0F1"
              }, null, 8, ["modelValue", "options", "text-by", "loading"])
            ]),
            _createElementVNode("div", _hoisted_8, [
              _createVNode(_component_VaButton, {
                loading: _ctx.updateMemberLoading,
                onClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.validate() && _ctx.handleUpdateMember()))
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Save")
                ]),
                _: 1
              }, 8, ["loading"])
            ])
          ]),
          _: 1
        }, 8, ["onSubmit"])
      ])
    ])
  ]))
}