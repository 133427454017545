<template>
  <VaModal
    v-model="deleteDialog"
    message="Delete this section?"
    ok-text="Delete"
    size="auto"
    @ok="handleDeleteSection"
  />

  <!-- <ContactTemplateModal
    @handleToggleAddModalTemp="handleToggleAddModalTemp"
    @handleAddTemplate="handleAddSection"
    @handleUpdateTemplate="handleUpdateSection"
    :addContactTempLoading="addContactTempLoading"
    :isAddContactTemp="isAddContactTemp"
    :selectedTemplate="selectedTemplate"
    :isUpdateTemplate="isUpdateTemplate"
  /> -->

  <ContactFilterModal
    :isAddContactFilterLoading="addContactFilterLoading"
    :isAddContactFilter="isAddContactFilter"
    :isUpdateFilter="isUpdateFilter"
    :selectedSection="selectedSection"
    @handleToggleAddModalFilter="handleToggleAddModalFilter"
    @handleAddFilter="handleAddSection"
    @handleUpdateFilter="handleUpdateSection"
  />

  <div class="toolbar-container">
    <div v-if="isSectionsLoading" class="toolbar-content">
      <VaSkeleton v-for="num in 3" :key="num" variant="squared" height="8rem" />
    </div>

    <draggable
      v-else
      v-model="sections"
      item-key="id"
      group="sections"
      class="draggable-sections"
      @end="onSectionDragEnd"
    >
      <template #item="{ element }">
        <div class="toolbar-content">
          <div class="toolbar">
            <div class="toolbar-header">
              <h2>{{ element.name }}</h2>
            </div>
            <div class="toolbar-actions">
              <VaIcon name="drag_handle" size="1.5rem" class="press-button" />
              <VaInput
                v-model="element.name"
                placeholder="Section Title"
                background="#ECF0F1"
                class="toolbar-input"
                :loading="isSectionLoading[element.id]?.updating"
              >
                <template
                  v-if="!isSectionLoading[element.id]?.updating"
                  #appendInner
                >
                  <VaIcon
                    name="check"
                    color="secondary"
                    class="press-button"
                    @click="
                      handleUpdateSection({
                        id: element.id,
                        body: { name: element?.name },
                      })
                    "
                  />
                </template>
              </VaInput>

              <p class="cards-count">
                <span class="icon">
                  <font-awesome-icon
                    icon="trash-can"
                    class="press-button"
                    :spin="isSectionLoading[element.id]?.deleting"
                    @click="handleToggleDeleteDialog(element.id)"
                  />
                </span>
              </p>
            </div>
          </div>
          <div class="toolbar-update">
            <p
              class="toolbar-update-modify press-button"
              @click="handleToggleAddModalFilter(element)"
            >
              Modify Filter
            </p>
            <VaButton
              size="small"
              class="duplicate-button"
              @click="handleAddSection(element)"
            >
              Duplicate Section
            </VaButton>
          </div>
        </div>
      </template>
    </draggable>

    <div class="toolbar-add-section">
      <VaButton icon-right="add" @click="handleToggleAddModalFilter()">
        Add New Section
      </VaButton>
    </div>
  </div>
</template>
<script lang="ts" src="./script.ts" />
<style lang="scss" scoped src="./styles.scss" />
