<template>
  <SharedHeader title-width="30rem" title="Files">
    <div class="sub-SharedHeader">
      <div class="sub-SharedHeader-left" />
      <div class="sub-SharedHeader-right" />
    </div>
  </SharedHeader>

  <VaModal
    v-model="isDeleteItem"
    message="Would you like to Delete?"
    size="auto"
    hide-default-actions
  >
    <template #footer>
      <div class="delete-form-modal-buttons">
        <VaButton
          preset="secondary"
          border-color="primary"
          @click="handleToggleDeleteItem()"
        >
          Cancel
        </VaButton>
        <VaButton @click="handleItemMember" :loading="deleteItemLoading">
          Delete
        </VaButton>
      </div>
    </template>
  </VaModal>

  <v-navigation-drawer
    v-model="isEditCategories"
    location="right"
    temporary
    :persistent="false"
    style="width: 25rem"
    class="category-side-drawer"
  >
    <div class="csd-header">
      <VaIcon name="folder" size="25px" />
      <p>Files Categories</p>

      <VaButton size="small" preset="primary" @click="handleToggleAddCategory">
        Add
      </VaButton>
    </div>
    <VaDivider />

    <div class="csd-body">
      <!-- Draggable Wrapper -->
      <draggable
        v-model="tabs"
        item-key="id"
        group="cats"
        class="draggable-list"
        @end="onDragEnd"
      >
        <template #item="{ element, index }">
          <div class="csd-body-cat">
            <VaIcon name="drag_handle" size="25px" />

            <!-- Inline Editing -->
            <div v-if="editedCatIndex === index" class="edit-container">
              <VaInput
                v-model="editedName"
                placeholder="Edit name"
                outlined
                clearable
                :error="editedNameError"
                @keyup.enter="submitEdit(element.id)"
                @blur="cancelEdit"
              />
              <VaButton
                size="small"
                color="primary"
                :disabled="editedNameError"
                @click="submitEdit(element.id)"
                @mousedown.prevent
              >
                ✔
              </VaButton>
            </div>

            <!-- Display Name -->
            <div v-else class="view-container">
              <p @click="editName(index, element.name)">
                {{ element.name }}
              </p>
              <span>
                <VaIcon
                  name="edit"
                  class="press-button"
                  @click="editName(index, element.name)"
                />
                <!-- <VaIcon name="delete" class="press-button" /> -->
              </span>
            </div>
          </div>
        </template>
      </draggable>
    </div>
  </v-navigation-drawer>

  <VaModal
    v-model="isAddCategory"
    size="auto"
    hide-default-actions
    fixed-layout
  >
    <div class="add-folder-form-header">
      <h1>Add Category</h1>
      <VaIcon
        class="header-icon press-button"
        name="close"
        size="30px"
        @click="handleToggleAddCategory"
      />
    </div>
    <VaForm
      ref="categoryForm"
      class="add-folder-form"
      @submit.prevent="handleAddCategory()"
    >
      <VaInput
        v-model="categoryForm.name"
        background="#ECF0F1"
        label="name"
        placeholder="Name"
        autofocus
        :rules="[validateInput]"
      />
    </VaForm>
    <div class="add-folder-form-modal-buttons">
      <VaButton :loading="addCategoryLoading" @click="handleAddCategory()">
        Save
      </VaButton>
    </div>
  </VaModal>

  <VaModal v-model="isAddFolder" size="auto" hide-default-actions fixed-layout>
    <div class="add-folder-form-header">
      <h1>Add Folder</h1>
      <VaIcon
        class="header-icon press-button"
        name="close"
        size="30px"
        @click="handleToggleAddFolder"
      />
    </div>
    <VaForm
      ref="folderForm"
      class="add-folder-form"
      @submit.prevent="handleAddFolder()"
    >
      <VaInput
        v-model="folderForm.name"
        background="#ECF0F1"
        label="name"
        placeholder="Name"
        autofocus
        :rules="[validateInput]"
        required-mark
      />
      <VaTextarea
        v-model="folderForm.description"
        background="#ECF0F1"
        label="description"
        placeholder="description"
      />
    </VaForm>
    <div class="add-folder-form-modal-buttons">
      <VaButton :loading="addFolderLoading" @click="handleAddFolder()">
        Save
      </VaButton>
    </div>
  </VaModal>

  <TabsBar
    :tabs="tabs"
    :active-tab-id="activeTabId"
    :loading="categoriesLoading"
    :add-button="true"
    @changeTab="handleTabChange"
    @addButtonFunction="isEditCategories = true"
  />

  <ActionsBar
    add-button="Add Folder"
    @addButtonAction="handleToggleAddFolder"
    secondAddButton="Add File"
    @SecondButtonAction="triggerFileInput"
  />

  <div class="back-bread">
    <VaButton
      preset="primary"
      icon="arrow_back"
      border-color="primary"
      size="small"
      round
      @click="handleBackButton"
    >
      Back
    </VaButton>
    <VaBreadcrumbs
      color="secondary"
      separator=">"
      separator-color="primary"
      class="breadcrumb-custom"
    >
      <VaBreadcrumbsItem
        v-for="(item, index) in breadCrumbItems"
        :key="index"
        :label="item.name"
        @click="handleBreadcrumbClick(item, index)"
      />
    </VaBreadcrumbs>
  </div>
  <div class="scroll-container">
    <div class="files-grid">
      <!-- Hidden File Input -->
      <input
        type="file"
        ref="fileInput"
        @change="handleFileUpload"
        style="display: none"
      />

      <div v-for="folder in storageFolders" :key="folder.id" class="folder">
        <FolderCard
          :folder="folder"
          @click="handleOpenFolder(folder)"
          @file-info="handleFileInfo"
          @rename="handleUpdateStorageFolder"
          @delete="handleToggleDeleteItem(folder)"
        />
      </div>
      <div v-for="file in storageFiles" :key="file.id" class="folder">
        <FileCard
          :file="file"
          @file-info="handleFileInfo"
          @rename="handleUpdateStorageFile"
          @click="handleFileInfo(file)"
          @delete="handleToggleDeleteItem(file)"
        />
      </div>
    </div>
  </div>

  <v-navigation-drawer
    v-model="isFolderInfo"
    location="right"
    temporary
    :persistent="false"
    style="width: 25rem"
    class="file-info-side-drawer"
  >
    <div class="fsd-header">
      <VaIcon name="folder" size="25px" />
      <p>{{ targetedFolder.name }}</p>
    </div>
    <VaDivider />
    <div class="fsd-body">
      <VaTabs stateful grow model-value="Details">
        <template #tabs>
          <VaTab
            v-for="title in ['Details', 'Activity Log']"
            :key="title"
            :name="title"
          >
            {{ title }}
          </VaTab>
        </template>
      </VaTabs>
      <VaInnerLoading :loading="fileLoading" class="file-info-img">
        <v-tooltip
          text="Open"
          location="right"
          size="12px"
          v-if="targetedFolder.file"
        >
          <template #activator="{ props }">
            <a
              :href="targetedFolder?.file?.url"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                v-if="targetedFolder?.file?.medium_url"
                v-bind="props"
                :src="targetedFolder?.file?.medium_url"
                alt="folder icon"
              />
              <div class="folder-icon" v-else>
                <VaIcon
                  v-bind="props"
                  name="description"
                  size="5rem"
                  color="primary"
                />
                <p><u>open</u></p>
              </div>
            </a>
          </template>
        </v-tooltip>

        <VaIcon
          v-else-if="!fileLoading"
          name="folder"
          size="5rem"
          class="folder-icon"
        />
      </VaInnerLoading>

      <div class="fsd-body-details">
        <h1><u>Details:</u></h1>

        <h3>Type</h3>
        <p>{{ fileType(targetedFolder.name, targetedFolder.class_name) }}</p>

        <h3>Location</h3>
        <p>{{ breadCrumbItemsText }}</p>

        <h3>Last Modified</h3>
        <p>{{ formatDate(targetedFolder.updated_at, true) }}</p>

        <h3>Created</h3>
        <p>{{ formatDate(targetedFolder.created_at, true) }}</p>

        <h3>Download Permissions</h3>
        <p>Viewers can download</p>

        <h3>Description</h3>

        <div>
          <!-- Show existing description or 'Add Description' button -->
          <div
            v-if="!isEditing"
            :class="{
              'description-view': true,
              'description-view-flex': targetedFolder.description,
            }"
          >
            <p v-if="targetedFolder.description">
              {{ targetedFolder.description }}
            </p>
            <VaButton v-else preset="primary" @click="enterEditMode">
              Add Description
            </VaButton>
            <VaIcon
              v-if="targetedFolder.description"
              name="edit"
              class="press-button-2 description-edit-icon"
              title="Edit Description"
              @click="enterEditMode"
            />
          </div>

          <!-- Editable Text Area with Save and Cancel -->
          <div v-else class="description-edit">
            <VaTextarea
              v-model="descriptionDraft"
              :loading="false"
              placeholder="Add description..."
              autofocus
              @keydown.enter="handleUpdateStorageFolder()"
              @keydown.esc="cancelEditDescription"
            />
            <div class="description-actions">
              <VaIcon
                name="check"
                class="press-button-2"
                @click="handleUpdateStorageFolder()"
              />
              <VaIcon
                name="close"
                class="press-button-2"
                @click="cancelEditDescription"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-navigation-drawer>
</template>

<script lang="ts" src="./script.ts"></script>
<style lang="scss" scoped src="./styles.scss"></style>
