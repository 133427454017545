import { NotificationPresenter } from '@/presenters/NotificationPresenter'
import { defineComponent } from 'vue'

const MoreVertIcon = new URL(
  '@/assets/icons/More-vert-icon.svg',
  import.meta.url,
).href

export type notificationCollectionType = {
  name: string
  number: number
  value: boolean
  data: NotificationPresenter[]
}

export default defineComponent({
  name: 'NotificationModal',
  props: {
    notificationLoading: {
      type: Boolean,
      default: false,
    },
    notificationNumber: {
      type: Number,
      default: 0,
    },
    notificationNumberClassification: {
      type: Object,
      required: true,
    },
    notifications: {
      type: Array as () => NotificationPresenter[],
      required: true,
    },
  },
  data() {
    const notificationCollection: notificationCollectionType[] = [
      {
        name: 'Client Related',
        number: 0,
        value: false,
        data: [] as NotificationPresenter[],
      },
      {
        name: 'Internal',
        number: 0,
        value: false,
        data: [] as NotificationPresenter[],
      },
      {
        name: 'Billing and Financial',
        number: 0,
        value: false,
        data: [] as NotificationPresenter[],
      },
      {
        name: 'Calendar and Deadline',
        number: 0,
        value: false,
        data: [] as NotificationPresenter[],
      },
      {
        name: 'General',
        number: 0,
        value: false,
        data: [] as NotificationPresenter[],
      },
    ]

    const notificationType: Record<string, string> = {
      LegalMatter: 'Legal Matter',
      Invoice: 'Invoice',
      Task: 'Task',
      Milestone: 'Milestone',
      Hearing: 'Hearing',
      Contact: 'Contact',
      Expense: 'Expense',
    }

    return {
      MoreVertIcon,
      notificationType,

      showNotifications: false,
      notificationCollection: [...notificationCollection],

      selectedLoading: -1,
    }
  },
  methods: {
    handleCloseNotification(isOpen?: boolean) {
      if (!isOpen) {
        this.notificationCollection = this.notificationCollection.map(
          (item) => ({ ...item, value: false }),
        )
      } else {
        this.showNotifications = !this.showNotifications
      }
    },
    handleGetNotification(
      notification: notificationCollectionType,
      isOpen: boolean,
      index: number,
    ) {
      if (isOpen) {
        this.selectedLoading = index
        this.$emit(
          'handleGetNotification',
          notification.name.toLowerCase().replace(/ /g, '_'),
        )
      }
    },

    handleGetNotificationsCount() {
      this.$emit('handleGetNotificationsCount')
    },
  },
  computed: {
    accordionModel: {
      get(): boolean[] {
        return this.notificationCollection.map((item) => item.value)
      },
      set(newValues: boolean[]) {
        newValues.forEach((value, index) => {
          this.notificationCollection[index].value = value
        })
      },
    },
  },
  watch: {
    notificationNumberClassification: {
      handler(data) {
        this.notificationCollection = this.notificationCollection.map(
          (item) => {
            const newItem = { ...item }
            const itemName = item.name.toLowerCase().replace(/ /g, '_')
            newItem.number = data[itemName]

            return newItem
          },
        )
      },
      deep: true,
      immediate: true,
    },
    notifications: {
      handler(data) {
        this.notificationCollection = this.notificationCollection?.map((item) =>
          item.name.toLowerCase().replace(/ /g, '_') ===
          data[0]?.notification_group_type.toLowerCase()
            ? { ...item, data: data }
            : item,
        )
      },
      deep: true,
      immediate: true,
    },
  },
})
