<template>
  <router-link
    class="card"
    :to="{ path: `/contacts/leads/${lead.id}`, force: true }"
  >
    <div class="card-title">
      <h1>
        {{ lead?.title }}
      </h1>
    </div>
    <VaDivider />
    <div>
      <p class="title">OWNER</p>
      <div class="employee-info">
        <VaAvatar
          color="#ECF0F1"
          :src="lead.relationship_owner?.avatar?.medium_url"
          alt=""
          size="1.5rem"
        >
          <p
            v-if="!lead.relationship_owner?.avatar.medium_url"
            class="avatar-p"
          >
            {{ lead.relationship_owner?.first_name?.[0] }}
            {{ lead.relationship_owner?.last_name?.[0] }}
          </p>
        </VaAvatar>

        <p>
          {{ lead.relationship_owner?.first_name }}
          {{ lead.relationship_owner?.last_name }}
        </p>
      </div>
    </div>
    <div
      v-if="lead.label?.name"
      class="stage-td"
      :style="{
        backgroundColor: lead.label?.color || '#babfc0',
      }"
    >
      {{ lead.label?.name }}
    </div>
  </router-link>
</template>
<script lang="ts" src="./script.ts" />
<style lang="scss" scoped src="./styles.scss" />
