import { CompanyConfigurationPresenter } from '@/presenters/companyConfigPresenters'
import { defineComponent } from 'vue'
import InvoiceTemplateModal from './InvoiceTemplateModal/index.vue'
import {
  addCompanyConfigType,
  addCompanyConfiguration,
  deleteCompanyConfiguration,
  updateCompanyConfiguration,
} from '@/services/companyConfigurations'

export default defineComponent({
  name: 'InvoiceTemplates',
  props: {},
  emits: [],
  data() {
    return {
      isDeleteTemplateModalOpen: false,
      isTemplateModalOpen: false,
      isDeleteTemplateLoading: false,
      isAddTemplateLoading: false,
      isUpdateTemplateLoading: false,

      isUpdateTemplate: false,

      selectedTemplate: {} as CompanyConfigurationPresenter,
    }
  },
  methods: {
    handleToggleDeleteTemplateModal(template: CompanyConfigurationPresenter) {
      this.selectedTemplate = template
      console.log({ selectedTemplate: this.selectedTemplate })

      this.isDeleteTemplateModalOpen = !this.isDeleteTemplateModalOpen
    },

    handleToggleTemplateModal(template?: CompanyConfigurationPresenter) {
      if (template) {
        this.isUpdateTemplate = true
        this.selectedTemplate = template
      } else {
        this.isUpdateTemplate = false
        this.selectedTemplate = {} as CompanyConfigurationPresenter
      }
      this.isTemplateModalOpen = !this.isTemplateModalOpen
    },

    /////////////////////////////////////////////////////////////////

    async handleDeleteTemplate() {
      this.isDeleteTemplateLoading = true
      const response = await deleteCompanyConfiguration(
        this.selectedTemplate.id,
      )

      if (response.success) {
        this.$stores.generalData.handleGetCompanyConfigurations()
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }

      this.isDeleteTemplateLoading = false
    },
    /////////////////////////////////////////////////////////////////

    async handleAddTemplate(body: addCompanyConfigType) {
      this.isAddTemplateLoading = true

      const response = await addCompanyConfiguration(body)
      if (response.success) {
        await this.$stores.generalData.handleGetCompanyConfigurations()
        this.handleToggleTemplateModal()
      } else {
        this.$vaToast.init({
          message: `${response.message}`,
          color: 'danger',
        })
      }

      this.isAddTemplateLoading = false
    },

    async handleUpdateTemplate(body: addCompanyConfigType) {
      this.isAddTemplateLoading = true
      const response = await updateCompanyConfiguration(body)

      if (response.success) {
        await this.$stores.generalData.handleGetCompanyConfigurations()
        this.handleToggleTemplateModal()
      } else {
        this.$vaToast.init({
          message: `${response.message}`,
          color: 'danger',
        })
      }
      this.isAddTemplateLoading = false
    },
  },
  computed: {
    invoiceTemplates(): CompanyConfigurationPresenter[] {
      return this.$stores.generalData.companyConfigs.invoice_template || []
    },
  },
  mounted() {
    console.log({ invoiceTemplates: this.invoiceTemplates })
  },
  components: {
    InvoiceTemplateModal,
  },
})
