import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-00dfb0b5"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "sub-SharedHeader"
}
const _hoisted_2 = ["src"]
const _hoisted_3 = {
  key: 2,
  class: "scroll-container-wrapper"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_va_dropdown_content = _resolveComponent("va-dropdown-content")!
  const _component_va_dropdown = _resolveComponent("va-dropdown")!
  const _component_SharedHeader = _resolveComponent("SharedHeader")!
  const _component_AddMatterModal = _resolveComponent("AddMatterModal")!
  const _component_UpdateLayoutColumns = _resolveComponent("UpdateLayoutColumns")!
  const _component_TabsBar = _resolveComponent("TabsBar")!
  const _component_ActionsBar = _resolveComponent("ActionsBar")!
  const _component_AllMatters = _resolveComponent("AllMatters")!
  const _component_KanbanInfiniteLoad = _resolveComponent("KanbanInfiniteLoad")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_SharedHeader, {
      "title-width": "30rem",
      title: "Legal Matters"
    }, {
      default: _withCtx(() => [
        (_ctx.activeTab !== 'All Matters')
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createVNode(_component_va_dropdown, {
                offset: [0, -70],
                "close-on-content-click": ""
              }, {
                anchor: _withCtx(() => [
                  _createElementVNode("img", {
                    src: _ctx.MoreVertIcon,
                    alt: "MoreVertIcon",
                    class: "press-button-2"
                  }, null, 8, _hoisted_2)
                ]),
                default: _withCtx(() => [
                  _createVNode(_component_va_dropdown_content, { class: "dropdown" }, {
                    default: _withCtx(() => [
                      _createElementVNode("p", {
                        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleToggleUpdateLayoutModal && _ctx.handleToggleUpdateLayoutModal(...args)))
                      }, "Edit Page Layout")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_component_AddMatterModal, {
      "add-matter-loading": _ctx.addMatterLoading,
      "is-add-matter": _ctx.isAddMatter,
      "sub-sections": _ctx.subSections,
      section: _ctx.section,
      onHandleToggleAddModal: _ctx.toggleAddMatter,
      onHandleCreateMatter: _ctx.handleCreateMatter
    }, null, 8, ["add-matter-loading", "is-add-matter", "sub-sections", "section", "onHandleToggleAddModal", "onHandleCreateMatter"]),
    _createVNode(_component_UpdateLayoutColumns, {
      "update-layout-loading": _ctx.isUpdateLayoutLoading,
      "is-update-layout-form": _ctx.isShowUpdateLayoutModal,
      section_stages: _ctx.stages,
      "is-delete-layout-loading": _ctx.isDeleteLayoutLoading,
      onHandleToggleUpdateLayoutModal: _ctx.handleToggleUpdateLayoutModal,
      onHandleUpdateStageData: _ctx.handleUpdateLayout,
      onOnDragEnd: _ctx.handleUpdateLayout,
      onHandleDeleteLayout: _ctx.handleDeleteLayout
    }, null, 8, ["update-layout-loading", "is-update-layout-form", "section_stages", "is-delete-layout-loading", "onHandleToggleUpdateLayoutModal", "onHandleUpdateStageData", "onOnDragEnd", "onHandleDeleteLayout"]),
    _createVNode(_component_TabsBar, {
      tabs: _ctx.sections,
      "active-tab-id": _ctx.activeTabId,
      loading: _ctx.isSectionsLoading,
      onChangeTab: _ctx.handleTabChange
    }, null, 8, ["tabs", "active-tab-id", "loading", "onChangeTab"]),
    (!_ctx.isSectionsLoading)
      ? (_openBlock(), _createBlock(_component_ActionsBar, {
          key: 0,
          "second-add-button": _ctx.$t('actionsBar.addMatter'),
          onSecondButtonAction: _ctx.toggleAddMatter
        }, null, 8, ["second-add-button", "onSecondButtonAction"]))
      : _createCommentVNode("", true),
    (_ctx.activeTabId === 1717171)
      ? (_openBlock(), _createBlock(_component_AllMatters, {
          key: 1,
          sections: _ctx.sections.filter((sec) => sec.id != 1717171),
          headers: _ctx.headers,
          refetchAllMattersTrigger: _ctx.refetchAllMattersTrigger
        }, null, 8, ["sections", "headers", "refetchAllMattersTrigger"]))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, [
          (!_ctx.isSectionsLoading)
            ? (_openBlock(), _createBlock(_component_KanbanInfiniteLoad, {
                key: 0,
                "is-legal-matters": true,
                stages: _ctx.stages,
                "is-cards-loading": _ctx.isMattersLoading,
                "is-columns-loading": _ctx.isStagesLoading,
                onTicketMoved: _ctx.handleTicketMoved,
                onHandleAddStage: _ctx.handleToggleUpdateLayoutModal,
                onHandleAddItem: _ctx.toggleAddMatter
              }, null, 8, ["stages", "is-cards-loading", "is-columns-loading", "onTicketMoved", "onHandleAddStage", "onHandleAddItem"]))
            : _createCommentVNode("", true)
        ]))
  ], 64))
}