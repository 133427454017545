import { defineComponent, nextTick } from 'vue'
import TabsBar from '@/components/tabsBar/index.vue'
import { useTabs } from '@/composables/useTabs'
import { Avatar } from '@/presenters/interface'
import { addMessage, getChatHistory } from '@/services/chat'
import { MessagePresenter } from '@/presenters/ChatPresenter'
import { TeamMemberPresenter } from '@/presenters/teamMember'
import { formatDate, formatTime } from '@/lib/utils'

type ChatUserInterface = {
  id: number
  name: string
  avatar?: {
    id: number | null
    large_url: string | null
    medium_url: string | null
    thumbnail_url: string | null
    url: string | null
  }
  avatar_letters?: string
  numberOfUnreadMessages?: number
}

type dataInterface = {
  id: number
  fullName: string
  avatar: Avatar
  client_avatar_letter?: string
  avatar_letter?: string
  numberOfUnreadMessages?: number
}

const mockGroups = [
  {
    id: 101,
    fullName: 'Development Team',
    avatar_letter: 'DT',
    avatar: {} as Avatar,
    numberOfUnreadMessages: 1,
  },
  {
    fullName: 'Marketing Squad',
    id: 102,
    avatar_letter: 'MS',
    numberOfUnreadMessages: 1,
  },
  {
    id: 103,
    fullName: 'Support Team',
    avatar_letter: 'ST',
    numberOfUnreadMessages: 1,
  },

  {
    fullName: 'Product Design',
    avatar_letter: 'PD',
    id: 104,
    numberOfUnreadMessages: 1,
  },
]

export default defineComponent({
  name: 'ChatPage',
  data() {
    const tabs = [
      { id: 0, name: 'CONTACTS', title: 'Contacts', loading: false },
      { id: 1, name: 'MEMBERS', title: 'Members', loading: false },
      { id: 2, name: 'GROUPS', title: 'Groups', loading: false },
    ]

    const { activeTabId, activeTab, changeTab } = useTabs(tabs, 0)

    const chats = tabs.reduce((acc, tab) => {
      acc[tab.id] = [] as ChatUserInterface[]
      return acc
    }, {} as Record<number, ChatUserInterface[]>)

    return {
      tabLoading: false,
      isCollapsed: true,

      tabs,
      activeTabId,
      activeTab,
      changeTab,
      currentUser: {} as ChatUserInterface,

      chats,

      messages: [] as MessagePresenter[],

      chatLoading: false,

      newMessage: '',

      formatTime,
      formatDate,
    }
  },
  methods: {
    async changeTabHandler({
      tab,
    }: {
      tab: { title?: string; name: string; id: number; loading?: boolean }
    }) {
      this.setLoadingState(tab.id, true)
      this.changeTab({ tab, pushToRouter: false })

      try {
        const data = await this.fetchTabData(tab.id)
        if (this.activeTabId === tab.id) {
          this.chats[tab.id] = this.formatChatData(data)
        }
      } catch (error) {
        console.error('Error changing tab:', error)
      } finally {
        this.setLoadingState(tab.id, false)
      }
    },

    setLoadingState(tabId: number, isLoading: boolean) {
      if (isLoading) {
        if (!this.chats[tabId]?.length) {
          this.tabLoading = isLoading
        } else {
          this.tabs[tabId].loading = isLoading
        }
      } else {
        this.tabLoading = isLoading
        this.tabs[tabId].loading = isLoading
      }
    },

    async fetchTabData(tabId: number) {
      const tabDataFetchers: Record<number, () => Promise<any[]>> = {
        0: this.fetchContactsData,
        1: this.fetchMembersData,
        2: this.fetchGroupsData,
      }

      const fetcher = tabDataFetchers[tabId]
      return fetcher ? await fetcher.call(this) : []
    },

    async fetchContactsData() {
      await this.$stores.contacts.handleGetContacts({}, 100)
      return this.$stores.contacts.contacts
    },

    async fetchMembersData() {
      await this.$stores.usersData.handleGetMembers()
      return (
        (this.$stores.usersData?.teamMembers as TeamMemberPresenter[])?.filter(
          (member: TeamMemberPresenter) =>
            member.id !== this.$stores.auth.user.id,
        ) || []
      )
    },

    async fetchGroupsData() {
      return Promise.resolve(mockGroups)
    },

    formatChatData(data: dataInterface[]) {
      return data.map((chat) => ({
        id: chat.id ?? 0,
        name: chat.fullName,
        avatar: chat.avatar,
        avatar_letters: chat.client_avatar_letter ?? chat.avatar_letter,

        numberOfUnreadMessages: chat.numberOfUnreadMessages ?? 0,
      }))
    },

    handleSendMessage() {
      if (!this.newMessage || this.newMessage.length === 0) return

      addMessage(
        this.newMessage,
        () => {
          this.handleGetChatHistory()
        },
        this.activeTabId,
      )

      this.newMessage = ''
    },

    handleGetChatHistory(resetMessages = false) {
      const chatHistory = getChatHistory(this.activeTabId, resetMessages)
      if (chatHistory.success) {
        this.messages = chatHistory.data.list
      }
      this.scrollToLatestMessage()
    },

    handleAddAttachment() {
      return
    },

    handleOpenChat(user: ChatUserInterface) {
      this.handleGetChatHistory(true)
      this.currentUser = user
    },

    handleCloseChat() {
      this.currentUser = {} as ChatUserInterface
    },

    shouldShowDate(id: number): boolean {
      const index = this.messages.findIndex((message) => message.id === id)
      if (index === 0) return true
      const currentDate = new Date(this.messages[index].date).toDateString()
      const previousDate = new Date(
        this.messages[index - 1].date,
      ).toDateString()
      return currentDate !== previousDate
    },

    // formatDate(date: string): string {
    //   const dateObj = new Date(date)

    //   const dayOfWeek = dateObj.toLocaleDateString(undefined, {
    //     weekday: 'long',
    //   })
    //   const day = dateObj.getDate()
    //   const month = dateObj.toLocaleDateString(undefined, { month: 'long' })
    //   const year = dateObj.getFullYear()

    //   return `${dayOfWeek} ${day}, ${month} ${year}`
    // },

    scrollToLatestMessage() {
      nextTick(() => {
        const messageGroups = this.$refs.messageGroups as HTMLElement[]
        if (messageGroups && messageGroups.length > 0) {
          const lastMessageGroup = messageGroups[messageGroups.length - 1]
          lastMessageGroup.scrollIntoView({ behavior: 'smooth', block: 'end' })
        }
      })
    },
  },
  computed: {
    groupedMessages() {
      const groups: Array<{ isMe: boolean; messages: MessagePresenter[] }> = []
      this.messages.forEach((message: MessagePresenter) => {
        const lastGroup = groups[groups.length - 1]
        if (lastGroup && lastGroup.isMe === message.isMe) {
          lastGroup.messages.push(message)
        } else {
          groups.push({ isMe: message.isMe, messages: [message] })
        }
      })
      return groups
    },
  },
  watch: {
    isCollapsed(newVal) {
      if (!newVal) {
        this.changeTabHandler({ tab: this.tabs[this.activeTabId] })
      }
    },
    messages: {
      handler() {
        this.scrollToLatestMessage()
      },
      deep: true,
    },
  },
  async mounted() {
    this.scrollToLatestMessage()
  },
  components: {
    TabsBar,
  },
})
