import { defineStore } from 'pinia'
import { useToast } from 'vuestic-ui/web-components'
import { getAllCurrencies } from '@/services/currencies'
import { CurrenciesPresenter } from '@/presenters/CurrenciesPresenter'

export const useCurrenciesStore = defineStore({
  id: 'currency',
  state: () => ({
    vaToast: useToast(),

    currencyLoading: false,
    currencies: [] as CurrenciesPresenter[],
  }),
  actions: {
    async handleGetCompanyCurrencies() {
      this.currencyLoading = true

      const responseCurrencies = await getAllCurrencies({})

      if (responseCurrencies.success) {
        this.currencies = responseCurrencies.data.list
      } else {
        this.vaToast.init({
          message: `${responseCurrencies.message}`,
          color: 'danger',
        })
      }

      this.currencyLoading = false
    },
  },
})
