<template>
  <VaModal
    v-model="isAddContactFilterBool"
    size="auto"
    hide-default-actions
    fixed-layout
    no-outside-dismiss
    @update:modelValue="handleToggleAddModalFilter"
  >
    <template #header>
      <div class="modal-form-header">
        <h1>{{ modalTitle }}</h1>
        <VaIcon
          class="press-button"
          name="close"
          size="30px"
          @click="handleToggleAddModalFilter"
        />
      </div>
    </template>

    <div class="body">
      <div>
        <VaInput
          v-model="name"
          placeholder="Name"
          background="#ECF0F1"
          label="Name"
        />
      </div>

      <div>
        <VaSelect
          placeholder="Contact Type"
          background="#ECF0F1"
          v-model="listing_filters.contact_type"
          label="Contact Type"
          :options="contactTypeOptions"
          track-by="value"
          value-by="value"
        />
      </div>
      <div>
        <VaCheckbox v-model="listing_filters.client" label="Is Client" />
      </div>
      <div>
        <VaCheckbox
          v-model="listing_filters.has_active_legal_matters"
          label="Has Active Legal Matter"
        />
      </div>
    </div>

    <template #footer>
      <div class="form-modal-buttons">
        <VaButton
          preset="secondary"
          border-color="primary"
          @click="handleToggleAddModalFilter"
        >
          Cancel
        </VaButton>
        <VaButton
          @click="handleSubmitFilter"
          :loading="isAddContactFilterLoading"
        >
          {{ submitButton }}
        </VaButton>
      </div>
    </template>
  </VaModal>
</template>

<script lang="ts" src="./script.ts" />
<style lang="scss" scoped src="./styles.scss" />
