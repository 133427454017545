<template>
  <SharedHeader title-width="30rem" title="Settings">
    <div class="sub-SharedHeader">
      <div class="sub-SharedHeader-left" />
      <div class="sub-SharedHeader-right" />
    </div>
  </SharedHeader>
  <TabsBar :tabs="tabs" :active-tab-id="activeTabId" @changeTab="changeTab" />

  <GeneralSettings v-if="activeTab === 'General'" />
  <ContactsSettings v-else-if="activeTab === 'Contacts'" />
  <LeadsSettings v-else-if="activeTab === 'Leads'" />
  <MattersSettings v-else-if="activeTab === 'Matters'" />
  <MilestonesSetting v-else-if="activeTab === 'Milestones'" />
  <HearingsSettings v-else-if="activeTab === 'Hearings'" />
  <TasksSettings v-else-if="activeTab === 'Tasks'" />
  <CalendarSettings v-else-if="activeTab === 'Calendar'" />

  <FinancialSettings v-else-if="activeTab === 'Financial'" />

  <div v-else class="coming-soon-container">
    <ComingSoon />
  </div>
</template>
<script lang="ts" src="./script.ts" />
<style lang="scss" scoped src="./styles.scss" />
