import { AnnouncementPresenter } from '@/presenters/AnnouncementPresenter'
import { mockCompanyUsers } from './mockCompanyUsers'

function createMockAnnouncement({
  id,
  title,
  description,
  date,
  requireConfirmation,
  recipientIndices,
}: {
  id: number
  title: string
  description: string
  date: string
  requireConfirmation: boolean
  recipientIndices: number[]
}): AnnouncementPresenter {
  return {
    id,
    title,
    description,
    dateAndTimeOfDispatching: date,
    date,
    requireConfirmation,
    recipients: recipientIndices.map((index) => mockCompanyUsers[index]),
  }
}

const mockAnnouncementsData = [
  {
    id: 1,
    title: 'June 2024 Target',
    description: 'Let’s exceed our target for June 2024.',
    date: '2025-01-15T10:00:00Z',
    requireConfirmation: false,
    recipientIndices: [0, 1, 2],
  },
  {
    id: 2,
    title: 'Warning',
    description: 'KPIs not met for the third consecutive time.',
    date: '2025-01-10T02:00:00Z',
    requireConfirmation: false,
    recipientIndices: [0, 1],
  },
  {
    id: 3,
    title: 'Warning 2',
    description: 'Firm will take action if this repeats.',
    date: '2025-01-10T02:00:00Z',
    requireConfirmation: false,
    recipientIndices: [2],
  },
]

const mockDraftAnnouncementsData = [
  {
    id: 4,
    title: 'June 2024 Target',
    description: 'Our dedication will lead us to victory.',
    date: '2025-01-15T10:00:00Z',
    requireConfirmation: true,
    recipientIndices: [0, 1, 2],
  },
  {
    id: 5,
    title: 'Warning',
    description: 'Firm will take action if this repeats.',
    date: '2025-01-10T02:00:00Z',
    requireConfirmation: false,
    recipientIndices: [2, 1],
  },
]

export const mockSentAnnouncements: AnnouncementPresenter[] =
  mockAnnouncementsData.map(createMockAnnouncement)

export const mockDraftAnnouncements: AnnouncementPresenter[] =
  mockDraftAnnouncementsData.map(createMockAnnouncement)
