import { CompanyConfigurationPresenter } from '@/presenters/companyConfigPresenters'

export const mockInvoicePrintTemplate: CompanyConfigurationPresenter = {
  id: 1,
  class_name: 'CompanyConfiguration',
  color: '#FF5733',
  configuration_type: 'invoice_template',
  name: 'MyConfiguration',
  created_at: '2021-07-01T00:00:00Z',
  updated_at: '2021-07-01T00:00:00Z',
  company_id: 1,
  icon_name: 'check',
  data: {
    header: [
      [
        { type: 'img', key: 'header', src: 'logo' },
        { type: 'img', key: 'header', src: 'logo1' },
      ],
      [
        {
          type: 'text',
          key: 'header',
          text: 'Rindala Beydoun Legal Consultancy \nUnit 1402, Maze Tower \nDubai - UAE \nTRN100580056800003, \nTelephone: +971 4 5979777 ',
        },
      ],
      [{ type: 'h1', key: 'header', text: 'Invoice' }],
      [
        { type: 'client', key: 'body' },
        { type: 'date|number', key: 'body' },
      ],
    ],
    body: [
      [{ type: 'table', key: 'body' }],
      [{ type: 'timekeepers', key: 'body' }],
    ],
    footer: [
      [
        {
          type: 'text',
          key: 'footer',
          text: 'Bank: First Abu Dhabi Bank \nAccount Name:Rindala Beydoun Legal Consultancy \nSWIFT: NBADAEAA \nUSD Account: AE880351031324757738002 \nAED Account: AE180351031324757738001',
        },
      ],
    ],
  },
}
