<template>
  <div class="chat-panel" :class="{ 'chat-panel-collapsed': isCollapsed }">
    <div class="chat-panel-container" :class="{ hide: isCollapsed }">
      <div class="header">
        <div class="header-left">
          <VaIcon
            name="menu_open"
            class="press-button collapse-right-icon mirror-horizontal"
            @click="isCollapsed = !isCollapsed"
          />

          <h2>Chats</h2>
        </div>

        <VaButton preset="secondary" icon="add" border-color="primary" />
      </div>
      <div v-if="currentUser?.id" class="scroll-container-wrapper">
        <div class="chat-header">
          <div class="chatting-person">
            <VaIcon
              name="arrow_back_ios"
              class="press-button"
              @click="handleCloseChat()"
            />
            <VaAvatar
              color="#ECF0F1"
              :src="currentUser?.avatar?.medium_url"
              :alt="currentUser?.name"
              size="medium"
            >
              <span v-if="!currentUser?.avatar?.medium_url">
                {{ currentUser?.avatar_letters }}
              </span>
            </VaAvatar>
            <h2>{{ currentUser?.name }}</h2>
          </div>
          <VaIcon name="settings" class="press-button" />
        </div>
        <div class="chat-container gradient-background">
          <VaInnerLoading v-if="chatLoading" :loading="chatLoading">
            <div class="chat-list-container"></div>
          </VaInnerLoading>
          <div v-if="groupedMessages.length === 0" class="unread-header">
            No messages yet. <br />Start the conversation!
          </div>
          <transition-group
            v-else
            name="message-fade"
            tag="div"
            class="message-group-wrapper"
          >
            <div
              v-for="(group, groupIndex) in groupedMessages"
              :key="groupIndex"
              class="message-group"
              ref="messageGroups"
            >
              <div
                v-for="(message, messageIndex) in group.messages"
                :key="message.id"
              >
                <div v-if="shouldShowDate(message.id)" class="date-header">
                  {{ formatDate(message.date, true) }}
                </div>

                <div v-if="!message.isRead" class="unread-header">
                  UNREAD MESSAGES
                </div>

                <div
                  class="chat-message"
                  :class="{
                    'is-me': message.isMe,
                    'is-them': !message.isMe,
                  }"
                >
                  <VaAvatar
                    color="#ECF0F1"
                    v-if="!message.isMe"
                    :src="currentUser?.avatar?.medium_url"
                    :alt="currentUser?.name"
                    size="small"
                    :class="{
                      'empty-avatar':
                        messageIndex !== group.messages.length - 1,
                    }"
                  >
                    <span
                      v-if="
                        !currentUser?.avatar?.medium_url &&
                        messageIndex === group.messages.length - 1
                      "
                    >
                      {{ currentUser?.avatar_letters }}
                    </span>
                  </VaAvatar>

                  <div
                    class="message-bubble"
                    :class="{
                      'first-message': messageIndex === 0,
                      'last-message':
                        messageIndex === group.messages.length - 1,
                      'middle-message':
                        messageIndex > 0 &&
                        messageIndex < group.messages.length - 1,
                    }"
                  >
                    <p>{{ message.content }}</p>
                    <p class="message-time">{{ formatTime(message.date) }}</p>
                  </div>
                  <VaAvatar
                    color="#ECF0F1"
                    v-if="message.isMe"
                    :src="$stores.auth.user?.avatar?.medium_url"
                    :alt="$stores.auth.user?.name"
                    size="small"
                    :class="{
                      'empty-avatar':
                        messageIndex !== group.messages.length - 1,
                    }"
                  >
                    <span
                      v-if="
                        !$stores.auth.user?.avatar?.medium_url &&
                        messageIndex === group.messages.length - 1
                      "
                    >
                      {{ $stores.auth.user?.avatar_letter }}
                    </span>
                  </VaAvatar>
                </div>
              </div>
            </div>
          </transition-group>
        </div>
        <div class="chat-input-wrapper">
          <VaInput
            v-model="newMessage"
            placeholder="Send Message"
            class="chat-input"
            preset="bordered"
            @keydown.enter="handleSendMessage"
          >
            <template #prependInner>
              <VaIcon
                name="attach_file"
                color="primary"
                size="1.5rem"
                @click="handleAddAttachment()"
              />
            </template>

            <template #appendInner>
              <VaIcon
                name="send"
                color="primary"
                size="1.5rem"
                :disabled="!newMessage || newMessage.length === 0"
                @click="handleSendMessage()"
              />
            </template>
          </VaInput>
        </div>
      </div>
      <div v-else class="scroll-container-wrapper">
        <div class="search-chats">
          <VaInput
            size="large"
            type="search"
            placeholder="Search..."
            preset="bordered"
          >
            <template #appendInner>
              <VaIcon name="search" color="primary" size="1.5rem" />
            </template>
          </VaInput>
        </div>
        <TabsBar
          :tabs="tabs"
          :full-tab="true"
          :active-tab-id="activeTabId"
          class="groups-chat-taskbar"
          @changeTab="changeTabHandler"
        />
        <div class="chat-list-container">
          <VaInnerLoading
            v-if="tabLoading"
            :loading="tabLoading"
            class="loading-flex"
          >
          </VaInnerLoading>

          <div
            v-else
            v-for="chat in chats[activeTabId]"
            :key="chat.id"
            class="chatting-person chat-list press-button"
            @click="handleOpenChat(chat)"
          >
            <VaAvatar
              color="#ECF0F1"
              :src="chat?.avatar?.medium_url"
              :alt="chat?.name"
              size="medium"
            >
              <span v-if="!chat?.avatar?.medium_url">
                {{ chat?.avatar_letters }}
              </span>
            </VaAvatar>
            <h2>{{ chat?.name }}</h2>
            <v-badge
              v-if="chat.numberOfUnreadMessages"
              color="error"
              :content="chat.numberOfUnreadMessages"
              inline
              class="person-badge"
            />
          </div>
        </div>
      </div>
    </div>

    <div v-if="isCollapsed" class="chat-panel-container-collapsed">
      <VaIcon
        name="menu_open"
        class="press-button collapse-left-icon"
        @click="isCollapsed = !isCollapsed"
      />

      <v-badge v-for="n in 9" :key="n" color="error" dot>
        <img
          :src="'https://i.pravatar.cc/300?img=' + n"
          alt="users"
          class="avatar"
        />
      </v-badge>
    </div>
  </div>
</template>

<script lang="ts" src="./script.ts"></script>
<style lang="scss" scoped src="./styles.scss"></style>
