import { defineComponent, PropType } from 'vue'
import { useForm } from 'vuestic-ui'
import {
  getOptionFullName,
  getOptionName,
  getOptionTruncateName50,
  validateInput,
  validateInputEmail,
  validateSelect,
  getOptionTruncateName110,
  validateColorInput,
  focusInputForSelect,
  getOptionCurrency,
} from '@/lib/utils'
import { IndividualContactPresenter } from '@/presenters/contactPresenter'
import { MatterPresenter } from '@/presenters/matterPresenter'
import { TeamMemberPresenter } from '@/presenters/teamMember'
import { CompanyUserPresenter } from '@/presenters/CompanyUserPresenter'
import { StagesPresenter } from '@/presenters/StagesPresenter'
import { CompanyConfigurationsResponse } from '@/services/companyConfigurations'
import { AddOptionModalProps } from '@/Interfaces/SettingsInterfaces'
import { OrganizationContactPresenter } from '@/presenters/organizationPresenter'
import {
  LeadTemplatePresenter,
  OptionsType,
} from '@/presenters/TemplatePresenter/Lead'
import { CurrenciesPresenter } from '@/presenters/CurrenciesPresenter'
import { CompanyConfigurationPresenter } from '@/presenters/companyConfigPresenters'

type OptionFormType = {
  individual_first_name?: string
  individual_last_name?: string
  name?: string
}

export default defineComponent({
  name: 'DynamicForm',

  props: {
    fields: {
      type: Array as PropType<LeadTemplatePresenter[][]>,
      required: true,
    },
    initialValues: {
      type: Object as PropType<Record<string, any>>,
      default: () => ({}),
    },

    toggleSubmit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const { validate } = useForm('form')
    const { validate: contactValidate } = useForm('contactForm')
    const { validate: optionValidate } = useForm('optionForm')

    return {
      validateInput,
      validateInputEmail,
      validateSelect,
      getOptionName,
      getOptionFullName,
      getOptionTruncateName50,
      getOptionTruncateName110,

      validate,
      contactValidate,
      optionValidate,

      formData: { ...this.initialValues },
      options: {} as OptionsType,

      isAddNewOption: false,
      selectedField: {} as AddOptionModalProps,

      optionForm: {} as OptionFormType,
      focusInputForSelect,
    }
  },
  computed: {
    contacts(): IndividualContactPresenter[] {
      return this.$stores.contacts.contacts
    },
    organizations(): OrganizationContactPresenter[] {
      return this.$stores.contacts.organizations
    },
    matters(): MatterPresenter[] {
      return this.$stores.matter.matters
    },
    countries(): { name: string }[] {
      return this.$stores.generalData.countries
    },
    teamMembers(): TeamMemberPresenter[] | CompanyUserPresenter[] {
      return this.$stores.usersData.teamMembers
    },
    sectionStages(): StagesPresenter[] {
      return this.$stores.matter.sectionStages
    },
    leadSectionStages(): StagesPresenter[] {
      return this.$stores.lead.sectionStages
    },
    companyConfigs(): CompanyConfigurationsResponse {
      return this.$stores.generalData.companyConfigs
    },
    presentationTypeOption(): string[] {
      return ['Plaintiff', 'Defendant']
    },
    organizationIndustryOptions(): string[] {
      return ['Type 1', 'Type 2']
    },
    organizationTypeOptions(): string[] {
      return [
        'Sole Proprietorship',
        'Individual Intrapreneur',
        'Freelancer',
        'Partnership',
        'General Partnership',
        'Limited Partnership',
      ]
    },
    clients(): Array<
      IndividualContactPresenter | OrganizationContactPresenter
    > {
      return [
        ...this.$stores.contacts.contacts,
        ...this.$stores.contacts.organizations,
      ]
    },
    currencies(): CurrenciesPresenter[] {
      return this.$stores.currency.currencies
    },
    leadLabels(): CompanyConfigurationPresenter[] | undefined {
      return this.$stores.generalData.companyConfigs.lead_label
    },
    noOptionsText() {
      return (
        option: keyof OptionsType | { value: string | number; label: string }[],
      ) => {
        return Array.isArray(option) ? 'Item not found' : 'Press enter to add'
      }
    },
  },
  methods: {
    validateField(field: LeadTemplatePresenter): boolean {
      const { required, rules, name } = field

      // If field is not required and empty, it's valid
      if (!required && !this.formData[name]) {
        return true
      }

      // Handle both single rule string and array of rules
      const rulesList = Array.isArray(rules) ? rules : [rules]

      // Map validation functions from utils
      const validators = {
        validateInput,
        validateInputEmail,
        validateSelect,
        validateColorInput,
      }

      // Check all rules
      return rulesList.every((rule) => {
        const validator = validators[rule as keyof typeof validators]
        if (!validator) {
          console.warn(`Validation rule '${rule}' not found`)
          return false
        }
        return validator(this.formData[name])
      })
    },

    handleTextBy(textBy: string, option: string): string {
      const textHandlers: Record<string, (option: string) => string> = {
        getOptionName,
        getOptionFullName,
        getOptionTruncateName110,
        getOptionCurrency,
      }

      if (textBy === 'getOptionName|getOptionFullName') {
        return [getOptionName(option), getOptionFullName(option)]
          .filter(Boolean)
          .join(' ')
      }

      // console.log(textHandlers[textBy ?? '']?.(option))

      return textHandlers[textBy ?? '']?.(option) ?? 'label'
    },

    handleOptions(
      option:
        | keyof OptionsType
        | {
            value: string | number
            label: string
          }[],
    ) {
      if (Array.isArray(option)) {
        return option
      }
      const optionsMap = {
        contacts: this.contacts,
        organizations: this.organizations,
        clients: this.clients,
        matters: this.matters,
        countries: this.countries,
        teamMembers: this.teamMembers,
        sectionStages: this.sectionStages,
        leadSectionStages: this.leadSectionStages,
        currencies: this.currencies,
        leadLabels: this.leadLabels,
        matterStatusOption: this.companyConfigs.matterStatusOption,
        matterSectorsOption: this.companyConfigs.matterSectorsOption,
        courtOptions: this.companyConfigs.courtOptions,
        matterSpecializationOptions:
          this.companyConfigs.matterSpecializationOptions,
        matterCategoryOptions: this.companyConfigs.matterCategoryOptions,
        presentationTypeOption: this.presentationTypeOption,
        organizationTypeOptions: this.organizationTypeOptions,
        organizationIndustryOptions: this.organizationIndustryOptions,
      }

      return optionsMap[option] || []
    },

    /////////////////////////////////// submit ///////////////////////////////////

    handleSubmit() {
      this.validate() && this.$emit('submit', this.formData)
    },

    ////////////////////////////////// search /////////////////////////////////////

    onOpen() {
      const selectComponent = this.$refs.select as any
      if (selectComponent?.$el) {
        const inputElement = selectComponent.$el.querySelector('input')
        if (inputElement) {
          inputElement.focus()
        }
      }
    },

    handleSearch(
      type: keyof OptionsType | { value: string | number; label: string }[],
      text: string,
    ) {
      const searchHandlers: Record<string, (searchText: string) => void> = {
        contacts: (searchText) =>
          this.$stores.contacts.handleSearchContacts(searchText),
        teamMembers: (searchText) =>
          this.$stores.usersData.handleSearchMembers(searchText),
        clients: (searchText) => (
          this.$stores.contacts.handleSearchContacts(searchText),
          this.$stores.contacts.handleSearchOrganizations(searchText)
        ),
        matters: (searchText) => this.$emit('handleSearchMatters', searchText),
      }

      if (typeof type === 'string' && searchHandlers[type]) {
        searchHandlers[type](text)
      }
    },

    getLoadingState(
      type: keyof OptionsType | { value: string | number; label: string }[],
    ): boolean {
      if (Array.isArray(type)) {
        return false
      }

      const loadingStates: Record<string, boolean> = {
        contacts: this.$stores.contacts.contactsLoading,
        teamMembers: this.$stores.usersData.membersLoading,
        clients:
          this.$stores.contacts.organizationsLoading ||
          this.$stores.contacts.contactsLoading,
        matters: this.$stores.matter.mattersLoading,
      }

      return loadingStates[type] ?? false
    },

    /////////////////////////////////// add new option ///////////////////////////////////

    handleToggleAddOption() {
      this.isAddNewOption = !this.isAddNewOption
    },

    getConfigType(option: string) {
      const configTypeMap: Record<string, string> = {
        courtOptions: 'court',
        matterStatusOption: 'legal_matter_status',
        matterSpecializationOptions: 'legal_matter_specialization',
        matterCategoryOptions: 'legal_matter_category',
        matterSectorsOption: 'matter_sector',
        hearingStatusOptions: 'hearing_status',
        milestonesStatusOption: 'milestone_status',
        taskPriorityOptions: 'task_priority',
        leadLabelOption: 'lead_label',
      }

      return configTypeMap[option] || ''
    },

    handleNewOption(field: LeadTemplatePresenter, newVal: string) {
      if (!Array.isArray(field.options)) {
        const configType = this.getConfigType(field.options)

        if (configType) {
          this.selectedField = {
            option: field.options,
            label: field.label,
            configuration_type: configType,
          }
          this.optionForm = { name: newVal }
        } else {
          this.selectedField = {
            option: field.options,
            label: field.label,
          }
        }

        this.handleToggleAddOption()
      }
    },

    handleAddNewOption() {
      if (this.selectedField.configuration_type) {
        this.$stores.generalData.handleAddGlobalConfig({
          name: this.optionForm.name as string,
          configuration_type: this.selectedField.configuration_type,
        })
      } else {
        this.$stores.contacts.handleAddContact(this.optionForm)
      }
    },

    resetAddOptionState(data: boolean) {
      if (!data) {
        this.isAddNewOption = false
        this.optionForm = {} as OptionFormType
      }
    },

    handleDateChange(date: string, name: string) {
      this.formData[name] = date.toString()
      console.log({ date: date.toString(), name })
    },
  },
  watch: {
    toggleSubmit: {
      handler() {
        this.handleSubmit()
      },
      immediate: false,
    },
    initialValues: {
      handler(newValues) {
        this.formData = { ...newValues }
      },
      deep: true,
      immediate: true,
    },
    '$stores.contacts.addContactLoading': {
      handler: 'resetAddOptionState',
      deep: true,
      immediate: true,
    },
    '$stores.generalData.isAddGlobalConfigLoading': {
      handler: 'resetAddOptionState',
      deep: true,
      immediate: true,
    },
  },
  components: {},
})
