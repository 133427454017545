import { mockTargets } from '@/constants/mockTargets'
import { TargetPresenter } from '@/presenters/TargetPresenter'

export const getAllTargets = () => {
  const targets = mockTargets

  const response = {
    success: true,
    message: 'success',
    data: {
      extra_data: {},
      limit_value: 100,
      total_pages: 1,
      current_page: 1,
      next_page: null,
      prev_page: null,
      list: targets,
    },
  }

  if (!response.success) return response

  const list = response.data?.list
  if (!Array.isArray(list)) return response

  return {
    ...response,
    data: {
      ...response.data,
      list: list.map((item: TargetPresenter) => {
        return new TargetPresenter(item)
      }),
    },
  }
}

export const addTarget = ({ newTarget }: { newTarget: TargetPresenter }) => {
  console.log(newTarget)
}
