import { addUpdateSectionType } from '@/Interfaces/Services'
import { SectionsPresenter } from '@/presenters/SectionsPresenter'
import { StorageFoldersPresenter } from '@/presenters/StorageFoldersPresenter'
import {
  addSection,
  deleteSection,
  getAllSections,
  updateSection,
} from '@/services/sections'
import { defineComponent } from 'vue'
import draggable from 'vuedraggable'
import ContactTemplateModal from '@/views/Settings/ContactsSettings/ContactsForms/ContactTemplateModal/index.vue'
import ContactFilterModal from '@/views/Settings/ContactsSettings/ContactsToolbar/ContactFilterModal/index.vue'
import { formatTemplateFields } from '@/lib/utils'
import { TemplateInterface } from '@/Interfaces/Constants'

export default defineComponent({
  name: 'ContactsToolbar',
  props: {},
  emits: [],
  data() {
    return {
      isSectionsLoading: false,

      isSectionAddLoading: false,
      sections: [] as SectionsPresenter[],

      deleteDialog: false,
      deleteSectionId: null as number | null,

      isSectionLoading: {} as Record<
        number,
        { updating: boolean; deleting: boolean }
      >,

      selectedSection: {} as SectionsPresenter,
      isUpdateFilter: false,

      addContactFilterLoading: false,
      isAddContactFilter: false,
    }
  },

  methods: {
    handleToggleDeleteDialog(id: number) {
      this.deleteDialog = !this.deleteDialog
      this.deleteSectionId = id
    },

    ////////////////////////////////////////////////////////////////////////////////

    async handleGetAllSections() {
      this.isSectionsLoading = true
      const response = await getAllSections('contacts')
      if (response.success) {
        this.sections = response.data?.list
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.isSectionsLoading = false
    },

    async handleUpdateSection({
      id,
      body,
    }: {
      id: number
      body: addUpdateSectionType
    }) {
      this.addContactFilterLoading = true
      if (!this.isSectionLoading[id]) {
        this.isSectionLoading[id] = { updating: false, deleting: false }
      }
      this.isSectionLoading[id].updating = true

      const response = await updateSection(id, {
        ...body,
        ...(body.form_columns?.[0] && {
          form_columns: formatTemplateFields(
            body.form_columns as TemplateInterface[][],
          ),
        }),
      })

      if (!response.success) {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.handleGetAllSections()

      this.isSectionLoading[id].updating = false

      this.addContactFilterLoading = false
    },

    async handleAddSection(section: SectionsPresenter) {
      this.isSectionAddLoading = true

      const {
        name,
        section_type,
        allow_multiple_stages_per_item,
        sort_order,
        form_columns,
        listing_filters,
      } = section

      const response = await addSection({
        name,
        ...(section_type ? { section_type } : { section_type: 'contacts' }),
        ...(allow_multiple_stages_per_item
          ? { allow_multiple_stages_per_item }
          : { allow_multiple_stages_per_item: true }),
        ...(sort_order && { sort_order: Number(sort_order) + 1 }),
        listing_filters,
        // form_columns: formatTemplateFields(form_columns)
      })

      if (response.success) {
        this.handleGetAllSections()
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }

      this.isSectionAddLoading = false
    },

    async handleDeleteSection() {
      if (this.deleteSectionId !== null) {
        if (!this.isSectionLoading[this.deleteSectionId]) {
          this.isSectionLoading[this.deleteSectionId] = {
            updating: false,
            deleting: false,
          }
        }
        this.isSectionLoading[this.deleteSectionId].deleting = true

        const response = await deleteSection(this.deleteSectionId)

        if (response.success) {
          this.deleteSectionId = null
          this.handleGetAllSections()
        } else {
          this.$vaToast.init({
            message: `${response.message}`,
            color: 'danger',
          })
        }

        if (this.deleteSectionId !== null) {
          this.isSectionLoading[this.deleteSectionId].deleting = false
        }
      }
    },

    ////////////////////////////////////////////////////////////////////

    onSectionDragEnd(event: {
      newIndex: number
      oldIndex: number
      item: { _underlying_vm_: StorageFoldersPresenter }
    }) {
      const {
        newIndex,
        oldIndex,
        item: { _underlying_vm_ },
      } = event

      const sort_order = newIndex >= oldIndex ? newIndex + 1 : newIndex

      this.handleUpdateSection({
        id: _underlying_vm_.id as number,
        body: {
          sort_order,
        },
      })
    },

    ////////////////////////////////////////////////////////////////////

    handleToggleAddModalFilter(filter?: SectionsPresenter) {
      this.isAddContactFilter = !this.isAddContactFilter
      if (filter) {
        console.log({ filter })

        this.selectedSection = { ...filter }
        this.isUpdateFilter = true
      } else {
        this.selectedSection = {} as SectionsPresenter
        this.isUpdateFilter = false
      }
    },
  },

  mounted() {
    this.handleGetAllSections()
  },
  components: {
    draggable,
    ContactTemplateModal,
    ContactFilterModal,
  },
})
