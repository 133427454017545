import { defineComponent } from 'vue'
import ActionsBar from '@/components/actionsBar/index.vue'
import {
  addStageItem,
  deleteStageItem,
  getAllStages,
  getStageItems,
  updateStageItem,
} from '@/services/stages'
import { StagesPresenter } from '@/presenters/StagesPresenter'
import KanbanInfiniteLoad from '@/components/KanbanInfiniteLoad/index.vue'
import UpdateLayoutColumns from '@/components/UpdateLayoutColumns/index.vue'
import AddLeadModal from '@/components/Contacts/Leads/AddLeadModal/index.vue'
import SharedHeader from '@/components/sharedHeader/index.vue'
import { TableColumnInterface } from '@/Interfaces/Constants'
import { addLead, updateLead } from '@/services/leads'
import { AddUpdateLeadFormInterface } from '@/Interfaces/Services'
import { SectionsPresenter } from '@/presenters/SectionsPresenter'
import { useTabs } from '@/composables/useTabs'
import { LocationQueryValue } from 'vue-router'
import { getAllSections } from '@/services/sections'
import { TableDataExporter } from '@/constants/Tables'
import TabsBar from '@/components/tabsBar/index.vue'
import AllLeads from './AllLeads/index.vue'

const MoreVertIcon = new URL(
  '@/assets/icons/More-vert-icon.svg',
  import.meta.url,
).href

export default defineComponent({
  name: 'ContactsLeadsSection',

  data() {
    const sections = [] as SectionsPresenter[]
    const subSections = [] as SectionsPresenter[]

    const { activeTabId, activeTab } = useTabs(sections)
    return {
      MoreVertIcon,
      activeTabId,
      activeTab,

      headers: [] as TableColumnInterface[],

      sections,
      subSections,

      searchKeyword: '',
      debounceTimeout: null as ReturnType<typeof setTimeout> | null, // Holds the debounce timeout ID

      isStagesLoading: false,
      stages: [] as StagesPresenter[],
      section: null as StagesPresenter | null,

      isLeadsLoading: false,
      isUpdateLayoutLoading: false,
      isDeleteLayoutLoading: false,
      isSectionsLoading: false,
      loadingUpdate: false,

      isAddLeadLoading: false,
      isAddLeadForm: false,
      isShowUpdateLayoutModal: false,

      refetchAllLeadsTrigger: false,
    }
  },
  methods: {
    handleTicketMoved(data: any) {
      this.handleUpdateLeadsToStage(data.id, data)
    },

    async handleTabChange({ tab: section }: { tab: SectionsPresenter }) {
      // Update active section data
      this.activeTabId = section.id as number
      this.activeTab = section.name

      // Update the query parameter in the route
      this.$router.push({ query: { tab: section.name } })
    },

    ///////////////////////////////////////////////////////////////////////

    async handleGetAllSections() {
      this.isSectionsLoading = true
      const response = await getAllSections('leads')
      if (response.success) {
        this.sections = [...this.fixedSections, ...response.data?.list]

        this.subSections = response.data?.list

        this.activeTabId = this.sections[0]?.id
        this.activeTab = this.sections[0]?.name

        this.headers =
          this.sections[0]?.table_columns?.length > 0
            ? this.sections[0]?.table_columns
            : TableDataExporter.getTableColumns(
                this.sections[0]?.listing_filters?.contact_type as string,
              )
        // it should be in this function cuz its on mount
        const queryTab = this.$route.query.tab
        const foundTab = queryTab
          ? this.sections.find(
              (tab: { name: string | LocationQueryValue[] }) =>
                tab.name === queryTab,
            )
          : null

        if (foundTab) {
          this.activeTabId = foundTab.id
          this.activeTab = foundTab.name
        } else {
          this.activeTabId = this.sections[0]?.id as number
          this.activeTab = this.sections[0]?.name
        }

        this.activeTabId !== 1717171 &&
          this.handleGetStagesSection(this.activeTabId)
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.isSectionsLoading = false
    },
    ///////////////////////////////////////////////////////////////////////

    async handleGetLeadsByStage(activeTabId: number, id: number) {
      this.isLeadsLoading = true
      const response = await getStageItems(activeTabId, id)
      const tickets = response.data?.list
      const stage = this.stages?.find((s: StagesPresenter) => s.id === id)
      if (stage) {
        stage.section_stage_items = tickets
      }
      this.isLeadsLoading = false
    },

    async handleGetStagesSection(section_id: number) {
      this.isStagesLoading = true

      const response = await getAllStages(section_id)
      if (response.success) {
        this.stages = response.data?.list

        this.subSections = this.subSections.map((sub) =>
          sub.id === section_id ? { ...sub, section_stages: this.stages } : sub,
        )

        await Promise.all(
          this.stages.map((stage: StagesPresenter) =>
            this.handleGetLeadsByStage(section_id, stage.id),
          ),
        )
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }

      this.isStagesLoading = false
    },

    ////////////////////////////// layout modal /////////////////////////////////////////

    handleToggleUpdateLayoutModal() {
      this.isShowUpdateLayoutModal = !this.isShowUpdateLayoutModal
    },

    async handleUpdateLayout(body: StagesPresenter) {
      this.isUpdateLayoutLoading = true
      const { id, name, sort_order, color } = body

      let response
      if (id) {
        response = await updateStageItem(this.activeTabId, id, {
          name,
          sort_order,
          color,
        })
      } else {
        response = await addStageItem(this.activeTabId, {
          name,
          sort_order,
          color,
        })
      }

      if (response.success) {
        this.handleGetStagesSection(this.activeTabId)
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.isUpdateLayoutLoading = false
    },

    async handleDeleteLayout(stageId: number) {
      this.isDeleteLayoutLoading = true

      const response = await deleteStageItem(this.activeTabId, stageId)

      if (response.success) {
        this.handleGetStagesSection(this.activeTabId)
        this.$vaToast.init({
          message: 'Stage Delete Successfully',
          color: 'success',
        })
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }

      this.isDeleteLayoutLoading = false
    },

    /////////////////////////////////// modal actions ////////////////////////////////

    toggleAddLeadForm(section?: StagesPresenter) {
      this.isAddLeadForm = !this.isAddLeadForm
      this.section = section ?? null
    },

    async handleCreateLead(data: AddUpdateLeadFormInterface) {
      this.isAddLeadLoading = true
      const response = await addLead(data)
      if (response.success) {
        this.$vaToast.init({
          message: 'Lead created successfully!',
          color: 'success',
        })
        if (this.activeTabId === 1717171) {
          this.refetchAllLeadsTrigger = !this.refetchAllLeadsTrigger
        } else {
          const stageId =
            response.data?.section_stage_items[0]?.section_stage_id
          this.handleGetLeadsByStage(this.activeTabId, stageId)
        }
        this.isAddLeadForm = false
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.isAddLeadLoading = false
    },

    async handleUpdateLeadsToStage(id: number, data: any) {
      this.loadingUpdate = true
      const response = await updateLead(id, data)

      if (!response.success) {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.loadingUpdate = false
    },
  },

  computed: {
    fixedSections() {
      return [
        {
          id: 1717171,
          name: 'All Leads',
          listing_filters: {
            contact_type: 'Leads',
          },
          table_columns:
            this.$stores.generalData.companyConfigs.leads_all_table_columns?.[0]
              .data.columns || [],
        },
      ]
    },
  },

  watch: {
    '$route.query.tab': async function (newTab) {
      const theFoundTab = this.sections.find((tab) => tab.name === newTab)

      if (theFoundTab?.id === 1717171) {
        this.activeTabId = theFoundTab.id as number
        this.activeTab = theFoundTab.name
        return
      }

      if (theFoundTab) {
        this.activeTabId = theFoundTab.id as number
        this.activeTab = theFoundTab.name
        this.stages = []
        this.handleGetStagesSection(this.activeTabId)
      }
    },
  },
  mounted() {
    this.handleGetAllSections()
  },
  components: {
    TabsBar,
    ActionsBar,
    KanbanInfiniteLoad,
    UpdateLayoutColumns,
    AddLeadModal,
    SharedHeader,
    AllLeads,
  },
})
