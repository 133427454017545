export const matterTemplateHeaders = [
  { title: 'Id', align: 'start', key: 'id', sortable: false },
  {
    title: 'Matter Name',
    align: 'start',
    key: 'name',
    sortable: false,
  },
  { title: 'Status', align: 'start', key: 'status', sortable: false },
  { title: 'Client', align: 'start', key: 'client', sortable: false },
  {
    title: 'Lead Lawyers',
    align: 'start',
    key: 'leadLawyers',
    sortable: false,
  },
  { title: 'Lawyers', align: 'start', key: 'lawyers', sortable: false },
  {
    title: 'Jurisdiction',
    align: 'starts',
    key: 'jurisdiction',
    sortable: false,
  },
  {
    title: 'Specialization',
    align: 'starts',
    key: 'specialization',
    sortable: false,
  },
  {
    title: 'Category',
    align: 'starts',
    key: 'category',
    sortable: false,
  },
  { title: 'Stage', align: 'starts', key: 'stage', sortable: false },
  {
    title: 'Created date',
    align: 'starts',
    key: 'created_at',
    sortable: false,
  },
  {
    title: 'Modified Date',
    align: 'starts',
    key: 'modifiedDate',
    sortable: false,
  },
  { title: 'City', align: 'starts', key: 'region', sortable: false },
  {
    title: 'Latest Notes',
    align: 'starts',
    key: 'latestNotes',
    sortable: false,
  },
  { title: 'Opt', align: 'center', key: 'action', sortable: false },
]
