<template>
  <div class="container">
    <h1>Update Forms</h1>

    <VaButton
      v-for="(section, index) in sections"
      :key="index"
      class="options"
      preset="secondary"
      border-color="primary"
      @click="handleToggleModalTemp(section)"
    >
      {{ section.name }}
    </VaButton>
  </div>

  <ContactTemplateModal
    @handleToggleAddModalTemp="handleToggleModalTemp()"
    @handleUpdateTemplate="handleUpdateSection"
    :addContactTempLoading="isTempLoading"
    :isAddContactTemp="isUpdateTempLoading"
    :selectedTemplate="selectedTemplate"
    :isUpdateTemplate="true"
  />
</template>
<script lang="ts" src="./script.ts" />
<style lang="scss" scoped src="./styles.scss" />
