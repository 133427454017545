import SideBar from '@/components/SideBar/index.vue'
import ChatComponent from '@/components/chat/index.vue'
import ChatActivityPanel from '@/components/ChatActivityPanel/index.vue'
import NotificationModal from '@/components/NotificationModal/index.vue'
import TimeRecordModal from '@/components/TimeRecordModal/index.vue'
import { defineComponent } from 'vue'
import { useRouter } from 'vue-router'
import { generalDataStore } from '@/stores/generalData'
import { calendarGlobalSearchInterface } from '@/Interfaces/Store'
import { camelCaseToNormal, storeUserData } from '@/lib/utils'
import { useGlobalSearch } from '@/stores/globalSearch'
import { useAuthStore } from '@/stores/auth'
import {
  getNotifications,
  getNotificationsCount,
  markNotificationAsViewed,
} from '@/services/notifications'
import { getNotificationsInterface } from '@/Interfaces/Services'
import { NotificationPresenter } from '@/presenters/NotificationPresenter'
import { getUserInfo } from '@/services/users'
import { chatActivityRoutesMap } from '@/services/versions'

interface SearchOption {
  title?: string

  icon?: string
  path_to: string
}

const arabicImage = new URL('../../src/assets/icons/saudi.png', import.meta.url)
  .href
const englishImage = new URL('../../src/assets/icons/usa.png', import.meta.url)
  .href
const logoImage = new URL('../../src/assets/icons/logo.svg', import.meta.url)
  .href
const teamsImage = new URL(
  '../../src/assets/icons/team-icon.svg',
  import.meta.url,
).href
const verifiedImage = new URL(
  '../../src/assets/icons/verified-icon.svg',
  import.meta.url,
).href
const zoomImage = new URL(
  '../../src/assets/icons/zoom-icon.svg',
  import.meta.url,
).href
const frenchImage = new URL(
  '../../src/assets/icons/france.png',
  import.meta.url,
).href

export default defineComponent({
  name: 'DefaultLayout',
  data() {
    const router = useRouter()

    const { user } = useAuthStore()

    const generalDataStores = generalDataStore()
    const useGlobalSearches = useGlobalSearch()

    return {
      user,
      generalDataStores,
      useGlobalSearches,
      camelCaseToNormal,
      lang: localStorage.getItem('locale') || 'en',
      router,
      arabicImage,
      englishImage,
      frenchImage,
      logoImage,
      teamsImage,
      zoomImage,
      verifiedImage,
      changeLanguage: false,

      notificationNumber: 0,
      notificationNumberClassification: {} as Record<string, number>,
      notificationLoading: false,
      notifications: [] as NotificationPresenter[],

      items: [
        { title: 'Dashboard', icon: 'dashboard' },
        { title: 'Sidebar demo', icon: 'room', active: true },
        { title: 'Loop', icon: 'loop' },
      ],

      minimized: false,

      searchQuery: null as string | null,
      globalSearchOptions: [] as calendarGlobalSearchInterface[],
      debounceTimeout: null as ReturnType<typeof setTimeout> | null,

      noDataText: 'No data found',
    }
  },
  methods: {
    selectLanguage(lang: string) {
      if (lang === this.lang) return
      localStorage.setItem('locale', lang)
      window.location.reload()
    },

    handleSideBarToggle(toggle: boolean) {
      this.minimized = toggle
    },
    ///////////////////////////////// global variables /////////////////////////////////
    async handleGetCompanyConfigurations() {
      this.generalDataStores.handleGetCompanyConfigurations()
    },
    async handleGetGeneralData() {
      this.generalDataStores.handleGetGeneralData()
    },
    ////////////////////////////////////////////////
    handleSearchGlobal(search: string) {
      // noDataText(text: string | null) {
      //   // return this.$t('noData') as string
      //   return `${text} is not found...`
      // },

      this.noDataText = search ? `${search} is not found...` : 'No data found'

      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout)
      }

      this.debounceTimeout = setTimeout(() => {
        try {
          this.useGlobalSearches.handleGlobalSearch(search)
        } catch (error) {
          console.error('Search error:', error)
          this.globalSearchOptions = []
        }
      }, 500)
    },

    /////////////////////////////////////////////////////////////////////

    handleOpenGlobalSearch() {
      this.$stores.globalSearch.handleGlobalSearch()
    },
    handleChooseOption(option: SearchOption) {
      console.log('Selected option:', option)
      if (option) {
        this.$router.push(option?.path_to)
      }
      this.searchQuery = null
    },

    ////////////////////////////////// Notifications ///////////////////////////

    async handleGetNotificationsCount() {
      const response = await getNotificationsCount()
      if (response.success) {
        this.notificationNumberClassification = response.data

        this.notificationNumber = Object.values(
          this.notificationNumberClassification,
        ).reduce((sum: number, value: number) => sum + value, 0)
      }
    },

    async handleMarkNotificationAsViewed(
      notification_group_type: getNotificationsInterface['notification_group_type'],
    ) {
      const response = await markNotificationAsViewed({
        notification_group_type,
      })
      if (response.success) {
        this.handleGetNotificationsCount()
      }
    },

    async handleGetNotification(
      notification_group_type: getNotificationsInterface['notification_group_type'],
    ) {
      this.notificationLoading = true

      const response = await getNotifications({ notification_group_type })

      if (response.success) {
        this.notifications = response.data?.list
        if (this.notifications?.[0]?.viewed_at === null) {
          this.handleMarkNotificationAsViewed(notification_group_type)
        }
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }

      this.notificationLoading = false
    },

    async updateUsersData() {
      const response = await getUserInfo()
      if (response.success) {
        storeUserData(response.data)
      }
    },
  },
  computed: {
    currentLanguage(): string {
      if (this.lang === 'ar') return 'العربية'
      if (this.lang === 'en') return 'English'
      if (this.lang === 'fr') return 'Français'
      return 'English'
    },
    currentFlag(): string {
      if (this.lang === 'ar') return this.arabicImage
      if (this.lang === 'en') return this.englishImage
      if (this.lang === 'fr') return this.frenchImage
      return this.englishImage
    },

    itemProps() {
      return (item: SearchOption) => ({
        title: item.title,
        icon: item.icon,
      })
    },

    shouldShowButtons() {
      return chatActivityRoutesMap[String(this.$route.name)] !== undefined
      // return !!this.$route.params.id
    },
  },

  watch: {
    '$stores.auth.user': {
      handler(data) {
        this.user = data
      },
      deep: true,
      immediate: true,
    },
    '$stores.globalSearch.calendarGlobalSearch': {
      handler(data) {
        this.globalSearchOptions = Array.isArray(data)
          ? data.map((item) => ({
              ...item,
              // Add any necessary transformations
              title: item.name || item.fullName || 'Unnamed Item',
              value: {
                ...item,
                icon: item.icon,
              },
            }))
          : []
      },
      deep: true,
      immediate: true,
    },
    '$route.path': {
      handler() {
        this.handleGetNotificationsCount()
      },
    },
  },

  mounted() {
    this.updateUsersData()
    this.handleGetCompanyConfigurations()
    this.handleGetNotificationsCount()

    // this.handleGetGeneralData();
    //   this.handleGetGeneralData();

    // this.$stores.globalSearch.handleGlobalSearch()
  },
  components: {
    ChatComponent,
    SideBar,
    NotificationModal,
    TimeRecordModal,
    ChatActivityPanel,
  },
})
