import { calendarItemsFormInterface } from '@/Interfaces/layout'
import {
  addSecondsToISOTime,
  camelCaseToNormal,
  deepCopy,
  formatDateRange,
  formatTimeSeconds,
} from '@/lib/utils'
import { useTimerStore } from '@/stores/timer'
import { defineComponent } from 'vue'
import DatePickerModal from '@/components/DatePickerModal/index.vue'
import TypeSelectModal from '@/components/TimeRecordModal/TypeSelectModal/index.vue'
import { CalendarItemsPresenter } from '@/presenters/calendarItemsPresenter'
import { CompanyConfigurationPresenter } from '@/presenters/companyConfigPresenters'

const stopImage = new URL('@/assets/icons/stop-icon.svg', import.meta.url).href
const playImage = new URL('@/assets/icons/play-icon.svg', import.meta.url).href

type timeRangeInterface = {
  hours: string | number
  minutes: string | number
}

export default defineComponent({
  name: 'ForItemTimeRecordModal',
  components: {
    DatePickerModal,
    TypeSelectModal,
  },
  props: {
    startRecordLoading: {
      type: Boolean,
      default: false,
    },
    loadingStates: {
      type: Array,
      required: true,
      default: () => [],
    },
    deleteRecordLoading: {
      type: Boolean,
      default: false,
    },
    updateRecordLoading: {
      type: Boolean,
      default: false,
    },
    manualFormLoading: {
      type: Boolean,
      default: false,
    },
    calendarItemsRangeFormLoading: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'handleStartEndTime',
    'handleStartEndRecord',
    'handleDeleteRecord',
    'handleUpdateRecord',
    'handleCreateManual',
    'handleAddTimeRange',
  ],
  data() {
    const manualForm = {
      start_time: new Date().toISOString(),
      end_time: new Date(new Date().getTime() + 60 * 1000).toISOString(),
    } as calendarItemsFormInterface

    return {
      timerStore: useTimerStore(),
      user: this.$stores.auth.user,

      formatDateRange,
      camelCaseToNormal,
      deepCopy,
      addSecondsToISOTime,
      formatTimeSeconds,

      stopImage,
      playImage,

      timerTabs: ['Manual', 'Range'],
      timerTab: 'Manual',

      isStartTimer: false,
      isItemAssignManual: false,

      showRecordOption: new Map<number, boolean>(),

      selectedRecord: {
        start_time: new Date().toISOString(),
        end_time: new Date(new Date().getTime() + 60 * 1000).toISOString(),
      } as calendarItemsFormInterface,

      manualForm,

      isItemTypeSelect: false,

      calendarItemsRangeForm: {} as calendarItemsFormInterface,
      timeRangeHours: null as number | null,

      timeRange: {
        hours: 0,
        minutes: 0,
      } as timeRangeInterface,

      timeRangeUpdate: {
        hours: 0,
        minutes: 0,
      } as timeRangeInterface,
    }
  },
  computed: {
    showRecordOptionAsObject(): Record<number, boolean> {
      return Object.fromEntries(this.showRecordOption)
    },
    isTimeRecord(): boolean {
      return this.timerStore?.specificCalendarItems[0]?.end_time !== null
    },
  },
  watch: {
    deleteRecordLoading: {
      handler(data) {
        !data && this.closeAllMenus()
      },
    },
    manualFormLoading: {
      handler(data) {
        if (!data) {
          this.manualForm = {
            start_time: new Date().toISOString(),
            end_time: new Date(new Date().getTime() + 60 * 1000).toISOString(),
          }
        }
      },
    },
    calendarItemsRangeFormLoading: {
      handler(data) {
        if (!data) {
          this.calendarItemsRangeForm = {} as calendarItemsFormInterface
          this.timeRange = {
            hours: 0,
            minutes: 0,
          } as timeRangeInterface
        }
      },
    },
  },
  methods: {
    handleStartEndTime(start: boolean) {
      this.$emit('handleStartEndTime', start)
    },
    handleStartEndRecord(record: CalendarItemsPresenter, index: number) {
      this.$emit('handleStartEndRecord', { record, index })
    },
    handleDeleteRecord() {
      this.$emit('handleDeleteRecord', this.selectedRecord.id)
    },
    handleUpdateRecord() {
      this.$emit('handleUpdateRecord', this.selectedRecord)
    },
    handleCreateManual() {
      this.$emit('handleCreateManual', this.manualForm)
    },

    ///////////////////////////////////////////////////////////////////////////////////

    handleUpdateStartDate(date: { date: string }) {
      this.selectedRecord.start_time = date.date
    },
    handleUpdateEndDate(date: { date: string }) {
      this.selectedRecord.end_time = date.date
    },

    handleUpdateStartDateManual(date: { date: string }) {
      this.manualForm.start_time = date.date
    },
    handleUpdateEndDateManual(date: { date: string }) {
      this.manualForm.end_time = date.date
    },

    ///////////////////////////////////////////////////////////////////////////////////

    toggleMenu(index: number, record: CalendarItemsPresenter) {
      this.selectedRecord = deepCopy(record)

      if (this.selectedRecord.time_range) {
        const seconds = this.selectedRecord.total_time_spent_seconds

        const hours = Math.floor(Number(seconds) / 3600)
        const minutes = Math.floor((Number(seconds) % 3600) / 60)

        this.timeRangeUpdate.hours = hours
        this.timeRangeUpdate.minutes = minutes
      }

      // Close all other menus
      this.showRecordOption?.forEach((_, key) => {
        this.showRecordOption.set(key, false)
      })

      // Toggle the selected menu
      const currentState = this.showRecordOption.get(index) || false
      this.showRecordOption.set(index, !currentState)
    },

    closeAllMenus() {
      this.showRecordOption?.forEach((_, key) => {
        this.showRecordOption.set(key, false)
      })
      this.selectedRecord = {
        start_time: new Date().toISOString(),
        end_time: new Date(new Date().getTime() + 60 * 1000).toISOString(),
      }
    },

    ///////////////////////////////////////////////////////////////////////////////////

    handleAssignType(type: CompanyConfigurationPresenter) {
      this.selectedRecord.item_type = type
      this.selectedRecord.item_type_id = type?.id

      this.isItemTypeSelect = !this.isItemTypeSelect
    },

    /////////////////////////////////////////////////////////////////////////////////////

    handleRangeTimeToSec() {
      let { hours, minutes } = this.timeRange

      hours = Number(hours) || 0
      minutes = Number(minutes) || 0

      // both = 0 => minutes = 1
      const adjustedHours = hours >= 0 ? hours : 0
      const adjustedMinutes = minutes > 0 || hours > 0 ? minutes : 1

      //  time in seconds
      if (adjustedHours >= 0 && adjustedMinutes >= 0) {
        const totalSeconds = adjustedHours * 3600 + adjustedMinutes * 60

        this.calendarItemsRangeForm.total_time_spent_seconds = totalSeconds
        this.calendarItemsRangeForm.time_range = true

        // Set start_time to midnight UTC
        const today = new Date()
        today.setUTCHours(0, 0, 0, 0)
        this.calendarItemsRangeForm.start_time = today.toISOString()

        this.calendarItemsRangeForm.end_time = addSecondsToISOTime(
          this.calendarItemsRangeForm.start_time,
          totalSeconds,
        )
      }
    },

    handleRangeTimeUpdateToSec() {
      let { hours, minutes } = this.timeRangeUpdate

      hours = Number(hours) || 0
      minutes = Number(minutes) || 0

      // both = 0 => minutes = 1
      const adjustedHours = hours >= 0 ? hours : 0
      const adjustedMinutes = minutes > 0 || hours > 0 ? minutes : 1

      //  time in seconds
      if (adjustedHours >= 0 && adjustedMinutes >= 0) {
        const totalSeconds = adjustedHours * 3600 + adjustedMinutes * 60

        this.selectedRecord.total_time_spent_seconds = totalSeconds
        this.selectedRecord.time_range = true

        // Set start_time to midnight UTC
        const today = new Date()
        today.setUTCHours(0, 0, 0, 0)
        this.selectedRecord.start_time = today.toISOString()

        this.selectedRecord.end_time = addSecondsToISOTime(
          this.selectedRecord.start_time,
          totalSeconds,
        )
      }
    },

    handleAddTimeRange() {
      const { calendar_item_linked_items, ...calendarItemsRangeForm } =
        this.calendarItemsRangeForm
      console.log({ calendar_item_linked_items })

      this.$emit('handleAddTimeRange', {
        ...calendarItemsRangeForm,
      })
    },
  },
})
