<template>
  <VaModal
    v-model="isTimeZoneBool"
    size="small"
    hide-default-actions
    fixed-layout
    no-outside-dismiss
    @update:modelValue="handleToggleTimeZone"
  >
    <template #header>
      <div class="global-config-header">
        <h1>Choose Time Zone</h1>
        <VaIcon
          class="header-icon press-button"
          name="close"
          size="30px"
          @click="handleToggleTimeZone"
        />
      </div>

      <div class="search">
        <VaSelect
          v-model="selectedTimeZone"
          placeholder="Select Time Zone"
          :options="filteredTimezones"
          searchable
          :text-by="getOptionTimeZone"
          :highlight-matched-text="false"
          @update:modelValue="handleAddTimeZone"
        />
      </div>
    </template>

    <div class="selected-zones">
      <h2>Selected Time Zones</h2>
      <div v-if="selectedZones.length === 0" class="empty">
        <p>No time zones selected yet.</p>
      </div>
      <div
        v-for="(zone, index) in selectedZones"
        :key="index"
        class="zone-item"
      >
        <p>{{ zone.country }} ({{ zone.abbreviation }})</p>
        <VaIcon
          name="delete"
          size="20px"
          class="remove-icon"
          @click="handleRemoveZone(zone)"
        />
      </div>
    </div>

    <template #footer>
      <div class="global-config-buttons">
        <VaButton
          preset="secondary"
          border-color="primary"
          @click="handleToggleTimeZone"
        >
          Cancel
        </VaButton>
        <VaButton
          @click="handleSaveSelectedZones"
          :loading="$stores.calendar.zoneLoading"
        >
          Done
        </VaButton>
      </div>
    </template>
  </VaModal>
</template>

<script lang="ts" src="./script.ts" />
<style lang="scss" scoped src="./styles.scss" />
