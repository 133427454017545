<template>
  <VaModal
    v-model="isAddLeadFormBool"
    size="auto"
    hide-default-actions
    fixed-layout
    no-outside-dismiss
    @update:modelValue="toggleAddLeadForm"
  >
    <template #header>
      <div class="create-form-header">
        <h1>Add Leads</h1>
        <div>
          <VaSelect
            v-model="subSection"
            background="#ECF0F1"
            :options="stages"
            :text-by="getOptionName"
            placeholder="Select Stage"
          />
        </div>
        <VaIcon
          class="header-icon press-button"
          name="close"
          size="30px"
          @click="toggleAddLeadForm"
        />
      </div>
    </template>

    <DynamicForm
      :fields="formFields"
      :initial-values="form"
      :toggleSubmit="toggleSubmit"
      @submit="handleCreateLead"
    />

    <template #footer>
      <div class="form-modal-buttons">
        <VaButton
          preset="secondary"
          border-color="primary"
          @click="toggleAddLeadForm"
        >
          Cancel
        </VaButton>
        <VaButton :loading="isAddLeadLoading" @click="handleToggleSubmit()">
          Save
        </VaButton>
      </div>
    </template>
  </VaModal>
</template>

<script lang="ts" src="./script.ts" />
<style lang="scss" scoped src="./styles.scss" />
