import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-bf621fe6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "sub-SharedHeader" }
const _hoisted_2 = { class: "scroll-container inherit-height" }
const _hoisted_3 = { class: "form-modal-row" }
const _hoisted_4 = { class: "form-modal-row" }
const _hoisted_5 = { class: "form-modal-row" }
const _hoisted_6 = { class: "form-modal-row" }
const _hoisted_7 = { class: "form-modal-row" }
const _hoisted_8 = { class: "form-modal-row" }
const _hoisted_9 = { class: "form-modal-row" }
const _hoisted_10 = { class: "form-modal-row" }
const _hoisted_11 = { class: "form-modal-row" }
const _hoisted_12 = { class: "action-buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VaButton = _resolveComponent("VaButton")!
  const _component_SharedHeader = _resolveComponent("SharedHeader")!
  const _component_TabsBar = _resolveComponent("TabsBar")!
  const _component_VaSelect = _resolveComponent("VaSelect")!
  const _component_VaInput = _resolveComponent("VaInput")!
  const _component_VaDateInput = _resolveComponent("VaDateInput")!
  const _component_VaForm = _resolveComponent("VaForm")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_SharedHeader, {
      "back-button": true,
      "title-width": "30rem",
      title: _ctx.form.title
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_VaButton, {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.form.won = true)),
            color: _ctx.form.won ? 'success' : '#808080',
            gradient: ""
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Won ")
            ]),
            _: 1
          }, 8, ["color"]),
          _createVNode(_component_VaButton, {
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.form.won = false)),
            color: !_ctx.form.won ? 'danger' : '#808080',
            gradient: ""
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Loss ")
            ]),
            _: 1
          }, 8, ["color"])
        ])
      ]),
      _: 1
    }, 8, ["title"]),
    _createVNode(_component_TabsBar, {
      tabs: _ctx.tabs,
      "active-tab-id": _ctx.activeTabId,
      onChangeTab: _ctx.changeTab
    }, null, 8, ["tabs", "active-tab-id", "onChangeTab"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_VaForm, {
        ref: "form",
        class: "form-modal",
        onSubmit: _cache[13] || (_cache[13] = _withModifiers(($event: any) => (_ctx.validate() && _ctx.handleUpdateLead()), ["prevent"]))
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_VaSelect, {
              modelValue: _ctx.form.individual_contact,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.form.individual_contact) = $event)),
              placeholder: "Contact",
              background: "#ECF0F1",
              label: "Contact",
              searchable: "",
              clearable: "",
              "highlight-matched-text": false,
              options: _ctx.$stores.contacts.contacts,
              "text-by": _ctx.getOptionFullName,
              loading: _ctx.$stores.contacts.contactsLoading,
              "onUpdate:search": _ctx.$stores.contacts.handleSearchContacts,
              "track-by": "id"
            }, null, 8, ["modelValue", "options", "text-by", "loading", "onUpdate:search"])
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_VaSelect, {
              modelValue: _ctx.form.organization_contact,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.form.organization_contact) = $event)),
              placeholder: "Organization",
              background: "#ECF0F1",
              label: "Organization",
              searchable: "",
              clearable: "",
              "highlight-matched-text": false,
              options: _ctx.$stores.contacts.organizations,
              "text-by": _ctx.getOptionTruncateName50,
              loading: _ctx.$stores.contacts.organizationsLoading,
              "onUpdate:search": _ctx.$stores.contacts.handleSearchOrganizations,
              "track-by": "id"
            }, null, 8, ["modelValue", "options", "text-by", "loading", "onUpdate:search"])
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_VaInput, {
              modelValue: _ctx.form.title,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.form.title) = $event)),
              placeholder: "Title",
              background: "#ECF0F1",
              label: "Title"
            }, null, 8, ["modelValue"])
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_VaInput, {
              modelValue: _ctx.form.lead_value,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.form.lead_value) = $event)),
              placeholder: "Value",
              background: "#ECF0F1",
              label: "Value",
              type: "number"
            }, null, 8, ["modelValue"]),
            _createVNode(_component_VaSelect, {
              modelValue: _ctx.form.currency,
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.form.currency) = $event)),
              label: "main currency",
              background: "#ECF0F1",
              options: _ctx.$stores.currency.currencies,
              "text-by": _ctx.getOptionCurrency,
              loading: _ctx.$stores.currency.currencyLoading,
              "track-by": "id"
            }, null, 8, ["modelValue", "options", "text-by", "loading"])
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_VaSelect, {
              modelValue: _ctx.form.stage,
              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.form.stage) = $event)),
              placeholder: "Stage",
              background: "#ECF0F1",
              label: "Stage",
              options: _ctx.stages,
              "highlight-matched-text": false,
              "text-by": _ctx.getOptionName,
              loading: _ctx.isStagesLoading,
              "track-by": "id"
            }, null, 8, ["modelValue", "options", "text-by", "loading"])
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createVNode(_component_VaSelect, {
              modelValue: _ctx.form.label,
              "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.form.label) = $event)),
              label: "Label",
              background: "#ECF0F1",
              options: _ctx.$stores.generalData.companyConfigs.lead_label,
              "text-by": _ctx.getOptionName,
              "track-by": "id"
            }, null, 8, ["modelValue", "options", "text-by"])
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createVNode(_component_VaInput, {
              modelValue: _ctx.form.probability,
              "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.form.probability) = $event)),
              placeholder: "Probability",
              background: "#ECF0F1",
              label: "Probability",
              type: "number",
              min: "0",
              max: "100",
              rules: [_ctx.validateProbability]
            }, null, 8, ["modelValue", "rules"])
          ]),
          _createElementVNode("div", _hoisted_10, [
            _createVNode(_component_VaDateInput, {
              modelValue: _ctx.form.expected_close_date,
              "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.form.expected_close_date) = $event)),
              placeholder: "MM/DD/YYYY",
              label: "expected close date",
              background: "#ECF0F1",
              "manual-input": ""
            }, null, 8, ["modelValue"])
          ]),
          _createElementVNode("div", _hoisted_11, [
            _createVNode(_component_VaSelect, {
              modelValue: _ctx.form.relationship_owner,
              "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.form.relationship_owner) = $event)),
              placeholder: "Relationship Owner",
              background: "#ECF0F1",
              label: "Relationship Owner",
              searchable: "",
              options: _ctx.$stores.usersData.teamMembers,
              "text-by": _ctx.getOptionFullName,
              "highlight-matched-text": false,
              loading: _ctx.$stores.usersData.membersLoading,
              "onUpdate:search": _ctx.$stores.usersData.handleSearchMembers,
              "track-by": "id"
            }, null, 8, ["modelValue", "options", "text-by", "loading", "onUpdate:search"])
          ]),
          _createElementVNode("div", _hoisted_12, [
            _createVNode(_component_VaButton, {
              loading: _ctx.isUpdateLoading,
              preset: "primary",
              onClick: _cache[12] || (_cache[12] = ($event: any) => (_ctx.validate() && _ctx.handleUpdateLead()))
            }, {
              default: _withCtx(() => [
                _createTextVNode(" Update ")
              ]),
              _: 1
            }, 8, ["loading"])
          ])
        ]),
        _: 1
      }, 512)
    ])
  ], 64))
}