import { defineComponent } from 'vue'
import ContactTemplateModal from './ContactTemplateModal/index.vue'
import { getFormColumns } from '@/lib/utils'
import { CompanyConfigurationPresenter } from '@/presenters/companyConfigPresenters'
import { updateCompanyConfiguration } from '@/services/companyConfigurations'

export default defineComponent({
  name: '',
  props: {},
  data() {
    return {
      sections: [
        {
          name: 'People',
          type: 'individual_contact_form_fields',
        },
        {
          name: 'Organizations',
          type: 'organization_contact_form_fields',
        },
      ],
      isTempLoading: false,
      isUpdateTempLoading: false,
      selectedTemplate: {},
      isUpdateGlobalConfigLoading: false,
    }
  },
  emits: [],
  methods: {
    handleToggleModalTemp(section?: { name: string; type: string }) {
      this.isUpdateTempLoading = !this.isUpdateTempLoading

      if (section?.type) {
        console.log({
          form_columns: getFormColumns(
            this.$stores.generalData.companyConfigs[
              section.type as
                | 'individual_contact_form_fields'
                | 'organization_contact_form_fields'
            ]?.[0]?.data.fields,
            'contacts',
          ),
          name: section?.name,
          type: section?.type,
        })

        this.selectedTemplate = {
          form_columns: getFormColumns(
            this.$stores.generalData.companyConfigs[
              section.type as
                | 'individual_contact_form_fields'
                | 'organization_contact_form_fields'
            ]?.[0]?.data.fields,
            'contacts',
          ),
          name: section?.name,
          type: section?.type,
        }
      }
    },

    async handleUpdateSection(config: any) {
      this.isTempLoading = true

      const selectedSection =
        this.$stores.generalData.companyConfigs[
          config.type as
            | 'individual_contact_form_fields'
            | 'organization_contact_form_fields'
        ]?.[0]

      const response = await updateCompanyConfiguration({
        ...selectedSection,
        data: { fields: config.form_columns },
      } as CompanyConfigurationPresenter)

      if (response.success) {
        this.$stores.generalData.handleGetCompanyConfigurations()
        this.handleToggleModalTemp()
      } else {
        this.$vaToast.init({
          message: response.message,
          color: 'danger',
        })
      }
      this.isTempLoading = false
    },
  },

  components: {
    ContactTemplateModal,
  },
})
