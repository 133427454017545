import { defineComponent } from 'vue'
import TabsBar from '@/components/tabsBar/index.vue'
import ComingSoon from '@/components/ComingSoon/index.vue'
import ContactsToolbar from './ContactsToolbar/index.vue'
import ContactsTables from './ContactsTables/index.vue'
import ContactsForms from './ContactsForms/index.vue'

export default defineComponent({
  name: 'ContactsSettings',
  components: {
    TabsBar,
    ComingSoon,
    ContactsToolbar,
    ContactsTables,
    ContactsForms,
  },
  props: {},
  emits: [],
  data() {
    const TABS = [
      { id: 0, name: 'TOOLBAR' },
      { id: 1, name: 'GLOBAL CONTACTS' },
      { id: 2, name: 'CONTACTS FORMS' },
      { id: 3, name: 'CONTACTS TABLES' },
    ]

    return {
      TABS,
      activeTabId: 0,
      activeTab: 'TOOLBAR',
    }
  },
  methods: {
    changeTab({ tab }: { tab: { title?: string; name: string; id: number } }) {
      this.activeTabId = tab.id
      this.activeTab = tab.name
    },
  },
})
