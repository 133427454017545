<template>
  <!-- Sidebar with minimize functionality -->
  <VaSidebar
    :minimized="minimized"
    color="#fff"
    animated
    width="18rem"
    class="sidebar"
  >
    <div v-if="!minimized" class="all-workspaces">
      <div class="workspaces-info-container">
        <v-menu
          v-model="showWorkSpaces"
          :close-on-content-click="false"
          location="bottom center"
        >
          <template #activator="{ props }">
            <div class="workspaces-info">
              <div class="workspace">
                <VaAvatar
                  class="avatar"
                  color="#F5F0F3"
                  square
                  :src="company?.avatar?.medium_url"
                  size="3.5rem"
                  font-size="1rem"
                >
                  <div v-if="!company?.avatar?.medium_url">
                    <span>
                      {{ company.company.name[0] }}
                    </span>
                  </div>
                </VaAvatar>
                <div class="workspace-data">
                  <h2>{{ company?.company?.name }}</h2>
                  <p>Law Firm</p>
                  <p class="online-members">
                    {{ company.company.company_users_count }}/{{
                      company.company.company_users_count
                    }}
                    online
                  </p>
                  <p class="press-button" v-bind="props">Change Workspace</p>
                </div>
              </div>
            </div>
            <!-- <div class="img-container">
              <img
                v-bind="props"
                :src="currentWorkspaceImage"
                alt="Logo"
                class="open-workspaces-btn"
              />
            </div> -->
          </template>

          <!-- This is the content that will appear inside the menu -->
          <v-card width="15rem">
            <div class="workspaces-list">
              <p>SWITCH WORKPLACES</p>
              <ul>
                <li
                  v-for="(company, index) in user.company_users"
                  :key="index"
                  @click="handleChangeWorkspace(company)"
                >
                  <div class="workspace">
                    <!-- <img src="https://i.pravatar.cc/300" alt="Logo" /> -->
                    <VaAvatar
                      class="avatar"
                      color="#F5F0F3"
                      square
                      :src="company?.avatar?.medium_url"
                    >
                      <div v-if="!company?.avatar?.medium_url">
                        <span>
                          {{ company.company.name[0] }}
                        </span>
                      </div>
                    </VaAvatar>
                    <div class="workspace-data">
                      <h2>{{ company.company.name }}</h2>
                      <!-- <p>Swiss Verein</p> -->
                    </div>
                  </div>
                  <span class="online-members"
                    >{{ company.company.company_users_count }} of
                    {{ company.company.company_users_count }} members
                    online</span
                  >
                </li>
                <VaDivider />
                <li @click="showWorkSpaces = false">
                  <div class="workspace">
                    <img :src="BATImage" alt="Logo" />
                    <div class="workspace-data">
                      <h2>North Lebanon Bar Association</h2>
                      <p>Lebanon</p>
                    </div>
                  </div>
                  <span class="online-members">1 0f 1 members online</span>
                </li>
              </ul>
              <VaButton
                icon="add"
                class="mx-2 px-3 py-1"
                border-radius="20px"
                size="small"
              >
                Add New Workspace
              </VaButton>
            </div>
          </v-card>
        </v-menu>

        <div class="toggle expand-toggle">
          <router-link to="/settings">
            <VaIcon color="primary" name="settings" class="press-button" />
          </router-link>
          <img
            v-if="!minimized"
            :src="currentSideBarImage"
            alt="close"
            class="press-button"
            @click="handleSideBarToggle"
          />
        </div>
      </div>

      <div class="workspace-divider">
        <VaDivider />
      </div>

      <!-- <div class="img-container">
              <img v-if="!isWorkspacesOpen" :src="currentWorkspaceImage" alt="Logo" class="open-workspaces-btn"
                 />
            </div> -->
    </div>

    <div class="user user-collapsed" :class="{ hide: !minimized }">
      <VaAvatar
        class="avatar"
        color="#F5F0F3"
        :src="company?.avatar?.medium_url"
      >
        <div v-if="!company?.avatar?.medium_url">
          <span>
            {{ company.company.name[0] }}
          </span>
        </div>
      </VaAvatar>
    </div>

    <div class="toggle collapsed-toggle">
      <img
        v-if="minimized"
        :src="currentSideBarImage"
        alt="open"
        class="press-button"
        @click="handleSideBarToggle"
      />
    </div>

    <div v-for="view in views" :key="view.name">
      <div class="link-container" v-if="view.view">
        <VaSidebarItem
          hover-color="rgba(0, 44, 108, 0.1)"
          :to="view.path"
          active-color="rgba(0, 44, 108, 0.1)"
          :active="
            view.path === '/'
              ? $route.path === view.path
              : $route.path.includes(view.path)
          "
        >
          <router-link :to="view.path" class="link">
            <va-icon :name="view.icon" color="primary" size="1.5rem" />
            <span v-if="!minimized">{{ view.name }}</span>
          </router-link>
        </VaSidebarItem>

        <VaDivider />
      </div>
    </div>
    <div class="link-container">
      <div class="link">
        <VaSidebarItem
          hover-color="transparent"
          :active="$route.path.includes('Log Out')"
          @click="handleLogOut"
        >
          <va-icon name="logout" color="primary" size="1.5rem" />
          <span v-if="!minimized">Log Out</span>
        </VaSidebarItem>
      </div>
      <VaDivider />
    </div>
  </VaSidebar>
</template>
<script lang="ts" src="./script.ts" />
<style lang="scss" scoped src="./styles.scss" />
