import { calendarGlobalSearchInterface } from '@/Interfaces/Store'
import { CompanyUserPresenter } from '../CompanyUserPresenter'
import { CompanyConfigurationPresenter } from '../companyConfigPresenters'

export class TargetPresenter {
  id!: number
  member!: CompanyUserPresenter
  title!: string
  target_type!: string
  target!: calendarGlobalSearchInterface[]
  tag!: string
  action_note!: string
  start_date!: string
  end_date!: string
  status!: string
  priority!: CompanyConfigurationPresenter
  note!: string

  constructor(data: TargetPresenter) {
    Object.assign(this, data)

    if (data?.member) {
      this.member = new CompanyUserPresenter(data.member)
    }

    if (data?.priority) {
      this.priority = new CompanyConfigurationPresenter(data.priority)
    }
  }
}
