<template>
  <!-- <div class="scroll-container-wrapper"> -->
  <SharedHeader :back-button="true" :title="`Title: ${form?.name}`">
    <div class="sub-SharedHeader">
      <div class="sub-SharedHeader-left" />
      <div class="sub-SharedHeader-right">
        <DatePickerModal
          :date="form?.start_date"
          @update:date="handleSelectDueDate"
        />

        <ForItemTimeRecordModal
          :start-record-loading="startRecordLoading"
          :loading-states="loadingStates"
          :delete-record-loading="deleteRecordLoading"
          :update-record-loading="updateRecordLoading"
          :manual-form-loading="manualFormLoading"
          :calendar-items-range-form-loading="calendarItemsRangeFormLoading"
          @handleAddTimeRange="handleAddTimeRange"
          @handleStartEndTime="handleStartEndTime"
          @handleStartEndRecord="handleStartEndRecord"
          @handleDeleteRecord="handleDeleteRecord"
          @handleUpdateRecord="handleUpdateRecord"
          @handleCreateManual="handleCreateManual"
        />
      </div>
    </div>
  </SharedHeader>

  <VaModal
    v-model="isLinkMatter"
    size="small"
    hide-default-actions
    fixed-layout
  >
    <template #header>
      <div class="add-matter-header">
        <h1>Add Links</h1>
        <VaIcon
          class="header-icon press-button"
          name="close"
          size="30px"
          color="#66023c"
          @click="toggleLinkMatter"
        />
      </div>

      <div class="search">
        <VaInput
          v-model="matterSearchKeywordModal"
          placeholder="Search Legal Matters..."
          @input="searchMatterData"
        >
          <template #prependInner>
            <VaIcon name="search" color="secondary" />
          </template>
        </VaInput>
      </div>
    </template>

    <div class="add-matter-body">
      <h2>
        {{ selectedMatter?.name }}
        <p v-if="selectedMatter" @click="handleSelectMatter(null)">
          Remove <VaIcon name="cancel" />
        </p>
      </h2>

      <h1>Recent</h1>
      <div class="matters-list">
        <ul v-for="(matter, index) in mattersModal" :key="index">
          <li @click="handleSelectMatter(matter)">
            <VaIcon name="inventory_2" color="primary" /> {{ matter.name }}
          </li>
        </ul>
      </div>
    </div>

    <template #footer>
      <div class="add-matter-buttons">
        <VaButton
          preset="secondary"
          border-color="primary"
          @click="toggleLinkMatter"
        >
          Cancel
        </VaButton>
        <VaButton :disabled="!selectedMatter" @click="handleLinkMatter()">
          Link
        </VaButton>
      </div>
    </template>
  </VaModal>

  <div class="info-container">
    <h2 class="info-container-status">
      Status :
      <VaMenu>
        <template #anchor>
          <VaButton
            icon="arrow_drop_down"
            round
            size="small"
            :color="form?.status?.color"
            :loading="companyConfigLoading"
          >
            {{ form?.status?.name }}
          </VaButton>
        </template>
        <VaMenuItem
          v-for="(stage, index) in $stores.generalData.companyConfigs
            .matterStatusOption"
          :key="index"
          @click="handleStatusMenuItemClick(stage)"
        >
          <VaIcon name="circle" :color="stage.color" />
          {{ stage.name }}
          <VaDivider />
        </VaMenuItem>
      </VaMenu>
    </h2>
  </div>

  <!-- <div class="taskbar-container"> -->
  <TabsBar
    :tabs="tabs"
    :active-tab-id="activeTabId"
    :add-btn="false"
    @changeTab="changeTab"
  />
  <!-- </div> -->

  <!-- <div> -->
  <div v-if="activeTab === 'INFO'" class="scroll-container">
    <div class="hearing-add-field-button-container">
      <VaButton
        icon-right="add"
        preset="secondary"
        border-color="primary"
        icon-color="#812E9E"
        disabled
      >
        Add Field
      </VaButton>
    </div>

    <div class="matter-info-relationships">
      <h6 v-if="form.legal_matter?.id">Relationship with *</h6>
      <ol>
        <li v-if="form.legal_matter?.id">
          <p>
            <va-icon size="large" name="subdirectory_arrow_right" />
            <VaButton
              icon-="link"
              preset="secondary"
              text-color="red"
              icon-color="red"
              class="matter-relation-name"
            >
              <router-link :to="`/legal-matters/${form.legal_matter?.id}`">
                {{ form.legal_matter?.name }}
              </router-link>
            </VaButton>

            <!-- <span class="matter-relation-subDetail"
                  >({{ relation.subDetail }})</span
                > -->
          </p>
          <VaButton
            icon-="link"
            size="small"
            preset="secondary"
            text-color="red"
            icon-color="red"
            @click="handleUnSelectMatter"
          >
            Remove
          </VaButton>
        </li>
      </ol>
      <VaButton
        :disabled="form.legal_matter?.id"
        icon="link"
        preset="secondary"
        icon-color="#812E9E"
        @click="toggleLinkMatter"
      >
        Link to another legal matter
      </VaButton>
    </div>

    <VaForm
      ref="form"
      class="create-hearing-form"
      @submit.prevent="validate() && handleCreateHearing()"
    >
      <VaInput
        v-model="form.name"
        background="#ECF0F1"
        label="name"
        required-mark
        :rules="[validateInput]"
        placeholder="Name"
      />
      <div class="case-assign">
        <div style="width: 70%">
          <VaSelect
            v-model="form.assigned_to"
            background="#ECF0F1"
            label="assign to"
            required-mark
            :rules="[validateSelect]"
            clearable
            searchable
            :highlight-matched-text="false"
            :options="$stores.usersData.teamMembers"
            :text-by="getOptionFullName"
            :loading="$stores.usersData.membersLoading"
            placeholder="Assign To"
            @update:search="$stores.usersData.handleSearchMembers"
          />
        </div>
        <VaCheckbox
          disabled
          style="height: 0.5rem"
          class="mb-6"
          label="Of Counsel"
        />
      </div>
      <VaSelect
        v-model="form.representing_contact"
        background="#ECF0F1"
        label="client"
        searchable
        :highlight-matched-text="false"
        :options="$stores.contacts.contacts"
        :text-by="getOptionFullName"
        :loading="$stores.contacts.contactsLoading"
        placeholder="Select Client"
        @update:search="$stores.contacts.handleSearchContacts"
      />
      <VaSelect
        v-model="form.opponent_contact"
        background="#ECF0F1"
        label="opponent name"
        clearable
        searchable
        :highlight-matched-text="false"
        :options="$stores.contacts.contacts"
        :text-by="getOptionFullName"
        :loading="$stores.contacts.contactsLoading"
        placeholder="Select The Opponent"
        @update:search="$stores.contacts.handleSearchContacts"
      />
      <div class="court-section">
        <VaSelect
          v-model="form.court"
          :options="$stores.generalData.companyConfigs.courtOptions"
          label="Court"
          required-mark
          :rules="[validateSelect]"
          style="width: 80%"
          background="#ECF0F1"
          placeholder="Court Type"
          :text-by="getOptionTruncateName110"
          :loading="companyConfigLoading"
        />

        <VaInput
          v-model="form.court_case_number"
          background="#ECF0F1"
          style="width: 20%"
          placeholder="Court ID"
          required-mark
          :rules="[validateInput]"
          label="Court ID "
        />
      </div>
      <VaSelect
        v-model="form.judge"
        background="#ECF0F1"
        label="judge name"
        clearable
        searchable
        :highlight-matched-text="false"
        :options="$stores.contacts.contacts"
        :text-by="getOptionFullName"
        :loading="$stores.contacts.contactsLoading"
        placeholder="Select The Judge"
        @update:search="$stores.contacts.handleSearchContacts"
      />
      <VaSelect
        v-model="form.jurisdriction"
        placeholder="Jurisdictions"
        background="#ECF0F1"
        :loading="countriesLoading"
        label="Jurisdictions"
        required-mark
        searchable
        :options="countries"
        :highlight-matched-text="false"
        :text-by="getOptionName"
        :rules="[validateSelect]"
      />
      <!-- <VaSelect
          placeholder="Jurisdictions District"
          background="#ECF0F1"
          v-model="form.jurisdriction_district"
          :loading="statesLoading"
          label="Jurisdictions District"
          searchable
          :options="states?.list"
          :highlight-matched-text="false"
          :text-by="getOptionName"

          /> -->
      <VaInput
        v-model="form.region"
        style="width: 100%"
        background="#ECF0F1"
        label="Region"
        placeholder="Region"
      />
      <VaSelect
        v-model="form.hearing_contacts_users"
        background="#ECF0F1"
        label="participation clients"
        searchable
        clearable
        :highlight-matched-text="false"
        :options="$stores.contacts.contacts"
        :text-by="getOptionFullName"
        :loading="$stores.contacts.contactsLoading"
        placeholder="Select Clients"
        multiple
        :max-visible-options="3"
        @update:search="$stores.contacts.handleSearchContacts"
      />
      <VaSelect
        v-model="form.hearing_users_users"
        background="#ECF0F1"
        label="participation Lawyers"
        searchable
        clearable
        :highlight-matched-text="false"
        :options="$stores.usersData.teamMembers"
        :text-by="getOptionFullName"
        :loading="$stores.usersData.membersLoading"
        placeholder="Select Lawyers"
        multiple
        :max-visible-options="3"
        @update:search="$stores.usersData.handleSearchMembers"
      />
      <VaSelect
        v-model="form.status"
        label="Status"
        background="#ECF0F1"
        :options="$stores.generalData.companyConfigs.matterStatusOption"
        :text-by="getOptionName"
        :loading="companyConfigLoading"
      />
    </VaForm>
    <div class="add-hearing-form-modal-buttons">
      <VaButton preset="secondary" border-color="primary"> Cancel </VaButton>
      <VaButton
        :loading="addHearingLoading"
        @click="validate() && handleCreateHearing()"
      >
        Save
      </VaButton>
    </div>
  </div>

  <!-- <div> -->
  <DetailsTimeLogs
    v-else-if="activeTab === 'TIME LOG'"
    :time-log="timeLogs"
    :loading="timeLogTableLoading"
    :items-per-page="timeLogsItemPerPage"
    :total-items="timeLogTotalItems"
    :update-time-log-loading="updateRecordLoading"
    :delete-time-log-loading="deleteRecordLoading"
    @loadMore="handlePaginationTimeLog"
    @handleUpdateTimeLog="handleUpdateRecord"
    @handleDeleteTimeLog="handleDeleteRecord"
  />
  <!-- </div> -->
  <div v-else class="coming-soon-container">
    <ComingSoon />
  </div>
  <!-- </div> -->
  <!-- </div> -->
</template>
<script lang="ts" src="./script.ts" />
<style lang="scss" scoped src="./styles.scss" />
