import {
  CompanyConfigurationPresenter,
  CurrencyPresenter,
} from '@/presenters/companyConfigPresenters'
import { IndividualContactPresenter } from '@/presenters/contactPresenter'
import { calendarGlobalSearchInterface } from './Store'
import { calendar_item_linked_items_attributesInterface } from './layout'
import { CurrenciesPresenter } from '@/presenters/CurrenciesPresenter'
import {
  TemplateInterface,
  TableColumnInterface,
  FilterForm,
} from './Constants'
import { TeamMemberPresenter } from '@/presenters/teamMember'

import { CompanyPresenter } from '@/presenters/CompanyPresenter'
import { MatterPresenter } from '@/presenters/matterPresenter'
import { OrganizationContactPresenter } from '@/presenters/organizationPresenter'
import { CompanyUserPresenter } from '@/presenters/CompanyUserPresenter'

export type getAllCalendarItemsInterface = {
  per_page?: number
  page?: number
  start_after?: string
  start_before?: string
  end_after?: string
  end_before?: string
  sort_by_direction?: 'asc' | 'desc'
  sort_by_field?: 'start_time' | 'end_time'
  item_type_id?: number
  skip_limit?: boolean
  for_whole_company?: boolean
  company_user_id?: number | null
  linked_item_type?: string
  linked_item_id?: number
  keyword?: string
  with_user?: boolean
}

export type getTimesForItemInterface = {
  linked_item_type: string
  linked_item_id: number
  with_user?: boolean
}

export enum ContactType {
  IndividualContact = 'IndividualContact',
  OrganizationContact = 'OrganizationContact',
}

export type getContactsInterface = {
  contact_type?: ContactType
  per_page?: number
  page?: number
  sort_by_field?:
    | 'individual_first_name'
    | 'individual_last_name'
    | 'organization_name'
    | undefined
  sort_by_direction?: 'asc' | 'desc'
  keyword?: string
  client?: boolean
  has_active_legal_matters?: boolean
}

export type getMembersInterfaces = {
  company_id?: number
  company_user_id?: number
  as_company_user?: boolean
  keyword?: string
  per_page?: number
  page?: number
}

export type paginationInterface = {
  page: number
  itemsPerPage: number
  sortBy: { key: string; order?: 'asc' | 'desc' }[]
}

export type getExpensesInterface = {
  per_page: number
  page?: number
  status?: 'not_started' | 'transferred' | 'in_progress' | 'not_retained'
  keyword?: string
}

export type getInvoicesInterface = {
  per_page: number
  page?: number
  status?: 'pending' | 'paid'
  keyword?: string
}

export type addExpenseFormInterface = {
  id?: number
  name: string
  unit_price: number
  quantity: number
  currency: CurrenciesPresenter
  secondary_currency: CurrenciesPresenter
  total_amount: number
  declared_amount: number
  category: string
  description: string
  billed_to_id: number
  paid_by_id: number
  paid_by?: TeamMemberPresenter | CompanyPresenter
  paid_by_type: string
  due_date: Date
  status: string
  expense_type: string
  total_tax: number
  related_to_id: number
  related_to_type: string
  related_to?: MatterPresenter
  template_id: number
}

export type updateExpenseFormInterface = {
  id?: number
  name: string
  unit_price: string | number
  quantity: number | number
  currency_id?: number
  total_amount: string | number
  declared_amount: string | number
  category: string
  description: string
  due_date: string
  status: string
  expense_type: string
  total_tax: string | number
  billed_to_id: number | undefined
  paid_by_id: number | null
  paid_by_type: string | null
  related_to_id: number
  related_to_type: string
  template_id: number
}

export type invoiceItemAttributesInterface = {
  billable_type: string
  billable_id: number
  description: string
  quantity: number
  unit_price: number
  tax_percentage: number
  discount_type: 'percentage' | 'amount'
  discount_type_amount: number
  created_at: string
  total_after_discount: string
  billable: any
  company_user: CompanyUserPresenter
  _destroy?: boolean
}

export type invoiceTaxItemsAttributesInterface = {
  id?: number
  _destroy?: boolean
  name: string
  tax_type: 'percentage' | 'amount'
  tax_type_amount: number
}

export type addInvoiceFormInterface = {
  invoiceable: OrganizationContactPresenter | IndividualContactPresenter
  organization_contact_id: number
  individual_contact_id: number
  invoice_number: string
  status: 'pending' | 'paid'
  customer_name: string
  customer_email: string
  invoice_date: string | Date
  payment_due_date: string | Date | null
  terms_and_conditions_note: string
  payment_details_note: string
  signature_note: string
  general_note: string
  currency?: CurrencyPresenter
  secondary_currency: CurrencyPresenter
  discount_type: 'percentage' | 'amount'
  discount_type_amount: number
  invoice_items_attributes: invoiceItemAttributesInterface[]
  invoice_tax_items_attributes: invoiceTaxItemsAttributesInterface[]

  currency_conversion_rate_against_us_dollars?: number
  secondary_currency_conversion_rate_against_us_dollars?: number
}

export type addUpdateCalendarItemInterface = {
  id?: number
  start_time?: string
  end_time?: string
  description?: string
  billable?: boolean
  title?: string
  item_type_id?: number
  item_type?: CompanyConfigurationPresenter
  all_day?: boolean
  private?: boolean
  send_reminder_at?: string
  location?: string
  // linked_item_id?: number;
  // linked_item_type?: string;
  calendar_item_linked_items?: calendarGlobalSearchInterface[]
  calendar_item_linked_items_attributes?: calendar_item_linked_items_attributesInterface[]
  time_range?: boolean
  total_time_spent_seconds?: number
}

export type globalSearchParamsInterface = {
  keyword?: string
  number_of_records?: number

  hearings_enabled?: boolean
  hearings_order_by_column?: 'name' | 'created_at'
  hearings_order_by_direction?: 'asc' | 'desc'

  legal_matters_enabled?: boolean
  legal_matters_order_by_column?: 'name' | 'created_at'
  legal_matters_order_by_direction?: 'asc' | 'desc'

  individual_contacts_enabled?: boolean
  individual_contacts_order_by_column?: 'name' | 'created_at'
  individual_contacts_order_by_direction?: 'asc' | 'desc'

  organization_contacts_enabled?: boolean
  organization_contacts_order_by_column?: 'name' | 'created_at'
  organization_contacts_order_by_direction?: 'asc' | 'desc'

  tasks_enabled?: boolean
  tasks_order_by_column?: 'name' | 'created_at'
  tasks_order_by_direction?: 'asc' | 'desc'

  calendar_items_enabled?: boolean
  calendar_items_order_by_column?: 'name' | 'created_at'
  calendar_items_order_by_direction?: 'asc' | 'desc'
}

export type StageItemBodyInterface = {
  name?: string
  sort_order?: number
  color?: string
}

export type SectionStageItemInterface = {
  id: number
  class_name: string
  created_at: string
  item_id: number
  item_type: string
  section_stage_id: number
  sort_order: number
  updated_at: string
}

export type getMilestonesInterface = {
  per_page: number
  page?: number
  keyword?: string
  related_to_type?: string
  related_to_id?: number
  status_id?: number
}

export type addPutMilestoneFormInterface = {
  related_to_type: string
  related_to_id: number
  name: string
  due_date?: string
  status_id: number
  description?: string
  fees_amount: string
  fees_currency_id: number
  expenses_amount: string
  expenses_currency_id: number
}

export type getCurrenciesInterface = {
  per_page?: number
  page?: number
}

export type addCurrencyFormInterface = {
  id?: number
  name: string
  code: string
  conversion_rate_against_us_dollars: number
}

export type ZoneType = {
  country: string
  abbreviation: string
  timezone: string
}

export type updateMemberInterface = {
  basic_salary_amount?: number | string
  basic_salary_currency_id?: number
  basic_salary_frequency?:
    | 'daily'
    | 'weekly'
    | 'biweekly'
    | 'monthly'
    | 'quarterly'
    | 'annually'
  billable_hourly_rate_earning_amount?: number | string
  billable_hourly_rate_earning_currency_id?: number
  billable_hourly_rate_charging_amount?: number | string
  billable_hourly_rate_charging_currency_id?: number
  calendar_timezones?: string[]
  role_ids?: number[]
}

export type CompanyInterface = {
  id: number
  class_name: string
  company_users_count: number
  created_at: string
  guid: string
  name: string
  updated_at: string
}

export type addUpdateSectionType = {
  id?: number
  name?: string
  table_columns?: TableColumnInterface[]
  form_columns?: TemplateInterface[][] | any[]
  sort_order?: number
  section_type?: 'contacts' | 'tasks' | 'legal_matters' | 'leads'
  allow_multiple_stages_per_item?: boolean
  listing_filters?: FilterForm
}

export type FilesAddInterface = {
  name: string
  description?: string
  file: File
  sort_order?: number
  parent_type: 'StorageFolder' | 'StorageCategory'
  parent_id: number
}

export type FilesUpdateInterface = {
  name?: string
  description?: string
}

export type getNotificationsInterface = {
  page?: number
  per_page?: number
  notification_group_type?:
    | 'client_related'
    | 'internal'
    | 'billing_and_financial'
    | 'calendar_and_deadline'
    | 'general'
  unviewed_only?: boolean
}

export type RoleResourceType =
  | 'Company'
  | 'Contact'
  | 'Currency'
  | 'Expense'
  | 'Hearing'
  | 'Invoice'
  | 'LegalMatter'
  | 'Milestone'
  | 'Role'
  | 'Section'
  | 'StorageCategory'
  | 'StorageFolder'
  | 'StorageFile'
  | 'Task'
  | 'CompanyUser'
  | 'CompanyConfiguration'

export type PermissionInterface = {
  actions: number
  resource_type: RoleResourceType
}

export type AddUpdateRoleInterface = {
  id?: number
  name: string
  role_permissions_attributes?: PermissionInterface[]
  company_user_roles?: { company_user_id: number }[]
}

export type AddUpdateLeadFormInterface = {
  id?: number
  title?: string
  section_stage_id?: number
  individual_contact_id?: number
  organization_contact_id?: number
  relationship_owner_id?: number
  label_id?: number
  currency_id?: number
  lead_value?: number
  probability?: number
  expected_close_date?: string
  // renewal_date?: string
  won?: boolean
  section_stage?: any
}
