import {
  getOptionFullName,
  getOptionName,
  getOptionTruncateName50,
  validateInput,
  validateInputEmail,
  validateSelect,
  truncateText,
  getOptionTruncateName110,
} from '@/lib/utils'
import { MatterPresenter } from '@/presenters/matterPresenter'

import { getAllCountries } from '@/services/address'
import { defineComponent } from 'vue'
import { useForm } from 'vuestic-ui/web-components'
import DynamicForm from '@/components/DynamicForms/DynamicMatterForm/index.vue'
import { MatterTemplatePresenter } from '@/presenters/TemplatePresenter/Matter'
import { SectionsPresenter } from '@/presenters/SectionsPresenter'
import { StagesPresenter } from '@/presenters/StagesPresenter'

export default defineComponent({
  name: 'AddMatterForm',
  props: {
    addMatterLoading: {
      type: Boolean,
      default: false,
    },
    isAddMatter: {
      type: Boolean,
      default: false,
    },
    subSections: {
      type: Array as () => SectionsPresenter[],
      required: true,
    },
    section: {
      type: {} as () => StagesPresenter,
      required: true,
    },
  },
  data() {
    const { isValid, validate, reset, resetValidation } = useForm('form')

    const subSection = {} as SectionsPresenter

    return {
      validateInput,
      validateInputEmail,
      validateSelect,
      getOptionName,
      getOptionFullName,
      getOptionTruncateName50,
      getOptionTruncateName110,

      formFields: [] as MatterTemplatePresenter[][],

      isAddMatterBool: this.isAddMatter,
      isValid,
      validate,
      reset,
      resetValidation,
      case_personal_of_counsel: false as boolean,
      form: {} as MatterPresenter,

      countriesLoading: false,
      contactsLoading: false,

      presentationTypeOption: ['Plaintiff', 'Defendant'],

      truncateText,

      mattersLoading: false,
      matterPage: 1,

      mattersItemPerPage: 10,

      debounceTimeout: null as ReturnType<typeof setTimeout> | null,

      subSection,
      toggleSubmit: false,
    }
  },
  methods: {
    handleToggleAddModal() {
      this.isAddMatterBool = !this.isAddMatterBool
      this.$emit('handleToggleAddModal')
    },
    async handleToggleSubmit() {
      this.toggleSubmit = !this.toggleSubmit
    },
    handleCreateMatter(form: MatterPresenter) {
      const {
        representation_type,
        parent_id,
        court,
        judge,
        representing_contact,
        opponent_contact,
        status,
        specialization,
        category,
        sector,
        jurisdriction,
        case_manager,
        personnel,
        section_stage,
        ...updatedForm
      } = form

      this.$emit('handleCreateMatter', {
        representation_type: representation_type?.toLocaleLowerCase(),
        parent_id: parent_id?.id,
        court_id: court?.id,
        judge_id: judge?.id,
        representing_contact_id: representing_contact?.id,
        opponent_contact_id: opponent_contact?.id,
        status_id: status?.id,
        specialization_id: specialization?.id,
        category_id: category?.id,
        sector_id: sector?.id,
        jurisdriction_id: jurisdriction?.id,
        section_stage_id: section_stage?.id,
        case_manager_ids: case_manager?.map((e) => e.id),
        personnel_ids: personnel?.map((e) => e.id),
        ...updatedForm,
      })
    },

    //////////////////////////////////////////////////////////////
    async handleGetAllCountries() {
      this.countriesLoading = true
      const response = await getAllCountries()

      if (response.success) {
        this.$stores.generalData.countries = response.data.list
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.countriesLoading = false
    },
    ////////////////////////////////////////////////////////////////////
  },
  watch: {
    isAddMatter: {
      handler(data) {
        this.isAddMatterBool = data

        if (data) {
          this.$stores.contacts.handleGetContacts()
          this.$stores.contacts.handleGetOrganizations()
          this.handleGetAllCountries()
          this.$stores.usersData.handleGetMembers()
          this.$stores.matter.handleGetMatters()

          this.subSection =
            this.subSections.find(
              (section) => section.name === this.$route.query.tab,
            ) || this.subSections[0]

          this.formFields = this.subSection.form_columns

          this.$stores.matter.sectionStages = this.subSection?.section_stages

          this.form.section_stage =
            this.subSection?.section_stages?.find(
              (stage) => stage.id === this.section?.id,
            ) || this.subSection?.section_stages[0]
        }
      },
    },
    subSection: {
      handler(data) {
        this.formFields = data.form_columns
      },
      deep: true,
    },
  },
  components: {
    DynamicForm,
  },
})
