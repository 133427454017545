import { defineComponent } from 'vue'
import TabsBar from '@/components/tabsBar/index.vue'
import SharedHeader from '@/components/sharedHeader/index.vue'
import { useForm } from 'vuestic-ui'
import {
  deleteContact,
  getContactById,
  updateOrganizationContact,
} from '@/services/contacts'
import ComingSoon from '@/components/ComingSoon/index.vue'
import FileUploadHelper, {
  getFormColumns,
  getOptionFullName,
  getOptionName,
  getOptionTruncateName50,
  truncateText,
  validateInput,
  validateInputEmail,
  validateSelect,
} from '@/lib/utils'
import { useTabs } from '@/composables/useTabs'
import { Avatar, OrganizationSpecificData } from '@/presenters/interface'
import { OrganizationContactPresenter } from '@/presenters/organizationPresenter'
import { ContactTemplatePresenter } from '@/presenters/TemplatePresenter/Contact'
import { StagesPresenter } from '@/presenters/StagesPresenter'
import DynamicContactForm from '@/components/DynamicForms/DynamicContactForm/index.vue'

const verifiedImage = new URL(
  '@/assets/icons/verified-icon.svg',
  import.meta.url,
).href

export default defineComponent({
  name: 'OrganizationDetailsPage',

  setup() {
    const { isValid, validate, reset, resetValidation } = useForm(
      'organizationDetailsFormRef',
    )
    return { isValid, validate, reset, resetValidation }
  },
  data() {
    const tabs = [
      { id: 0, name: 'DASHBOARD', icon: 'dashboard' },
      { id: 1, name: 'ORGANIZATION INFO', icon: 'info' },
      { id: 2, name: 'LEADS', icon: 'my_location' },
      { id: 12, name: 'LEGAL MATTERS', icon: 'inventory_2' },
      { id: 3, name: 'TASKS', icon: 'assignment' },
      { id: 4, name: 'CALENDAR', icon: 'calendar_month' },
      { id: 5, name: 'MILESTONES', icon: 'flag' },
      { id: 6, name: 'FILES', icon: 'file_copy' },
      { id: 7, name: 'FINANCIAL', icon: 'attach_money' },
      { id: 8, name: 'HEARINGS', icon: 'gavel' },
      { id: 9, name: 'ENGAGEMENT LETTER', icon: 'article' },
      { id: 10, name: 'EMAILS', icon: 'mail' },
      { id: 11, name: 'KYC DOCUMENTS', icon: 'article' },
    ]

    const { activeTabId, activeTab, changeTab } = useTabs(tabs, 1)
    return {
      validateInput,
      validateInputEmail,
      validateSelect,
      getOptionName,
      getOptionFullName,
      getOptionTruncateName50,

      verifiedImage,
      loading: false,
      tabs,
      activeTabId,
      activeTab,
      changeTab,

      loadingUpdate: false,

      isAddressAccordion: [false],
      isBankAccountAccordion: [false],
      isDeleteModal: false,

      formFields: [] as ContactTemplatePresenter[][],
      stagesLoading: false,
      stages: [] as StagesPresenter[],
      toggleSubmit: false,

      form: {
        specific_data: {
          organization_about: '',
          organization_date_of_foundation: '',
          organization_industry: '',
          organization_jurisdiction_of_incorporation: '',
          organization_logo: {},
          organization_name: '',
          organization_owner_email: '',
          organization_registration_number: '',
          organization_tax_identification_number: '',
          organization_type: '',
        } as OrganizationSpecificData,
      } as OrganizationContactPresenter,

      debounceTimeout: null as ReturnType<typeof setTimeout> | null,
      truncateText,
    }
  },

  methods: {
    async handleToggleSubmit() {
      this.toggleSubmit = !this.toggleSubmit
    },
    //////////////////////////////////////////////////////////////////

    async handleGetContactByID() {
      this.loading = true

      const contactResponse = await getContactById(
        this.$route.params.id as string,
      )

      if (contactResponse.success) {
        this.form = contactResponse.data

        this.formFields = getFormColumns(
          this.$stores.generalData.companyConfigs
            .organization_contact_form_fields?.[0].data.fields,
          'contacts',
        ) as ContactTemplatePresenter[][]
      } else {
        this.$vaToast.init({
          message: contactResponse?.message,
          color: 'danger',
        })
      }

      this.loading = false
    },

    ////////////////////////////////////////////////////////////////////////////////////////////

    async handleDelete() {
      const response = await deleteContact(this.$route.params.id as string)
      if (response.success) {
        this.$router.push({ name: 'ContactsView' })
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
    },
    handleBack() {
      this.$router.back()
    },
    async handleUpdate(form: OrganizationContactPresenter) {
      const {
        referred_by,
        organization_contact,
        organization_logo,
        kyc_document,
        email_addresses,
        phone_numbers,
        contact_addresses,
        contact_bank_accounts,
        relationship_owner,
        assigned_to,
        organization_jurisdiction_of_incorporation,
        linked_legal_matter_ids,
        ...dynamicForm
      } = { ...form }
      this.loadingUpdate = true

      const response = await updateOrganizationContact({
        id: this.$route.params.id as string,
        body: {
          organization_logo:
            this.form.organization_logo ??
            new File([], 'empty.jpg', {
              type: 'image/jpeg',
            }),
          organization_contact_id: organization_contact?.id,
          relationship_owner_id: relationship_owner?.id,
          referred_by_id: referred_by?.id,
          assigned_to_id: assigned_to?.id,
          organization_jurisdiction_of_incorporation,
          ...(!!contact_addresses?.[0].city?.id && {
            contact_addresses_attributes: contact_addresses?.map((address) => ({
              ...address,
              city_id: address.city.id,
            })),
          }),

          ...(!!contact_bank_accounts?.[0].iban && {
            contact_bank_accounts_attributes: contact_bank_accounts,
          }),
          linked_legal_matter_ids: linked_legal_matter_ids?.length
            ? linked_legal_matter_ids.map((matter: { id: number }) => matter.id)
            : null,
          ...dynamicForm,
        },
      })

      if (response.success) {
        this.$vaToast.init({
          message: 'Contact updates successfully!',
          color: 'success',
        })
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }

      this.loadingUpdate = false
    },

    ///////////////////////////////////////////////////////////////////

    handleRemoveImage() {
      this.form.avatar = {} as Avatar
      this.form.organization_logo = new File([], 'empty.jpg', {
        type: 'image/jpeg',
      })
    },

    handleFileUpload(event: File) {
      FileUploadHelper.handleFileUpload({
        event,
        form: this.form,
        key: 'organization_logo',
      })
    },
  },
  mounted() {
    this.handleGetContactByID()
    this.$stores.usersData.handleGetMembers()
    this.$stores.generalData.handleGetAllCountries()
    this.$stores.contacts.handleGetOrganizations()
    this.$stores.contacts.handleGetContacts()
    this.$stores.matter.handleGetMatters()
  },
  components: {
    TabsBar,
    SharedHeader,
    ComingSoon,
    DynamicContactForm,
  },
})
