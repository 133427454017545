import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-cd3f2650"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "scroll-container-wrapper for-tables" }
const _hoisted_2 = { class: "matters-client-td" }
const _hoisted_3 = { class: "matters-client-td" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { class: "matters-leadLawyers-td" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { class: "white--text" }
const _hoisted_9 = { class: "matters-leadLawyers-td" }
const _hoisted_10 = { key: 0 }
const _hoisted_11 = { key: 1 }
const _hoisted_12 = { class: "white--text" }
const _hoisted_13 = { class: "matter-note-td" }
const _hoisted_14 = { class: "table-action-td" }
const _hoisted_15 = ["src"]
const _hoisted_16 = ["onClick"]
const _hoisted_17 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_VaAvatar = _resolveComponent("VaAvatar")!
  const _component_v_avatar = _resolveComponent("v-avatar")!
  const _component_VaPopover = _resolveComponent("VaPopover")!
  const _component_va_icon = _resolveComponent("va-icon")!
  const _component_va_dropdown_content = _resolveComponent("va-dropdown-content")!
  const _component_va_dropdown = _resolveComponent("va-dropdown")!
  const _component_v_data_table_server = _resolveComponent("v-data-table-server")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_data_table_server, {
      selection: _ctx.selectedRows,
      "onUpdate:selection": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedRows) = $event)),
      loading: _ctx.loading,
      headers: _ctx.headers,
      items: _ctx.matters,
      "show-select": "",
      class: "custom-table",
      "items-length": _ctx.localTotalItems,
      "items-per-page": _ctx.itemsPerPage,
      "items-per-page-options": [10, 25, 50],
      "onUpdate:options": _ctx.handleLoadMore,
      "fixed-header": true,
      "fixed-footer": true
    }, {
      [`item.name`]: _withCtx(({ item }) => [
        _createVNode(_component_router_link, {
          to: `/legal-matters/${item?.id}`
        }, {
          default: _withCtx(() => [
            _createElementVNode("td", _hoisted_2, _toDisplayString(item?.name), 1)
          ]),
          _: 2
        }, 1032, ["to"])
      ]),
      [`item.client`]: _withCtx(({ item }) => [
        _createElementVNode("td", _hoisted_3, [
          (
              item.representing_contact?.client_avatar_letter ||
              item.avatar?.medium_url
            )
            ? (_openBlock(), _createBlock(_component_VaAvatar, {
                key: 0,
                color: "#ECF0F1",
                src: item.avatar?.medium_url
              }, {
                default: _withCtx(() => [
                  (!item.avatar?.medium_url)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(item.representing_contact?.client_avatar_letter), 1))
                    : _createCommentVNode("", true)
                ]),
                _: 2
              }, 1032, ["src"]))
            : _createCommentVNode("", true),
          _createTextVNode(" " + _toDisplayString(item.representing_contact?.fullName), 1)
        ])
      ]),
      [`item.leadLawyers`]: _withCtx(({ item }) => [
        _createElementVNode("td", _hoisted_5, [
          (item.personnel?.[0])
            ? (_openBlock(), _createBlock(_component_VaAvatar, {
                key: 0,
                color: "#ECF0F1",
                src: item.personnel?.[0]?.avatar?.medium_url
              }, {
                default: _withCtx(() => [
                  (!item.personnel?.[0]?.avatar?.medium_url)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(item.personnel[0]?.avatar_letter), 1))
                    : _createCommentVNode("", true)
                ]),
                _: 2
              }, 1032, ["src"]))
            : _createCommentVNode("", true),
          _createTextVNode(" " + _toDisplayString(item.personnel[0]?.fullName) + " ", 1),
          (item.personnel.length > 1)
            ? (_openBlock(), _createElementBlock("span", _hoisted_7, [
                _createVNode(_component_VaPopover, {
                  placement: "top",
                  color: "#F5F0F3"
                }, {
                  body: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.personnel, (person, index) => {
                      return (_openBlock(), _createElementBlock("span", {
                        key: index,
                        class: "matters-leadLawyers-td"
                      }, [
                        _createVNode(_component_v_avatar, {
                          color: "#7F325E",
                          size: "32"
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("span", _hoisted_8, _toDisplayString(person.avatar_letter), 1)
                          ]),
                          _: 2
                        }, 1024),
                        _createTextVNode(" " + _toDisplayString(person.fullName), 1)
                      ]))
                    }), 128))
                  ]),
                  default: _withCtx(() => [
                    _createElementVNode("p", null, "+" + _toDisplayString(item.personnel.length - 1) + " others", 1)
                  ]),
                  _: 2
                }, 1024)
              ]))
            : _createCommentVNode("", true)
        ])
      ]),
      [`item.lawyers`]: _withCtx(({ item }) => [
        _createElementVNode("td", _hoisted_9, [
          (item.case_manager?.[0])
            ? (_openBlock(), _createBlock(_component_VaAvatar, {
                key: 0,
                color: "#ECF0F1",
                src: item.case_manager?.[0]?.avatar?.medium_url
              }, {
                default: _withCtx(() => [
                  (!item.case_manager?.[0]?.avatar?.medium_url)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(item.case_manager[0]?.avatar_letter), 1))
                    : _createCommentVNode("", true)
                ]),
                _: 2
              }, 1032, ["src"]))
            : _createCommentVNode("", true),
          _createTextVNode(" " + _toDisplayString(item.case_manager[0]?.fullName) + " ", 1),
          (item.case_manager.length > 1)
            ? (_openBlock(), _createElementBlock("span", _hoisted_11, [
                _createVNode(_component_VaPopover, {
                  placement: "top",
                  color: "#F5F0F3"
                }, {
                  body: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.case_manager, (person, index) => {
                      return (_openBlock(), _createElementBlock("span", {
                        key: index,
                        class: "matters-leadLawyers-td"
                      }, [
                        _createVNode(_component_v_avatar, {
                          color: "#7F325E",
                          size: "32"
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("span", _hoisted_12, _toDisplayString(person.avatar_letter), 1)
                          ]),
                          _: 2
                        }, 1024),
                        _createTextVNode(" " + _toDisplayString(person.fullName), 1)
                      ]))
                    }), 128))
                  ]),
                  default: _withCtx(() => [
                    _createElementVNode("p", null, "+" + _toDisplayString(item.case_manager.length - 1) + " others", 1)
                  ]),
                  _: 2
                }, 1024)
              ]))
            : _createCommentVNode("", true)
        ])
      ]),
      [`item.jurisdiction`]: _withCtx(({ item }) => [
        _createElementVNode("td", null, _toDisplayString(item.jurisdriction_id), 1)
      ]),
      [`item.status`]: _withCtx(({ item }) => [
        _createElementVNode("td", {
          class: "matters-status-td",
          style: _normalizeStyle({ backgroundColor: item?.status?.color })
        }, _toDisplayString(item.status?.name), 5)
      ]),
      [`item.specialization`]: _withCtx(({ item }) => [
        (item.specialization?.name)
          ? (_openBlock(), _createElementBlock("td", {
              key: 0,
              class: "matters-status-td",
              style: _normalizeStyle({
            backgroundColor: item?.specialization?.color || '#babfc0',
          })
            }, _toDisplayString(item.specialization?.name), 5))
          : _createCommentVNode("", true)
      ]),
      [`item.category`]: _withCtx(({ item }) => [
        (item.category?.name)
          ? (_openBlock(), _createElementBlock("td", {
              key: 0,
              class: "matters-status-td",
              style: _normalizeStyle({ backgroundColor: item?.category?.color || '#babfc0' })
            }, _toDisplayString(item.category?.name), 5))
          : _createCommentVNode("", true)
      ]),
      [`item.stage`]: _withCtx(({ item }) => [
        (item.section_stage_items?.[0]?.section_stage?.name)
          ? (_openBlock(), _createElementBlock("td", {
              key: 0,
              class: "matters-status-td",
              style: _normalizeStyle({
            backgroundColor:
              item.section_stage_items?.[0]?.section_stage?.color || '#babfc0',
          })
            }, _toDisplayString(item.section_stage_items?.[0]?.section_stage?.name), 5))
          : _createCommentVNode("", true)
      ]),
      [`item.created_at`]: _withCtx(({ item }) => [
        _createElementVNode("td", null, _toDisplayString(_ctx.formatDate(item.created_at)), 1)
      ]),
      [`item.modifiedDate`]: _withCtx(({ item }) => [
        _createElementVNode("td", null, _toDisplayString(_ctx.formatDate(item.due_date)), 1)
      ]),
      [`item.latestNotes`]: _withCtx(({ item }) => [
        _createElementVNode("td", _hoisted_13, _toDisplayString(item.notes), 1)
      ]),
      [`item.action`]: _withCtx(({ item }) => [
        _createElementVNode("td", _hoisted_14, [
          _createVNode(_component_va_dropdown, {
            offset: [0, -70],
            "close-on-content-click": ""
          }, {
            anchor: _withCtx(() => [
              _createElementVNode("img", {
                src: _ctx.MoreVertIcon,
                alt: "MoreVertIcon",
                class: "press-button-2"
              }, null, 8, _hoisted_15)
            ]),
            default: _withCtx(() => [
              _createVNode(_component_va_dropdown_content, { class: "dropdown" }, {
                default: _withCtx(() => [
                  _createElementVNode("p", {
                    onClick: ($event: any) => (_ctx.viewProfile(item))
                  }, [
                    _createVNode(_component_va_icon, { name: "person" }),
                    _createTextVNode(" View Profile ")
                  ], 8, _hoisted_16),
                  (_ctx.unLink)
                    ? (_openBlock(), _createElementBlock("p", {
                        key: 0,
                        onClick: ($event: any) => (_ctx.handleUnLink(item))
                      }, [
                        _createVNode(_component_va_icon, { name: "link_off" }),
                        _createTextVNode(" Unlink Matter ")
                      ], 8, _hoisted_17))
                    : _createCommentVNode("", true)
                ]),
                _: 2
              }, 1024)
            ]),
            _: 2
          }, 1024)
        ])
      ]),
      _: 2
    }, 1032, ["selection", "loading", "headers", "items", "items-length", "items-per-page", "onUpdate:options"])
  ]))
}