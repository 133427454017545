import { defineComponent } from 'vue'
import {
  validateInput,
  getOptionFullName,
  getDueDateByDays,
  handleDateChange,
} from '@/lib/utils'
import { useForm } from 'vuestic-ui/web-components'
import { AnnouncementPresenter } from '@/presenters/AnnouncementPresenter'
import { CompanyUserPresenter } from '@/presenters/CompanyUserPresenter'

export default defineComponent({
  name: 'AddAnnouncementModal',
  props: {
    isAddAnnouncementModalOpen: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    const { validate, reset } = useForm('form')

    const announcementDueOptions = [
      {
        label: 'Due Today',
        value: getDueDateByDays(),
      },
      {
        label: 'Net 15',
        value: getDueDateByDays(15),
      },
      {
        label: 'Net 30',
        value: getDueDateByDays(30),
      },
      {
        label: 'Net 60',
        value: getDueDateByDays(60),
      },
    ]

    return {
      validate,
      reset,

      announcementDueOptions,
      isShowDueDate: false,
      datePickerValue: new Date(),

      getOptionFullName,

      isAddToDraftModalOpen: false,

      validateInput,

      isAddLoading: false,
      isAddAnnouncementModalBool: this.isAddAnnouncementModalOpen,
      addAnnouncementLoading: false,

      form: {} as AnnouncementPresenter,
    }
  },
  emits: ['toggleAddAnnouncementModal', 'handleAddAnnouncement'],
  methods: {
    beforeAddAnnouncementModalClose() {
      if (this.isAddAnnouncementModalBool) {
        this.toggleAddToDraftModal(false)
      }
    },

    handleToggleAddAnnouncementModal() {
      this.$emit('toggleAddAnnouncementModal')
      this.reset()
      this.form = {} as AnnouncementPresenter
    },

    toggleAddToDraftModal(toggleAddModal?: boolean, addToDraft?: boolean) {
      const isFromEmpty = this.handleCheckIfFormIsEmpty()
      if (isFromEmpty) {
        this.handleToggleAddAnnouncementModal()
        return
      }
      this.isAddToDraftModalOpen = !this.isAddToDraftModalOpen
      if (toggleAddModal) {
        if (addToDraft) {
          this.handleAddAnnouncement(false)
          return
        }
        this.handleToggleAddAnnouncementModal()
      }
    },

    handleCheckIfFormIsEmpty() {
      if (Object.keys(this.form).length === 0) {
        return true
      }
      return false
    },

    handleGetResponseFromForm() {
      let plainRecipients: CompanyUserPresenter[]
      if (this.form?.recipients) {
        plainRecipients = JSON.parse(
          JSON.stringify(
            this.form?.recipients?.filter((recipient) => recipient != null),
          ),
        )
      } else {
        plainRecipients = []
      }
      const response = {
        ...this.form,
        id: Date.now(),
        date: new Date().toISOString().split('T')[0],
        recipients: plainRecipients,
      }

      return response
    },

    handleAddAnnouncement(isSent?: boolean) {
      this.addAnnouncementLoading = true

      const newAnnouncement = this.handleGetResponseFromForm()

      this.$emit('handleAddAnnouncement', { isSent, newAnnouncement })

      this.handleToggleAddAnnouncementModal()
      this.addAnnouncementLoading = false
    },

    handleDatePickerChange(date: Date) {
      handleDateChange(
        date,
        this.announcementDueOptions,
        'dateAndTimeOfDispatching',
        this.form,
        () => {
          this.isShowDueDate = false
        },
      )
    },
  },
  mounted() {
    this.$stores.usersData.handleGetMembers({
      as_company_user: true,
    })
  },
  watch: {
    isAddAnnouncementModalOpen: {
      handler(data) {
        this.isAddAnnouncementModalBool = data
      },
    },
  },
  components: {},
})
