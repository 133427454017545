<template>
  <ActionsBar
    secondAddButton="Add Team"
    @secondButtonAction="toggleUpdateTeamModal"
  />

  <AddTeamModal
    :isUpdateTeamModalOpen="isUpdateTeamModalOpen"
    :currentTeam="currentTeam"
    @handleUpdateTeam="handleUpdateTeam"
    @toggleUpdateTeamModal="toggleUpdateTeamModal"
  />

  <AddMembers
    :isAddMembersModalOpen="isAddMembersModalOpen"
    @handleAddMembers="handleAddMembers"
    @toggleAddMembersModal="toggleAddMembersModal"
  />

  <SearchAndViews @search="searchTeamsData" />

  <div class="scroll-container">
    <VaAccordion v-model="teamsAccordionCollapseValues" multiple>
      <VaCollapse
        v-for="(team, index) in teams"
        :header="`${team.name}`"
        :key="index"
        solid
        v-model="teamsAccordionCollapseValues[index]"
      >
        <template #header="{ value, attrs, iconAttrs }">
          <div :class="!value && 'header-container'">
            <div v-bind="attrs" class="collapse-header">
              <div class="header-title">
                <h2>
                  {{ team.name }}
                </h2>
                <VaIcon
                  :name="!value ? 'va-arrow-down' : 'va-arrow-up'"
                  v-bind="iconAttrs"
                />
              </div>
              <div @click.stop>
                <transition name="fade">
                  <div v-show="value">
                    <VaButton
                      icon-right="person"
                      size="small"
                      @click="toggleAddMembersModal"
                    >
                      Add New Member
                    </VaButton>
                  </div>
                </transition>
                <transition name="fade">
                  <div v-show="!value">
                    <va-dropdown placement="bottom-end" close-on-content-click>
                      <template #anchor>
                        <img
                          :src="MoreVertIcon"
                          class="action-img press-button-2"
                          alt="MoreVertIcon"
                        />
                      </template>
                      <va-dropdown-content>
                        <p
                          class="action-option"
                          @click="toggleUpdateTeamModal(team.id)"
                        >
                          Update
                        </p>
                        <p class="action-option">Assign Matter</p>
                        <p class="action-option">KPI & Targets</p>
                      </va-dropdown-content>
                    </va-dropdown>
                  </div>
                </transition>
              </div>
            </div>
            <transition name="accordion">
              <div v-bind="attrs" v-show="!value">
                <div class="body-container">
                  <div>Team Manager</div>
                  <div class="user-container">
                    <VaAvatar
                      color="#ECF0F1"
                      :src="team?.manager?.avatar?.medium_url"
                    >
                      <span v-if="!team?.manager?.avatar?.medium_url">{{
                        team?.manager?.avatar_letter
                      }}</span>
                    </VaAvatar>
                    <div class="details-container">
                      <h1>{{ team?.manager?.fullName }}</h1>
                      <p class="title">{{ team?.manager?.title }}</p>
                      <p>
                        {{ team?.manager?.company_users?.[0]?.company?.name }}
                      </p>
                    </div>
                  </div>
                  <div class="jurisdictions-container">
                    <div>Jurisdictions</div>
                    <div class="tags">
                      <p
                        v-for="(tag, index) in team.jurisdictions"
                        :key="index"
                      >
                        {{ tag }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </transition>
            <div class="divider" v-show="!value">
              <VaDivider />
            </div>
          </div>
        </template>

        <template #body>
          <div class="body-container">
            <div>{{ team.description }}</div>
            <div class="tags">
              <p>{{ team.name }}</p>
            </div>
            <p class="note">
              *This tag is automatically created for every team.
            </p>

            <div>Jurisdictions</div>

            <div class="tags">
              <p v-for="(tag, index) in team.jurisdictions" :key="index">
                {{ tag }}
              </p>
            </div>
            <p class="note">
              *The jurisdiction list is based on the jurisdictions of the team
              members.
            </p>
            <p class="chat-text">Chat with the team here</p>

            <div>Team Manager</div>
            <div>
              <div class="user-container">
                <VaAvatar
                  color="#ECF0F1"
                  :src="team?.manager?.avatar?.medium_url"
                >
                  <span v-if="!team?.manager?.avatar?.medium_url">{{
                    team?.manager?.avatar_letter
                  }}</span>
                </VaAvatar>
                <div class="details-container">
                  <h1>{{ team?.manager?.fullName }}</h1>
                  <p class="title">{{ team?.manager?.title }}</p>
                  <p>
                    {{ team?.manager?.company_users?.[0]?.company?.name }}
                  </p>
                </div>
              </div>
            </div>
            <div>Team Members</div>
            <div v-for="(member, index) in team.members" :key="index">
              <div class="user-container">
                <VaAvatar color="#ECF0F1" :src="member?.avatar?.medium_url">
                  <span v-if="!member?.avatar?.medium_url">{{
                    member?.avatar_letter
                  }}</span>
                </VaAvatar>
                <div class="details-container">
                  <h1>{{ member?.fullName }}</h1>
                  <p class="title">{{ member?.title }}</p>
                  <p>
                    {{ member?.company_users?.[0]?.company?.name }}
                  </p>
                </div>
              </div>
            </div>
            <div class="divider">
              <VaDivider />
            </div>
          </div>
        </template>
      </VaCollapse>
    </VaAccordion>
  </div>
</template>
<script lang="ts" src="./script.ts" />
<style lang="scss" scoped src="./styles.scss" />
