import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SearchAndViews = _resolveComponent("SearchAndViews")!
  const _component_LeadTable = _resolveComponent("LeadTable")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_SearchAndViews, {
      search: _ctx.leadSearchKeyword,
      onSearch: _ctx.searchLeadData
    }, null, 8, ["search", "onSearch"]),
    _createVNode(_component_LeadTable, {
      loading: _ctx.leadTableLoading,
      "items-per-page": _ctx.leadsItemPerPage,
      "total-items": _ctx.leadTotalItems,
      items: _ctx.leads,
      headers: _ctx.headers,
      onSelectedRows: _ctx.getSelectedRows,
      onLoadMore: _ctx.handlePaginationLeads
    }, null, 8, ["loading", "items-per-page", "total-items", "items", "headers", "onSelectedRows", "onLoadMore"])
  ], 64))
}