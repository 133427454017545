import { LeadPresenter } from '@/presenters/LeadPresenter'
import { getLeadById, updateLead } from '@/services/leads'
import { defineComponent } from 'vue'
import SharedHeader from '@/components/sharedHeader/index.vue'
import {
  getOptionCurrency,
  getOptionFullName,
  getOptionName,
  getOptionTruncateName50,
} from '@/lib/utils'
import { useTabs } from '@/composables/useTabs'
import TabsBar from '@/components/tabsBar/index.vue'
import { useForm } from 'vuestic-ui/web-components'
import { getAllSections } from '@/services/sections'
import { StagesPresenter } from '@/presenters/StagesPresenter'

export default defineComponent({
  name: 'LeadsDetails',
  props: {},
  emits: [],
  data() {
    const tabs = [{ id: 0, name: 'LEAD INFO', icon: 'info' }]
    const { activeTabId, activeTab, changeTab } = useTabs(tabs)
    const { validate } = useForm('form')
    return {
      validate,

      activeTabId,
      activeTab,
      changeTab,
      tabs,

      getOptionName,
      getOptionFullName,
      getOptionTruncateName50,
      getOptionCurrency,

      form: {} as LeadPresenter,
      isLeadLoading: false,
      isUpdateLoading: false,
      isStagesLoading: false,

      stages: [] as StagesPresenter[],
    }
  },
  methods: {
    validateProbability(value: number) {
      return value >= 0 && value <= 100
    },

    ////////////////////////////////////////////////////////////

    async handleGetLeadStages() {
      this.isStagesLoading = true
      const response = await getAllSections('leads')
      if (response.success) {
        this.stages = response.data.list[0]?.section_stages
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.isStagesLoading = false
    },

    ////////////////////////////////////////////////////////////

    async handleGetLeadByID() {
      this.isLeadLoading = true
      const response = await getLeadById(Number(this.$route.params.id))

      if (response.success) {
        this.form = response.data
        console.log({ form: this.form })
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.isLeadLoading = false
    },

    async handleUpdateLead() {
      this.isUpdateLoading = true

      const {
        stage,
        individual_contact,
        organization_contact,
        relationship_owner,
        label,
        currency,
        expected_close_date,
        ...form
      } = this.form

      const response = await updateLead(Number(this.$route.params.id), {
        section_stage_id: stage?.id,
        individual_contact_id: individual_contact?.id,
        organization_contact_id: organization_contact?.id,
        relationship_owner_id: Number(relationship_owner?.id),
        label_id: label?.id,
        currency_id: currency?.id,
        ...(expected_close_date && {
          expected_close_date: new Date(expected_close_date).toISOString(),
        }),
        ...form,
      })
      if (response.success) {
        this.$vaToast.init({
          message: 'Lead updates successfully',
          color: 'success',
        })
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }

      this.isUpdateLoading = false
    },
  },

  mounted() {
    this.handleGetLeadByID()
    this.handleGetLeadStages()
    this.$stores.contacts.handleGetContacts()
    this.$stores.usersData.handleGetMembers()
    this.$stores.currency.handleGetCompanyCurrencies()
    this.$stores.contacts.handleGetOrganizations()
  },
  components: {
    SharedHeader,
    TabsBar,
  },
})
