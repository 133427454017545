import {
  calendarItemsFormInterface,
  calendar_item_linked_items_attributesInterface,
} from '@/Interfaces/layout'
import { useTimerStore } from '@/stores/timer'
import { defineComponent } from 'vue'
import { useForm } from 'vuestic-ui/web-components'
import DatePickerModal from '@/components/DatePickerModal/index.vue'
import { calendarGlobalSearchInterface } from '@/Interfaces/Store'
import TimeLinkModal from './TimeLinkModal/index.vue'
import TypeSelectModal from './TypeSelectModal/index.vue'
import {
  addSecondsToISOTime,
  camelCaseToNormal,
  deepCopy,
  formatDateRange,
  formatTimeSeconds,
  getOptionName,
  getRunningTimeFormatted,
  validateInput,
} from '@/lib/utils'
import { CalendarItemsPresenter } from '@/presenters/calendarItemsPresenter'
import { CompanyConfigurationPresenter } from '@/presenters/companyConfigPresenters'

const openWorkspaces = new URL(
  '@/assets/icons/open-workspaces-icon.svg',
  import.meta.url,
).href
const playImage = new URL('@/assets/icons/play-icon.svg', import.meta.url).href
const stopImage = new URL('@/assets/icons/stop-icon.svg', import.meta.url).href
const descriptionImage = new URL(
  '@/assets/icons/description-icon.svg',
  import.meta.url,
).href
const description2Image = new URL(
  '@/assets/icons/description2-icon.svg',
  import.meta.url,
).href

type timeRangeInterface = {
  hours: string | number
  minutes: string | number
}

export default defineComponent({
  name: 'TimeRecordModal',

  components: {
    DatePickerModal,
    TimeLinkModal,
    TypeSelectModal,
  },
  props: {},
  emits: [],
  data() {
    const { validate, reset } = useForm('form')
    const { validate: rangeValidate, reset: rangeReset } = useForm('rangeForm')
    const timerStore = useTimerStore()
    const user = this.$stores.auth.user

    return {
      validate,
      reset,
      rangeValidate,
      rangeReset,
      addSecondsToISOTime,

      camelCaseToNormal,
      formatDateRange,
      deepCopy,
      formatTimeSeconds,
      getOptionName,
      validateInput,

      timerStore,
      user,

      playImage,
      stopImage,
      descriptionImage,
      description2Image,
      openWorkspaces,

      showRecord: false,
      showRecordOption: new Map<number, boolean>(),

      calendarItemsForm: {} as calendarItemsFormInterface,

      selectedRecord: {} as CalendarItemsPresenter,
      loadingStates: {} as any,
      updateRecordLoading: false,
      deleteRecordLoading: false,

      formRecordLoading: false,
      manualFormLoading: false,

      itemsPerPage: 10,
      totalPages: 1,
      currentPage: 1,
      isLoading: false,

      timerTabs: ['Timer', 'Manual', 'Range'],
      timerTab: 'Timer',

      manualForm: {} as calendarItemsFormInterface,

      isItemAssignNew: false,
      isItemAssignUpdate: false,
      isItemAssignManual: false,
      isItemTypeSelect: false,

      calendarItemsRangeForm: {} as calendarItemsFormInterface,

      timeRange: {
        hours: 0,
        minutes: 0,
      } as timeRangeInterface,

      timeRangeUpdate: {
        hours: 0,
        minutes: 0,
      } as timeRangeInterface,

      calendarItemsRangeFormLoading: false,

      timestamp: Date.now(),
      intervalId: 0,
    }
  },

  methods: {
    handleStartTimer() {
      this.calendarItemsForm.start_time = new Date()

      this.timerStore.handleToggleTimer(this.calendarItemsForm)
      // this.$emit('handleStartTimer', this.calendarItemsForm)
    },

    handleStopTimer() {
      // this.$emit('handleStopTimer', this.calendarItemsForm)
    },

    ///////////////////////////////////// link to///////////////////////////////////////////

    handleLinkToNew(data: {
      selectedItems: calendarGlobalSearchInterface[]
      calendar_item_linked_items_attributes: calendar_item_linked_items_attributesInterface[]
    }) {
      this.calendarItemsForm.calendar_item_linked_items = data.selectedItems
      this.calendarItemsForm.calendar_item_linked_items_attributes =
        data.calendar_item_linked_items_attributes
    },

    handleLinkToManual(data: {
      selectedItems: calendarGlobalSearchInterface[]
      calendar_item_linked_items_attributes: calendar_item_linked_items_attributesInterface[]
    }) {
      this.manualForm.calendar_item_linked_items = data.selectedItems
      this.manualForm.calendar_item_linked_items_attributes =
        data.calendar_item_linked_items_attributes
    },

    handleLinkToRange(data: {
      selectedItems: calendarGlobalSearchInterface[]
      calendar_item_linked_items_attributes: calendar_item_linked_items_attributesInterface[]
    }) {
      this.calendarItemsRangeForm.calendar_item_linked_items =
        data.selectedItems
      this.calendarItemsRangeForm.calendar_item_linked_items_attributes =
        data.calendar_item_linked_items_attributes
    },

    handleLinkToUpdate(data: {
      selectedItems: calendarGlobalSearchInterface[]
      calendar_item_linked_items_attributes: calendar_item_linked_items_attributesInterface[]
    }) {
      this.selectedRecord.calendar_item_linked_items = data.selectedItems
      this.selectedRecord.calendar_item_linked_items_attributes =
        data.calendar_item_linked_items_attributes
    },

    ////////////////////////////////////////////////////////////////////////////////

    toggleMenu(index: number, record: any) {
      this.selectedRecord = deepCopy(record)

      if (this.selectedRecord.time_range) {
        const seconds = this.selectedRecord.total_time_spent_seconds

        const hours = Math.floor(seconds / 3600)
        const minutes = Math.floor((seconds % 3600) / 60)

        this.timeRangeUpdate.hours = hours
        this.timeRangeUpdate.minutes = minutes
      }

      // Close all other menus
      this.showRecordOption?.forEach((_, key) => {
        this.showRecordOption.set(key, false)
      })

      // Toggle the selected menu
      const currentState = this.showRecordOption.get(index) || false
      this.showRecordOption.set(index, !currentState)
    },

    closeAllMenus() {
      this.showRecordOption?.forEach((_, key) => {
        this.showRecordOption.set(key, false)
      })
      this.selectedRecord = {} as CalendarItemsPresenter
    },

    handleUpdateStartDate(date: { date: string }) {
      this.selectedRecord.start_time = date.date
    },
    handleUpdateEndDate(date: { date: string }) {
      this.selectedRecord.end_time = date.date
    },

    handleUpdateStartDateManual(date: { date: string }) {
      this.manualForm.start_time = date.date
    },
    handleUpdateEndDateManual(date: { date: string }) {
      this.manualForm.end_time = date.date
    },

    ////////////////////////////////////// time format //////////////////////////////////////////

    formatDateStart(dateString: Date) {
      const options: Intl.DateTimeFormatOptions = {
        // weekday: 'short',
        // month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
      }
      return new Intl.DateTimeFormat('en-US', options).format(
        new Date(dateString),
      )
    },

    ///////////////////////////////////////////////////////////////////////////////

    async handleGetTimes() {
      const { itemsPerPage, currentPage } = this

      const response = await this.$stores.mockTimer.handleGetTimes({
        per_page: itemsPerPage,
        page: currentPage,
        sort_by_direction: 'desc',
        sort_by_field: 'start_time',
      })

      if (response) {
        this.currentPage = response.current_page
        this.totalPages = response.total_pages
      }
    },

    async handleUpdateRecord() {
      this.updateRecordLoading = true

      await this.timerStore.handleUpdateTimer(this.selectedRecord, {
        per_page: this.itemsPerPage,
        page: this.currentPage,
        sort_by_direction: 'desc',
        sort_by_field: 'start_time',
      })

      this.updateRecordLoading = false
    },

    async handleStartEndRecord(record: any, index: number) {
      const { id, end_time, description, billable, linked_item } = record
      console.log({ linked_item })

      this.loadingStates[index] = true

      if (end_time == null) {
        this.selectedRecord.end_time = new Date().toDateString()
        await this.timerStore.handleUpdateTimer(
          {
            id,
            end_time: new Date(),
          },
          {
            per_page: this.itemsPerPage,
            page: this.currentPage,
            sort_by_direction: 'desc',
            sort_by_field: 'start_time',
          },
        )
      } else {
        await this.timerStore.handleStartTimer(
          {
            description,
            start_time: new Date().toISOString(),
            billable,
          },
          {
            per_page: this.itemsPerPage,
            page: this.currentPage,
            sort_by_direction: 'desc',
            sort_by_field: 'start_time',
          },
        )
      }

      this.loadingStates[index] = false
    },

    async handleStartFormTimer() {
      this.formRecordLoading = true

      const { calendar_item_linked_items, ...calendarItemsForm } =
        this.calendarItemsForm

      console.log({ calendar_item_linked_items })

      await this.timerStore.handleStartTimer(
        {
          ...calendarItemsForm,
          start_time: new Date().toISOString(),
        },
        {
          per_page: this.itemsPerPage,
          page: this.currentPage,
          sort_by_direction: 'desc',
          sort_by_field: 'start_time',
        },
      )
      this.calendarItemsForm = {} as calendarItemsFormInterface
      this.manualForm = {} as calendarItemsFormInterface
      this.formRecordLoading = false
    },

    async handleCreateManual() {
      const { calendar_item_linked_items, ...manualForm } = this.manualForm
      console.log({ calendar_item_linked_items })

      this.manualFormLoading = true
      await this.timerStore.handleStartTimer(manualForm, {
        per_page: this.itemsPerPage,
        page: this.currentPage,
        sort_by_direction: 'desc',
        sort_by_field: 'start_time',
      })

      this.manualForm = {} as calendarItemsFormInterface
      this.manualFormLoading = false
    },

    async handleDeleteRecord() {
      this.deleteRecordLoading = true

      const res = await this.timerStore.handleDeleteTimer(
        this.selectedRecord.id,
        {
          per_page: this.itemsPerPage,
          page: this.currentPage,
          sort_by_direction: 'desc',
          sort_by_field: 'start_time',
        },
      )

      res && this.closeAllMenus()

      this.deleteRecordLoading = false
    },

    /////////////////////////////////////////////////////////////////////////////

    debounce<T extends (...args: any[]) => void>(fn: T, delay: number): T {
      let timer: any
      return function (this: any, ...args: Parameters<T>) {
        clearTimeout(timer)
        timer = setTimeout(() => fn.apply(this, args), delay)
      } as T
    },

    onScrollTimeRecord(event: Event) {
      const box = event.target as HTMLElement

      if (
        box.scrollTop + box.clientHeight >= box.scrollHeight - 10 &&
        !this.isLoading &&
        this.currentPage < this.totalPages
      ) {
        console.log('Loading more items...')

        this.isLoading = true

        this.itemsPerPage += 10
        this.handleGetTimes()

        this.isLoading = false
      }
    },

    /////////////////////////////////////////////////////////////////////////

    handleAssignType(type: CompanyConfigurationPresenter) {
      this.selectedRecord.item_type = type
      this.selectedRecord.item_type_id = type?.id

      this.isItemTypeSelect = !this.isItemTypeSelect
    },

    ///////////////////////////////////// Time Range ////////////////////////////////////////

    handleRangeTimeToSec() {
      let { hours, minutes } = this.timeRange

      hours = Number(hours) || 0
      minutes = Number(minutes) || 0

      // both = 0 => minutes = 1
      const adjustedHours = hours >= 0 ? hours : 0
      const adjustedMinutes = minutes > 0 || hours > 0 ? minutes : 1

      //  time in seconds
      if (adjustedHours >= 0 && adjustedMinutes >= 0) {
        const totalSeconds = adjustedHours * 3600 + adjustedMinutes * 60

        this.calendarItemsRangeForm.total_time_spent_seconds = totalSeconds
        this.calendarItemsRangeForm.time_range = true

        const today = new Date()
        today.setUTCHours(5, 0, 0, 0)
        this.calendarItemsRangeForm.start_time = today.toISOString()

        this.calendarItemsRangeForm.end_time = addSecondsToISOTime(
          this.calendarItemsRangeForm.start_time,
          totalSeconds,
        )
      }
    },

    handleRangeTimeUpdateToSec() {
      let { hours, minutes } = this.timeRangeUpdate

      hours = Number(hours) || 0
      minutes = Number(minutes) || 0

      // both = 0 => minutes = 1
      const adjustedHours = hours >= 0 ? hours : 0
      const adjustedMinutes = minutes > 0 || hours > 0 ? minutes : 1

      //  time in seconds
      if (adjustedHours >= 0 && adjustedMinutes >= 0) {
        const totalSeconds = adjustedHours * 3600 + adjustedMinutes * 60

        this.selectedRecord.total_time_spent_seconds = totalSeconds
        this.selectedRecord.time_range = true

        // Set start_time to midnight UTC
        const today = new Date()
        today.setUTCHours(5, 0, 0, 0)
        this.selectedRecord.start_time = today.toISOString()

        this.selectedRecord.end_time = addSecondsToISOTime(
          this.selectedRecord.start_time,
          totalSeconds,
        )
      }
    },

    async handleAddTimeRange() {
      const { calendar_item_linked_items, ...calendarItemsRangeForm } =
        this.calendarItemsRangeForm

      this.calendarItemsRangeFormLoading = true

      await this.timerStore.handleStartTimer(
        {
          ...calendarItemsRangeForm,
        },
        {
          per_page: this.itemsPerPage,
          page: this.currentPage,
          sort_by_direction: 'desc',
          sort_by_field: 'start_time',
        },
      )

      this.calendarItemsRangeForm = {} as calendarItemsFormInterface
      this.timeRange = {
        hours: 0,
        minutes: 0,
      }
      this.calendarItemsRangeFormLoading = false
    },

    ///////////////////////////////////////// Global Search /////////////////////////////////////////////////

    handleGlobalSearch(bool: boolean) {
      if (bool) {
        this.$stores.globalSearch.calendarGlobalSearch = []
        this.$stores.globalSearch.handleGlobalSearch('', {
          individual_contacts_enabled: false,
          organization_contacts_enabled: false,
        })
      }
    },

    handleToggleAssignToMenu() {
      this.isItemAssignNew = !this.isItemAssignNew
    },
    handleToggleAssignUpdateToMenu() {
      this.isItemAssignUpdate = !this.isItemAssignUpdate
    },
  },

  computed: {
    showRecordOptionAsObject(): Record<number, boolean> {
      return Object.fromEntries(this.showRecordOption)
    },
    isTimeRecord(): boolean {
      return this.timerStore?.calendarItems[0]?.end_time !== null
    },
    runningTimes(oldValue: Record<number, string>): Record<number, string> {
      return this.$stores.mockTimer.calendarItems.reduce(
        (acc: Record<string, string>, item: CalendarItemsPresenter) => {
          if (!item.end_time) {
            const updatedTime = getRunningTimeFormatted(
              item.start_time,
              new Date(this.timestamp),
              // oldValue?.[item.id],
            )
            acc[item.id] = ` (${updatedTime})`
          }
          return acc
        },
        {},
      )
    },
  },

  watch: {
    calendarItemsRangeForm: {
      handler(data: calendarItemsFormInterface) {
        console.log({
          form: data,
        })
      },
      deep: true,
    },
    showRecord: {
      handler(data: boolean) {
        if (data) {
          this.calendarItemsForm = {} as calendarItemsFormInterface
          this.manualForm = {} as calendarItemsFormInterface
        }
      },
      deep: true,
    },
    isItemAssignNew: {
      handler(data) {
        this.handleGlobalSearch(data)
      },
    },
    isItemAssignUpdate: {
      handler(data) {
        this.handleGlobalSearch(data)
      },
    },
    selectedRecord: {
      handler(data) {
        console.log({ selectedRecord: data })
      },
      deep: true,
    },
    '$stores.mockTimer.calendarItems': {
      handler(data) {
        if (data && this.selectedRecord?.id) {
          this.selectedRecord = data.find(
            (item: { id: number }) => item.id === this.selectedRecord.id,
          )
        }
      },
      deep: true,
      immediate: true,
    },
  },

  created() {
    // Wrap the scroll handler with debounce for smoother performance
    this.onScrollTimeRecord = this.debounce(this.onScrollTimeRecord, 100)
  },

  mounted() {
    this.handleGetTimes()
    this.intervalId = setInterval(() => {
      this.timestamp = Date.now()
    }, 1000)
  },

  beforeUnmount() {
    // Clear interval when component is destroyed
    clearInterval(this.intervalId)
  },
})
