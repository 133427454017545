import {
  camelCaseToNormal,
  deepCopy,
  getOptionFullName,
  validateInput,
} from '@/lib/utils'
import { RolePresenter } from '@/presenters/RolePresenter'
import { TeamMemberPresenter } from '@/presenters/teamMember'
import { defineComponent } from 'vue'
import { useForm } from 'vuestic-ui/web-components'

type RoleForm = {
  id?: number
  name: string
  company_user_roles: TeamMemberPresenter[]
}

export default defineComponent({
  name: '',
  props: {
    isRoleModalOpen: {
      type: Boolean,
      required: true,
    },
    addRoleLoading: {
      type: Boolean,
      default: false,
    },
    updateRoleLoading: {
      type: Boolean,
      default: false,
    },
    getRoleLoading: {
      type: Boolean,
      default: false,
    },
    permissions: {
      type: {} as () => Record<string, Record<string, boolean>>,
      default: {} as Record<string, Record<string, boolean>>,
    },
    isUpdateRole: {
      type: Boolean,
      default: false,
    },
    selectedRole: {
      type: Object as () => RolePresenter,
      default: {} as RolePresenter,
    },
  },
  emits: [
    'handleToggleModal',
    'handleToggleSubmit',
    'handleToggleSubmitAdd',
    'handleToggleSubmitUpdate',
  ],
  data() {
    const { validate } = useForm('form')
    return {
      validate,
      getOptionFullName,
      camelCaseToNormal,
      validateInput,

      isRoleModalOpenBool: this.isRoleModalOpen,

      form: {} as RoleForm,
      rolePermissions: deepCopy(this.permissions) as Record<
        string,
        Record<string, boolean>
      >,
      isSelectAll: false,

      noOptionsText: 'press enter to add',
      debounceTimeout: null as ReturnType<typeof setTimeout> | null,
    }
  },
  methods: {
    handleCalculatePermissions(
      permissions: Record<string, Record<string, boolean>>,
    ) {
      const actionMap: Record<string, number> = {
        create: 1,
        read: 2,
        update: 4,
        delete: 8,
      }
      return Object.entries(permissions).map(
        ([resourceType, resourcePermissions]) => {
          const actions = Object.entries(actionMap).reduce(
            (acc, [key, bit]) => acc | (resourcePermissions[key] ? bit : 0),
            0,
          )
          return {
            resource_type: resourceType,
            actions,
            id: resourcePermissions.id,
          }
        },
      )
    },

    handleReversePermissions(
      calculatedPermissions: {
        resource_type: string
        actions: number
        id: number
      }[],
    ) {
      const actionMap: Record<string, number> = {
        create: 1,
        read: 2,
        update: 4,
        delete: 8,
      }

      return calculatedPermissions.reduce(
        (acc, { resource_type, actions, id }) => {
          acc[resource_type] = {
            ...Object.fromEntries(
              Object.entries(actionMap).map(([key, bit]) => [
                key,
                !!(actions & bit),
              ]),
            ),
            id,
          }

          return acc
        },
        {} as Record<string, Record<string, boolean | number>>,
      )
    },

    ///////////////////////////// actions /////////////////////////////

    handleToggleModal() {
      this.isRoleModalOpenBool = !this.isRoleModalOpenBool

      this.$emit('handleToggleModal')
    },

    handleToggleSubmitAdd() {
      this.$emit('handleToggleSubmitAdd', {
        ...this.form,
        role_permissions_attributes: this.handleCalculatePermissions(
          this.rolePermissions,
        ),
      })
    },

    handleToggleSubmitUpdate() {
      const { id, name } = this.form

      this.$emit('handleToggleSubmitUpdate', {
        id,
        name,
        role_permissions_attributes: this.handleCalculatePermissions(
          this.rolePermissions,
        ),
      })
    },

    handleToggleSubmit() {
      if (!this.isUpdateRole) {
        this.handleToggleSubmitAdd()
      } else {
        this.handleToggleSubmitUpdate()
      }
    },

    handleResetCheckbox() {
      this.rolePermissions = deepCopy(
        this.handleReversePermissions(this.selectedRole?.role_permissions),
      )
    },
  },
  computed: {
    submitButton(): string {
      return this.isUpdateRole ? 'Update Role' : 'Add Role'
    },
    headerTitle(): string {
      return this.isUpdateRole ? 'Update Role' : 'Add Role'
    },
  },
  watch: {
    isRoleModalOpen: {
      immediate: true,
      handler(data) {
        this.isRoleModalOpenBool = this.isRoleModalOpen
        if (data) {
          this.$stores.usersData.handleGetMembers()
        } else {
          this.rolePermissions = deepCopy(this.permissions)
        }
      },
    },
    permissions: {
      immediate: true,
      handler(data) {
        if (!this.isUpdateRole) {
          this.rolePermissions = deepCopy(data)
        }
      },
    },
    selectedRole: {
      immediate: true,
      handler(data) {
        if (data) {
          this.form = data
          if (data.role_permissions) {
            this.rolePermissions = deepCopy(
              this.handleReversePermissions(data?.role_permissions),
            )
          }
        }
      },
    },
    isSelectAll: {
      immediate: true,
      handler(data) {
        for (const resourceType in this.rolePermissions) {
          this.rolePermissions[resourceType] = {
            read: data,
            create: data,
            delete: data,
            update: data,
          }
        }
      },
    },
  },
})
