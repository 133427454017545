<template>
  <SharedHeader title-width="30rem" title="Hearings">
    <div class="sub-SharedHeader">
      <div class="sub-SharedHeader-left" />
      <div class="sub-SharedHeader-right" />
    </div>
  </SharedHeader>

  <!-- <SearchAndViews
    :activeView="activeView"
    :withViews="true"
    @changeView="changeView"
    @search="searchHearingData"
    :search="hearingSearchKeyword"
  /> -->

  <SearchAndViews :search="hearingSearchKeyword" @search="searchHearingData" />

  <ActionsBar
    :show-select-button="true"
    :show-download-icon="true"
    :show-archive-icon="true"
    :show-label-icon="true"
    :show-share-icon="true"
    :second-add-button="$t('actionsBar.addHearing')"
    :edit-columns="true"
    @secondButtonAction="toggleAddHearing"
  />

  <AddHearingModal
    :add-hearing-loading="addHearingLoading"
    :is-add-hearing="isAddHearing"
    @handleToggleAddModal="toggleAddHearing"
    @handleCreateHearing="handleCreateHearing"
  />

  <HearingsTable
    v-if="activeView === 'list'"
    :is-select="isSelect"
    :hearings="hearings"
    :loading="hearingTableLoading"
    @selectedRows="getSelectedRows"
  />

  <!-- <div class="hearing-card-view" v-if="activeView === 'board'">
    <div class="board-container">
      <div class="scrollable-cards">
        <HearingCards
          v-for="(card, index) in cardsList"
          :key="index"
          :data="card"
          testy="testy"
        />
      </div>
    </div>
  </div> -->
</template>
<script lang="ts" src="./script.ts" />
<style lang="scss" src="./styles.scss" scoped></style>
