import { RolePresenter } from '@/presenters/RolePresenter'
import { apiRequest } from './apiUtils'
import { AddUpdateRoleInterface } from '@/Interfaces/Services'

export const getRoles = async () => {
  const response = await apiRequest('get', '/roles')

  if (!response.success) {
    return response
  }

  const list = response.data?.list
  if (!Array.isArray(list)) {
    return response
  }

  return {
    ...response,
    list: list.map((item) => new RolePresenter(item)),
  }
}

export const getRoleById = async ({ id }: { id: number }) => {
  const response = await apiRequest('get', `/roles/${id}`)

  if (!response.success) {
    return response
  }

  return {
    ...response,
    data: new RolePresenter(response.data),
  }
}

export const updateRole = async ({
  id,
  body,
}: {
  id: number
  body: AddUpdateRoleInterface
}) => {
  return await apiRequest('put', `/roles/${id}`, body)
}

export const addRole = async ({ body }: { body: AddUpdateRoleInterface }) => {
  return await apiRequest('post', '/roles', body)
}

export const deleteRole = async ({ id }: { id: number }) => {
  return await apiRequest('delete', `/roles/${id}`)
}
