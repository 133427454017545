<template>
  <div class="scroll-container-wrapper for-tables">
    <v-data-table-server
      v-model:selection="selectedRows"
      :loading="loading"
      :headers="headers"
      :items="matters"
      show-select
      class="custom-table"
      :items-length="localTotalItems"
      :items-per-page="itemsPerPage"
      :items-per-page-options="[10, 25, 50]"
      @update:options="handleLoadMore"
      :fixed-header="true"
      :fixed-footer="true"
    >
      <template #[`item.name`]="{ item }">
        <router-link :to="`/legal-matters/${item?.id}`">
          <td class="matters-client-td">
            {{ item?.name }}
          </td>
        </router-link>
      </template>

      <template #[`item.client`]="{ item }">
        <td class="matters-client-td">
          <VaAvatar
            color="#ECF0F1"
            v-if="
              item.representing_contact?.client_avatar_letter ||
              item.avatar?.medium_url
            "
            :src="item.avatar?.medium_url"
          >
            <span v-if="!item.avatar?.medium_url">{{
              item.representing_contact?.client_avatar_letter
            }}</span>
          </VaAvatar>
          {{ item.representing_contact?.fullName }}
        </td>
      </template>

      <template #[`item.leadLawyers`]="{ item }">
        <td class="matters-leadLawyers-td">
          <VaAvatar
            color="#ECF0F1"
            v-if="item.personnel?.[0]"
            :src="item.personnel?.[0]?.avatar?.medium_url"
          >
            <span v-if="!item.personnel?.[0]?.avatar?.medium_url">{{
              item.personnel[0]?.avatar_letter
            }}</span>
          </VaAvatar>
          {{ item.personnel[0]?.fullName }}

          <span v-if="item.personnel.length > 1">
            <VaPopover placement="top" color="#F5F0F3">
              <p>+{{ item.personnel.length - 1 }} others</p>
              <template #body>
                <span
                  v-for="(person, index) in item.personnel"
                  :key="index"
                  class="matters-leadLawyers-td"
                >
                  <v-avatar color="#7F325E" size="32">
                    <span class="white--text">{{ person.avatar_letter }}</span>
                  </v-avatar>

                  {{ person.fullName }}
                </span>
              </template>
            </VaPopover>
          </span>
        </td>
      </template>
      <template #[`item.lawyers`]="{ item }">
        <!-- <td
          v-for="(person, index) in item.case_manager"
          :key="index"
          class="matters-leadLawyers-td"
        >
          <VaAvatar color="#ECF0F1" color="#ECF0F1">
            <span>{{ person.avatar_letter }}</span>
          </VaAvatar>
          {{ person.fullName }}
        </td> -->
        <td class="matters-leadLawyers-td">
          <VaAvatar
            color="#ECF0F1"
            v-if="item.case_manager?.[0]"
            :src="item.case_manager?.[0]?.avatar?.medium_url"
          >
            <span v-if="!item.case_manager?.[0]?.avatar?.medium_url">{{
              item.case_manager[0]?.avatar_letter
            }}</span>
          </VaAvatar>
          {{ item.case_manager[0]?.fullName }}

          <span v-if="item.case_manager.length > 1">
            <VaPopover placement="top" color="#F5F0F3">
              <p>+{{ item.case_manager.length - 1 }} others</p>
              <template #body>
                <span
                  v-for="(person, index) in item.case_manager"
                  :key="index"
                  class="matters-leadLawyers-td"
                >
                  <v-avatar color="#7F325E" size="32">
                    <span class="white--text">{{ person.avatar_letter }}</span>
                  </v-avatar>

                  {{ person.fullName }}
                </span>
              </template>
            </VaPopover>
          </span>
        </td>
      </template>

      <template #[`item.jurisdiction`]="{ item }">
        <td>
          {{ item.jurisdriction_id }}
        </td>
      </template>

      <template #[`item.status`]="{ item }">
        <td
          class="matters-status-td"
          :style="{ backgroundColor: item?.status?.color }"
        >
          {{ item.status?.name }}
        </td>
      </template>

      <template #[`item.specialization`]="{ item }">
        <td
          v-if="item.specialization?.name"
          class="matters-status-td"
          :style="{
            backgroundColor: item?.specialization?.color || '#babfc0',
          }"
        >
          {{ item.specialization?.name }}
        </td>
      </template>

      <template #[`item.category`]="{ item }">
        <td
          v-if="item.category?.name"
          class="matters-status-td"
          :style="{ backgroundColor: item?.category?.color || '#babfc0' }"
        >
          {{ item.category?.name }}
        </td>
      </template>

      <template #[`item.stage`]="{ item }">
        <td
          v-if="item.section_stage_items?.[0]?.section_stage?.name"
          class="matters-status-td"
          :style="{
            backgroundColor:
              item.section_stage_items?.[0]?.section_stage?.color || '#babfc0',
          }"
        >
          {{ item.section_stage_items?.[0]?.section_stage?.name }}
        </td>
      </template>

      <template #[`item.created_at`]="{ item }">
        <td>
          {{ formatDate(item.created_at) }}
        </td>
      </template>

      <template #[`item.modifiedDate`]="{ item }">
        <td>
          {{ formatDate(item.due_date) }}
        </td>
      </template>

      <template #[`item.latestNotes`]="{ item }">
        <td class="matter-note-td">
          {{ item.notes }}
        </td>
      </template>

      <template #[`item.action`]="{ item }">
        <td class="table-action-td">
          <va-dropdown :offset="[0, -70]" close-on-content-click>
            <template #anchor>
              <img
                :src="MoreVertIcon"
                alt="MoreVertIcon"
                class="press-button-2"
              />
            </template>
            <va-dropdown-content class="dropdown">
              <p @click="viewProfile(item)">
                <va-icon name="person" /> View Profile
              </p>
              <p v-if="unLink" @click="handleUnLink(item)">
                <va-icon name="link_off" /> Unlink Matter
              </p>
            </va-dropdown-content>
          </va-dropdown>
        </td>
      </template>
    </v-data-table-server>
  </div>
</template>

<script lang="ts" src="./script.ts" />
<style lang="scss" src="./styles.scss" scoped></style>
