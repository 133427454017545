import { CurrenciesPresenter } from '../CurrenciesPresenter'
import { TeamMemberPresenter } from '../teamMember'
import { TimezonesInterface } from '@/Interfaces/Constants'
import { timezoneOptions } from '@/constants/timezones'
import { RolePresenter } from '../RolePresenter'
import { Avatar } from '../interface'
import { CompanyPresenter } from '../CompanyPresenter'

export class CompanyUserPresenter {
  id!: number
  basic_salary_amount!: string | number
  basic_salary_currency!: CurrenciesPresenter
  basic_salary_frequency!:
    | 'daily'
    | 'weekly'
    | 'biweekly'
    | 'monthly'
    | 'quarterly'
    | 'annually'
  billable_hourly_rate_charging_amount!: string | number
  billable_hourly_rate_charging_currency!: CurrenciesPresenter
  billable_hourly_rate_earning_amount!: string | number
  billable_hourly_rate_earning_currency!: CurrenciesPresenter

  calendar_timezones!: string[]
  calendar_timezones_options!: TimezonesInterface[]

  class_name!: string
  created_at!: string
  role!: string
  company_id!: number

  company!: CompanyPresenter

  roles?: RolePresenter[]
  updated_at!: string
  user_id!: number
  fullName!: string
  avatar_letter!: string
  roles_extended!: Record<string, Record<string, boolean>>

  user!: TeamMemberPresenter

  roleable_type!: string // we need to remove this and update the announcement section

  avatar!: Avatar

  constructor(data: CompanyUserPresenter) {
    Object.assign(this, data)

    this.calendar_timezones = data.calendar_timezones || []
    this.calendar_timezones_options = data.calendar_timezones_options || []

    if (
      this.calendar_timezones.length === 1 &&
      this.calendar_timezones[0] === ''
    ) {
      this.calendar_timezones = []
    }

    if (this.calendar_timezones.length > 0) {
      this.calendar_timezones_options = this.calendar_timezones.map((tz) =>
        timezoneOptions.find((option) => option.timezone === tz),
      ) as TimezonesInterface[]
    }

    if (data.roles) {
      this.roles = data.roles.map(
        (role: RolePresenter) => new RolePresenter(role),
      )
    }

    if (data.company) {
      this.company = new CompanyPresenter(data.company)
    }

    this.user = new TeamMemberPresenter(data.user)
    this.fullName = this.user.fullName

    this.avatar_letter = [
      this.user.first_name?.charAt(0),
      this.user.last_name?.charAt(0),
    ]
      .filter(Boolean)
      .join(' ')

    this.avatar = this.user?.avatar || ({} as Avatar)
  }
}
