import { SectionsPresenter } from '@/presenters/SectionsPresenter'
import { defineComponent } from 'vue'
import draggable from 'vuedraggable'
import TablesConfigModal from '@/components/TablesConfigModal/index.vue'
import { TableColumnInterface } from '@/Interfaces/Constants'
import { updateCompanyConfiguration } from '@/services/companyConfigurations'
import { CompanyConfigurationPresenter } from '@/presenters/companyConfigPresenters'

export default defineComponent({
  name: '',
  data() {
    return {
      isSectionsLoading: false,
      sections: [] as SectionsPresenter[],

      isTableConfigUpdate: false,
      selectedSection: {} as SectionsPresenter,

      isTableConfigUpdateLoading: false,
    }
  },
  methods: {
    handleToggleTableConfig(section?: SectionsPresenter) {
      if (section) {
        this.selectedSection = section
      } else {
        this.selectedSection = {} as SectionsPresenter
      }
      this.isTableConfigUpdate = !this.isTableConfigUpdate
    },
    //////////////////////////////////////////////////////////////////////////////

    async handleSubmitTableConfig(headersTemplate: TableColumnInterface[]) {
      this.isTableConfigUpdateLoading = true

      const selectedSection =
        this.$stores.generalData.companyConfigs[
          this.selectedSection
            .type as keyof typeof this.$stores.generalData.companyConfigs
        ]?.[0]

      const response = await updateCompanyConfiguration({
        ...selectedSection,
        data: { columns: headersTemplate },
      } as CompanyConfigurationPresenter)

      if (response.success) {
        await this.$stores.generalData.handleGetCompanyConfigurations()
        this.handleToggleTableConfig()
      } else {
        this.$vaToast.init({
          message: response.message,
          color: 'danger',
        })
      }

      this.isTableConfigUpdateLoading = false
    },
  },
  computed: {
    fixedSections(): any[] {
      return [
        {
          id: 2727272,
          fixed: true,
          name: 'All Leads',
          type: 'leads_all_table_columns',
          listing_filters: {
            contact_type: 'Leads',
          },
          table_columns:
            this.$stores.generalData.companyConfigs.leads_all_table_columns?.[0]
              .data.columns || [],
        },
      ]
    },
  },
  components: {
    draggable,
    TablesConfigModal,
  },
})
