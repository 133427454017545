import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7fb19ad0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "create-team-form-header" }
const _hoisted_2 = { class: "circular-image-upload-modal-container" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "add-team-form-modal-buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VaIcon = _resolveComponent("VaIcon")!
  const _component_VaFileUpload = _resolveComponent("VaFileUpload")!
  const _component_VaInput = _resolveComponent("VaInput")!
  const _component_VaSelect = _resolveComponent("VaSelect")!
  const _component_VaTextarea = _resolveComponent("VaTextarea")!
  const _component_VaForm = _resolveComponent("VaForm")!
  const _component_VaButton = _resolveComponent("VaButton")!
  const _component_VaModal = _resolveComponent("VaModal")!

  return (_openBlock(), _createBlock(_component_VaModal, {
    modelValue: _ctx.isUpdateTeamModalOpenBool,
    "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.isUpdateTeamModalOpenBool) = $event)),
    size: "small",
    "hide-default-actions": "",
    "fixed-layout": "",
    noOutsideDismiss: "",
    "before-close": _ctx.beforeUpdateTeamModalClose
  }, {
    header: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("h1", null, _toDisplayString(_ctx.teamActionTitle), 1),
        _createVNode(_component_VaIcon, {
          class: "header-icon",
          name: "close",
          size: "30px",
          onClick: _ctx.handleCancel
        }, null, 8, ["onClick"])
      ])
    ]),
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_VaButton, {
          preset: "secondary",
          "border-color": "primary",
          onClick: _ctx.handleCancel
        }, {
          default: _withCtx(() => [
            _createTextVNode("Cancel")
          ]),
          _: 1
        }, 8, ["onClick"]),
        _createVNode(_component_VaButton, {
          onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.validate() && _ctx.handleUpdateTeam())),
          loading: _ctx.updateTeamLoading
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.teamActionTitle), 1)
          ]),
          _: 1
        }, 8, ["loading"])
      ])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_VaForm, {
        ref: "form",
        class: "create-team-form",
        onSubmit: _withModifiers(_ctx.validate, ["prevent"])
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_VaFileUpload, {
              hideFileList: "true",
              modelValue: _ctx.form.image,
              "onUpdate:modelValue": [
                _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.image) = $event)),
                _ctx.handleFileUpload
              ],
              "file-types": _ctx.$stores.generalData.imagesFormats,
              type: "single"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", null, [
                  _createElementVNode("img", {
                    src: _ctx.form?.avatar?.medium_url || _ctx.UploadAvatarIcon,
                    alt: "Avatar",
                    class: "avatar-preview"
                  }, null, 8, _hoisted_3)
                ]),
                (_ctx.form?.avatar?.medium_url)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      class: "close-icon press-button",
                      onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleRemoveImage && _ctx.handleRemoveImage(...args)), ["stop"]))
                    }, " ✕ "))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["modelValue", "file-types", "onUpdate:modelValue"])
          ]),
          _createVNode(_component_VaInput, {
            placeholder: "Enter team's name",
            background: "#ECF0F1",
            modelValue: _ctx.form.name,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.form.name) = $event)),
            label: "Team Name"
          }, null, 8, ["modelValue"]),
          _createVNode(_component_VaSelect, {
            placeholder: "Workspace",
            background: "#ECF0F1",
            modelValue: _ctx.form.parentWorkspace,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.form.parentWorkspace) = $event)),
            label: "Team Parent",
            searchable: "",
            clearable: "",
            options: _ctx.workspaceSearchOptions,
            "highlight-matched-text": false
          }, null, 8, ["modelValue", "options"]),
          _createVNode(_component_VaSelect, {
            placeholder: "Team",
            background: "#ECF0F1",
            modelValue: _ctx.form.parentTeam,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.form.parentTeam) = $event)),
            searchable: "",
            clearable: "",
            options: _ctx.teamSearchOptions,
            "highlight-matched-text": false
          }, null, 8, ["modelValue", "options"]),
          _createVNode(_component_VaSelect, {
            placeholder: "Team Manager",
            background: "#ECF0F1",
            modelValue: _ctx.form.manager,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.form.manager) = $event)),
            label: "Team Manager",
            searchable: "",
            options: _ctx.$stores.usersData.teamMembers,
            "text-by": _ctx.getOptionFullName,
            "highlight-matched-text": false
          }, null, 8, ["modelValue", "options", "text-by"]),
          _createVNode(_component_VaSelect, {
            placeholder: "Members",
            background: "#ECF0F1",
            modelValue: _ctx.form.members,
            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.form.members) = $event)),
            label: "Team Members",
            searchable: "",
            options: _ctx.$stores.usersData.teamMembers,
            "text-by": _ctx.getOptionFullName,
            "highlight-matched-text": false,
            multiple: ""
          }, null, 8, ["modelValue", "options", "text-by"]),
          _createVNode(_component_VaTextarea, {
            modelValue: _ctx.form.description,
            "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.form.description) = $event)),
            "max-rows": "5",
            background: "#ECF0F1",
            label: "Team Bio",
            placeholder: "Write here the team's bio",
            autosize: "",
            class: "text-area"
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      }, 8, ["onSubmit"])
    ]),
    _: 1
  }, 8, ["modelValue", "before-close"]))
}