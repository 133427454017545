import { defineComponent } from 'vue'
import KanbanColumn from '@/components/kanbanColumn/index.vue'
import TabsBar from '@/components/tabsBar/index.vue'
import SearchAndViews from '@/components/searchAndViews/index.vue'
import ActionsBar from '@/components/actionsBar/index.vue'
import ComingSoon from '@/components/ComingSoon/index.vue'
import AllContactsTable from '@/components/Contacts/AllContacts/Table/index.vue'
import AddContactModal from '@/components/Contacts/AddContactModal/index.vue'
import AddOrganizationModal from '@/components/Organizations/AddOrganizationModal/index.vue'
import ContactCard from '@/components/Contacts/AllContacts/Card/index.vue'
import SharedHeader from '@/components/sharedHeader/index.vue'
import {
  addIndividualContact,
  addOrganizationContact,
  getContacts,
} from '@/services/contacts'
import { useTabs } from '@/composables/useTabs'

import { getAllSections } from '@/services/sections'

import { SectionsPresenter } from '@/presenters/SectionsPresenter'
import { LocationQueryValue } from 'vue-router'
import { TableColumnInterface } from '@/Interfaces/Constants'

import { peopleTemplateHeaders } from '@/constants/Tables/peopleTemplate'
import { organizationsTemplateHeaders } from '@/constants/Tables/organizationsTemplate'

import SectionLeads from '@/views/Leads/index.vue'
import { TableDataExporter } from '@/constants/Tables'
import { ContactType } from '@/Interfaces/Services'

const MoreVertIcon = new URL(
  '@/assets/icons/More-vert-icon.svg',
  import.meta.url,
).href

export default defineComponent({
  name: 'ContactsPage',

  data() {
    const sections = [] as SectionsPresenter[]

    const { activeTabId, activeTab } = useTabs(sections)

    return {
      addEntryLoading: false,
      activeTabId,
      activeTab,
      headers: [] as TableColumnInterface[],
      section: {} as SectionsPresenter,

      selectedRows: [],

      isImportContacts: false,
      uploadFilesList: [],

      isAddOrganizationForm: false,
      isAddContact: false,
      isAddContactForm: false,

      tableLoading: false,

      tableData: [] as any[],
      searchKeyword: '',
      itemsPerPage: 10,
      sortField: '' as
        | 'individual_first_name'
        | 'individual_last_name'
        | 'organization_name'
        | undefined,
      sortDirection: '' as 'asc' | 'desc' | undefined,
      page: 1,
      totalItems: 0,

      debounceTimeout: null as ReturnType<typeof setTimeout> | null,

      isSectionsLoading: false,
      sections,

      peopleTemplateHeaders,
      organizationsTemplateHeaders,
      MoreVertIcon,

      isShowUpdateLayoutModal: false,

      leadsSucSections: [] as SectionsPresenter[],
    }
  },

  methods: {
    getSelectedRows(rows: any) {
      this.selectedRows = rows
    },

    handleToggleImportContacts() {
      this.isImportContacts = true
    },

    async handleTabChange({ tab: section }: { tab: SectionsPresenter }) {
      this.tableData = []
      this.$router.push({ query: { tab: section.name } })
    },

    handleToggleUpdateLayoutModal() {
      this.isShowUpdateLayoutModal = !this.isShowUpdateLayoutModal
    },

    ////////////////////////////////////////////////////////////////////
    searchData(search: string) {
      this.searchKeyword = search
      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout)
      }
      this.debounceTimeout = setTimeout(() => {
        this.handleGetContacts()
      }, 500)
    },

    ////////////////////////////////////////////////////////////////////

    toggleAddContactForm() {
      this.isAddContact = false
      this.isAddContactForm = true
    },

    toggleAddOrganizationForm() {
      this.isAddOrganizationForm = true
    },

    cancelForm() {
      this.isAddContactForm = false
      this.isAddOrganizationForm = false
    },

    async handleGetContacts() {
      if (!this.section?.listing_filters) return
      this.tableLoading = true
      console.log({ section: this.section })

      const { listing_filters } = this.section
      if (!this.section.listing_filters.contact_type)
        listing_filters.contact_type = ContactType.IndividualContact

      const response = await getContacts({
        ...listing_filters,
        per_page: this.itemsPerPage,
        page: this.page,
        sort_by_field: this.sortField,
        sort_by_direction: this.sortDirection,
        keyword: this.searchKeyword,
      })

      if (response.success) {
        this.tableData = response.data?.list
        this.totalItems = response.data.total_pages * this.itemsPerPage
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }

      this.tableLoading = false
    },

    ////////////////////////////////////////////////////////////////////////////

    async handleCreateEntry(form: any) {
      this.addEntryLoading = true
      const { listing_filters } = this.section
      if (!this.section.listing_filters.contact_type)
        listing_filters.contact_type = ContactType.IndividualContact

      const contactFunctions = {
        [ContactType.IndividualContact]: addIndividualContact,
        [ContactType.OrganizationContact]: addOrganizationContact,
      }
      if (listing_filters.contact_type) {
        const response = await contactFunctions[listing_filters.contact_type](
          form,
        )
        if (response.success) {
          await this.handleGetContacts()
          this.$vaToast.init({
            message: 'Contact created successfully!',
            color: 'success',
          })
          this.cancelForm()
        } else {
          this.$vaToast.init({
            message: response.message,
            color: 'danger',
          })
        }
      }

      this.addEntryLoading = false
    },

    ////////////////////////////////////////////////////////////////////////////

    async handlePagination({ page, itemsPerPage, sortBy }: any) {
      this.page = page
      this.itemsPerPage = itemsPerPage
      if (sortBy.length > 0) {
        this.sortField = sortBy[0].key.split('.')[1]
        this.sortDirection = sortBy[0].order
      } else {
        this.sortField = undefined
        this.sortDirection = undefined
      }

      await this.handleGetContacts()
    },

    ////////////////////////////////////////////////////////////////////////////

    async handleGetAllSections() {
      const queryTab = this.$route.query.tab
      this.isSectionsLoading = true

      const contactRes = await getAllSections('contacts')
      if (contactRes.success) {
        this.sections = [...this.fixedSections, ...contactRes.data?.list]

        this.setActiveTabFromQuery(queryTab as LocationQueryValue)
      } else {
        this.$vaToast.init({
          message: contactRes?.message,
          color: 'danger',
        })
      }

      this.isSectionsLoading = false
    },

    setActiveTabFromQuery(queryTab: LocationQueryValue) {
      if (!this.sections || this.sections.length === 0) return

      const foundTab = queryTab
        ? this.sections.find((tab) => tab.name === queryTab)
        : this.sections[1] // Default to second section if no match

      if (!foundTab) return

      const data = foundTab || this.sections[1]

      console.log({ data })

      this.activeTabId = data?.id
      this.activeTab = data?.name
      this.section = data
      this.headers =
        data?.table_columns?.length > 0
          ? data?.table_columns
          : TableDataExporter.getTableColumns(
              data?.listing_filters?.contact_type as string,
            )

      this.handleGetContacts()
    },

    ////////////////////////////////////////////////////////////////////////////
  },

  computed: {
    fixedSections() {
      return [
        {
          id: 1717171,
          name: 'Dashboard',
        },
        {
          id: 2727272,
          name: 'People',
          listing_filters: {
            contact_type: 'IndividualContact',
          },
          table_columns:
            this.$stores.generalData.companyConfigs
              .individual_contact_table_fields?.[0].data.columns || [],
        },
        {
          id: 3737373,
          name: 'Organizations',
          listing_filters: {
            contact_type: 'OrganizationContact',
          },
          table_columns:
            this.$stores.generalData.companyConfigs
              .organization_contact_table_fields?.[0].data.columns || [],
        },
      ]
    },
  },

  mounted() {
    this.handleGetAllSections()
  },

  watch: {
    '$route.query.tab': async function (newTab) {
      this.setActiveTabFromQuery(newTab as LocationQueryValue)
    },
  },

  components: {
    SharedHeader,
    AllContactsTable,
    SearchAndViews,
    KanbanColumn,
    ActionsBar,
    TabsBar,
    ComingSoon,
    ContactCard,
    AddContactModal,
    AddOrganizationModal,

    SectionLeads,
  },
})
