import { StorageFilePresenter } from '@/presenters/StorageFilePresenter'
import { apiRequest } from './apiUtils'
import { FilesAddInterface, FilesUpdateInterface } from '@/Interfaces/Services'

export const getStorageFileById = async (id: number) => {
  const response = await apiRequest('get', `/storage_files/${id}`)
  if (response.success) {
    return {
      ...response,
      data: new StorageFilePresenter(response.data),
    }
  } else {
    return response
  }
}

export const addStorageFile = (body: FilesAddInterface) => {
  const formData = new FormData()

  // Convert FilesAddInterface to FormData
  Object.keys(body).forEach((key) => {
    const value = body[key as keyof FilesAddInterface]
    if (value !== undefined && value !== null) {
      if (key === 'file') {
        formData.append(key, value as File) // Add the file
      } else {
        formData.append(key, value.toString()) // Convert other values to strings
      }
    }
  })

  return apiRequest('post', '/storage_files', formData)
}

export const updateStorageFile = (id: number, body: FilesUpdateInterface) => {
  return apiRequest('put', `/storage_files/${id}`, body)
}

export const deleteStorageFile = (id: number) => {
  return apiRequest('delete', `/storage_files/${id}`)
}
