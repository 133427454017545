import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4dc39fce"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "modal-form-header" }
const _hoisted_2 = { class: "body" }
const _hoisted_3 = { class: "form-modal-buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VaIcon = _resolveComponent("VaIcon")!
  const _component_VaInput = _resolveComponent("VaInput")!
  const _component_VaSelect = _resolveComponent("VaSelect")!
  const _component_VaCheckbox = _resolveComponent("VaCheckbox")!
  const _component_VaButton = _resolveComponent("VaButton")!
  const _component_VaModal = _resolveComponent("VaModal")!

  return (_openBlock(), _createBlock(_component_VaModal, {
    modelValue: _ctx.isAddContactFilterBool,
    "onUpdate:modelValue": [
      _cache[4] || (_cache[4] = ($event: any) => ((_ctx.isAddContactFilterBool) = $event)),
      _ctx.handleToggleAddModalFilter
    ],
    size: "auto",
    "hide-default-actions": "",
    "fixed-layout": "",
    "no-outside-dismiss": ""
  }, {
    header: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("h1", null, _toDisplayString(_ctx.modalTitle), 1),
        _createVNode(_component_VaIcon, {
          class: "press-button",
          name: "close",
          size: "30px",
          onClick: _ctx.handleToggleAddModalFilter
        }, null, 8, ["onClick"])
      ])
    ]),
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_VaButton, {
          preset: "secondary",
          "border-color": "primary",
          onClick: _ctx.handleToggleAddModalFilter
        }, {
          default: _withCtx(() => [
            _createTextVNode(" Cancel ")
          ]),
          _: 1
        }, 8, ["onClick"]),
        _createVNode(_component_VaButton, {
          onClick: _ctx.handleSubmitFilter,
          loading: _ctx.isAddContactFilterLoading
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.submitButton), 1)
          ]),
          _: 1
        }, 8, ["onClick", "loading"])
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", null, [
          _createVNode(_component_VaInput, {
            modelValue: _ctx.name,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.name) = $event)),
            placeholder: "Name",
            background: "#ECF0F1",
            label: "Name"
          }, null, 8, ["modelValue"])
        ]),
        _createElementVNode("div", null, [
          _createVNode(_component_VaSelect, {
            placeholder: "Contact Type",
            background: "#ECF0F1",
            modelValue: _ctx.listing_filters.contact_type,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.listing_filters.contact_type) = $event)),
            label: "Contact Type",
            options: _ctx.contactTypeOptions,
            "track-by": "value",
            "value-by": "value"
          }, null, 8, ["modelValue", "options"])
        ]),
        _createElementVNode("div", null, [
          _createVNode(_component_VaCheckbox, {
            modelValue: _ctx.listing_filters.client,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.listing_filters.client) = $event)),
            label: "Is Client"
          }, null, 8, ["modelValue"])
        ]),
        _createElementVNode("div", null, [
          _createVNode(_component_VaCheckbox, {
            modelValue: _ctx.listing_filters.has_active_legal_matters,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.listing_filters.has_active_legal_matters) = $event)),
            label: "Has Active Legal Matter"
          }, null, 8, ["modelValue"])
        ])
      ])
    ]),
    _: 1
  }, 8, ["modelValue", "onUpdate:modelValue"]))
}