<template>
  <ActionsBar
    second-add-button="Add New Space"
    @secondButtonAction="toggleAddWorkspaceModal"
  />
  <SearchAndViews @search="searchWorkspacesData" />
  <AddWorkspaceModal
    @handleAddWorkspace="handleAddWorkspace"
    @toggleAddWorkspaceModal="toggleAddWorkspaceModal"
    :isWorkspaceModalOpen="isWorkspaceModalOpen"
  />
  <div class="scroll-container">
    <div>
      <div v-for="(company, index) in workspaces" :key="index">
        <div class="user-container">
          <div>
            <div class="workspace">
              <VaAvatar color="#F5F0F3" square>
                {{ company.company.name[0] }}
              </VaAvatar>
              <div class="workspace-data">
                <h2>{{ company.company.name }}</h2>
              </div>
            </div>
            <span class="online-members"
              >{{ company.company.company_users_count }} of
              {{ company.company.company_users_count }} members online</span
            >
          </div>

          <div class="action-option">
            <va-dropdown :offset="[0, -70]" close-on-content-click>
              <template #anchor>
                <img
                  :src="MoreVertIcon"
                  class="workspace-action-option-img press-button-2"
                  alt="MoreVertIcon"
                />
              </template>
              <va-dropdown-content class="dropdown">
                <p @click="handleChangeWorkspace(company)">Select Space</p>
                <p>Assign Matter</p>
                <p>KPI & Targets</p>
              </va-dropdown-content>
            </va-dropdown>
          </div>
        </div>
        <VaDivider />
      </div>
    </div>
  </div>
</template>
<script lang="ts" src="./script.ts" />
<style lang="scss" scoped src="./styles.scss" />
