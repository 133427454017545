import { defineComponent } from 'vue'
import AddButton from '@/components/addButton/index.vue'

export default defineComponent({
  name: 'TabsBar',
  props: {
    tabs: {
      type: Array as any,
      required: true,
    },
    activeTabId: {
      type: Number,
      required: true,
    },
    fullTab: {
      type: Boolean,
      default: false,
    },
    gapSize: {
      type: String,
      default: '1.5rem',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    addButton: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    AddButton,
  },
  emits: ['changeTab', 'addTab', 'editTab', 'addButtonFunction'],
  data() {
    return {
      hoveredTabId: null as number | null,
      IsTabOpen: false,
      value: '',
    }
  },
  watch: {},
  methods: {
    changeTab(tabId: number) {
      const tab = this.tabs.find((tabb: { id: number }) => tabb.id === tabId)
      this.$emit('changeTab', { tab })
    },
    handleAddTab() {
      this.$emit('addTab', this.value)
    },
    handleOpenAddTab() {
      this.IsTabOpen = true
    },
    handleEditTab() {
      this.$emit('editTab')
    },
    handleAddButtonFunction() {
      this.$emit('addButtonFunction')
    },
  },
})
