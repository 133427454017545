import { CurrencyPresenter } from '../companyConfigPresenters'
import { IndividualContactPresenter } from '../contactPresenter'
import { MatterPresenter } from '../matterPresenter'
import { OrganizationContactPresenter } from '../organizationPresenter'
import { TeamMemberPresenter } from '../teamMember'

type classMapping = {
  LegalMatter: MatterPresenter
  IndividualContact: IndividualContactPresenter
  OrganizationContact: OrganizationContactPresenter
}

const classType = {
  LegalMatter: MatterPresenter,
  IndividualContact: IndividualContactPresenter,
  OrganizationContact: OrganizationContactPresenter,
} as const

type classTypeKey = keyof typeof classType

export class ExpensesPresenter {
  id!: number
  billed_to!: classMapping[classTypeKey]
  category!: string
  class_name!: 'Expense'
  currency!: CurrencyPresenter
  declared_amount!: string | number
  description!: string
  due_date!: string
  expense_type!: string
  paid_by!: TeamMemberPresenter
  name!: string
  quantity!: number
  status!: string
  total_amount!: string | number
  total_tax!: string | number
  unit_price!: string | number
  related_to!: classMapping[classTypeKey]

  constructor(data: ExpensesPresenter) {
    Object.assign(this, data)

    const BilledToPresenter = classType[data.billed_to?.class_name]
    if (BilledToPresenter && data.billed_to) {
      this.billed_to = new BilledToPresenter(data.billed_to as never)
    }

    if (data.paid_by) {
      this.paid_by = new TeamMemberPresenter(data.paid_by)
    }

    if (data.currency) {
      this.currency = new CurrencyPresenter(data.currency)
    }

    const RelatedToPresenter = classType[data.related_to?.class_name]
    if (RelatedToPresenter && data.related_to) {
      this.related_to = new RelatedToPresenter(data.related_to as any)
    }
  }
}
