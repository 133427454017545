import { defineComponent, computed, ref } from 'vue'
import { InvoicePresenter } from '@/presenters/InvoicesPresenter'
import { useVueToPrint } from 'vue-to-print'
import { mockInvoicePrintTemplate } from '@/constants/mockInvoicePrintTemplate'
import DynamicSections from './DynamicSection/DynamicSections.vue'

const logoImage = new URL('@/assets/icons/logo.svg', import.meta.url).href

export default defineComponent({
  name: 'InvoiceComponent',
  components: {
    DynamicSections,
  },
  props: {
    form: {
      type: Object as () => InvoicePresenter,
      required: true,
    },
    subtotal: {
      type: String,
      required: true,
    },
    total: {
      type: String,
      required: true,
    },
    convertToSecondaryCurrencyTotal: {
      type: String,
      required: true,
    },
    convertToSecondaryCurrencySubTotal: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
  setup(props) {
    const componentRef = ref<HTMLElement | null>(null)
    const { handlePrint } = useVueToPrint({
      content: () => componentRef.value as HTMLElement,
      documentTitle: 'Invoice',
    })

    console.log({ props: props.form })

    // Get raw template data (without grouping)
    const templateDataHeader = computed(
      () =>
        props.form?.template?.data?.header ||
        mockInvoicePrintTemplate?.data?.header,
    )
    const templateDataBody = computed(
      () =>
        props.form?.template?.data?.body || mockInvoicePrintTemplate.data.body,
    )
    const templateDataFooter = computed(
      () =>
        props.form?.template?.data?.footer ||
        mockInvoicePrintTemplate.data.footer,
    )

    // Check if discount exists
    const isDiscountItemsAmount = computed(() =>
      props.form.invoice_items?.some((item) => item?.discount_type_amount > 0),
    )

    return {
      componentRef,
      handlePrint,
      templateDataHeader,
      templateDataBody,
      templateDataFooter,
      isDiscountItemsAmount,
      logoImage,
    }
  },
})
