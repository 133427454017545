import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createSlots as _createSlots, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-207b64ca"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "toolbar-container" }
const _hoisted_2 = {
  key: 0,
  class: "toolbar-content"
}
const _hoisted_3 = { class: "toolbar-content" }
const _hoisted_4 = { class: "toolbar" }
const _hoisted_5 = { class: "toolbar-header" }
const _hoisted_6 = { class: "toolbar-actions" }
const _hoisted_7 = { class: "cards-count" }
const _hoisted_8 = {
  key: 0,
  class: "icon"
}
const _hoisted_9 = { class: "toolbar-update" }
const _hoisted_10 = { class: "toolbar-update-modify press-button" }
const _hoisted_11 = { class: "toolbar-add-section" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VaModal = _resolveComponent("VaModal")!
  const _component_VaSkeleton = _resolveComponent("VaSkeleton")!
  const _component_VaIcon = _resolveComponent("VaIcon")!
  const _component_VaInput = _resolveComponent("VaInput")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_VaButton = _resolveComponent("VaButton")!
  const _component_draggable = _resolveComponent("draggable")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_VaModal, {
      modelValue: _ctx.deleteDialog,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.deleteDialog) = $event)),
      message: "Delete this section?",
      "ok-text": "Delete",
      size: "auto",
      onOk: _ctx.handleDeleteSection
    }, null, 8, ["modelValue", "onOk"]),
    _createElementVNode("div", _hoisted_1, [
      (_ctx.isSectionsLoading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            (_openBlock(), _createElementBlock(_Fragment, null, _renderList(3, (num) => {
              return _createVNode(_component_VaSkeleton, {
                key: num,
                variant: "squared",
                height: "8rem"
              })
            }), 64))
          ]))
        : (_openBlock(), _createBlock(_component_draggable, {
            key: 1,
            modelValue: _ctx.sections,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.sections) = $event)),
            "item-key": "id",
            group: "sections",
            class: "draggable-sections",
            onEnd: _ctx.onSectionDragEnd
          }, {
            item: _withCtx(({ element }) => [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("h2", null, _toDisplayString(element.name), 1)
                  ]),
                  _createElementVNode("div", _hoisted_6, [
                    _createVNode(_component_VaIcon, {
                      name: "drag_handle",
                      size: "1.5rem",
                      class: "press-button"
                    }),
                    _createVNode(_component_VaInput, {
                      modelValue: element.name,
                      "onUpdate:modelValue": ($event: any) => ((element.name) = $event),
                      placeholder: "Section Title",
                      background: "#ECF0F1",
                      class: "toolbar-input",
                      loading: _ctx.isSectionLoading[element.id]?.updating
                    }, _createSlots({ _: 2 }, [
                      (!_ctx.isSectionLoading[element.id]?.updating)
                        ? {
                            name: "appendInner",
                            fn: _withCtx(() => [
                              _createVNode(_component_VaIcon, {
                                name: "check",
                                color: "secondary",
                                class: "press-button",
                                onClick: ($event: any) => (
                      _ctx.handleUpdateSection(element.id, { name: element.name })
                    )
                              }, null, 8, ["onClick"])
                            ]),
                            key: "0"
                          }
                        : undefined
                    ]), 1032, ["modelValue", "onUpdate:modelValue", "loading"]),
                    _createElementVNode("p", _hoisted_7, [
                      _createTextVNode(" Contains " + _toDisplayString(element.section_stages?.length) + " cards ", 1),
                      (_ctx.sections?.length > 1)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_8, [
                            _createVNode(_component_font_awesome_icon, {
                              icon: "trash-can",
                              class: "press-button",
                              spin: _ctx.isSectionLoading[element.id]?.deleting,
                              onClick: ($event: any) => (_ctx.handleToggleDeleteDialog(element.id))
                            }, null, 8, ["spin", "onClick"])
                          ]))
                        : _createCommentVNode("", true)
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_9, [
                  _withDirectives(_createElementVNode("p", _hoisted_10, " Modify Template ", 512), [
                    [_vShow, false]
                  ]),
                  _createVNode(_component_VaButton, {
                    size: "small",
                    class: "duplicate-button",
                    onClick: ($event: any) => (_ctx.handleAddSection(element))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(" Duplicate Section ")
                    ]),
                    _: 2
                  }, 1032, ["onClick"])
                ])
              ])
            ]),
            _: 1
          }, 8, ["modelValue", "onEnd"])),
      _createElementVNode("div", _hoisted_11, [
        _createVNode(_component_VaButton, { "icon-right": "add" }, {
          default: _withCtx(() => [
            _createTextVNode(" Add New Section ")
          ]),
          _: 1
        })
      ])
    ])
  ], 64))
}