import { paginationInterface } from '@/Interfaces/Services'
import { TaskPresenter } from '@/presenters/taskPresenter'
import { getAllTasks } from '@/services/tasks'
import { defineComponent } from 'vue'
import SearchAndViews from '@/components/searchAndViews/index.vue'
import TasksTable from '@/components/Task/Table/index.vue'

export default defineComponent({
  name: '',
  components: {
    TasksTable,
    SearchAndViews,
  },
  props: {
    refetchAllTasksTrigger: {
      type: Boolean,
      required: false,
    },
    headers: {
      type: Array,
      required: true,
    },
  },
  emits: [],
  data() {
    return {
      taskTableLoading: false,

      tasks: [] as TaskPresenter[],
      tasksItemPerPage: 10,
      taskPage: 1,
      taskSortField: '' as any,
      taskSortDirection: '' as 'asc' | 'desc' | undefined,
      taskSearchKeyword: '',
      taskTotalItems: 0,

      debounceTimeout: null as ReturnType<typeof setTimeout> | null,

      selectedRows: [] as TaskPresenter[],
      isSelect: false,
    }
  },
  mounted() {
    this.handleGetTasks()
  },
  methods: {
    getSelectedRows(rows: TaskPresenter[]) {
      this.selectedRows = rows
    },
    ////////////////////////////////////////////////////////////////////////////
    async handleGetTasks() {
      this.taskTableLoading = true

      const response = await getAllTasks({
        per_page: this.tasksItemPerPage,
        page: this.taskPage,
        keyword: this.taskSearchKeyword,
      })
      if (response.success) {
        this.tasks = response.data.list
        console.log({ tasks: this.tasks })

        this.taskTotalItems = response.data.total_pages * this.tasksItemPerPage
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.taskTableLoading = false
    },

    async handlePaginationTasks({
      page,
      itemsPerPage,
      sortBy,
    }: paginationInterface) {
      this.tasksItemPerPage = itemsPerPage
      this.taskPage = page
      if (sortBy?.length > 0) {
        this.taskSortField = sortBy[0].key.split('.')[1]
        this.taskSortDirection = sortBy[0].order
      } else {
        this.taskSortField = undefined
        this.taskSortDirection = undefined
      }

      await this.handleGetTasks()
    },

    searchTaskData(search: string) {
      this.taskSearchKeyword = search

      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout)
      }

      this.debounceTimeout = setTimeout(() => {
        this.handleGetTasks()
      }, 500)
    },
  },
  watch: {
    refetchAllTasksTrigger: {
      handler(data) {
        this.handleGetTasks()
      },
    },
  },
})
