<template>
  <div class="scroll-container-wrapper for-tables">
    <v-data-table-server
      v-model:selection="selectedRows"
      :loading="loading"
      :headers="headers"
      :items="tasks"
      show-select
      class="custom-table"
      :items-length="localTotalItems"
      :items-per-page="itemsPerPage"
      :items-per-page-options="[10, 25, 50]"
      @update:options="handleLoadMore"
      :fixed-header="true"
      :fixed-footer="true"
    >
      <template #[`item.name`]="{ item }">
        <router-link class="link" :to="`/tasks/${item.id}`">
          <p class="tasks-description-td">
            {{ item.name }}
          </p>
        </router-link>
      </template>

      <template #[`item.description`]="{ item }">
        <p class="tasks-description-td">
          {{ item.description }}
        </p>
      </template>

      <template #[`item.assigned_to`]="{ item }">
        <td class="tasks-assignedTo-td">
          <VaAvatar
            color="#ECF0F1"
            v-if="
              item.assigned_to?.avatar_letter ||
              item.assigned_to?.avatar?.medium_url
            "
            :src="item.assigned_to?.avatar?.medium_url"
          >
            <span v-if="!item.assigned_to?.avatar?.medium_url">{{
              item.assigned_to?.avatar_letter
            }}</span>
          </VaAvatar>
          {{ item.assigned_to?.fullName }}
        </td>
      </template>

      <template #[`item.owner`]="{ item }">
        <td class="tasks-taskOwner-td">
          <VaAvatar
            color="#ECF0F1"
            v-if="item.owner?.avatar_letter || item.owner?.avatar?.medium_url"
            :src="item.owner?.avatar?.medium_url"
          >
            <span v-if="!item.owner?.avatar?.medium_url">{{
              item.owner?.avatar_letter
            }}</span>
          </VaAvatar>
          {{ item.owner?.fullName }}
        </td>
      </template>

      <template #[`item.status`]="{ item }">
        <!-- :class="` status-${item.status
            ?.toLowerCase()
            .replace(' ', '-')}`" -->
        <td
          class="tasks-status-td"
          :style="{
            color: getContrastingTextColor(item.status?.color),
            backgroundColor: item.status?.color,
          }"
        >
          {{ item.status?.name }}
        </td>
      </template>

      <template #[`item.start_date`]="{ item }">
        <p class="tasks-description-td">
          {{ item.start_date ? formattedDate(item.start_date) : '-' }}
        </p>
      </template>

      <template #[`item.end_date`]="{ item }">
        <p class="tasks-description-td">
          {{ item.start_date ? formattedDate(item.end_date) : '-' }}
        </p>
      </template>

      <template #[`item.priority`]="{ item }">
        <td v-if="item.priority" class="tasks-priority-td">
          <VaButton
            round
            icon="flag"
            size="small"
            :color="item.priority?.color"
          />
          {{ item.priority?.name }}
        </td>
      </template>

      <template #[`item.action`]="{ item }">
        <td class="table-action-td">
          <va-dropdown :offset="[0, -50]">
            <template #anchor>
              <img
                :src="MoreVertIcon"
                alt="MoreVertIcon"
                class="press-button-2"
              />
            </template>
            <VaDropdownContent class="dropdown" v-if="!matterTasksSection">
              <p @click="handleButtonClick(item, 'open-with')">Open with</p>
              <p @click="handleButtonClick(item, 'request_signature')">
                Request Signature
              </p>
              <p @click="handleButtonClick(item, 'preview')">Preview</p>
              <p @click="handleButtonClick(item, 'rename')">Rename</p>
              <p @click="handleButtonClick(item, 'file_info')">File Info</p>
              <p @click="handleButtonClick(item, 'make_a_copy')">Make A Copy</p>
              <p @click="handleButtonClick(item, 'access_permissions')">
                Access Permissions
              </p>
              <p @click="handleButtonClick(item, 'share')">Share</p>
              <p @click="handleButtonClick(item, 'move')">Move</p>
              <p @click="handleButtonClick(item, 'make_available_online')">
                Make Available Offline
              </p>
              <p @click="handleButtonClick(item, 'delete')">delete</p>
            </VaDropdownContent>

            <VaDropdownContent class="dropdown" v-else>
              <p @click="handleButtonClick(item, 'remove')">Remove</p>
            </VaDropdownContent>
          </va-dropdown>
        </td>
      </template>
    </v-data-table-server>
  </div>
</template>

<script lang="ts" src="./script.ts" />
<style lang="scss" src="./styles.scss" scoped></style>
