<template>
  <SearchAndViews :search="taskSearchKeyword" @search="searchTaskData" />

  <TasksTable
    :loading="taskTableLoading"
    :items-per-page="tasksItemPerPage"
    :total-items="taskTotalItems"
    :tasks="tasks"
    :headers="headers"
    @selectedRows="getSelectedRows"
    @loadMore="handlePaginationTasks"
  />
</template>

<script lang="ts" src="./script.ts" />
<style lang="scss" scoped src="./styles.scss" />
