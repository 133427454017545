import { TeamMemberPresenter } from '@/presenters/teamMember'
import { CompanyUserPresenter } from '@/presenters/CompanyUserPresenter'
import { apiRequest } from './apiUtils'
import {
  getMembersInterfaces,
  updateMemberInterface,
} from '@/Interfaces/Services'

export const getMembers = async ({
  company_id,
  as_company_user,
  keyword,
}: getMembersInterfaces) => {
  const response = await apiRequest(
    'get',
    `companies/${company_id}/users`,
    null,
    { as_company_user, keyword },
  )

  if (!response.success) {
    return response
  }

  const list = response.data?.list
  if (!Array.isArray(list)) {
    return response
  }

  return {
    ...response,
    data: {
      ...response.data,
      list: list.map((item: TeamMemberPresenter | CompanyUserPresenter) =>
        !as_company_user === true
          ? new TeamMemberPresenter(item as TeamMemberPresenter)
          : new CompanyUserPresenter(item as CompanyUserPresenter),
      ),
    },
  }
}

export const getMemberById = async ({
  company_id,
  company_user_id,
}: getMembersInterfaces) => {
  const response = await apiRequest(
    'get',
    `companies/${company_id}/users/${company_user_id}`,
  )

  if (response.success) {
    return {
      ...response,
      data: new CompanyUserPresenter(response.data),
    }
  } else {
    return response
  }
}

export const addMember = (
  company_id: number,
  body: {
    email: string
    role_ids: number[]
    title: string
    first_name: string
    middle_name: string
    last_name: string
  },
) => {
  return apiRequest('post', `companies/${company_id}/users`, body)
}

export const updateMember = async (
  company_id: number,
  user_role_id: number,
  body: updateMemberInterface,
) => {
  const response = await apiRequest(
    'put',
    `companies/${company_id}/users/${user_role_id}`,
    body,
  )

  if (response.success) {
    return {
      ...response,
      data: new CompanyUserPresenter(response.data),
    }
  } else {
    return response
  }
}

export const deleteMember = (company_id: number, user_role_id: number) => {
  return apiRequest('delete', `companies/${company_id}/users/${user_role_id}`)
}
