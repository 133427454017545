import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7b7c9302"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "form-modal-header" }
const _hoisted_2 = { class: "form-modal-buttons" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = {
  key: 2,
  class: "va-textarea"
}
const _hoisted_6 = { key: 3 }
const _hoisted_7 = { class: "radio-label" }
const _hoisted_8 = { key: 4 }
const _hoisted_9 = ["aria-labelledby"]
const _hoisted_10 = { key: 5 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VaIcon = _resolveComponent("VaIcon")!
  const _component_VaInput = _resolveComponent("VaInput")!
  const _component_VaForm = _resolveComponent("VaForm")!
  const _component_VaButton = _resolveComponent("VaButton")!
  const _component_VaModal = _resolveComponent("VaModal")!
  const _component_VaSelect = _resolveComponent("VaSelect")!
  const _component_VaTextarea = _resolveComponent("VaTextarea")!
  const _component_VaRadio = _resolveComponent("VaRadio")!
  const _component_VaCheckbox = _resolveComponent("VaCheckbox")!
  const _component_VaDateInput = _resolveComponent("VaDateInput")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_VaModal, {
      modelValue: _ctx.isAddNewOption,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.isAddNewOption) = $event)),
      size: "auto",
      "hide-default-actions": "",
      "fixed-layout": ""
    }, {
      header: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("h1", null, "Add " + _toDisplayString(_ctx.selectedField?.label) + " Option", 1),
          _createVNode(_component_VaIcon, {
            class: "header-icon press-button",
            name: "close",
            size: "30px",
            onClick: _ctx.handleToggleAddOption
          }, null, 8, ["onClick"])
        ])
      ]),
      footer: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_VaButton, {
            preset: "secondary",
            "border-color": "primary",
            onClick: _ctx.handleToggleAddOption
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Cancel ")
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_VaButton, {
            loading: 
            _ctx.$stores.contacts.addContactLoading ||
            _ctx.$stores.generalData.isAddGlobalConfigLoading
          ,
            onClick: _ctx.handleAddNewOption
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Save ")
            ]),
            _: 1
          }, 8, ["loading", "onClick"])
        ])
      ]),
      default: _withCtx(() => [
        (_ctx.selectedField.configuration_type)
          ? (_openBlock(), _createBlock(_component_VaForm, {
              key: 0,
              ref: "optionForm",
              class: "modal-container",
              onSubmit: _withModifiers(_ctx.handleToggleAddOption, ["prevent"])
            }, {
              default: _withCtx(() => [
                _createVNode(_component_VaInput, {
                  modelValue: _ctx.optionForm.name,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.optionForm.name) = $event)),
                  label: "Option Name",
                  placeholder: "Enter option name",
                  outlined: "",
                  background: "#ECF0F1",
                  class: "input",
                  "required-mark": ""
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }, 8, ["onSubmit"]))
          : (_openBlock(), _createBlock(_component_VaForm, {
              key: 1,
              ref: "contactForm",
              class: "modal-container",
              onSubmit: _withModifiers(_ctx.handleToggleAddOption, ["prevent"])
            }, {
              default: _withCtx(() => [
                _createVNode(_component_VaInput, {
                  modelValue: _ctx.optionForm.individual_first_name,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.optionForm.individual_first_name) = $event)),
                  label: "First Name",
                  placeholder: "First Name",
                  outlined: "",
                  background: "#ECF0F1",
                  class: "input",
                  "required-mark": ""
                }, null, 8, ["modelValue"]),
                _createVNode(_component_VaInput, {
                  modelValue: _ctx.optionForm.individual_last_name,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.optionForm.individual_last_name) = $event)),
                  label: "Last Name",
                  placeholder: "Last Name",
                  outlined: "",
                  background: "#ECF0F1",
                  class: "input",
                  "required-mark": ""
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }, 8, ["onSubmit"]))
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_VaForm, {
      ref: "form",
      class: "form-column",
      onSubmit: _withModifiers(_ctx.handleSubmit, ["prevent"])
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fields, (row, rowIndex) => {
          return (_openBlock(), _createElementBlock("div", {
            key: `row-${rowIndex}`,
            class: "form-row",
            style: _normalizeStyle(`--columns: ${Math.min(_ctx.fields.length, 4)}`)
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(row, (field, index) => {
              return (_openBlock(), _createElementBlock("div", {
                key: index,
                class: "form-field",
                style: _normalizeStyle({ flex: field.flex || '1' })
              }, [
                (_ctx.$slots.field)
                  ? _renderSlot(_ctx.$slots, "field", {
                      key: 0,
                      field: field,
                      model: _ctx.formData
                    }, undefined, true)
                  : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                      (field.type === 'text')
                        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                            _createVNode(_component_VaInput, {
                              modelValue: _ctx.formData[field.name],
                              "onUpdate:modelValue": ($event: any) => ((_ctx.formData[field.name]) = $event),
                              label: field.label,
                              placeholder: field.placeholder,
                              "required-mark": field.required,
                              rules: [() => _ctx.validateField(field)],
                              background: "#ECF0F1",
                              outlined: "",
                              class: "input",
                              clearable: ""
                            }, null, 8, ["modelValue", "onUpdate:modelValue", "label", "placeholder", "required-mark", "rules"])
                          ]))
                        : (field.type === 'select')
                          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                              _createVNode(_component_VaSelect, {
                                ref_for: true,
                                ref: "select",
                                modelValue: _ctx.formData[field.name],
                                "onUpdate:modelValue": ($event: any) => ((_ctx.formData[field.name]) = $event),
                                label: field.label,
                                placeholder: field.placeholder,
                                options: _ctx.handleOptions(field.options),
                                "text-by": (e) => _ctx.handleTextBy(field.textBy, e),
                                "value-by": field.valueBy,
                                "required-mark": field.required,
                                background: "#ECF0F1",
                                outlined: "",
                                class: "input",
                                multiple: field.multiple,
                                rules: [() => _ctx.validateField(field)],
                                searchable: field.searchable,
                                "highlight-matched-text": false,
                                loading: _ctx.getLoadingState(field.options),
                                "allow-create": "",
                                "no-options-text": _ctx.noOptionsText(field.options),
                                "onUpdate:search": ($event: any) => (_ctx.handleSearch(field.options, $event)),
                                onCreateNew: ($event: any) => (_ctx.handleNewOption(field, $event)),
                                "onUpdate:isOpen": _ctx.onOpen,
                                "track-by": "id",
                                clearable: "",
                                onClick: ($event: any) => (field.searchable && _ctx.focusInputForSelect)
                              }, null, 8, ["modelValue", "onUpdate:modelValue", "label", "placeholder", "options", "text-by", "value-by", "required-mark", "multiple", "rules", "searchable", "loading", "no-options-text", "onUpdate:search", "onCreateNew", "onUpdate:isOpen", "onClick"])
                            ]))
                          : (field.type === 'textarea')
                            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                                _createVNode(_component_VaTextarea, {
                                  modelValue: _ctx.formData[field.name],
                                  "onUpdate:modelValue": ($event: any) => ((_ctx.formData[field.name]) = $event),
                                  label: field.label,
                                  placeholder: field.placeholder,
                                  "required-mark": field.required,
                                  autosize: "",
                                  background: "#ECF0F1",
                                  outlined: "",
                                  "max-rows": "7",
                                  class: "input",
                                  style: {"height":"10rem"},
                                  rules: [() => _ctx.validateField(field)]
                                }, null, 8, ["modelValue", "onUpdate:modelValue", "label", "placeholder", "required-mark", "rules"])
                              ]))
                            : (field.type === 'radio')
                              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                                  _createElementVNode("p", _hoisted_7, _toDisplayString(field.label), 1),
                                  _createVNode(_component_VaRadio, {
                                    class: "radio-label-input",
                                    modelValue: _ctx.formData[field.name],
                                    "onUpdate:modelValue": ($event: any) => ((_ctx.formData[field.name]) = $event),
                                    options: field.options,
                                    "value-by": "value"
                                  }, null, 8, ["modelValue", "onUpdate:modelValue", "options"])
                                ]))
                              : (field.type === 'checklist')
                                ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                                    _createElementVNode("div", {
                                      "aria-labelledby": field.label
                                    }, [
                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Array.isArray(field.options)
                  ? field.options
                  : [], (option, idx) => {
                                        return (_openBlock(), _createBlock(_component_VaCheckbox, {
                                          key: idx,
                                          modelValue: _ctx.formData[field.name],
                                          "onUpdate:modelValue": ($event: any) => ((_ctx.formData[field.name]) = $event),
                                          label: option.label,
                                          "true-value": option.value,
                                          "false-value": null,
                                          class: "input"
                                        }, null, 8, ["modelValue", "onUpdate:modelValue", "label", "true-value"]))
                                      }), 128))
                                    ], 8, _hoisted_9)
                                  ]))
                                : (field.type === 'date')
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                                      _createVNode(_component_VaDateInput, {
                                        modelValue: _ctx.formData[field.name],
                                        "onUpdate:modelValue": [($event: any) => ((_ctx.formData[field.name]) = $event), ($event: any) => (_ctx.handleDateChange($event, field.name))],
                                        label: field.label,
                                        placeholder: field.placeholder,
                                        "required-mark": field.required,
                                        rules: [() => _ctx.validateField(field)],
                                        background: "#ECF0F1",
                                        outlined: "",
                                        class: "input",
                                        "manual-input": ""
                                      }, null, 8, ["modelValue", "onUpdate:modelValue", "label", "placeholder", "required-mark", "rules"])
                                    ]))
                                  : _createCommentVNode("", true)
                    ], 64))
              ], 4))
            }), 128))
          ], 4))
        }), 128))
      ]),
      _: 3
    }, 8, ["onSubmit"])
  ], 64))
}