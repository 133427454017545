<template>
  <div class="member-container">
    <ActionsBar secondAddButton="CHAT" />

    <div class="contact-image">
      <div class="avatar-container">
        <VaAvatar
          color="#ECF0F1"
          :src="currentMember?.user?.avatar?.medium_url"
          size="small"
          alt="Avatar"
          class="avatar-preview"
        >
          <span v-if="!currentMember?.user?.avatar?.medium_url">
            {{ currentMember?.avatar_letter }}
          </span>
        </VaAvatar>
      </div>
    </div>
    <div class="member-details">
      <h2>
        {{ currentMember?.fullName }}
      </h2>
      <p>
        {{ currentMember?.user?.email }}
      </p>
      <p>
        {{ currentMember?.user?.title }}
      </p>
    </div>
  </div>
</template>
<script lang="ts" src="./script.ts" />
<style lang="scss" scoped src="./styles.scss" />
