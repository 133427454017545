import { getNotificationsInterface } from '@/Interfaces/Services'
import { apiRequest } from './apiUtils'
import { NotificationPresenter } from '@/presenters/NotificationPresenter'

export const getNotifications = async ({
  per_page,
  page,
  notification_group_type,
  unviewed_only,
}: getNotificationsInterface) => {
  const response = await apiRequest('get', '/notifications', null, {
    per_page,
    page,
    notification_group_type,
    unviewed_only,
  })

  if (!response.success) {
    return response
  }

  const list = response.data?.list
  if (!Array.isArray(list)) {
    return response
  }

  return {
    ...response,
    data: {
      ...response.data,
      list: list.map((item) => new NotificationPresenter(item)),
    },
  }
}

export const getNotificationsCount = async () => {
  return await apiRequest('get', '/notifications/count')
}

export const markNotificationAsViewed = async ({
  per_page,
  page,
  notification_group_type,
}: getNotificationsInterface) => {
  return await apiRequest('post', '/notifications/viewed', null, {
    per_page,
    page,
    notification_group_type,
  })
}
