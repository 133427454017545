<template>
  <SharedHeader :back-button="true" title-width="30rem" :title="form?.fullName">
    <div class="sub-SharedHeader">
      <div class="sub-SharedHeader-left" />
      <div class="sub-SharedHeader-right" />
    </div>
  </SharedHeader>

  <TabsBar :tabs="tabs" :active-tab-id="activeTabId" @changeTab="changeTab" />

  <div class="scroll-container inherit-height">
    <div v-if="activeTabId == 1" class="contact-info-container">
      <div class="circular-image-upload-container">
        <VaFileUpload
          hideFileList="true"
          v-model="form.organization_logo"
          :file-types="$stores.generalData.imagesFormats"
          @update:modelValue="handleFileUpload"
          type="single"
        >
          <div class="avatar-container">
            <VaAvatar
              color="#ECF0F1"
              :src="form?.avatar?.medium_url"
              size="small"
              alt="Avatar"
              class="avatar-preview"
            >
              <span v-if="!form?.avatar?.medium_url">
                {{ form.avatar_letter }}
              </span>
            </VaAvatar>
          </div>
          <div class="verified-icon">
            <p>
              <img
                :src="verifiedImage"
                alt="VERIFIED"
                class="profile-icon"
              />Verified <br />
              HAQQ User
            </p>
          </div>
          <div
            v-if="form?.avatar?.medium_url"
            class="close-icon press-button"
            @click.stop="handleRemoveImage"
          >
            ✕
          </div>
        </VaFileUpload>
      </div>

      <DynamicContactForm
        :fields="formFields"
        :initial-values="form"
        :toggle-submit="toggleSubmit"
        @submit="handleUpdate"
      />

      <VaForm ref="organizationDetailsFormRef" @submit.prevent="">
        <VaAccordion v-model="isAddressAccordion" multiple>
          <VaCollapse
            v-for="(address, index) in form.contact_addresses"
            :key="index"
            :header="`Address ${index + 1}`"
            class="address-accordion"
          >
            <div class="address-accordion">
              <div class="address-accordion-row">
                <div class="country-select">
                  <VaSelect
                    v-model="address.country"
                    placeholder="Country"
                    background="#ECF0F1"
                    :loading="$stores.generalData.countriesLoading"
                    label="Country"
                    required-mark
                    searchable
                    :options="$stores.generalData.countries"
                    :highlight-matched-text="false"
                    :text-by="getOptionName"
                    :rules="[validateSelect]"
                    @update:modelValue="$stores.generalData.handleGetState"
                    track-by="id"
                    class="input"
                  />
                </div>

                <div class="zip-code-input">
                  <VaInput
                    v-model="address.zip_code"
                    placeholder="Zip Code"
                    background="#ECF0F1"
                    label="Zip Code"
                    class="input"
                  />
                </div>
              </div>

              <div class="address-accordion-row">
                <VaSelect
                  v-model="address.state"
                  placeholder="Province/State"
                  background="#ECF0F1"
                  :loading="$stores.generalData.statesLoading"
                  label="Province/State"
                  required-mark
                  searchable
                  :options="$stores.generalData.states"
                  :highlight-matched-text="false"
                  :text-by="getOptionName"
                  track-by="id"
                  :rules="[validateSelect]"
                  @update:modelValue="$stores.generalData.handleGetCities"
                  class="input"
                />

                <VaSelect
                  v-model="address.city"
                  placeholder="City"
                  background="#ECF0F1"
                  :loading="$stores.generalData.citiesLoading"
                  label="City"
                  required-mark
                  searchable
                  :options="$stores.generalData.cities"
                  :highlight-matched-text="false"
                  :text-by="getOptionName"
                  :rules="[validateSelect]"
                  track-by="id"
                  class="input"
                />
              </div>

              <div class="address-accordion-row">
                <VaInput
                  v-model="address.street_name"
                  placeholder="Street"
                  background="#ECF0F1"
                  class="input"
                  label="Street"
                />

                <VaInput
                  v-model="address.building_name"
                  placeholder="Building"
                  background="#ECF0F1"
                  class="input"
                  label="Building"
                />
              </div>

              <div class="address-accordion-row">
                <VaInput
                  v-model="address.phone_number"
                  placeholder="Office Number"
                  background="#ECF0F1"
                  class="input phone-number"
                  label="Office Number"
                />

                <VaInput
                  v-model="address.floor"
                  placeholder="Floor"
                  background="#ECF0F1"
                  class="input floor"
                  label="Floor"
                />
              </div>

              <VaInput
                v-model="address.google_maps_url"
                placeholder="Google Maps Link"
                background="#ECF0F1"
                class="input"
                label="Google Maps Link"
              />
            </div>
          </VaCollapse>
        </VaAccordion>

        <VaAccordion v-model="isBankAccountAccordion" multiple>
          <VaCollapse
            v-for="(bank, index) in form.contact_bank_accounts"
            :key="index"
            :header="`Bank Account ${index + 1}`"
            class="address-accordion"
          >
            <div class="address-accordion">
              <div class="address-accordion-row">
                <VaInput
                  v-model="bank.iban"
                  placeholder="Iban"
                  background="#ECF0F1"
                  style="width: 100%"
                  label="iban"
                  required-mark
                  :rules="[validateInput]"
                  class="input"
                />
                <VaInput
                  v-model="bank.swift_or_bic"
                  placeholder="swift/bic code"
                  background="#ECF0F1"
                  style="width: 100%"
                  label="swift/bic code"
                  required-mark
                  :rules="[validateInput]"
                  class="input"
                />
              </div>

              <div class="address-accordion-row">
                <VaInput
                  v-model="bank.account_number"
                  placeholder="Account Number"
                  background="#ECF0F1"
                  style="width: 100%"
                  label="account number"
                  required-mark
                  :rules="[validateInput]"
                  class="input"
                />
                <VaInput
                  v-model="bank.bank_name"
                  placeholder="Bank Name"
                  background="#ECF0F1"
                  style="width: 100%"
                  label="bank name"
                  required-mark
                  :rules="[validateInput]"
                  class="input"
                />
              </div>

              <div class="address-accordion-row">
                <VaInput
                  v-model="bank.branch_name"
                  placeholder="Branch Name"
                  background="#ECF0F1"
                  style="width: 100%"
                  label="branch name"
                  class="input"
                />

                <VaSelect
                  v-model="bank.branch_country"
                  placeholder="Branch Country"
                  background="#ECF0F1"
                  :loading="$stores.generalData.countriesLoading"
                  style="width: 100%; height: 2rem; margin-bottom: 1.5rem"
                  label="Branch country"
                  required-mark
                  :rules="[validateSelect]"
                  searchable
                  :options="$stores.generalData.countries"
                  :highlight-matched-text="false"
                  :text-by="getOptionName"
                  value-by="name"
                  class="input"
                />
              </div>

              <div class="address-accordion-row">
                <VaInput
                  v-model="bank.routing_number"
                  placeholder="Routing Number"
                  background="#ECF0F1"
                  style="width: 100%"
                  label="routing number"
                  class="input"
                />

                <VaInput
                  v-model="bank.short_code"
                  placeholder="Sort Code"
                  background="#ECF0F1"
                  style="width: 100%"
                  label="sort code"
                  class="input"
                />
              </div>

              <div class="address-accordion-row">
                <VaInput
                  v-model="bank.bsb_number"
                  placeholder="BSB number"
                  background="#ECF0F1"
                  style="width: 100%"
                  label="BSB number"
                  class="input"
                />

                <VaInput
                  v-model="bank.ifcs_code"
                  placeholder="sifcs code"
                  background="#ECF0F1"
                  style="width: 100%"
                  label="sifcs code"
                  class="input"
                />
              </div>

              <div class="address-accordion-row">
                <VaInput
                  v-model="bank.transit_number"
                  placeholder="Transit Number"
                  background="#ECF0F1"
                  style="width: 100%"
                  label="transit number"
                  class="input"
                />
                <VaInput
                  v-model="bank.clabe"
                  placeholder="Clabe"
                  background="#ECF0F1"
                  style="width: 100%"
                  label="clabe"
                  class="input"
                />
              </div>

              <VaInput
                v-model="bank.extra_notes"
                placeholder="Other"
                background="#ECF0F1"
                style="width: 100%"
                label="other"
                class="input"
              />
            </div>
          </VaCollapse>
        </VaAccordion>
      </VaForm>

      <div class="action-buttons">
        <VaButton preset="secondary" border-color="primary" @click="handleBack">
          Back
        </VaButton>

        <VaButton
          preset="primary"
          :loading="loadingUpdate"
          @click="validate() && handleToggleSubmit()"
        >
          Update
        </VaButton>
      </div>
    </div>
    <div v-else class="coming-soon-container">
      <ComingSoon />
    </div>
  </div>
</template>
<script lang="ts" src="./script.ts" />
<style lang="scss" scoped src="./styles.scss" />
