import { defineComponent } from 'vue'
import { useForm } from 'vuestic-ui/web-components'
import TimeRange from '@/components/TimeRange/index.vue'
import DatePickerModal from '@/components/DatePickerModal/index.vue'
import { getAllMatters } from '@/services/legalMatter'
import { MatterPresenter } from '@/presenters/matterPresenter'
import {
  getOptionFullName,
  getOptionName,
  validateInput,
  validateSelect,
} from '@/lib/utils'
import { CompanyConfigurationPresenter } from '@/presenters/companyConfigPresenters'
import { SectionsPresenter } from '@/presenters/SectionsPresenter'
import { StagesPresenter } from '@/presenters/StagesPresenter'
import { MatterTemplatePresenter } from '@/presenters/TemplatePresenter/Matter'

const DatePickerImage = new URL(
  '@/assets/icons/date-picker-icon.svg',
  import.meta.url,
).href
const StartDateImage = new URL(
  '@/assets/icons/start-date-icon.svg',
  import.meta.url,
).href
const MoreVertIcon = new URL(
  '@/assets/icons/More-vert-icon.svg',
  import.meta.url,
).href
const AttachFileIcon = new URL(
  '@/assets/icons/attach-file-icon.svg',
  import.meta.url,
).href
const FlagIcon = new URL('@/assets/icons/flag-icon.svg', import.meta.url).href

type addTaskForm = {
  name: string
  tag: any[]
  start_date: string
  end_date: string
  due_date: string
  priority: CompanyConfigurationPresenter
  section_stage_id: string
  isOwner_counsel: boolean
  owner_id: string
  isAssigned_counsel: boolean
  assigned_to_id: string
  description: string
  location: string
  notes: string
  budget: string
  billable: string
  // pre_charged: false;
  // value: string;
  // currency: 'USD';
  section_stage: StagesPresenter
  task_relationships_attributes: any[]
}

export default defineComponent({
  name: 'AddTaskForm',
  props: {
    addTaskLoading: {
      type: Boolean,
      default: false,
    },
    isAddTaskForm: {
      type: Boolean,
      default: false,
    },
    subSections: {
      type: Array as () => SectionsPresenter[],
      required: true,
    },
    section: {
      type: {} as () => StagesPresenter,
      required: true,
    },
  },
  data() {
    const alert = (...args: any) => console.log(args)
    const { isValid, validate, reset, resetValidation } = useForm('form')

    const subSection = {} as SectionsPresenter

    return {
      validateInput,
      validateSelect,
      getOptionName,
      getOptionFullName,
      alert,
      isAddTaskFormBool: this.isAddTaskForm,
      isValid,
      validate,
      reset,
      resetValidation,
      DatePickerImage,
      StartDateImage,
      MoreVertIcon,
      AttachFileIcon,
      FlagIcon,
      flagMenuOpen: false,
      loadingUpdate: false,

      formFields: [] as MatterTemplatePresenter[][],

      form: {} as addTaskForm,

      link_to: [] as any,
      file: [],
      time_range: {},
      due_time: '',

      debounceTimeout: null as ReturnType<typeof setTimeout> | null,
      noOptionsText: 'press enter to add',

      subSection,
    }
  },
  methods: {
    addNewOption(newOption: string) {
      console.log({ newOption })
    },
    ////////////////////////////////////////////////////////////////////////
    handleToggleAddTaskModal() {
      this.isAddTaskFormBool = !this.isAddTaskFormBool
      this.$emit('handleToggleAddTaskModal')
    },
    handleCreateTask() {
      const { billable, priority, section_stage, section_stage_id, ...form } =
        this.form

      this.$emit('handleCreateTask', {
        ...form,
        priority_id: priority?.id,
        billable: billable?.toString() || 'false',
        section_stage_id: section_stage_id || section_stage?.id,
      })
    },
    handleSelectedRangeDate(date: { start: string; end: string }) {
      this.form.start_date = date.start
      this.form.end_date = date.end
    },
    handleSelectedDate(date: { date: string }) {
      this.form.due_date = date.date
    },
    handleFlagMenuItemClick(flag: any) {
      this.form.priority = flag
    },
    handleLinkMatters(data: number[]) {
      if (data.length > 0) {
        this.form.task_relationships_attributes = data.map((id: number) => ({
          related_to_type: 'LegalMatter',
          related_to_id: id,
        }))
      } else {
        this.form.task_relationships_attributes = []
      }
    },

    ////////////////////////////////////////////////////////////////////
  },
  watch: {
    form: {
      handler(data) {
        console.log({ form: data })
      },
      deep: true, // Enable deep watching to detect changes within the object
    },
    handleChooseFlag(flag) {
      this.form.priority = flag.name
    },
    isAddTaskForm: {
      handler(data) {
        this.isAddTaskFormBool = data
        if (data) {
          this.$stores.usersData.handleGetMembers()
          this.$stores.matter.handleGetMatters()

          this.subSection =
            this.subSections.find(
              (section) => section.name === this.$route.query.tab,
            ) || this.subSections[0]

          this.form = {
            section_stage:
              this.subSection?.section_stages?.find(
                (stage) => stage.id === this.section?.id,
              ) || this.subSection?.section_stages[0],
          } as addTaskForm
        }
      },
    },
    subSection: {
      handler(data) {
        this.formFields = data.form_columns
        this.form.section_stage_id = data?.section_stages?.[0]?.id
      },
      deep: true,
    },
  },
  components: {
    TimeRange,
    DatePickerModal,
  },
})
