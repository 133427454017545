import { StorageCategoryPresenter } from '@/presenters/StorageCategoryPresenter'
import { ref, onMounted, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'

export function useTabs(
  tabs: Array<{ id: number; name: string } | StorageCategoryPresenter>,
  initialTabId?: number, // Optional parameter
) {
  const route = useRoute()
  const router = useRouter()

  const activeTabId = ref(0)
  const activeTab = ref('')

  const changeTab = ({
    tab,
    pushToRouter = true,
  }: {
    tab: { title?: string; name: string; id: number }
    pushToRouter?: boolean
  }) => {
    console.log({ tab, pushToRouter })
    activeTabId.value = tab.id
    activeTab.value = tab.name

    if (pushToRouter) router.push({ query: { tab: tab.name } })
  }

  const initActiveTab = () => {
    const queryTab = route?.query.tab as string | undefined

    // If there's a 'tab' query parameter, use it to set the active tab
    if (queryTab) {
      const foundTab = tabs.find((tab) => tab.name === queryTab)
      if (foundTab) {
        activeTabId.value = foundTab.id as number
        activeTab.value = foundTab.name as string
        return // Exit the function since we've set the active tab
      }
    }

    // If 'initialTabId' is provided, and it's valid, use it
    if (initialTabId !== undefined && initialTabId !== null) {
      if (initialTabId >= 0 && initialTabId < tabs?.length) {
        activeTabId.value = tabs[initialTabId].id as number // Use the tab at the given index
        activeTab.value = tabs[initialTabId].name as string // Assign its title
        return
      }
    }

    // Default case: set to tab with id 0
    const defaultTab = tabs.find((tab) => tab.id === 0) || tabs[0]
    activeTabId.value = defaultTab?.id ?? 0
    activeTab.value = defaultTab?.name || ''
  }

  // Initialize the active tab when the component is mounted
  onMounted(() => {
    initActiveTab()
  })

  // Watch for route changes and update the active tab accordingly
  watch(
    () => [route?.query.tab, route?.path],
    () => {
      initActiveTab()
    },
  )

  return {
    activeTabId,
    activeTab,
    changeTab,
  }
}
