<template>
  <div class="search-and-views">
    <div class="filter-search">
      <div class="filter">
        <Button
          button-text="Filter"
          :text-color="'#66023c'"
          :icon-before="filterIcon"
          :custom-styles="{
            padding: '10px',
            borderRadius: '5px',
            fontSize: '12px',
            fontWeight: '500',
            backgroundColor: '#fff',
            border: '1px solid #66023c',
          }"
          :hover-styles="{
            scale: '1.02',
          }"
          @click="openFilterModal"
        />
      </div>
      <div class="search">
        <VaInput v-model="internalSearch" placeholder="Search" icon="search">
          <template #appendInner>
            <VaIcon name="search" color="secondary" />
          </template>
        </VaInput>
      </div>
    </div>

    <div v-if="showViews" class="views">
      <div
        class="view"
        :class="{ active: activeView === 'list' }"
        @click="changeView('list')"
      >
        <img :src="listViewIcon" alt="list" />
      </div>
      <div
        class="view"
        :class="{ active: activeView === 'board' }"
        @click="changeView('board')"
      >
        <img :src="kanbanView" alt="kanban" />
      </div>
    </div>

    <Button
      v-if="withAddButton"
      :button-text="withAddButton?.buttonText ?? 'Add'"
      :icon-after="withAddButton?.isSecondary ? addIcon : whiteAddIcon"
      class="basic-primary-button"
      :class="{ secondary: withAddButton?.isSecondary }"
      :custom-styles="{
        ...(withAddButton?.isSecondary && {
          color: '#66023c',
          backgroundColor: '#fff',
          border: '0.5px solid #E2E8F0',
          textColor: '#66023c',
        }),
      }"
      :hover-styles="{
        border: '1px solid #fff',
      }"
      @click="withAddButton?.buttonAction"
    />
  </div>
</template>

<script lang="ts" src="./script.ts"></script>
<style lang="scss" scoped src="./styles.scss"></style>
