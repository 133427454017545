<template>
  <div class="scroll-container-wrapper for-tables">
    <v-data-table-server
      v-model:selection="selectedRows"
      :loading="loading"
      :headers="headers"
      :items="items"
      show-select
      class="custom-table"
      :items-length="totalItems"
      :items-per-page="itemsPerPage"
      :items-per-page-options="[10, 25, 50]"
      @update:options="handleLoadMore"
      :fixed-header="true"
      :fixed-footer="true"
    >
      <template #[`item.name`]="{ item }">
        <router-link :to="item.path_to">
          <p>{{ item?.fullName || 'N/A' }}</p>
        </router-link>
      </template>

      <template #[`item.kyc_completed`]="{ item }">
        <div class="kyc-status">
          <va-icon
            :name="item?.kyc_completed ? 'check_circle' : 'cancel'"
            :color="item?.kyc_completed ? 'green' : 'red'"
          />
          <span :style="{ color: item?.kyc_completed ? 'green' : 'red' }">
            {{ item?.kyc_completed }}
          </span>
        </div>
      </template>

      <template #[`item.website_url`]="{ item }">
        <div>
          <a v-if="item?.website_url" :href="item?.website_url" target="_blank">
            <p>{{ item?.website_url || 'N/A' }}</p>
          </a>
        </div>
      </template>

      <template #[`item.referred_by`]="{ item }">
        <p class="referred-by">
          <VaAvatar
            color="#ECF0F1"
            v-if="
              item.referred_by?.client_avatar_letter ||
              item?.referred_by?.avatar?.medium_url
            "
            :src="item?.referred_by?.avatar?.medium_url"
            :alt="item?.referred_by?.fullName"
            size="small"
          >
            <span
              v-if="!item?.referred_by?.avatar?.medium_url"
              style="color: black"
            >
              {{ item.referred_by?.client_avatar_letter }}
            </span>
          </VaAvatar>
          {{ item?.referred_by?.fullName }}
        </p>
      </template>

      <template #[`item.assigned_to`]="{ item }">
        <p class="assigned-to">
          <VaAvatar
            color="#ECF0F1"
            v-if="
              item.assigned_to?.avatar_letter ||
              item?.assigned_to?.avatar?.medium_url
            "
            :src="item?.assigned_to?.avatar?.medium_url"
            :alt="item?.assigned_to?.fullName"
            size="small"
          >
            <span
              v-if="!item?.assigned_to?.avatar?.medium_url"
              style="color: black"
            >
              {{ item.assigned_to?.avatar_letter }}
            </span>
          </VaAvatar>
          {{ item?.assigned_to?.fullName }}
        </p>
      </template>

      <template #[`item.relationship_owner`]="{ item }">
        <p class="relationship-owner">
          <VaAvatar
            color="#ECF0F1"
            v-if="
              item.relationship_owner?.avatar_letter ||
              item?.relationship_owner?.avatar?.medium_url
            "
            :src="item?.relationship_owner?.avatar?.medium_url"
            :alt="item?.relationship_owner?.fullName"
            size="small"
          >
            <span
              v-if="!item?.relationship_owner?.avatar?.medium_url"
              style="color: black"
            >
              {{ item.relationship_owner?.avatar_letter }}
            </span>
          </VaAvatar>
          {{ item?.relationship_owner?.fullName }}
        </p>
      </template>

      <template #[`item.designated_point_of_contact`]="{ item }">
        <p class="designated-point-of-contact">
          <VaAvatar
            color="#ECF0F1"
            v-if="
              item.designated_point_of_contact?.avatar_letter ||
              item?.designated_point_of_contact?.avatar?.medium_url
            "
            :src="item?.designated_point_of_contact?.avatar?.medium_url"
            :alt="item?.designated_point_of_contact?.fullName"
            size="small"
          >
            <span
              v-if="!item?.designated_point_of_contact?.avatar?.medium_url"
              style="color: black"
            >
              {{ item.designated_point_of_contact?.avatar_letter }}
            </span>
          </VaAvatar>
          {{ item?.designated_point_of_contact?.fullName }}
        </p>
      </template>

      <template #[`item.contact_type`]="{}">
        <p>Organization</p>
      </template>

      <template #[`item.organization_contact`]="{ item }">
        <p class="organization">
          <VaAvatar
            color="#ECF0F1"
            v-if="
              item.organization_contact?.name ||
              item?.organization_contact?.avatar?.medium_url
            "
            :src="item?.organization_contact?.avatar?.medium_url"
            :alt="item?.referred_by?.fullName"
            size="small"
          >
            <span
              v-if="!item?.organization_contact?.avatar?.medium_url"
              style="color: black"
            >
              {{ item.organization_contact?.avatar_letter }}
            </span>
          </VaAvatar>
          {{ item.organization_contact?.fullName }}
        </p>
      </template>

      <template #[`item.avatar`]="{ item }">
        <VaAvatar
          color="#ECF0F1"
          :src="item?.avatar?.medium_url"
          :alt="item?.first_name + ' ' + item?.last_name"
          size="small"
        >
          <span v-if="!item?.avatar?.medium_url" style="color: black">{{
            item?.specific_data.organization_name?.charAt(0)
          }}</span>
        </VaAvatar>
      </template>

      <template #[`item.notes`]="{ item }">
        <p class="tasks-note-td">
          {{ item?.notes || 'No notes available' }}
        </p>
      </template>

      <template #[`item.kyc_notes`]="{ item }">
        <p class="tasks-note-td">
          {{ item?.kyc_notes || 'No KYC notes' }}
        </p>
      </template>

      <template #[`item.img`]="{ item }">
        <VaAvatar
          color="#ECF0F1"
          v-if="item.client_avatar_letter || item?.avatar?.medium_url"
          :src="item?.avatar?.medium_url"
          :alt="item?.fullName"
          size="small"
        >
          <span v-if="!item?.avatar?.medium_url" style="color: black">
            {{ item.client_avatar_letter }}
          </span>
        </VaAvatar>
      </template>

      <template #[`item.firstName`]="{ item }">
        <router-link :to="item.path_to">
          <p>{{ item?.firstName }}</p>
        </router-link>
      </template>

      <template #[`item.middleName`]="{ item }">
        <router-link :to="item.path_to">
          <p>{{ item?.middleName }}</p>
        </router-link>
      </template>

      <template #[`item.lastName`]="{ item }">
        <router-link :to="item.path_to">
          <p>{{ item?.lastName }}</p>
        </router-link>
      </template>

      <template #[`item.email_addresses`]="{ item }">
        <div v-for="(email, index) in item.email_addresses" :key="index">
          <a :href="`mailto:${email?.email_address}`" class="email-link">
            <VaIcon name="mail_outline" size="1.7rem" />
            {{ email?.email_address }}
          </a>
        </div>
      </template>

      <template #[`item.phone_numbers`]="{ item }">
        <div v-for="(phone, index) in item.phone_numbers" :key="index">
          <a :href="`tel:${phone?.phone_number}`" class="phone-link">
            <VaIcon name="call" size="1.7rem" />
            {{ phone?.phone_number }} ({{ phone?.label }})
          </a>
        </div>
      </template>

      <template #[`item.organization`]="{ item }">
        <div class="social-media-icons">
          <router-link :to="item.path_to">
            <p class="organization">
              <VaAvatar
                color="#ECF0F1"
                v-if="
                  item.organization_contact?.fullName ||
                  item?.organization_contact?.avatar?.medium_url
                "
                :src="item?.organization_contact?.avatar?.medium_url"
                :alt="item?.referred_by?.fullName"
                size="small"
              >
                <span
                  v-if="!item?.organization_contact?.avatar?.medium_url"
                  style="color: black"
                >
                  {{ item.organization_contact?.avatar_letter }}
                </span>
              </VaAvatar>
              {{ item?.organization_contact?.fullName }}
            </p>
          </router-link>
        </div>
      </template>

      <template #[`item.action`]="{ item }">
        <td class="table-action-td">
          <va-dropdown :offset="[0, -70]" close-on-content-click>
            <template #anchor>
              <img
                :src="MoreVertIcon"
                alt="MoreVertIcon"
                class="press-button-2"
              />
            </template>
            <va-dropdown-content class="dropdown">
              <!-- <p class="table-action-option" @click="handleEdit(item)">
                <va-icon name="edit" /> Edit
              </p>
              <p class="table-action-option" @click="confirmDelete(item)">
                <va-icon name="delete" /> Delete
              </p> -->
              <p @click="viewProfile(item)">
                <va-icon name="person" /> View Profile
              </p>
            </va-dropdown-content>
          </va-dropdown>
        </td>
      </template>
    </v-data-table-server>

    <va-modal v-model="deleteDialog" title="Confirm Deletion">
      <h1>Are you sure you want to delete this item?</h1>
    </va-modal>
  </div>
</template>

<script lang="ts" src="./script.ts" />
<style lang="scss" src="./styles.scss" scoped></style>
