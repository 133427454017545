import { deepCopy, validateInput } from '@/lib/utils'
import { StagesPresenter } from '@/presenters/StagesPresenter'
import { defineComponent, PropType } from 'vue'
import draggable from 'vuedraggable'

export default defineComponent({
  name: '',
  props: {
    updateLayoutLoading: {
      type: Boolean,
      default: false,
    },
    isDeleteLayoutLoading: {
      type: Boolean,
      default: false,
    },
    isUpdateLayoutForm: {
      type: Boolean,
      default: false,
    },
    section_stages: {
      type: Array as PropType<StagesPresenter[]>,
      required: true,
    },
  },
  emits: [
    'handleToggleUpdateLayoutModal',
    'handleUpdateStageData',
    'onDragEnd',
    'handleDeleteLayout',
  ],
  data() {
    const palette: string[] = [
      '#DD6B20',
      '#002C6C',
      '#66023C',
      '#E53E3E',
      '#D69E2E',
      '#38A169',
      '#319795',
      '#3182CE',
      '#00B5D8',
      '#805AD5',
      '#D53F8C',
    ]
    return {
      validateInput,
      deepCopy,

      isUpdateLayoutFormBool: this.isUpdateLayoutForm,

      stages:
        this.section_stages.length > 0
          ? deepCopy(this.section_stages)
          : [{ color: palette[0], name: '', order: 0 }], // Default column
      originalStages:
        this.section_stages.length > 0
          ? deepCopy(this.section_stages)
          : [{ color: palette[0], name: '', order: 0 }], // Default column

      palette,

      deleteStates: {} as Record<string, boolean>,
      currentLoadingStageId: null as null | number,
    }
  },
  watch: {
    isUpdateLayoutForm: {
      handler(data) {
        this.isUpdateLayoutFormBool = data
      },
    },

    section_stages: {
      immediate: true,
      handler(newStages) {
        // console.log({ newStages })
        if (newStages.length === 0) {
          // If no stages are present, initialize with a default empty column
          const defaultStage = { color: this.palette[0], name: '', order: 0 }
          this.originalStages = [defaultStage]
          this.stages = [defaultStage]
        } else {
          this.originalStages = this.deepCopy(newStages)
          this.stages = this.deepCopy(newStages)
        }
      },
    },
  },
  methods: {
    handleToggleUpdateLayoutModal() {
      this.isUpdateLayoutFormBool = !this.isUpdateLayoutFormBool
      this.stages = this.deepCopy(this.originalStages)
      this.$emit('handleToggleUpdateLayoutModal')
    },

    handleReset() {
      this.stages = this.deepCopy(this.originalStages)
    },

    selectColor(stage: StagesPresenter, color: string) {
      stage.color = color
    },

    handleUpdateStageData(stage: StagesPresenter) {
      this.currentLoadingStageId = stage.id
      this.$emit('handleUpdateStageData', {
        ...(stage?.id && { id: stage.id }),
        name: stage.name,
        color: stage.color,
        ...(stage?.order && { sort_order: stage.order }),
      })
    },

    toggleDeleteState(id: string | number) {
      this.deleteStates = {} as Record<string, boolean>
      this.deleteStates[id] = true
    },
    handleDeleteLayout(id: number, index: number) {
      console.log({ id, index })

      if (id) {
        this.$emit('handleDeleteLayout', id)
      } else {
        this.stages.splice(index, 1)
      }
    },

    onDragEnd(event: {
      newIndex: number
      oldIndex: number
      item: { _underlying_vm_: StagesPresenter }
    }) {
      const {
        newIndex,
        oldIndex,
        item: { _underlying_vm_ },
      } = event

      const sort_order = newIndex >= oldIndex ? newIndex + 1 : newIndex

      this.$emit('onDragEnd', {
        id: _underlying_vm_.id,
        section_id: _underlying_vm_.section_id,
        sort_order,
      })
    },
    //////////////////////////////////////////////////////////////////

    addNewStage(index: number, position: 'left' | 'right') {
      let newStage

      if (position === 'left') {
        newStage = {
          color: this.palette[0],
          name: '',
          order: index,
        }
        this.stages.splice(index, 0, newStage)
      } else if (position === 'right') {
        newStage = {
          color: this.palette[0],
          name: '',
          order: index + 1,
        }
        this.stages.splice(index + 1, 0, newStage)
      }
    },
  },
  components: {
    draggable,
  },
})
