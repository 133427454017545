import { deepCopy } from '@/lib/utils'
import { MessagePresenter } from '@/presenters/ChatPresenter'

export const messagesData: MessagePresenter[] = [
  {
    id: 1,
    isRead: true,
    content:
      'Hey! Its been a while since we last caught up. How have you been? Hope everything is going well on your end!',
    date: '2025-02-01T12:00:00Z',
    attachments: [],
    isMe: false,
  },
  {
    id: 2,
    content: 'Im doing great! How about you?',
    date: '2025-02-01T12:05:00Z',
    isRead: true,
    isMe: true,
    attachments: [],
  },
  {
    id: 3,
    content:
      'Oh, and by the way, I started working on a new project recently! Its been super exciting but also really challenging.',
    date: '2025-02-01T12:06:00Z',
    isRead: true,
    attachments: [],
    isMe: true,
  },
  {
    content:
      'That sounds amazing! What kind of project is it? Something personal, or is it related to work?',
    date: '2025-02-01T12:10:00Z',
    isRead: true,
    attachments: [],
    id: 4,
    isMe: false,
  },
  {
    id: 5,
    isRead: true,
    attachments: [],
    isMe: true,
    content:
      'It’s actually a work project! We’re building a platform that helps teams collaborate more efficiently.',
    date: '2025-02-02T12:15:00Z',
  },
  {
    id: 6,
    isRead: false,
    content:
      'That’s awesome! Keep going, and don’t forget to take breaks when needed',
    date: '2025-02-02T12:20:00Z',
    isMe: false,
    attachments: [],
  },
]

export const mockMessages = {
  success: true,
  data: { list: deepCopy(messagesData) },
}

export const mockMessagesEmpty = { success: true, data: { list: [] } }
