import { TeamMemberPresenter } from '../teamMember'

export class VersionPresenter {
  id!: number
  class_name!: string
  created_at!: string
  event!: string
  item_id!: number
  item_type!: string
  updated_at!: string

  whodunnit!: number
  whodunnit_user!: TeamMemberPresenter

  changeset!: Record<string, [string, string]>

  messages!: string[]

  constructor(data: VersionPresenter) {
    Object.assign(this, data)

    if (data.whodunnit_user) {
      this.whodunnit_user = new TeamMemberPresenter(data.whodunnit_user)
    }

    if (data.changeset) {
      this.messages = this.generateMessages(data.changeset)
    }
  }
  private generateMessages(
    changeset: Record<string, [string, string]>,
  ): string[] {
    return Object.keys(changeset)
      .filter((key) => key !== 'updated_at')
      .map((key) => {
        const values = changeset[key]

        if (!Array.isArray(values) || values.length < 2) return ''

        const oldValue = values[0]
        const newValue = values[1]

        const readableKey = this.formatKeyName(key)

        if (oldValue == null) {
          return `Updated ${readableKey} to "${newValue}"`
        } else {
          return `Updated ${readableKey} from "${oldValue}" to "${newValue}"`
        }
      })
      .filter((message) => message)
  }
  private formatKeyName(key: string): string {
    const removedStrings = new Set(['individual', 'id'])
    return key
      .split('_')
      .reduce((acc, word) => {
        if (!removedStrings.has(word.toLowerCase())) {
          acc.push(word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        }
        return acc
      }, [] as string[])
      .join(' ')
  }
}
