<template>
  <VaModal
    v-model="isCurrencyUpdate"
    size="auto"
    hide-default-actions
    fixed-layout
    no-outside-dismiss
  >
    <template #header>
      <div class="update-header">
        <h1>Update Currency</h1>
        <VaIcon
          class="header-icon press-button"
          name="close"
          size="30px"
          @click="toggleEdit"
        />
      </div>
    </template>

    <VaForm
      ref="updateForm"
      class="update-form"
      @submit.prevent="handleUpdateCurrency()"
    >
      <VaInput
        v-model="selectedCurrency.name"
        placeholder="Add currency name"
        :rules="[validateInput]"
        class="form-option-name"
      />

      <VaInput
        v-model="selectedCurrency.code"
        placeholder="Code (USD)"
        :rules="[validateInput]"
        class="form-option-code"
      />

      <VaInput
        v-model="selectedCurrency.conversion_rate_against_us_dollars"
        placeholder="Rate"
        :rules="[validateInput]"
        class="form-option-rate"
        type="number"
        min="0"
      >
        <template #appendInner>
          <VaIcon name="attach_money" color="secondary" />
        </template>
      </VaInput>
    </VaForm>
    <template #footer>
      <div class="update-buttons">
        <VaButton preset="secondary" border-color="primary" @click="toggleEdit">
          Cancel
        </VaButton>
        <VaButton
          :loading="isUpdateCurrencyLoading"
          @click="handleUpdateCurrency"
        >
          Save
        </VaButton>
      </div>
    </template>
  </VaModal>

  <VaModal
    v-model="isCurrencyBool"
    size="small"
    hide-default-actions
    fixed-layout
    no-outside-dismiss
    @update:modelValue="toggleCurrency"
  >
    <template #header>
      <div class="global-config-header">
        <h1>Currencies</h1>
        <VaIcon
          class="header-icon press-button"
          name="close"
          size="30px"
          @click="toggleCurrency"
        />
      </div>

      <div class="search">
        <VaInput v-model="currencySearch" placeholder="Search...">
          <template #prependInner>
            <VaIcon name="search" color="secondary" />
          </template>
        </VaInput>
      </div>
    </template>
    <div class="global-config-body">
      <div class="config-list">
        <ul v-for="(curr, index) in currencies" :key="index">
          <li>
            <VaAvatar color="#ECF0F1" size="small">
              {{ curr.code }}
            </VaAvatar>

            <p>
              {{ curr.name }}
            </p>
            -
            <p>{{ curr.conversion_rate_against_us_dollars }}</p>

            <div
              v-show="showConfirmCurrencyId !== curr.id"
              v-if="loadingCurrencyId !== curr.id"
              class="delete-icon"
            >
              <VaIcon
                name="delete"
                color="primary"
                @click="toggleConfirm(curr.id)"
              />

              <VaIcon
                name="info"
                color="primary"
                class="header-icon press-button"
                @click="toggleEdit(curr)"
              />
            </div>

            <VaIcon
              v-show="showConfirmCurrencyId !== curr.id"
              v-if="loadingCurrencyId === curr.id"
              class="loading-icon"
              name="autorenew"
              color="primary"
            />

            <div v-if="showConfirmCurrencyId === curr.id" class="confirm-box">
              <VaIcon name="check" @click="confirmDelete(curr.id)" />
              <VaIcon name="close" @click="cancelDelete" />
            </div>
          </li>
        </ul>
      </div>
    </div>

    <VaForm
      ref="form"
      class="global-config-add"
      @submit.prevent="handleAddCurrency"
    >
      <VaInput
        v-model="newCurrency.name"
        placeholder="Add currency name"
        :rules="[validateInput]"
        class="form-option-name"
      />

      <VaInput
        v-model="newCurrency.code"
        placeholder="Code (USD)"
        :rules="[validateInput]"
        class="form-option-code"
      />

      <VaInput
        v-model="newCurrency.conversion_rate_against_us_dollars"
        placeholder="Rate"
        :rules="[validateInput]"
        class="form-option-rate"
        type="number"
        min="0"
      >
        <template #appendInner>
          <VaIcon name="attach_money" color="secondary" />
        </template>
      </VaInput>
    </VaForm>

    <template #footer>
      <div class="global-config-buttons">
        <VaButton
          preset="secondary"
          border-color="primary"
          @click="toggleCurrency"
        >
          Cancel
        </VaButton>
        <VaButton :loading="isAddCurrencyLoading" @click="handleAddCurrency">
          Add
        </VaButton>
      </div>
    </template>
  </VaModal>
</template>
<script lang="ts" src="./script.ts" />
<style lang="scss" scoped src="./styles.scss" />
