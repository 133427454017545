<template>
  <div class="scroll-container-wrapper for-tables">
    <v-data-table-server
      v-model:selection="selectedRows"
      :loading="loading"
      :headers="headers"
      :items="items"
      show-select
      class="custom-table"
      :items-length="totalItems"
      :items-per-page="itemsPerPage"
      :items-per-page-options="[10, 25, 50]"
      @update:options="handleLoadMore"
      :fixed-header="true"
      :fixed-footer="true"
    >
      <template #[`item.title`]="{ item }">
        <router-link :to="item?.path_to" v-if="item?.path_to">
          <p class="title">
            {{ item.title }}
          </p>
        </router-link>
      </template>

      <template #[`item.currency`]="{ item }">
        <p class="currency">
          {{ item.currency?.code }}
        </p>
      </template>

      <template #[`item.section_stage_items`]="{ item }">
        <td
          v-if="item.section_stage_items?.[0]?.section_stage?.name"
          class="stage-td"
          :style="{
            backgroundColor:
              item.section_stage_items?.[0]?.section_stage?.color || '#babfc0',
          }"
        >
          {{ item.section_stage_items?.[0]?.section_stage?.name }}
        </td>
      </template>

      <template #[`item.label`]="{ item }">
        <td
          v-if="item.label?.name"
          class="stage-td"
          :style="{
            backgroundColor: item.label?.color || '#babfc0',
          }"
        >
          {{ item.label?.name }}
        </td>
      </template>

      <template #[`item.organization_contact`]="{ item }">
        <div class="social-media-icons" v-if="item?.organization_contact">
          <router-link :to="item?.organization_contact?.path_to">
            <p class="organization">
              <VaAvatar
                color="#ECF0F1"
                v-if="
                  item.organization_contact?.fullName ||
                  item?.organization_contact?.avatar?.medium_url
                "
                :src="item?.organization_contact?.avatar?.medium_url"
                :alt="item?.referred_by?.fullName"
                size="small"
              >
                <span
                  v-if="!item?.organization_contact?.avatar?.medium_url"
                  style="color: black"
                >
                  {{ item.organization_contact?.avatar_letter }}
                </span>
              </VaAvatar>
              {{ item?.organization_contact?.fullName }}
            </p>
          </router-link>
        </div>
      </template>

      <template #[`item.individual_contact`]="{ item }">
        <div class="social-media-icons" v-if="item?.individual_contact">
          <router-link :to="item?.individual_contact?.path_to">
            <p class="organization">
              <VaAvatar
                color="#ECF0F1"
                v-if="
                  item.individual_contact?.fullName ||
                  item?.individual_contact?.avatar?.medium_url
                "
                :src="item?.individual_contact?.avatar?.medium_url"
                :alt="item?.referred_by?.fullName"
                size="small"
              >
                <span
                  v-if="!item?.individual_contact?.avatar?.medium_url"
                  style="color: black"
                >
                  {{ item.individual_contact?.fullName[0] }}
                </span>
              </VaAvatar>
              {{ item?.individual_contact?.fullName }}
            </p>
          </router-link>
        </div>
      </template>

      <template #[`item.relationship_owner`]="{ item }">
        <p class="assigned-to">
          <VaAvatar
            color="#ECF0F1"
            v-if="
              item.relationship_owner?.avatar_letter ||
              item?.relationship_owner?.avatar?.medium_url
            "
            :src="item?.relationship_owner?.avatar?.medium_url"
            :alt="item?.relationship_owner?.fullName"
            size="small"
          >
            <span
              v-if="!item?.relationship_owner?.avatar?.medium_url"
              style="color: black"
            >
              {{ item.relationship_owner?.avatar_letter }}
            </span>
          </VaAvatar>
          {{ item.relationship_owner?.fullName }}
        </p>
      </template>

      <template #[`item.action`]="{ item }">
        <td class="table-action-td">
          <va-dropdown :offset="[0, -70]" close-on-content-click>
            <template #anchor>
              <img
                :src="MoreVertIcon"
                alt="MoreVertIcon"
                class="press-button-2"
              />
            </template>
            <va-dropdown-content class="dropdown">
              <p @click="viewProfile(item)">
                <VaIcon name="person" /> View Lead
              </p>
            </va-dropdown-content>
          </va-dropdown>
        </td>
      </template>
    </v-data-table-server>

    <!-- <va-modal v-model="deleteDialog" title="Confirm Deletion">
      <h1>Are you sure you want to delete this item?</h1>
    </va-modal> -->
  </div>
</template>

<script lang="ts" src="./script.ts" />
<style lang="scss" src="./styles.scss" scoped />
