import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-dd0cc3fe"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "scroll-container-wrapper for-tables" }
const _hoisted_2 = { class: "currency" }
const _hoisted_3 = { class: "tax_amount" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { class: "invoice-action-td" }
const _hoisted_7 = ["src"]
const _hoisted_8 = ["onClick"]
const _hoisted_9 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_VaIcon = _resolveComponent("VaIcon")!
  const _component_va_icon = _resolveComponent("va-icon")!
  const _component_va_dropdown_content = _resolveComponent("va-dropdown-content")!
  const _component_va_dropdown = _resolveComponent("va-dropdown")!
  const _component_v_data_table_server = _resolveComponent("v-data-table-server")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_data_table_server, {
      selection: _ctx.selectedRows,
      "onUpdate:selection": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedRows) = $event)),
      loading: _ctx.loading,
      headers: _ctx.headers,
      items: _ctx.invoices?.list,
      "show-select": "",
      class: "custom-table scroll-container",
      "items-length": _ctx.localTotalItems,
      "items-per-page": _ctx.itemsPerPage,
      "items-per-page-options": [10, 25, 50],
      "onUpdate:options": _ctx.handleLoadMore,
      "fixed-header": true,
      "fixed-footer": true
    }, {
      [`item.invoice_number`]: _withCtx(({ item }) => [
        _createVNode(_component_router_link, {
          to: `/financial/invoice/${item?.id}`
        }, {
          default: _withCtx(() => [
            _createElementVNode("p", null, _toDisplayString(item?.invoice_number), 1)
          ]),
          _: 2
        }, 1032, ["to"])
      ]),
      [`item.customer_name`]: _withCtx(({ item }) => [
        _createVNode(_component_router_link, {
          to: `/financial/invoice/${item?.id}`
        }, {
          default: _withCtx(() => [
            _createElementVNode("p", null, _toDisplayString(item?.customer_name), 1)
          ]),
          _: 2
        }, 1032, ["to"])
      ]),
      [`item.status`]: _withCtx(({ item }) => [
        _createElementVNode("p", {
          class: _normalizeClass(["status", item?.status.toLowerCase()])
        }, _toDisplayString(item.status), 3)
      ]),
      [`item.currency`]: _withCtx(({ item }) => [
        _createElementVNode("p", _hoisted_2, _toDisplayString(item.currency.code), 1)
      ]),
      [`item.tax_amount`]: _withCtx(({ item }) => [
        _createElementVNode("p", _hoisted_3, _toDisplayString(item.tax_amount || '-'), 1)
      ]),
      [`item.created_at`]: _withCtx(({ item }) => [
        _createElementVNode("p", null, _toDisplayString(item.created_at.split('T')[0]), 1)
      ]),
      [`item.approved`]: _withCtx(({ item }) => [
        (item.approved)
          ? (_openBlock(), _createElementBlock("span", _hoisted_4, [
              _createVNode(_component_VaIcon, {
                name: "task_alt",
                color: "#28A745"
              })
            ]))
          : (_openBlock(), _createElementBlock("span", _hoisted_5, [
              _createVNode(_component_VaIcon, {
                name: "cancel",
                color: "#FD7E14"
              })
            ]))
      ]),
      [`item.action`]: _withCtx(({ item }) => [
        _createElementVNode("td", _hoisted_6, [
          _createVNode(_component_va_dropdown, {
            offset: [0, -70],
            "close-on-content-click": ""
          }, {
            anchor: _withCtx(() => [
              _createElementVNode("img", {
                src: _ctx.MoreVertIcon,
                alt: "MoreVertIcon",
                class: "press-button-2"
              }, null, 8, _hoisted_7)
            ]),
            default: _withCtx(() => [
              _createVNode(_component_va_dropdown_content, { class: "dropdown" }, {
                default: _withCtx(() => [
                  _createElementVNode("p", {
                    onClick: ($event: any) => (_ctx.viewProfile(item))
                  }, [
                    _createVNode(_component_va_icon, { name: "person" }),
                    _createTextVNode(" View Invoice ")
                  ], 8, _hoisted_8),
                  (!item.approved)
                    ? (_openBlock(), _createElementBlock("p", {
                        key: 0,
                        onClick: ($event: any) => (_ctx.handleApproveInvoice(item?.id))
                      }, [
                        _createVNode(_component_va_icon, { name: "task_alt" }),
                        _createTextVNode(" Approve Invoice ")
                      ], 8, _hoisted_9))
                    : _createCommentVNode("", true)
                ]),
                _: 2
              }, 1024)
            ]),
            _: 2
          }, 1024)
        ])
      ]),
      _: 2
    }, 1032, ["selection", "loading", "headers", "items", "items-length", "items-per-page", "onUpdate:options"])
  ]))
}