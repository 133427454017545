<template>
  <SharedHeader title-width="30rem" title="Recycle Bin">
    <div class="sub-SharedHeader">
      <div class="sub-SharedHeader-left" />
      <div class="sub-SharedHeader-right" />
    </div>
  </SharedHeader>

  <div class="coming-soon-container">
    <ComingSoon />
  </div>
</template>

<script lang="ts" src="./script.ts"></script>

<style lang="scss" scoped src="./styles.scss"></style>
