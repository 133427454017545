<template>
  <SearchAndViews :search="leadSearchKeyword" @search="searchLeadData" />

  <LeadTable
    :loading="leadTableLoading"
    :items-per-page="leadsItemPerPage"
    :total-items="leadTotalItems"
    :items="leads"
    :headers="headers"
    @selectedRows="getSelectedRows"
    @loadMore="handlePaginationLeads"
  />
</template>

<script lang="ts" src="./script.ts" />
<style lang="scss" scoped src="./styles.scss" />
