export class StorageFilePresenter {
  id?: number
  class_name!: string
  content_type!: string | null
  created_at!: string
  description!: string
  file!: {
    id: number
    large_url: string
    medium_url: string
    thumbnail_url: string
    url: string
  }
  file_size!: number
  name!: string
  updated_at!: string

  constructor(data: StorageFilePresenter) {
    Object.assign(this, data)
  }
}
