<template>
  <TabsBar :tabs="tabs" :active-tab-id="activeTabId" @changeTab="changeTab" />
  <VaDivider />
  <div class="scroll-container inherit-height">
    <CalendarsGlobal v-if="activeTab === 'GLOBAL CALENDAR'" />
    <div v-else class="coming-soon-container">
      <ComingSoon />
    </div>
  </div>
</template>
<script lang="ts" src="./script.ts" />
<style lang="scss" scoped src="./styles.scss" />
