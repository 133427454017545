import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withKeys as _withKeys, withDirectives as _withDirectives, createElementVNode as _createElementVNode, mergeProps as _mergeProps, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-528c68bb"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "folder-header" }
const _hoisted_2 = { class: "info" }
const _hoisted_3 = { class: "file-details" }
const _hoisted_4 = { class: "name-container" }
const _hoisted_5 = { class: "size" }
const _hoisted_6 = { class: "folder-options" }
const _hoisted_7 = ["src"]
const _hoisted_8 = { class: "menu" }
const _hoisted_9 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VaIcon = _resolveComponent("VaIcon")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_menu = _resolveComponent("v-menu")!

  return (_openBlock(), _createElementBlock("div", {
    class: "file",
    onContextmenu: _cache[6] || (_cache[6] = _withModifiers(($event: any) => (_ctx.isOpenOptions = true), ["prevent"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_VaIcon, {
          name: "description",
          size: "1.7rem",
          color: "primary"
        }),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            (!_ctx.isEditingName)
              ? (_openBlock(), _createElementBlock("h3", {
                  key: 0,
                  class: "name",
                  onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.enableEditing && _ctx.enableEditing(...args)), ["stop"]))
                }, _toDisplayString(_ctx.file?.name), 1))
              : _withDirectives((_openBlock(), _createElementBlock("input", {
                  key: 1,
                  ref: "nameInput",
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.editableName) = $event)),
                  type: "text",
                  class: "name-input",
                  autofocus: "",
                  onKeydown: _cache[2] || (_cache[2] = _withKeys(
//@ts-ignore
(...args) => (_ctx.saveName && _ctx.saveName(...args)), ["enter"])),
                  onBlur: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.cancelEditing && _ctx.cancelEditing(...args))),
                  onClick: _cache[4] || (_cache[4] = _withModifiers(() => {}, ["stop"]))
                }, null, 544)), [
                  [_vModelText, _ctx.editableName]
                ]),
            _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.file?.file_size) + " GB", 1)
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_v_menu, {
          modelValue: _ctx.isOpenOptions,
          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.isOpenOptions) = $event)),
          "close-on-content-click": true,
          location: "bottom left"
        }, {
          activator: _withCtx(({ props }) => [
            _createElementVNode("img", _mergeProps(props, {
              src: _ctx.MoreVertIcon,
              alt: "MoreVertIcon",
              class: "press-button-2"
            }), null, 16, _hoisted_7)
          ]),
          default: _withCtx(() => [
            _createVNode(_component_v_card, { class: "menu-list" }, {
              default: _withCtx(() => [
                _createElementVNode("ul", _hoisted_8, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fileOptions, (option) => {
                    return (_openBlock(), _createElementBlock("li", {
                      key: option.id,
                      class: _normalizeClass([
                  'menu-item',
                  { 'menu-item-disabled': option.deactivate },
                ]),
                      onClick: ($event: any) => (!option.deactivate && _ctx.handleOptionClick(option))
                    }, _toDisplayString(option.option), 11, _hoisted_9))
                  }), 128))
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["modelValue"])
      ])
    ])
  ], 32))
}