import { FilterForm } from '@/Interfaces/Constants'
import { ContactType } from '@/Interfaces/Services'
import { removeNullableFields } from '@/lib/utils'
import { SectionsPresenter } from '@/presenters/SectionsPresenter'
import { defineComponent } from 'vue'

interface ContactTypeOption {
  value: 'IndividualContact' | 'OrganizationContact'
  text: string
}

export default defineComponent({
  name: 'ContactFilterModal',
  props: {
    isAddContactFilterLoading: {
      type: Boolean,
      default: false,
    },
    isAddContactFilter: {
      type: Boolean,
      default: false,
    },
    isUpdateFilter: {
      type: Boolean,
      default: false,
    },
    selectedSection: {
      type: Object as () => SectionsPresenter,
      required: true,
    },
  },
  data() {
    return {
      listing_filters: {
        contact_type: ContactType.IndividualContact,
      } as FilterForm,
      name: '',
      contactTypeOptions: [
        {
          value: 'IndividualContact',
          text: 'Individual Contact',
        },
        {
          value: 'OrganizationContact',
          text: 'Organization Contact',
        },
      ] as ContactTypeOption[],
      isAddContactFilterBool: false,
    }
  },
  emits: [
    'handleToggleAddModalFilter',
    'handleAddFilter',
    'handleUpdateFilter',
  ],
  methods: {
    handleSubmitFilter(): void {
      // Implementation needed
      console.log({ isUpdateFilter: this.isUpdateFilter })

      if (this.isUpdateFilter) {
        this.$emit('handleUpdateFilter', {
          id: this.selectedSection.id,
          body: removeNullableFields({
            ...this.selectedSection,
            listing_filters: this.listing_filters,
          }),
        })
      } else {
        this.$emit('handleAddFilter', {
          name: this.name,
          listing_filters: this.listing_filters,
        })
      }
    },

    handleToggleAddModalFilter(): void {
      this.isAddContactFilterBool = !this.isAddContactFilterBool
      this.$emit('handleToggleAddModalFilter')
    },
  },
  computed: {
    modalTitle(): string {
      return this.isUpdateFilter ? 'Update Filter' : 'Add Filter'
    },
    submitButton(): string {
      return this.isUpdateFilter ? 'Update Filter' : 'Add Filter'
    },
  },
  watch: {
    isAddContactFilter: {
      handler(value: boolean): void {
        this.isAddContactFilterBool = value
        if (!value) {
          this.listing_filters = {
            contact_type: ContactType.IndividualContact,
          } as FilterForm
        }
        if (!this.isUpdateFilter) {
          this.listing_filters = {
            contact_type: ContactType.IndividualContact,
          } as FilterForm
        }
      },
      immediate: true,
    },
    selectedSection: {
      handler(value: SectionsPresenter): void {
        this.listing_filters = value.listing_filters
        this.name = value.name
      },
    },
    isAddContactFilterLoading: {
      handler(data) {
        if (!data) {
          this.isAddContactFilterBool = false
        }
      },
    },
  },
})
