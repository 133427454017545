import {
  messagesData,
  mockMessages,
  mockMessagesEmpty,
} from '@/constants/mockMessages'
import { deepCopy } from '@/lib/utils'
import { MessagePresenter } from '@/presenters/ChatPresenter'

export const getChatHistory = (tabId: number, resetMessages?: boolean) => {
  if (resetMessages) {
    if (tabId === 2) {
      mockMessages.data.list = deepCopy(messagesData)
    } else {
      mockMessagesEmpty.data.list = []
    }
  }
  const response = tabId === 2 ? mockMessages : mockMessagesEmpty

  if (!response.success) {
    return response
  }

  const list = response.data?.list
  if (!Array.isArray(list)) {
    return response
  }

  return {
    ...response,
    data: {
      ...response.data,
      list: list.map((item: MessagePresenter) => new MessagePresenter(item)),
    },
  }
}

export const addMessage = (
  body: string,
  callback: () => void,
  tabId?: number,
) => {
  const targetMessages = tabId === 2 ? mockMessages : mockMessagesEmpty
  const lastMessage =
    targetMessages.data.list[targetMessages.data.list.length - 1]
  const nextId = lastMessage ? lastMessage.id + 1 : 1

  targetMessages.data.list.push({
    id: nextId,
    isRead: true,
    content: body,
    date: new Date().toISOString(),
    attachments: [],
    isMe: true,
  })

  callback()

  setTimeout(() => {
    targetMessages.data.list.push({
      id: nextId + 1,
      isRead: true,
      content: 'The Chat system is coming soon.',
      date: new Date().toISOString(),
      attachments: [],
      isMe: false,
    })

    callback()
  }, 500)
}
