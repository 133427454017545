import { defineComponent } from 'vue'
import TabsBar from '@/components/tabsBar/index.vue'
import ComingSoon from '@/components/ComingSoon/index.vue'
import LeadTable from './LeadTable/index.vue'
import LeadToolbar from './LeadToolbar/index.vue'
import LeadGlobal from './LeadGlobal/index.vue'

export default defineComponent({
  name: 'MattersSettings',
  components: {
    TabsBar,
    ComingSoon,
    LeadTable,
    LeadToolbar,
    LeadGlobal,
  },
  data() {
    const tabs = [
      { id: 0, name: 'TEMPLATES' },
      { id: 1, name: 'GLOBAL LEADS' },
      { id: 2, name: 'LEAD TABLE' },
    ]

    return {
      tabs,
      activeTabId: 0,
      activeTab: 'TEMPLATES',
    }
  },
  computed: {},
  methods: {
    /////////////////////////////////////////////////////////////////////////////
    changeTab({ tab }: { tab: { title?: string; name: string; id: number } }) {
      this.activeTabId = tab.id
      this.activeTab = tab.name
    },
  },
})
