import { paginationInterface } from '@/Interfaces/Services'
import { MatterPresenter } from '@/presenters/matterPresenter'
import { getAllMatters } from '@/services/legalMatter'
import { defineComponent } from 'vue'

import MatterTable from '@/components/LegalMatter/Table/index.vue'
import SearchAndViews from '@/components/searchAndViews/index.vue'
import { StagesPresenter } from '@/presenters/StagesPresenter'

export default defineComponent({
  name: '',
  props: {
    refetchAllMattersTrigger: {
      type: Boolean,
      required: false,
    },
    sections: {
      type: Array as () => StagesPresenter[],
      required: true,
    },
    headers: {
      type: Array,
      required: true,
    },
  },
  emits: [],
  data() {
    return {
      activeView: 'list',

      matterTableLoading: false,

      selectedRows: [] as MatterPresenter[],

      matters: [] as MatterPresenter[],
      mattersItemPerPage: 10,
      matterPage: 1,
      matterSortField: '' as any,
      matterSortDirection: '' as 'asc' | 'desc' | undefined,
      matterSearchKeyword: '',
      matterTotalItems: 0,

      debounceTimeout: null as ReturnType<typeof setTimeout> | null,
    }
  },
  mounted() {
    this.handleGetMatters()
  },
  methods: {
    changeView(view: string) {
      this.activeView = view
    },
    getSelectedRows(rows: MatterPresenter[]) {
      this.selectedRows = rows
    },
    ////////////////////////////////////////////////////////////////////////////
    async handleGetMatters() {
      this.matterTableLoading = true

      const response = await getAllMatters({
        per_page: this.mattersItemPerPage,
        page: this.matterPage,
        keyword: this.matterSearchKeyword,
      })
      if (response.success) {
        this.matters = response.data?.list
        this.matterTotalItems =
          response.data.total_pages * this.mattersItemPerPage
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.matterTableLoading = false
    },

    async handlePaginationMatters({
      page,
      itemsPerPage,
      sortBy,
    }: paginationInterface) {
      this.mattersItemPerPage = itemsPerPage
      this.matterPage = page
      if (sortBy.length > 0) {
        this.matterSortField = sortBy[0]?.key.split('.')[1]
        this.matterSortDirection = sortBy[0]?.order
      } else {
        this.matterSortField = undefined
        this.matterSortDirection = undefined
      }

      await this.handleGetMatters()
    },

    searchMatterData(search: string) {
      this.matterSearchKeyword = search

      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout)
      }

      this.debounceTimeout = setTimeout(() => {
        this.handleGetMatters()
      }, 500)
    },

    ////////////////////////////////////////////////////////////////////////////
  },
  watch: {
    refetchAllMattersTrigger: {
      handler(data) {
        this.handleGetMatters()
      },
    },
  },
  components: {
    MatterTable,
    SearchAndViews,
  },
})
