<template>
  <SharedHeader title-width="30rem" title="Tasks">
    <div class="sub-SharedHeader">
      <va-dropdown :offset="[0, -70]" close-on-content-click>
        <template #anchor>
          <img :src="MoreVertIcon" alt="MoreVertIcon" class="press-button-2" />
        </template>
        <va-dropdown-content class="dropdown">
          <p @click="handleToggleUpdateLayoutModal">Edit Page Layout</p>
        </va-dropdown-content>
      </va-dropdown>
    </div>
  </SharedHeader>

  <AddTaskModal
    :add-task-loading="isAddLoading"
    :is-add-task-form="isAddTask"
    :sub-sections="subSections"
    :section="section"
    @handleToggleAddTaskModal="handleToggleAddTaskModal"
    @handleCreateTask="handleCreateTask"
  />

  <UpdateLayoutColumns
    :update-layout-loading="isUpdateLayoutLoading"
    :is-update-layout-form="isShowUpdateLayoutModal"
    :section_stages="stages"
    :is-delete-layout-loading="isDeleteLayoutLoading"
    @handleToggleUpdateLayoutModal="handleToggleUpdateLayoutModal"
    @handleUpdateStageData="handleUpdateLayout"
    @onDragEnd="handleUpdateLayout"
    @handleDeleteLayout="handleDeleteLayout"
  />

  <TabsBar
    :tabs="sections"
    :active-tab-id="activeTabId"
    :loading="isLoading"
    @changeTab="handleTabChange"
  />
  <ActionsBar
    v-if="!isLoading"
    :second-add-button="$t('actionsBar.addTask')"
    @secondButtonAction="handleToggleAddTaskModal"
  />

  <AllTasks
    v-if="activeTabId === 1717171"
    :headers="headers"
    :refetchAllTasksTrigger="refetchAllTasksTrigger"
  />

  <div v-else class="scroll-container-wrapper">
    <KanbanInfiniteLoad
      v-if="!isLoading"
      :is-tasks="true"
      :stages="stages"
      :is-cards-loading="isTasksLoading"
      :is-columns-loading="isStageLoading"
      @ticketMoved="handleTicketMoved"
      @handleAddStage="handleToggleUpdateLayoutModal"
    />
  </div>
</template>

<script lang="ts" scoped src="./script.ts"></script>
<style lang="scss" scoped src="./styles.scss" />
