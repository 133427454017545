import FileUploadHelper, {
  truncateText,
  getOptionFullName,
  getOptionTruncateName50,
  validateInput,
  validateInputEmail,
  validateSelect,
  getOptionName,
  getFormColumns,
} from '@/lib/utils'
import { AddressPresenter } from '@/presenters/AddressPresenter'
import { BankAccountPresenter } from '@/presenters/BankAccountPresenter'
import { Avatar } from '@/presenters/interface'
import { OrganizationContactPresenter } from '@/presenters/organizationPresenter'
import { defineComponent } from 'vue'
import { useForm } from 'vuestic-ui/web-components'
import DynamicContactForm from '@/components/DynamicForms/DynamicContactForm/index.vue'

const UploadLogoIcon = new URL(
  '@/assets/icons/upload-logo-icon.svg',
  import.meta.url,
).href

export default defineComponent({
  name: 'AddOrganizationForm',
  props: {
    addOrganizationLoading: {
      type: Boolean,
      default: false,
    },
    isAddOrganizationForm: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const { isValid, validate, reset, resetValidation } = useForm('form')

    const form = {
      contact_addresses: [
        {
          city: null,
          floor: '',
          zip_code: '',
          street_name: '',
          building_name: '',
          phone_number: '',
          google_maps_url: '',
        },
      ] as AddressPresenter[],
      contact_bank_accounts: [
        {
          iban: null,
          swift_or_bic: '',
          account_number: '',
          bank_name: '',
          branch_name: '',
          branch_country: '',
          routing_number: '',
          short_code: '',
          bsb_number: '',
          ifcs_code: '',
          transit_number: '',
          clabe: '',
          extra_notes: '',
        },
      ] as BankAccountPresenter[],
    } as OrganizationContactPresenter

    return {
      validateInput,
      validateInputEmail,
      validateSelect,
      getOptionName,
      getOptionFullName,
      getOptionTruncateName50,

      formFields: [] as any[][],
      toggleSubmit: false,

      isAddOrganizationFormBool: this.isAddOrganizationForm,
      isValid,
      validate,
      reset,
      resetValidation,
      kyc_documents: [],
      UploadLogoIcon,
      form,

      isAddressAccordion: [false],
      isBankAccordion: [false],

      organizationTypeOptions: [
        'Sole Proprietorship',
        'Individual Intrapreneur',
        'Freelancer',
        'Partnership',
        'General Partnership',
        'Limited Partnership',
      ],

      debounceTimeout: null as ReturnType<typeof setTimeout> | null,
      truncateText,
    }
  },
  methods: {
    toggleAddOrganizationForm() {
      this.isAddOrganizationFormBool = !this.isAddOrganizationFormBool
      this.$emit('toggleAddOrganizationForm')
    },

    async handleToggleSubmit() {
      this.toggleSubmit = !this.toggleSubmit
    },

    //////////////////////////////////////////////////////////////

    handleCreateOrganization(form: OrganizationContactPresenter) {
      const {
        referred_by,
        organization_contact,
        organization_logo,
        email_addresses,
        phone_numbers,
        contact_addresses,
        contact_bank_accounts,
        relationship_owner,
        assigned_to,
        organization_jurisdiction_of_incorporation,
        linked_legal_matter_ids,
        ...dynamicForm
      } = { ...form }

      this.$emit('handleCreateOrganization', {
        organization_logo:
          this.form.organization_logo ??
          new File([], 'empty.jpg', {
            type: 'image/jpeg',
          }),
        organization_contact_id: organization_contact?.id,
        relationship_owner_id: relationship_owner?.id,
        referred_by_id: referred_by?.id,
        assigned_to_id: assigned_to?.id,
        organization_jurisdiction_of_incorporation,

        ...(contact_addresses?.[0].city !== null && {
          contact_addresses_attributes: contact_addresses?.map((address) => ({
            ...address,
            city_id: address.city.id,
          })),
        }),

        ...(contact_bank_accounts?.[0].iban !== null && {
          contact_bank_accounts_attributes: contact_bank_accounts,
        }),
        linked_legal_matter_ids: linked_legal_matter_ids?.map(
          (matter: { id: number }) => matter.id,
        ),
        ...dynamicForm,
      })
    },

    ///////////////////////////////////////////////////////////////////

    handleRemoveImage() {
      this.form.avatar = {} as Avatar
      this.form.organization_logo = new File([], 'empty.jpg', {
        type: 'image/jpeg',
      })
    },

    handleFileUpload(event: File) {
      FileUploadHelper.handleFileUpload({
        event,
        form: this.form,
        key: 'organization_logo',
      })
    },
  },
  watch: {
    isAddOrganizationForm: {
      handler(data) {
        this.isAddOrganizationFormBool = data
        if (data) {
          this.$stores.usersData.handleGetMembers()
          this.$stores.generalData.handleGetAllCountries()
          this.$stores.contacts.handleGetOrganizations()
          this.$stores.matter.handleGetMatters()

          this.formFields = getFormColumns(
            this.$stores.generalData.companyConfigs
              .organization_contact_form_fields?.[0].data.fields,
            'contacts',
          )
        } else {
          this.form = {
            contact_addresses: [
              {
                city: null,
                floor: '',
                zip_code: '',
                street_name: '',
                building_name: '',
                phone_number: '',
                google_maps_url: '',
              },
            ] as AddressPresenter[],
            contact_bank_accounts: [
              {
                iban: null,
                swift_or_bic: '',
                account_number: '',
                bank_name: '',
                branch_name: '',
                branch_country: '',
                routing_number: '',
                short_code: '',
                bsb_number: '',
                ifcs_code: '',
                transit_number: '',
                clabe: '',
                extra_notes: '',
              },
            ] as BankAccountPresenter[],
          } as OrganizationContactPresenter
        }
      },
    },
  },
  components: {
    DynamicContactForm,
  },
})
