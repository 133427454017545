<template>
  <SharedHeader title-width="30rem" title="Contacts">
    <div v-if="activeTab === 'Leads'" class="sub-SharedHeader">
      <va-dropdown :offset="[0, -70]" close-on-content-click>
        <template #anchor>
          <img :src="MoreVertIcon" alt="MoreVertIcon" class="press-button-2" />
        </template>
        <va-dropdown-content class="dropdown">
          <p @click="handleToggleUpdateLayoutModal">Edit Page Layout</p>
        </va-dropdown-content>
      </va-dropdown>
    </div>
  </SharedHeader>

  <TabsBar
    :tabs="sections"
    :active-tab-id="activeTabId"
    :loading="isSectionsLoading"
    @changeTab="handleTabChange"
  />

  <VaModal v-model="isImportContacts" ok-text="Upload">
    <VaFileUpload
      v-model="uploadFilesList"
      file-types="csv,png"
      undo
      file-incorrect-message="Only CSV files are accepted. (eg: file)"
    >
      <div class="fileUpload-container">
        <div class="custom-upload-file-area">
          <p class="upload-text">
            <i class="fas fa-cloud-upload-alt" />
            <span class="highlight">Click</span> or
            <span class="highlight">drag & drop</span> to upload your file
          </p>
        </div>
      </div>
    </VaFileUpload>
  </VaModal>

  <div v-if="activeTab === 'Dashboard'" class="coming-soon-container">
    <ComingSoon />
  </div>

  <div v-else class="scroll-container-wrapper">
    <SearchAndViews :search="searchKeyword" @search="searchData" />

    <ActionsBar
      v-if="activeTabId === 2727272"
      :show-select-button="true"
      :show-download-icon="true"
      :show-archive-icon="true"
      :show-label-icon="true"
      :show-share-icon="true"
      :add-button="'Add Contact'"
      :second-add-button="'Import Contacts'"
      :edit-columns="true"
      :is-filter-columns="true"
      @SecondButtonAction="handleToggleImportContacts"
      @addButtonAction="toggleAddContactForm"
    />

    <ActionsBar
      v-if="activeTabId === 3737373"
      :show-select-button="true"
      :show-download-icon="true"
      :show-archive-icon="true"
      :show-label-icon="true"
      :show-share-icon="true"
      :add-button="'Add Organization'"
      :edit-columns="true"
      :is-filter-columns="true"
      @addButtonAction="toggleAddOrganizationForm"
    />

    <!-- <VaModal v-model="isAddContact" size="auto" hide-default-actions>
      <template #header>
        <div class="create-form-header">
          <h1>Contact Creation</h1>
          <VaIcon
            class="header-icon press-button"
            name="close"
            size="30px"
            @click="isAddContact = !isAddContact"
          />
        </div>
      </template>
      <div class="add-contact-modal-container">
        <h2>Send a platform invite link to the contact.</h2>
        <VaInput background="#ECF0F1" placeholder="Type email" />
        <VaButton> Send Invite </VaButton>

        <h2>
          Or, create the contact <b @click="toggleAddContactForm">here</b>.
        </h2>
      </div>
    </VaModal> -->

    <AddContactModal
      :v-model="isAddContactForm"
      :add-contact-loading="addEntryLoading"
      :is-add-contact-form="isAddContactForm"
      @toggleAddContactForm="cancelForm"
      @handleCreateContact="handleCreateEntry"
    />

    <AddOrganizationModal
      :add-organization-loading="addEntryLoading"
      :is-add-organization-form="isAddOrganizationForm"
      @toggleAddOrganizationForm="cancelForm"
      @handleCreateOrganization="handleCreateEntry"
    />

    <AllContactsTable
      :loading="tableLoading"
      :items-per-page="itemsPerPage"
      :total-items="totalItems"
      :headers="headers"
      :items="tableData"
      @selectedRows="getSelectedRows"
      @loadMore="handlePagination"
    />
  </div>

  <router-view />
</template>

<script lang="ts" src="./script.ts" />
<style lang="scss" scoped src="./styles.scss"></style>
