<template>
  <TabsBar :tabs="TABS" :active-tab-id="activeTabId" @changeTab="changeTab" />
  <VaDivider />
  <div class="scroll-container inherit-height">
    <ContactsToolbar v-if="activeTab === 'TOOLBAR'" />
    <ContactsForms v-else-if="activeTab === 'CONTACTS FORMS'" />
    <ContactsTables v-else-if="activeTab === 'CONTACTS TABLES'" />
    <div v-else class="coming-soon-container">
      <ComingSoon />
    </div>
  </div>
</template>
<script lang="ts" src="./script.ts" />
<style lang="scss" scoped src="./styles.scss" />
