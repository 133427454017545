import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5e9157da"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "member-container" }
const _hoisted_2 = { class: "contact-image" }
const _hoisted_3 = { class: "avatar-container" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { class: "member-details" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ActionsBar = _resolveComponent("ActionsBar")!
  const _component_VaAvatar = _resolveComponent("VaAvatar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ActionsBar, { secondAddButton: "CHAT" }),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_VaAvatar, {
          color: "#ECF0F1",
          src: _ctx.currentMember?.user?.avatar?.medium_url,
          size: "small",
          alt: "Avatar",
          class: "avatar-preview"
        }, {
          default: _withCtx(() => [
            (!_ctx.currentMember?.user?.avatar?.medium_url)
              ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.currentMember?.avatar_letter), 1))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["src"])
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("h2", null, _toDisplayString(_ctx.currentMember?.fullName), 1),
      _createElementVNode("p", null, _toDisplayString(_ctx.currentMember?.user?.email), 1),
      _createElementVNode("p", null, _toDisplayString(_ctx.currentMember?.user?.title), 1)
    ])
  ]))
}