import { SectionsPresenter } from '@/presenters/SectionsPresenter'
import { getAllSections, updateSection } from '@/services/sections'
import { defineComponent } from 'vue'
import draggable from 'vuedraggable'
import TablesConfigModal from '@/components/TablesConfigModal/index.vue'
import { TableColumnInterface } from '@/Interfaces/Constants'
import { updateCompanyConfiguration } from '@/services/companyConfigurations'
import { CompanyConfigurationPresenter } from '@/presenters/companyConfigPresenters'

export default defineComponent({
  name: 'ContactsTables',
  components: {
    draggable,
    TablesConfigModal,
  },

  data() {
    return {
      isSectionsLoading: false,
      sections: [] as SectionsPresenter[],

      isTableConfigUpdate: false,
      selectedSection: {} as SectionsPresenter,

      isTableConfigUpdateLoading: false,
    }
  },

  methods: {
    handleToggleTableConfig(section?: SectionsPresenter) {
      if (section) {
        this.selectedSection = section
      } else {
        this.selectedSection = {} as SectionsPresenter
      }
      this.isTableConfigUpdate = !this.isTableConfigUpdate
    },

    //////////////////////////////////////////////////////////////////////////////

    async handleGetAllSections() {
      this.isSectionsLoading = true
      const contactRes = await getAllSections('contacts')

      if (contactRes.success) {
        this.sections = [...this.fixedSections, ...contactRes.data?.list]
        console.log({ sections: this.sections })
      } else {
        this.$vaToast.init({
          message: contactRes?.message,
          color: 'danger',
        })
      }
      this.isSectionsLoading = false
    },

    handleSubmitTableConfig(headersTemplate: TableColumnInterface[]) {
      if (this.selectedSection?.fixed) {
        this.handleGlobalTableConfigUpdate(headersTemplate)
      } else {
        this.handleUpdateSection(headersTemplate)
      }
    },

    async handleGlobalTableConfigUpdate(
      headersTemplate: TableColumnInterface[],
    ) {
      this.isTableConfigUpdateLoading = true

      const selectedSection =
        this.$stores.generalData.companyConfigs[
          this.selectedSection
            .type as keyof typeof this.$stores.generalData.companyConfigs
        ]?.[0]

      const response = await updateCompanyConfiguration({
        ...selectedSection,
        data: { columns: headersTemplate },
      } as CompanyConfigurationPresenter)

      if (response.success) {
        await this.$stores.generalData.handleGetCompanyConfigurations()
        this.handleGetAllSections()
        this.handleToggleTableConfig()
      } else {
        this.$vaToast.init({
          message: response.message,
          color: 'danger',
        })
      }

      this.isTableConfigUpdateLoading = false
    },

    async handleUpdateSection(headersTemplate: TableColumnInterface[]) {
      this.isTableConfigUpdateLoading = true

      const response = await updateSection(this.selectedSection.id, {
        table_columns: headersTemplate,
      })
      if (response.success) {
        this.$vaToast.init({
          message: 'Table configuration updated successfully',
          color: 'success',
        })
        this.handleGetAllSections()
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }

      this.isTableConfigUpdateLoading = false
    },
  },
  computed: {
    fixedSections(): any[] {
      return [
        {
          id: 2727272,
          fixed: true,
          name: 'People',
          type: 'individual_contact_table_fields',
          listing_filters: {
            contact_type: 'IndividualContact',
          },
          table_columns:
            this.$stores.generalData.companyConfigs
              .individual_contact_table_fields?.[0].data.columns || [],
        },
        {
          id: 3737373,
          fixed: true,
          name: 'Organizations',
          type: 'organization_contact_table_fields',
          listing_filters: {
            contact_type: 'OrganizationContact',
          },
          table_columns:
            this.$stores.generalData.companyConfigs
              .organization_contact_table_fields?.[0].data.columns || [],
        },
      ]
    },
  },
  mounted() {
    this.handleGetAllSections()
  },
})
