<template>
  <VaModal
    v-model="isAddContactFormBool"
    size="auto"
    hide-default-actions
    fixed-layout
    @update:modelValue="toggleAddContactForm"
  >
    <template #header>
      <div class="create-form-header">
        <h1>Add Contacts</h1>
        <VaIcon
          class="header-icon press-button"
          name="close"
          size="30px"
          @click="toggleAddContactForm"
        />
      </div>
    </template>
    <div class="add-contact-form-modal-body">
      <div class="left">
        <VaFileUpload
          class="rectangular-image-upload-container"
          hideFileList="true"
          v-model="form.individual_profile_picture"
          :file-types="$stores.generalData.imagesFormats"
          @update:modelValue="handleFileUpload"
          type="single"
        >
          <div class="custom-upload-file">
            <VaAvatar
              color="#ECF0F1"
              :src="form?.avatar?.medium_url"
              alt="Avatar"
              class="avatar-preview"
            >
              <img
                v-if="!form?.avatar?.medium_url"
                :src="UploadAvatarIcon"
                alt=""
              />
            </VaAvatar>
            <div class="custom-upload-file-text">
              <h1>Upload Profile Picture</h1>
              <h2>RECOMMENDED 1000X1000 PX</h2>
              <h3>Max. 1.3Mb</h3>
              <div>
                <VaButton
                  v-if="form?.avatar?.medium_url"
                  preset="secondary"
                  border-color="primary"
                  size="small"
                  class="remove-button"
                  @click.stop="handleRemoveImage"
                >
                  Remove
                </VaButton>
                <VaButton
                  v-else
                  preset="secondary"
                  border-color="primary"
                  size="small"
                >
                  Upload
                </VaButton>
              </div>
            </div>
          </div>
        </VaFileUpload>

        <DynamicContactForm
          :fields="formFields"
          :initial-values="form"
          :toggle-submit="toggleSubmit"
          @submit="handleCreateContact"
        />

        <VaForm ref="form" @submit.prevent="">
          <VaAccordion v-model="isAddressAccordion" multiple>
            <VaCollapse
              v-for="(address, index) in form.contact_addresses"
              :key="index"
              :header="`Address ${index + 1}`"
              class="address-accordion"
            >
              <div class="address-accordion">
                <div class="address-accordion-row">
                  <div class="country-select">
                    <VaSelect
                      v-model="address.country"
                      placeholder="Country"
                      background="#ECF0F1"
                      :loading="$stores.generalData.countriesLoading"
                      label="Country"
                      required-mark
                      searchable
                      :options="$stores.generalData.countries"
                      :highlight-matched-text="false"
                      :text-by="getOptionName"
                      :rules="[validateSelect]"
                      @update:modelValue="$stores.generalData.handleGetState"
                      track-by="id"
                      class="input"
                    />
                  </div>

                  <div class="zip-code-input">
                    <VaInput
                      v-model="address.zip_code"
                      placeholder="Zip Code"
                      background="#ECF0F1"
                      label="Zip Code"
                      class="input"
                    />
                  </div>
                </div>

                <VaSelect
                  v-model="address.state"
                  placeholder="Province/State"
                  background="#ECF0F1"
                  :loading="$stores.generalData.statesLoading"
                  label="Province/State"
                  required-mark
                  searchable
                  :options="$stores.generalData.states"
                  :highlight-matched-text="false"
                  :text-by="getOptionName"
                  track-by="id"
                  :rules="[validateSelect]"
                  @update:modelValue="$stores.generalData.handleGetCities"
                  class="input"
                />

                <VaSelect
                  v-model="address.city"
                  placeholder="City"
                  background="#ECF0F1"
                  :loading="$stores.generalData.citiesLoading"
                  label="City"
                  required-mark
                  searchable
                  :options="$stores.generalData.cities"
                  :highlight-matched-text="false"
                  :text-by="getOptionName"
                  :rules="[validateSelect]"
                  track-by="id"
                  class="input"
                />

                <VaInput
                  v-model="address.street_name"
                  placeholder="Street"
                  background="#ECF0F1"
                  class="input"
                  label="Street"
                />

                <VaInput
                  v-model="address.building_name"
                  placeholder="Building"
                  background="#ECF0F1"
                  class="input"
                  label="Building"
                />

                <div class="address-accordion-row">
                  <VaInput
                    v-model="address.phone_number"
                    placeholder="Office Number"
                    background="#ECF0F1"
                    class="input phone-number"
                    label="Office Number"
                  />

                  <VaInput
                    v-model="address.floor"
                    placeholder="Floor"
                    background="#ECF0F1"
                    class="input floor"
                    label="Floor"
                  />
                </div>

                <VaInput
                  v-model="address.google_maps_url"
                  placeholder="Google Maps Link"
                  background="#ECF0F1"
                  class="input"
                  label="Google Maps Link"
                />
              </div>
            </VaCollapse>
          </VaAccordion>
        </VaForm>
      </div>

      <VDivider vertical />

      <div class="right">
        <VaCheckbox
          v-model="form.kyc_completed"
          class="mb-6"
          label="KYC Complete"
        />

        <VaTextarea
          v-model="form.kyc_notes"
          max-rows="11"
          label="KYC Notes"
          class="input"
          autosize
          background="#ECF0F1"
          placeholder="Add your KYC note here..."
        />

        <div class="custom-upload-file-area-kyc-container">
          <h3>KYC DOCUMENTS</h3>
          <VaFileUpload v-model="kyc_documents">
            <div class="custom-upload-file-area-kyc">
              <p class="mb-2">Upload or drag and drop files here</p>
            </div>
          </VaFileUpload>
        </div>

        <VaTextarea
          v-model="form.extra_notes"
          max-rows="11"
          label="Office Notes"
          class="input"
          autosize
          background="#ECF0F1"
          placeholder="Add your Office note here..."
        />
      </div>
    </div>
    <template #footer>
      <div class="add-contact-form-modal-buttons">
        <VaButton
          preset="secondary"
          border-color="primary"
          @click="toggleAddContactForm"
        >
          Cancel
        </VaButton>
        <VaButton
          :loading="addContactLoading"
          @click="validate() && handleToggleSubmit()"
        >
          Save
        </VaButton>
      </div>
    </template>
  </VaModal>
</template>
<script lang="ts" src="./script.ts" />
<style lang="scss" scoped src="./styles.scss" />
