import { MatterPresenter } from '@/presenters/matterPresenter'
import { StagesPresenter } from '@/presenters/StagesPresenter'
import { getAllMatters } from '@/services/legalMatter'
import { defineStore } from 'pinia'
import { useToast } from 'vuestic-ui/web-components'

export const useMatterStore = defineStore({
  id: 'Matter',
  state: () => ({
    vaToast: useToast(),
    stages: [] as StagesPresenter[],
    matters: [] as MatterPresenter[],
    mattersLoading: false,
    sectionStages: [] as StagesPresenter[],

    debounceTimeout: null as ReturnType<typeof setTimeout> | null,
  }),
  actions: {
    async handleGetMatters({ keyword }: { keyword?: string } = {}) {
      this.mattersLoading = true

      const response = await getAllMatters({
        per_page: 20,
        keyword,
      })

      if (response.success) {
        this.matters = response.data?.list
      } else {
        this.vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.mattersLoading = false
    },
    handleSearchMatters(search: string) {
      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout)
      }
      this.debounceTimeout = setTimeout(() => {
        this.handleGetMatters({ keyword: search })
      }, 500)
    },
  },
})
