import { CompanyConfigurationPresenter } from '@/presenters/companyConfigPresenters'
import { apiRequest } from './apiUtils'

export type CompanyConfigurationsResponse = {
  courtOptions?: CompanyConfigurationPresenter[]
  matterStatusOption?: CompanyConfigurationPresenter[]
  matterSpecializationOptions?: CompanyConfigurationPresenter[]
  matterCategoryOptions?: CompanyConfigurationPresenter[]
  taskPriorityOptions?: CompanyConfigurationPresenter[]
  hearingStatusOptions?: CompanyConfigurationPresenter[]
  milestonesStatusOption?: CompanyConfigurationPresenter[]
  calendarTypesOption?: CompanyConfigurationPresenter[]
  matterSectorsOption?: CompanyConfigurationPresenter[]
  invoice_template?: CompanyConfigurationPresenter[]
  expense_template?: CompanyConfigurationPresenter[]
  lead_label?: CompanyConfigurationPresenter[]
  individual_contact_form_fields?: CompanyConfigurationPresenter[]
  individual_contact_table_fields?: CompanyConfigurationPresenter[]
  organization_contact_form_fields?: CompanyConfigurationPresenter[]
  organization_contact_table_fields?: CompanyConfigurationPresenter[]
  legal_matters_all_table_columns?: CompanyConfigurationPresenter[]
  hearings_all_table_columns?: CompanyConfigurationPresenter[]
  tasks_all_table_columns?: CompanyConfigurationPresenter[]
  leads_all_table_columns?: CompanyConfigurationPresenter[]
  menu_structure?: CompanyConfigurationPresenter[]
}

interface Field {
  label?: string
  type: string
  key: string
  src?: string
  text?: string
  required?: boolean
}

type SectionKey = 'header' | 'body' | 'footer'

export type addCompanyConfigType = {
  id?: number
  configuration_type?: string
  name: string
  color?: string
  icon?: string
  body?: Record<SectionKey, Field[][]>
}

export const getCompanyConfigurations = async () => {
  const response = await apiRequest('get', 'company_configurations')

  if (response.success && Array.isArray(response.data?.list)) {
    const data = response.data?.list.reduce(
      (acc: any, config: CompanyConfigurationPresenter) => {
        const { configuration_type } = config
        if (!acc[configuration_type]) {
          acc[configuration_type] = []
        }
        acc[configuration_type]?.push(new CompanyConfigurationPresenter(config))
        return acc
      },
      {} as any,
    )

    const roleId = localStorage.getItem('RoleId') as string

    // Sorting function: move items with matching company_id to the top
    const sortByRoleId = (items: CompanyConfigurationPresenter[] = []) => {
      return items.sort((a, b) => {
        const aIsRoleMatch = a?.company_id?.toString() === roleId ? -1 : 1
        const bIsRoleMatch = b?.company_id?.toString() === roleId ? -1 : 1
        return aIsRoleMatch - bIsRoleMatch
      })
    }

    return {
      ...response,
      data: {
        courtOptions:
          sortByRoleId(
            data?.court?.map(
              (item: CompanyConfigurationPresenter) =>
                new CompanyConfigurationPresenter(item),
            ),
          ) || [],

        matterStatusOption:
          sortByRoleId(
            data?.legal_matter_status?.map(
              (item: CompanyConfigurationPresenter) =>
                new CompanyConfigurationPresenter(item),
            ),
          ) || [],

        matterSpecializationOptions:
          sortByRoleId(
            data?.legal_matter_specialization?.map(
              (item: CompanyConfigurationPresenter) =>
                new CompanyConfigurationPresenter(item),
            ),
          ) || [],

        matterCategoryOptions:
          sortByRoleId(
            data?.legal_matter_category?.map(
              (item: CompanyConfigurationPresenter) =>
                new CompanyConfigurationPresenter(item),
            ),
          ) || [],

        taskPriorityOptions:
          sortByRoleId(
            data?.task_priority?.map(
              (item: CompanyConfigurationPresenter) =>
                new CompanyConfigurationPresenter(item),
            ),
          ) || [],

        hearingStatusOptions:
          sortByRoleId(
            data?.hearing_status?.map(
              (item: CompanyConfigurationPresenter) =>
                new CompanyConfigurationPresenter(item),
            ),
          ) || [],

        milestonesStatusOption:
          sortByRoleId(
            data?.milestone_status?.map(
              (item: CompanyConfigurationPresenter) =>
                new CompanyConfigurationPresenter(item),
            ),
          ) || [],

        calendarTypesOption:
          sortByRoleId(
            data?.calendar_item_type?.map(
              (item: CompanyConfigurationPresenter) =>
                new CompanyConfigurationPresenter(item),
            ),
          ) || [],

        matterSectorsOption:
          sortByRoleId(
            data?.legal_matter_sector?.map(
              (item: CompanyConfigurationPresenter) =>
                new CompanyConfigurationPresenter(item),
            ),
          ) || [],

        invoice_template: data?.invoice_template?.map(
          (item: CompanyConfigurationPresenter) =>
            new CompanyConfigurationPresenter(item),
        ),

        expense_template: data?.expense_template?.map(
          (item: CompanyConfigurationPresenter) =>
            new CompanyConfigurationPresenter(item),
        ),

        lead_label:
          sortByRoleId(
            data?.lead_label?.map(
              (item: CompanyConfigurationPresenter) =>
                new CompanyConfigurationPresenter(item),
            ),
          ) || [],
        individual_contact_form_fields:
          data?.individual_contact_form_fields?.map(
            (item: CompanyConfigurationPresenter) =>
              new CompanyConfigurationPresenter(item),
          ) || [],
        individual_contact_table_fields:
          data?.individual_contact_table_fields?.map(
            (item: CompanyConfigurationPresenter) =>
              new CompanyConfigurationPresenter(item),
          ),

        organization_contact_form_fields:
          data?.organization_contact_form_fields?.map(
            (item: CompanyConfigurationPresenter) =>
              new CompanyConfigurationPresenter(item),
          ) || [],
        organization_contact_table_fields:
          data?.organization_contact_table_fields?.map(
            (item: CompanyConfigurationPresenter) =>
              new CompanyConfigurationPresenter(item),
          ),

        legal_matters_all_table_columns:
          data?.legal_matters_all_table_columns?.map(
            (item: CompanyConfigurationPresenter) =>
              new CompanyConfigurationPresenter(item),
          ) || [],

        hearings_all_table_columns: data?.hearings_all_table_columns?.map(
          (item: CompanyConfigurationPresenter) =>
            new CompanyConfigurationPresenter(item),
        ),

        tasks_all_table_columns: data?.tasks_all_table_columns?.map(
          (item: CompanyConfigurationPresenter) =>
            new CompanyConfigurationPresenter(item),
        ),

        leads_all_table_columns: data?.leads_all_table_columns?.map(
          (item: CompanyConfigurationPresenter) =>
            new CompanyConfigurationPresenter(item),
        ),

        menu_structure:
          sortByRoleId(
            data?.menu_structure?.map(
              (item: CompanyConfigurationPresenter) =>
                new CompanyConfigurationPresenter(item),
            ),
          ) || [],
      },
    }
  } else {
    return response
  }
}

export const addCompanyConfiguration = (body: addCompanyConfigType) => {
  return apiRequest('post', 'company_configurations', body)
}

export const updateCompanyConfiguration = (body: addCompanyConfigType) => {
  return apiRequest('put', `company_configurations/${body.id}`, body)
}

export const deleteCompanyConfiguration = (id: number) => {
  return apiRequest('delete', `company_configurations/${id}`)
}
