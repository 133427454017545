import { defineComponent } from 'vue'
import { useForm } from 'vuestic-ui/web-components'
import FileUploadHelper, {
  getOptionFullName,
  getOptionCurrency,
  validateInput,
  validateInputEmail,
  validateSelect,
  deepCopy,
  isEmptyObject,
} from '@/lib/utils'
import { TeamPresenter } from '@/presenters/TeamPresenter'

const UploadAvatarIcon = new URL(
  '@/assets/images/avatarWithCamera.png',
  import.meta.url,
).href

export default defineComponent({
  name: 'UpdateTeamModal',
  props: {
    isUpdateTeamModalOpen: {
      type: Boolean,
      required: true,
    },
    currentTeam: {
      type: TeamPresenter,
    },
  },
  data() {
    const { validate, reset } = useForm('form')

    const workspaceSearchOptions = ['Workspace1', 'Workspace2']
    const teamSearchOptions = ['team1', 'team2']

    const form = !isEmptyObject(this.currentTeam)
      ? deepCopy(this.currentTeam)
      : {}

    return {
      validate,
      reset,

      form,

      getOptionFullName,
      getOptionCurrency,
      validateInput,
      validateInputEmail,
      validateSelect,

      workspaceSearchOptions,
      teamSearchOptions,

      updateTeamLoading: false,

      UploadAvatarIcon,

      isEmptyObject,
    }
  },
  emits: ['handleUpdateTeam', 'toggleUpdateTeamModal'],
  methods: {
    beforeUpdateTeamModalClose() {
      if (this.isUpdateTeamModalOpenBool) {
        this.handleCancel()
      }
    },
    handleUpdateTeam() {
      this.updateTeamLoading = true

      this.$emit('handleUpdateTeam', this.form)
      this.handleCancel()

      this.updateTeamLoading = false
    },

    handleCancel() {
      this.$emit('toggleUpdateTeamModal')
      this.form = {}
      this.handleRemoveImage()
    },

    handleRemoveImage() {
      this.form.avatar = {
        id: null,
        large_url: null,
        medium_url: null,
        thumbnail_url: null,
        url: null,
      }
      this.form.image = new File([], 'empty.jpg', {
        type: 'image/jpeg',
      })
    },

    handleFileUpload(event: File) {
      FileUploadHelper.handleFileUpload({
        event,
        form: this.form,
        key: 'image',
      })
    },
  },
  computed: {
    teamActionTitle(): string {
      return !isEmptyObject(this.currentTeam) ? 'Update Team' : 'Create Team'
    },
    isUpdateTeamModalOpenBool: {
      get(): boolean {
        return this.isUpdateTeamModalOpen
      },
      set() {
        this.handleCancel()
      },
    },
  },
  mounted() {
    this.$stores.usersData.handleGetMembers({
      as_company_user: true,
    })
  },
  watch: {
    currentTeam: {
      handler(data) {
        if (!isEmptyObject(data)) {
          this.form = deepCopy(data)
        }
      },
    },
  },
})
