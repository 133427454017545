import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ae36f530"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "scroll-container-wrapper" }
const _hoisted_2 = {
  key: 4,
  class: "coming-soon-container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TabsBar = _resolveComponent("TabsBar")!
  const _component_Members = _resolveComponent("Members")!
  const _component_Workspace = _resolveComponent("Workspace")!
  const _component_Teams = _resolveComponent("Teams")!
  const _component_KPIAndTargets = _resolveComponent("KPIAndTargets")!
  const _component_ComingSoon = _resolveComponent("ComingSoon")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_TabsBar, {
      tabs: _ctx.tabs,
      "active-tab-id": _ctx.activeTabId,
      onChangeTab: _ctx.changeTab
    }, null, 8, ["tabs", "active-tab-id", "onChangeTab"]),
    _createElementVNode("div", _hoisted_1, [
      (_ctx.activeTab === 'MEMBERS')
        ? (_openBlock(), _createBlock(_component_Members, { key: 0 }))
        : (_ctx.activeTab === 'WORKSPACE')
          ? (_openBlock(), _createBlock(_component_Workspace, { key: 1 }))
          : (_ctx.activeTab === 'TEAMS')
            ? (_openBlock(), _createBlock(_component_Teams, { key: 2 }))
            : (_ctx.activeTab === 'KPI & TARGETS')
              ? (_openBlock(), _createBlock(_component_KPIAndTargets, { key: 3 }))
              : (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _createVNode(_component_ComingSoon)
                ]))
    ])
  ], 64))
}