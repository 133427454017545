import { convertToCalendarFormat } from '@/lib/utils'
import { Avatar, Currency } from '../interface'
import { HearingsPresenter } from '../hearingsPresenter'
import { IndividualContactPresenter } from '../contactPresenter'
import { MatterPresenter } from '../matterPresenter'
import { OrganizationContactPresenter } from '../organizationPresenter'
import { TaskPresenter } from '../taskPresenter'
import { CompanyConfigurationPresenter } from '../companyConfigPresenters'
import { calendar_item_linked_items_attributesInterface } from '@/Interfaces/layout'
import { CompanyUserPresenter } from '../CompanyUserPresenter'

const PRESENTER_MAPPING = {
  Hearing: HearingsPresenter,
  LegalMatter: MatterPresenter,
  IndividualContact: IndividualContactPresenter,
  OrganizationContact: OrganizationContactPresenter,
  Task: TaskPresenter,
} as const

export class CalendarItemsPresenter {
  id!: number
  all_day!: boolean
  billable!: boolean
  billable_hourly_rate_charging_amount!: string
  billable_hourly_rate_charging_currency!: Currency
  billable_hourly_rate_earning_amount!: string
  billable_hourly_rate_earning_currency!: Currency
  class_name!: string
  company_user_id!: number
  company_user!: CompanyUserPresenter
  created_at!: string
  description!: string
  end_time!: string
  item_type!: CompanyConfigurationPresenter
  item_type_id!: number
  location!: string
  private!: boolean
  send_reminder_at!: string
  start_time!: string | Date
  total_billable_hourly_rate_charging_amount!: string
  total_billable_hourly_rate_earning_amount!: string
  total_time_spent_seconds!: number
  updated_at!: string
  title!: string

  calendar_item_linked_items!: any
  linked_item_id?: number
  linked_item_type!: string

  time_range!: boolean

  calendar_item_linked_items_attributes!: calendar_item_linked_items_attributesInterface[]

  start!: string
  end!: string
  allDay!: boolean
  icon?: string

  avatar!: Avatar

  constructor(data: CalendarItemsPresenter) {
    Object.assign(this, data)

    if (data.time_range) {
      this.start = data.start_time?.toString().split('T')[0]
      this.end = data.end_time?.toString().split('T')[0]

      this.allDay = data.time_range
    } else {
      if (data.start_time) {
        this.start = convertToCalendarFormat(data.start_time as string)
      }

      if (data.end_time) {
        this.end = convertToCalendarFormat(data.end_time as string)
      }
    }

    if (data.all_day) {
      this.allDay = data.all_day
    }

    if (
      data.calendar_item_linked_items &&
      Array.isArray(data.calendar_item_linked_items)
    ) {
      this.calendar_item_linked_items = data.calendar_item_linked_items.map(
        (item) => {
          const nestedLinkedItem = item.linked_item
          if (nestedLinkedItem && nestedLinkedItem.class_name) {
            const type =
              nestedLinkedItem.class_name as keyof typeof PRESENTER_MAPPING
            const PresenterClass = PRESENTER_MAPPING[type]

            if (PresenterClass) {
              return new PresenterClass(nestedLinkedItem)
            }
          }

          // If no matching presenter class is found, return the raw item
          return item
        },
      )
    }

    if (data.company_user) {
      this.company_user = new CompanyUserPresenter(data.company_user)

      this.avatar = this.company_user?.avatar || ({} as Avatar)
    }
  }
}
