import { defineComponent } from 'vue'
import TabsBar from '@/components/tabsBar/index.vue'
import ComingSoon from '@/components/ComingSoon/index.vue'
import HearingGlobal from '@/views/Settings/HearingsSettings/HearingGlobal/index.vue'
export default defineComponent({
  name: 'HearingsSettings',
  // mounted() {},
  components: {
    TabsBar,
    ComingSoon,
    HearingGlobal,
  },
  data() {
    const tabs = [
      { id: 0, name: 'TOOLBAR' },
      { id: 1, name: 'GLOBAL HEARING' },
      { id: 2, name: 'HEARING' },
    ]

    return {
      tabs,
      activeTabId: 1,
      activeTab: 'GLOBAL HEARING',
    }
  },
  computed: {},
  methods: {
    /////////////////////////////////////////////////////////////////////////////
    changeTab({ tab }: { tab: { title: string; name: string; id: number } }) {
      this.activeTabId = tab.id
      this.activeTab = tab.name
    },
  },
})
