import { LeadPresenter } from '@/presenters/LeadPresenter'
import { StagesPresenter } from '@/presenters/StagesPresenter'
import { getAllLeads } from '@/services/leads'
import { defineStore } from 'pinia'
import { useToast } from 'vuestic-ui/web-components'

export const useLeadStore = defineStore({
  id: 'Lead',
  state: () => ({
    vaToast: useToast(),
    stages: [] as StagesPresenter[],
    leads: [] as LeadPresenter[],
    leadsLoading: false,
    sectionStages: [] as StagesPresenter[],

    debounceTimeout: null as ReturnType<typeof setTimeout> | null,
  }),
  actions: {
    async handleGetLeads({ keyword }: { keyword?: string } = {}) {
      this.leadsLoading = true

      const response = await getAllLeads({
        per_page: 20,
        keyword,
      })

      if (response.success) {
        this.leads = response.data?.list
      } else {
        this.vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.leadsLoading = false
    },
    handleSearchLeads(search: string) {
      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout)
      }
      this.debounceTimeout = setTimeout(() => {
        this.handleGetLeads({ keyword: search })
      }, 500)
    },
  },
})
