import { CalendarItemsPresenter } from '../calendarItemsPresenter'
import { IndividualContactPresenter } from '../contactPresenter'
import { HearingsPresenter } from '../hearingsPresenter'
import { MatterPresenter } from '../matterPresenter'
import { OrganizationContactPresenter } from '../organizationPresenter'
import { TaskPresenter } from '../taskPresenter'

export type AllItemsSortedType =
  | HearingsPresenter
  | IndividualContactPresenter
  | MatterPresenter
  | OrganizationContactPresenter
  | TaskPresenter

export class GlobalSearchPresenter {
  hearings?: HearingsPresenter[]
  legal_matters?: MatterPresenter[]
  individual_contacts?: IndividualContactPresenter[]
  organization_contacts?: OrganizationContactPresenter[]
  tasks?: TaskPresenter[]
  calendar_items?: CalendarItemsPresenter[]

  allItemsSorted?: AllItemsSortedType[]

  constructor(data: GlobalSearchPresenter) {
    Object.assign(this, data)

    this.hearings = (data.hearings ?? []).map(
      (item) =>
        new HearingsPresenter({
          ...item,
          icon: 'gavel',
        }),
    )

    this.legal_matters = (data.legal_matters ?? []).map(
      (item) =>
        new MatterPresenter({
          ...item,
          icon: 'inventory_2',
        }),
    )

    this.individual_contacts = (data.individual_contacts ?? []).map(
      (item) =>
        new IndividualContactPresenter({
          ...item,
          icon: 'contacts',
        }),
    )

    this.organization_contacts = (data.organization_contacts ?? []).map(
      (item) =>
        new OrganizationContactPresenter({
          ...item,
          icon: 'corporate_fare',
        }),
    )

    this.tasks = (data.tasks ?? []).map(
      (item) =>
        new TaskPresenter({
          ...item,
          icon: 'assignment',
        }),
    )

    this.calendar_items = (data.calendar_items ?? []).map(
      (item) =>
        new CalendarItemsPresenter({
          ...item,
          icon: 'calendar_month',
        }),
    )

    this.allItemsSorted = [
      ...(this.hearings ?? []),
      ...(this.individual_contacts ?? []),
      ...(this.legal_matters ?? []),
      ...(this.organization_contacts ?? []),
      ...(this.tasks ?? []),
    ].sort((a, b) => {
      const dateA = new Date(a.updated_at).getTime()
      const dateB = new Date(b.updated_at).getTime()
      return dateB - dateA
    })
  }
}
