<template>
  <VaModal
    v-model="isTemplateModalOpenBool"
    size="auto"
    hide-default-actions
    fixed-layout
    no-outside-dismiss
    @update:modelValue="handleToggleModal"
    class="split-modal-form"
  >
    <template #header>
      <div class="form-modal-header">
        <h1>{{ headerTitle }}</h1>
        <VaIcon
          class="press-button"
          name="close"
          size="30px"
          @click="handleToggleModal"
        />
      </div>
    </template>

    <div class="split-modal-body-wrapper">
      <div class="split-modal-scrollable-section left">
        <VaForm ref="form" class="form" @submit.prevent="">
          <VaInput
            background="#ECF0F1"
            v-model="templateForm.name"
            label="Template Name"
            placeholder="Name"
            requiredMark
            :rules="[validateInput]"
          />
          <VaDivider />
          <div
            v-for="(section, sectionKey) in orderedSelectedData"
            :key="sectionKey"
            class="all-fields"
          >
            <h1>{{ capitalizeFirstLetter(sectionKey) }}</h1>
            <draggable
              v-model="selectedData[sectionKey]"
              item-key="index"
              group="sections"
              class="draggable-sections"
            >
              <template #item="{ element, index }">
                <div class="AF-row">
                  <div class="AF-row">
                    <VaIcon
                      name="drag_handle"
                      size="1.5rem"
                      class="press-button"
                    />

                    <div
                      v-for="(field, subIndex) in element"
                      :key="subIndex"
                      class="AF-map"
                    >
                      <div>
                        <p class="AF-name">{{ field?.label || field.type }}</p>

                        <!-- Show text area when the field type is 'text' -->
                        <VaTextarea
                          v-if="field.type === 'text' || field.type === 'h1'"
                          v-model="
                            selectedData[sectionKey][index][subIndex].text
                          "
                          placeholder="Enter text here..."
                          class="field-textarea"
                          auto-grow
                        />
                      </div>

                      <font-awesome-icon
                        icon="trash-can"
                        size="sm"
                        class="press-button"
                        color="#66023c"
                        @click="handleRemoveField(sectionKey, index, subIndex)"
                      />
                    </div>
                  </div>

                  <font-awesome-icon
                    icon="circle-plus"
                    size="lg"
                    class="press-button"
                    color="#66023c"
                    @click="handleToggleSelectFields(sectionKey, index)"
                  />
                  <font-awesome-icon
                    icon="trash-can"
                    size="lg"
                    class="press-button"
                    color="#66023c"
                    @click="handleRemoveRow(sectionKey, index)"
                  />
                </div>
              </template>
            </draggable>

            <VaButton
              color="#ECF0F1"
              size="small"
              icon-right="add"
              @click="handleToggleSelectFields(sectionKey)"
            >
              Add Field
            </VaButton>
          </div>
        </VaForm>
      </div>

      <VaDivider vertical />

      <div class="split-modal-scrollable-section right">
        <div class="invoice-container-header">
          <DynamicSections
            :form="mockInvoiceData"
            :subtotal="subtotal"
            :convertToSecondaryCurrencyTotal="convertToSecondaryCurrencyTotal"
            :convertToSecondaryCurrencySubTotal="
              convertToSecondaryCurrencySubTotal
            "
            :total="total"
            :templateData="selectedData.header"
          />
        </div>

        <div class="invoice-container-body">
          <DynamicSections
            :form="mockInvoiceData"
            :subtotal="subtotal"
            :convertToSecondaryCurrencyTotal="convertToSecondaryCurrencyTotal"
            :convertToSecondaryCurrencySubTotal="
              convertToSecondaryCurrencySubTotal
            "
            :total="total"
            :templateData="selectedData.body"
          />
        </div>

        <!-- Footer (Fixed at Bottom) -->
        <div class="container-bottom">
          <DynamicSections
            :form="mockInvoiceData"
            :subtotal="subtotal"
            :convertToSecondaryCurrencyTotal="convertToSecondaryCurrencyTotal"
            :convertToSecondaryCurrencySubTotal="
              convertToSecondaryCurrencySubTotal
            "
            :total="total"
            :templateData="selectedData.footer"
          />
          <div class="logo-section">
            <img :src="logoImage" width="30rem" alt="LOGO" /> Billed through
            MyHAQQ
          </div>
        </div>
      </div>
    </div>

    <template #footer>
      <div class="form-modal-buttons">
        <VaButton
          preset="secondary"
          border-color="primary"
          @click="handleToggleModal"
        >
          Cancel
        </VaButton>
        <VaButton
          :loading="isAddTemplateLoading || isUpdateTemplateLoading"
          @click="validate() && handleToggleSubmit()"
        >
          {{ submitButton }}
        </VaButton>
      </div>
    </template>
  </VaModal>

  <VaModal
    v-model="isSelectFields"
    size="30rem"
    hide-default-actions
    fixed-layout
    no-outside-dismiss
    @update:modelValue="handleToggleSelectFields()"
  >
    <template #header>
      <div class="modal-form-header">
        <h1>Add Field</h1>
        <VaIcon
          class="press-button"
          name="close"
          size="30px"
          @click="handleToggleSelectFields()"
        />
      </div>
    </template>

    <div class="add-field-container">
      <h1 class="title">All Fields</h1>
      <div class="all-fields">
        <div
          v-for="(field, index) in formFields"
          :key="index"
          class="AF-map"
          @click="handleAddField(field)"
        >
          <div>
            <p class="AF-name">{{ field.label }}</p>
          </div>
        </div>
      </div>
    </div>
  </VaModal>
</template>

<script lang="ts" src="./script.ts" />

<style lang="scss" scoped src="./styles.scss" />
