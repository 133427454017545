<template>
  <div class="tables-container">
    <h1>Update Tables</h1>

    <VaButton
      v-for="(section, index) in fixedSections"
      :key="index"
      class="options"
      preset="secondary"
      border-color="primary"
      @click="handleToggleTableConfig(section)"
    >
      {{ section.name }}
    </VaButton>
  </div>

  <TablesConfigModal
    :is-table-config-update="isTableConfigUpdate"
    :selected-section="selectedSection"
    :is-table-config-update-loading="isTableConfigUpdateLoading"
    @handleToggleTableConfig="handleToggleTableConfig"
    @handleSubmitTableConfig="handleSubmitTableConfig"
  />
</template>

<script lang="ts" src="./script.ts" />
<style lang="scss" scoped src="./styles.scss" />
