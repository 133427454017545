<template>
  <VaModal
    v-model="isAddInvoiceBool"
    size="auto"
    hide-default-actions
    no-outside-dismiss
    fixed-layout
    @update:modelValue="handleToggleAddModal"
  >
    <template #header>
      <div class="create-invoice-form-header">
        <h1>Create Invoice</h1>

        <VaInput
          v-model="form.invoice_number"
          background="#ECF0F1"
          placeholder="Invoice Number"
          :loading="invoiceNumberLoading"
        />

        <VaIcon
          class="header-icon press-button"
          name="close"
          size="30px"
          @click="handleToggleAddModal"
        />
      </div>
    </template>

    <div class="create-invoice-form">
      <VaForm ref="form" @submit.prevent="validate">
        <div class="scrollable-container">
          <div class="create-invoice-form-container">
            <div style="width: 76%">
              <VaSelect
                v-model="form.invoiceable"
                background="#ECF0F1"
                style="width: 70%; height: 1.5rem; margin-bottom: 1.8rem"
                label="Bill To"
                placeholder="Bill To"
                searchable
                :highlight-matched-text="false"
                :options="clients"
                :text-by="getOptionFullName"
                :loading="
                  $stores.contacts.contactsLoading ||
                  $stores.contacts.organizationsLoading
                "
                @update:search="$stores.contacts.handleSearchContacts"
                @update:modelValue="handleSearchClients"
              />
            </div>

            <div class="form-invoice-currency-container">
              <div class="form-invoice-currency">
                <VaSelect
                  v-model="form.currency"
                  label="main currency"
                  background="#ECF0F1"
                  :options="$stores.currency.currencies"
                  :text-by="getOptionCurrency"
                  :loading="$stores.currency.currencyLoading"
                  track-by="id"
                  placeholder="1st Currency"
                />

                <VaSelect
                  v-model="form.secondary_currency"
                  label="Secondary currency"
                  background="#ECF0F1"
                  :options="$stores.currency.currencies"
                  :text-by="getOptionCurrency"
                  :loading="$stores.currency.currencyLoading"
                  track-by="id"
                  placeholder="2nd Currency"
                />
              </div>
            </div>

            <div style="width: 76%">
              <VaInput
                v-model="form.customer_name"
                background="#ECF0F1"
                style="width: 70%"
                label="Invoice title"
                placeholder="Invoice Title"
              />
            </div>
            <div class="form-payment-due">
              <VaDateInput
                v-model="form.invoice_date"
                placeholder="MM/DD/YYYY"
                label="Invoice Date"
                background="#ECF0F1"
                :rules="[validateInput]"
              />
            </div>
            <div style="width: 76%">
              <VaInput
                v-model="form.customer_email"
                background="#ECF0F1"
                style="width: 70%"
                label="email"
                placeholder="Email"
                :rules="[validateInputEmail]"
              />
            </div>
            <div class="form-payment-due">
              <VaSelect
                v-model="form.payment_due_date"
                label="payment due"
                placeholder="Payment Due"
                background="#ECF0F1"
                :options="paymentDueOptions"
                text-by="label"
                value-by="value"
              >
                <template #appendInner>
                  <v-menu
                    v-model="isShowDueDate"
                    :close-on-content-click="false"
                    location="bottom right"
                  >
                    <template #activator="{ props }">
                      <VaIcon
                        v-bind="props"
                        name="calendar_month"
                        color="primary"
                        @click.stop="console.log('calendar_month')"
                      />
                    </template>
                    <v-card class="date-picker-card">
                      <VaDatePicker
                        v-model="datePickerValue"
                        @update:modelValue="handleDatePickerChange"
                      />
                    </v-card>
                  </v-menu>
                </template>
              </VaSelect>
            </div>

            <!-- <VAIcon name="group" color="#bbb" /> -->
            <p>Link to matter</p>
          </div>

          <VaDivider inset />
        </div>
      </VaForm>

      <div class="items-container">
        <table class="invoice-table">
          <!-- Header Section -->
          <thead>
            <tr class="invoice-header">
              <th class="invoice-icon-header" />
              <th class="invoice-item-header">Items</th>
              <th class="invoice-quantity-header">Quantity</th>
              <th class="invoice-unit-header">Unit</th>
              <th class="invoice-price-header">Price</th>
              <th class="invoice-amount-header">Discount Type</th>
              <th class="invoice-amount-header">Discount</th>
              <th class="invoice-amount-header">Tax</th>
              <th class="invoice-amount-header">Amount</th>
              <th class="invoice-icon-header" />
            </tr>
          </thead>

          <!-- Items Section -->
          <tbody v-if="invoiceItemsAttributes.length > 0">
            <tr
              v-for="(item, index) in invoiceItemsAttributes"
              :key="index"
              class="invoice-row"
            >
              <td class="invoice-icon-cell">
                <v-tooltip
                  :text="
                    getIconDetails(item?.billable?.class_name)?.tooltip ||
                    'Item'
                  "
                  location="top"
                >
                  <template #activator="{ props }">
                    <VaIcon
                      v-bind="props"
                      :name="
                        getIconDetails(item?.billable?.class_name)?.icon ||
                        'radio_button_checked'
                      "
                      class="press-button-2"
                      color="#bbb"
                    />
                  </template>
                </v-tooltip>
              </td>
              <td class="invoice-item-cell">
                <VaInput
                  v-model="item.description"
                  background="#fff"
                  class="invoice-item-input"
                  placeholder="Enter item description"
                  :rules="[validateInput]"
                />
              </td>

              <td class="invoice-quantity-cell" v-if="!isCalendarItem(item)">
                <VaInput
                  v-model="item.quantity"
                  background="#fff"
                  type="number"
                  min="1"
                  placeholder="Quantity"
                  class="invoice-quantity-input"
                />
              </td>
              <td class="invoice-quantity-cell" v-else>
                <div class="time-adjust-container">
                  <VaInput
                    :modelValue="getFormattedQuantity(index)"
                    @update:modelValue="setFormattedQuantity(index, $event)"
                    background="#fff"
                    placeholder="Quantity"
                    class="invoice-quantity-input"
                  >
                    <template #prependInner>
                      <VaIcon
                        name="remove"
                        color="primary"
                        @click="adjustCalendarItemTime(index, 'decrease')"
                      />
                    </template>
                    <template #appendInner>
                      <VaIcon
                        name="add"
                        color="primary"
                        @click="adjustCalendarItemTime(index, 'increase')"
                      />
                    </template>
                  </VaInput>
                </div>
              </td>

              <td class="invoice-amount-cell">
                <p>
                  {{
                    item?.billable?.class_name == 'CalendarItem' ? 'HOURS' : ''
                  }}
                </p>
              </td>

              <td class="invoice-price-cell">
                <VaInput
                  v-model="item.unit_price"
                  background="#fff"
                  type="number"
                  placeholder="Price"
                  class="invoice-price-input"
                />
              </td>

              <td class="invoice-quantity-cell">
                <VaSelect
                  v-model="item.discount_type"
                  background="#fff"
                  :options="[
                    { title: '%', value: 'percentage' },
                    { title: 'Amount', value: 'amount' },
                  ]"
                  text-by="title"
                  value-by="value"
                />
              </td>

              <td class="invoice-quantity-cell">
                <VaInput
                  v-model="item.discount_type_amount"
                  background="#fff"
                  type="number"
                  min="0"
                  placeholder="Discount"
                  class="invoice-quantity-input"
                >
                  <template
                    v-if="item.discount_type === 'percentage'"
                    #prependInner
                  >
                    <VaIcon name="percent" color="secondary" />
                  </template>
                </VaInput>
              </td>

              <td class="invoice-quantity-cell">
                <VaInput
                  v-model="item.tax_percentage"
                  background="#fff"
                  type="number"
                  min="0"
                  placeholder="Tax"
                  class="invoice-quantity-input"
                >
                  <template #prependInner>
                    <VaIcon name="percent" color="secondary" />
                  </template>
                </VaInput>
              </td>

              <td class="invoice-amount-cell">
                {{
                  totalAmount(
                    item.quantity,
                    item.unit_price,
                    item.tax_percentage,
                    item.discount_type,
                    item.discount_type_amount,
                  )
                }}
              </td>
              <td class="invoice-icon-cell">
                <VaIcon
                  name="delete"
                  class="press-button-2"
                  color="#bbb"
                  @click="removeInvoiceItem(index)"
                />
              </td>
            </tr>
          </tbody>

          <tfoot>
            <tr class="invoice-add-item-row">
              <td colspan="5" class="invoice-add-item-cell">
                <va-button
                  @click="addInvoiceItem"
                  class="add-item-button"
                  icon="add_circle"
                  color="primary"
                  preset="secondary"
                  size="small"
                >
                  Add an item
                </va-button>

                <v-menu
                  v-model="isItemAssignEvent"
                  :close-on-content-click="false"
                  location="bottom right"
                >
                  <template #activator="{ props }">
                    <va-button
                      v-bind="props"
                      class="add-item-button"
                      icon="add_circle"
                      color="primary"
                      preset="secondary"
                      size="small"
                    >
                      Add Calendar event
                    </va-button>
                  </template>
                  <LinkToCalendarEvent
                    :calendar-items="calendarItems"
                    :isCalendarItemsLoading="isCalendarItemsLoading"
                    @handleLinkTo="addInvoiceCalendarEvent"
                  />
                </v-menu>

                <v-menu
                  v-model="isItemAssignExpense"
                  :close-on-content-click="false"
                  location="bottom right"
                >
                  <template #activator="{ props }">
                    <va-button
                      v-bind="props"
                      class="add-item-button"
                      icon="add_circle"
                      color="primary"
                      preset="secondary"
                      size="small"
                    >
                      Add an Expense
                    </va-button>
                  </template>
                  <LinkExpense
                    :expenses="expenses"
                    :isExpensesLoading="isExpensesLoading"
                    @handleLinkTo="addInvoiceExpense"
                  />
                </v-menu>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>

      <VaDivider />

      <div class="text-area-section">
        <div class="payment-group">
          <VaSelect
            v-model="form.signature_note"
            label="payment address"
            placeholder="Payment Address"
            background="#fff"
            :options="['UAE BANK', 'US BANK']"
          />
          <VaSelect
            v-model="form.terms_and_conditions_note"
            label="terms"
            background="#fff"
            placeholder="Terms"
            :options="['Term 1', 'Term 2', 'Term 3']"
          />
        </div>

        <VaTextarea
          v-model="form.general_note"
          max-rows="7"
          label="general note"
          placeholder="Add your note here..."
          style="width: 100%; height: 10rem"
          autosize
        />
      </div>

      <div class="price-section">
        <div class="subtotal-price">
          <h2>Subtotal</h2>
          <span>
            {{ computedSubTotal() }}
            {{ form.currency?.code }}</span
          >
        </div>
        <div class="divider">
          <VaDivider />
        </div>

        <div class="discount-container">
          <p>Discount</p>
          <div class="discount-item">
            <VaSelect
              v-model="form.discount_type"
              placeholder="Select Payment Due"
              background="#fff"
              :options="taxTypeOptions"
              text-by="label"
              value-by="value"
            />

            <VaInput
              v-model="form.discount_type_amount"
              style="width: 6rem"
              background="#fff"
              type="number"
            >
              <template #prependInner>
                <VaIcon
                  v-if="form.discount_type === 'percentage'"
                  name="percent"
                  color="secondary"
                />
              </template>
            </VaInput>

            <VaIcon name="delete" class="press-button-2" color="#fff" />
          </div>
        </div>

        <div class="tax-container">
          <p>Taxes</p>

          <div
            v-for="(taxItem, index) in form.invoice_tax_items_attributes"
            :key="index"
            class="tax-item"
          >
            <VaInput
              v-model="taxItem.name"
              background="#fff"
              type="text"
              placeholder="Tax Name"
            />
            <VaSelect
              v-model="taxItem.tax_type"
              :options="taxTypeOptions"
              text-by="label"
              value-by="value"
              placeholder="Type"
            />
            <VaInput
              v-model.number="taxItem.tax_type_amount"
              background="#fff"
              type="number"
              min="0"
              placeholder="Amount"
            >
              <template #prependInner>
                <VaIcon
                  v-if="taxItem.tax_type === 'percentage'"
                  name="percent"
                  color="secondary"
                />
              </template>
            </VaInput>

            <VaIcon
              name="delete"
              class="press-button-2"
              color="#bbb"
              @click="removeTaxItem(index)"
            />
          </div>

          <VaButton
            preset="primary"
            icon="add_circle"
            class="add-tax-button"
            @click="addTaxItem"
          >
            Add Tax
          </VaButton>
        </div>

        <div class="divider">
          <VaDivider />
        </div>

        <div class="total-price">
          <h2>Total</h2>
          <div style="width: 5rem" />
          <span>
            {{ computedGrandTotal() }}
            {{ form.currency?.code }}</span
          >
        </div>

        <div v-if="convertToSecondaryCurrency != null" class="total-price">
          <div style="width: 5rem" />
          <span
            >{{ convertToSecondaryCurrency }}
            {{ form.secondary_currency?.code }}</span
          >
        </div>
      </div>
    </div>

    <template #footer>
      <div class="add-invoice-form-modal-buttons">
        <VaButton
          preset="secondary"
          border-color="primary"
          @click="handleToggleAddModal"
        >
          Cancel
        </VaButton>
        <VaButton
          :loading="addInvoiceLoading"
          :disabled="invoiceItemsAttributes.length < 1"
          @click="validate() && handleCreateInvoice()"
        >
          Save
        </VaButton>
      </div>
    </template>
  </VaModal>
</template>
<script lang="ts" src="./script.ts" />
<style lang="scss" scoped src="./styles.scss" />
