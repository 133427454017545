import { TableColumnInterface } from '@/Interfaces/Constants'
import { defineComponent } from 'vue'
import SearchAndViews from '@/components/searchAndViews/index.vue'
import ActionsBar from '@/components/actionsBar/index.vue'
import { KPIAndTargetsTemplate } from '@/constants/Tables/KPIAndTargetsTemplate'
import KPIAndTargetsTable from '@/components/KPIAndTargetsTable/index.vue'
import { addTarget, getAllTargets } from '@/services/kpiAndTargets'
import { TargetPresenter } from '@/presenters/TargetPresenter'
import AddTargetModal from '@/components/KPIAndTargetsTable/AddTargetModal/index.vue'

export default defineComponent({
  name: 'KPIAndTargets',
  props: {},
  data() {
    return {
      tableLoading: false,
      targetsItemPerPage: 10,
      targetTotalItems: 0,

      targets: [] as TargetPresenter[],

      headers: [] as TableColumnInterface[],
      KPIAndTargetsTemplate,

      targetPage: 1,

      isAddLoading: false,
      isAddTargetModalOpen: false,
    }
  },
  emits: [],
  methods: {
    handleToggleAddTargetModal() {
      this.isAddTargetModalOpen = !this.isAddTargetModalOpen
    },

    ////////////////////////////////////////////////////////////////////////////
    getHeaders() {
      if (this.headers && this.headers.length) {
        return this.headers
      }

      return this.KPIAndTargetsTemplate
    },

    async handleGetTargets(search?: string) {
      this.tableLoading = true

      const response = getAllTargets()

      if (response.success) {
        this.targets = search
          ? response.data?.list.filter((target) =>
              target.member.fullName
                .toLowerCase()
                .includes(search.toLowerCase()),
            )
          : response.data?.list

        this.targetTotalItems =
          response.data.total_pages * this.targetsItemPerPage
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.tableLoading = false
    },

    ////////////////////////////////////////////////////////////////////////////

    async handlePaginationTargets({ page, itemsPerPage }: any) {
      this.targetsItemPerPage = itemsPerPage
      this.targetPage = page
      await this.handleGetTargets()
    },

    ///////////////////////////////////////////////////////////////////////////////////////
    async handleCreateTarget({ newTarget }: { newTarget: TargetPresenter }) {
      this.isAddLoading = true
      addTarget({ newTarget })

      this.isAddLoading = false
    },

    ///////////////////////////////////////////////////////////////////////////////////////
    searchTargetData(search: string) {
      this.handleGetTargets(search)
    },
  },
  mounted() {
    this.handleGetTargets()
  },
  components: {
    SearchAndViews,
    ActionsBar,
    AddTargetModal,
    KPIAndTargetsTable,
  },
})
