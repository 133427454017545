import { paginationInterface } from '@/Interfaces/Services'
import { isLongDescription, truncateText } from '@/lib/utils'
import { defineComponent } from 'vue'
const MoreVertIcon = new URL(
  '@/assets/icons/More-vert-icon.svg',
  import.meta.url,
).href

export default defineComponent({
  name: 'KPIAndTargetsTable',
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    itemsPerPage: {
      type: Number,
      default: 10,
    },
    totalItems: {
      type: Number,
      default: 10,
    },
    items: {
      type: Array,
      required: true,
    },
    headers: {
      type: Array,
      required: true,
    },
  },
  emits: ['selectedRows', 'loadMore'],
  data() {
    return {
      selectedRows: [],
      MoreVertIcon,
      deleteDialog: false,
      deleteItem: null,
      currentPage: 1,
      currentSortBy: [] as { key: string; order?: 'asc' | 'desc' }[],
      currentItemsPerPage: this.itemsPerPage,

      isLongDescription,
      truncateText,
    }
  },
  watch: {
    selectedRows() {
      this.$emit('selectedRows', this.selectedRows)
    },
  },
  methods: {
    confirmDelete(item: null) {
      this.deleteItem = item
      this.deleteDialog = true
    },
    confirmDeleteAction() {
      console.log('Deleting', this.deleteItem)
      this.deleteDialog = false
    },
    viewProfile(item: { id: number }) {
      this.$router.push(`/firm/members/${item?.id}`)
    },

    handleLoadMore({ page, itemsPerPage, sortBy }: paginationInterface) {
      const { currentPage, currentItemsPerPage, currentSortBy } = this

      // Check if there are any changes in pagination or sorting
      const hasPaginationChanged =
        page !== currentPage || itemsPerPage !== currentItemsPerPage
      const hasSortingChanged =
        JSON.stringify(sortBy) !== JSON.stringify(currentSortBy) // Compare sorting objects

      // Only emit if there's a change in pagination or sorting
      if (hasPaginationChanged || hasSortingChanged) {
        this.$emit('loadMore', { page, itemsPerPage, sortBy })

        // Update state after the change
        this.currentPage = page
        this.currentItemsPerPage = itemsPerPage
        this.currentSortBy = sortBy
      }
    },
  },
})
