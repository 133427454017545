<template>
  <TabsBar :tabs="tabs" :active-tab-id="activeTabId" @changeTab="changeTab" />
  <VaDivider />
  <div class="scroll-container inherit-height">
    <InfoSecurity v-if="activeTab === 'INFO & SECURITY'" />
    <RolesPermissions v-else-if="activeTab === 'ROLES & PERMISSIONS'" />

    <div v-else class="coming-soon-container">
      <ComingSoon />
    </div>
    <!-- </div> -->
  </div>
</template>

<script lang="ts" src="./script.ts"></script>
<style lang="scss" scoped src="./styles.scss"></style>
