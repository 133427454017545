import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ContactCard',
  props: {
    contact: {
      type: Object,
      required: true,
    },
  },
  data() {
    console.log({ contact: this.contact })

    return {
      isHovered: false,
    }
  },
  methods: {
    // Fallback to get initials if no avatar
    getInitials(firstName: string, lastName: string) {
      return `${firstName?.charAt(0)}${lastName?.charAt(0)}`.toUpperCase()
    },
  },
})
