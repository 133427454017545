<template>
  <SharedHeader title-width="30rem" title="Leads">
    <div v-if="activeTab !== 'All Leads'" class="sub-SharedHeader">
      <va-dropdown :offset="[0, -70]" close-on-content-click>
        <template #anchor>
          <img :src="MoreVertIcon" alt="MoreVertIcon" class="press-button-2" />
        </template>
        <va-dropdown-content class="dropdown">
          <p @click="handleToggleUpdateLayoutModal">Edit Page Layout</p>
        </va-dropdown-content>
      </va-dropdown>
    </div>
  </SharedHeader>

  <AddLeadModal
    :isAddLeadLoading="isAddLeadLoading"
    :isAddLeadForm="isAddLeadForm"
    :stages="subSections"
    :section="section"
    @toggleAddLeadForm="toggleAddLeadForm"
    @handleCreateLead="handleCreateLead"
  />

  <UpdateLayoutColumns
    :update-layout-loading="isUpdateLayoutLoading"
    :is-update-layout-form="isShowUpdateLayoutModal"
    :section_stages="stages"
    :is-delete-layout-loading="isDeleteLayoutLoading"
    @handleToggleUpdateLayoutModal="handleToggleUpdateLayoutModal"
    @handleUpdateStageData="handleUpdateLayout"
    @onDragEnd="handleUpdateLayout"
    @handleDeleteLayout="handleDeleteLayout"
  />

  <TabsBar
    :tabs="sections"
    :active-tab-id="activeTabId"
    :loading="isSectionsLoading"
    @changeTab="handleTabChange"
  />

  <ActionsBar
    v-if="!isSectionsLoading"
    :second-add-button="'Add Lead'"
    @secondButtonAction="toggleAddLeadForm"
  />

  <AllLeads
    v-if="activeTabId === 1717171"
    :sections="sections.filter((sec) => sec.id != 1717171)"
    :headers="headers"
    :refetchAllLeadsTrigger="refetchAllLeadsTrigger"
  />

  <div v-else class="scroll-container-wrapper">
    <KanbanInfiniteLoad
      v-if="!isSectionsLoading"
      :isLead="true"
      :stages="stages"
      :is-cards-loading="isLeadsLoading"
      :is-columns-loading="isStagesLoading"
      @ticketMoved="handleTicketMoved"
      @handleAddStage="handleToggleUpdateLayoutModal"
      @handleAddItem="toggleAddLeadForm"
    />
  </div>
</template>

<script lang="ts" src="./script.ts" />
<style lang="scss" scoped src="./styles.scss" />
