import {
  addSecondsToISOTime,
  camelCaseToNormal,
  deepCopy,
  formatDate,
  formatTime,
  formatTimeSeconds,
  isLongDescription,
  truncateText,
} from '@/lib/utils'
import { CalendarItemsPresenter } from '@/presenters/calendarItemsPresenter'
import { defineComponent } from 'vue'
import DatePickerModal from '@/components/DatePickerModal/index.vue'
import TypeSelectModal from '@/components/TimeRecordModal/TypeSelectModal/index.vue'
import { paginationInterface } from '@/Interfaces/Services'
import { CompanyConfigurationPresenter } from '@/presenters/companyConfigPresenters'
import { calendarGlobalSearchInterface } from '@/Interfaces/Store'
import { calendar_item_linked_items_attributesInterface } from '@/Interfaces/layout'
import TimeLinkModal from '@/components/TimeRecordModal/TimeLinkModal/index.vue'

type timeRangeInterface = {
  hours: string | number
  minutes: string | number
}

const moreVertIcon = new URL(
  '@/assets/icons/More-vert-icon.svg',
  import.meta.url,
).href

export default defineComponent({
  name: 'DetailsTimeLogs',
  props: {
    timeLog: {
      type: Array as () => CalendarItemsPresenter[],
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    itemsPerPage: {
      type: Number,
      default: 10,
    },
    totalItems: {
      type: Number,
      default: 10,
    },
    updateTimeLogLoading: {
      type: Boolean,
      required: true,
    },
    deleteTimeLogLoading: {
      type: Boolean,
      required: true,
    },
    timeSheet: {
      type: Boolean,
      required: true,
    },
  },
  emits: [
    'selectedRows',
    'loadMore',
    'handleUpdateTimeLog',
    'handleDeleteTimeLog',
  ],

  data() {
    const headers = [
      {
        title: '#',
        align: 'center',
        key: 'id',
        sortable: false,
        width: '3rem',
      },
      {
        title: 'Name',
        align: 'start',
        key: 'company_user_id',
        sortable: false,
        width: '11rem',
      },
      ...(this.timeSheet
        ? [
            {
              title: 'Linked To',
              align: 'start',
              key: 'linked_to',
              sortable: false,
              width: '11rem',
            },
          ]
        : []),
      {
        title: 'Description',
        align: 'start',
        key: 'description',
        sortable: false,
      },
      {
        title: 'Date',
        align: 'center',
        key: 'created_at',
        sortable: false,
        width: '8.8rem',
      },
      {
        title: 'Time',
        align: 'center',
        key: 'start_time',
        sortable: false,
        width: '6.6rem',
      },
      {
        title: 'Duration',
        align: 'start',
        key: 'total_time_spent_seconds',
        sortable: false,
        width: '9rem',
      },
      {
        title: 'Billable',
        align: 'center',
        key: 'total_billable_hourly_rate_charging_amount',
        sortable: false,
        width: '10rem',
      },
      {
        title: 'Type',
        align: 'center',
        key: 'type',
        sortable: false,
        width: '3rem',
      },
      {
        title: '',
        align: 'center',
        key: 'action',
        sortable: false,
        width: '3rem',
      },
    ]

    return {
      truncateText,
      isLongDescription,
      formatDate,
      formatTime,
      formatTimeSeconds,
      camelCaseToNormal,
      deepCopy,
      moreVertIcon,

      isItemAssignUpdate: false,

      selectedRows: [''],
      headers,

      currentPage: 1,
      currentSortBy: [] as {
        key: string
        order?: 'asc' | 'desc'
      }[],
      currentItemsPerPage: this.itemsPerPage,

      isEditTimeLog: false,
      selectedRecord: {} as CalendarItemsPresenter,

      isItemTypeSelect: false,

      timeRangeUpdate: {
        hours: 0,
        minutes: 0,
      } as timeRangeInterface,
    }
  },
  methods: {
    handleLoadMore({ page, itemsPerPage, sortBy }: paginationInterface) {
      const { currentPage, currentItemsPerPage, currentSortBy } = this

      // Check if there are any changes in pagination or sorting
      const hasPaginationChanged =
        page !== currentPage || itemsPerPage !== currentItemsPerPage
      const hasSortingChanged =
        JSON.stringify(sortBy) !== JSON.stringify(currentSortBy) // Compare sorting objects

      // Only emit if there's a change in pagination or sorting
      if (hasPaginationChanged || hasSortingChanged) {
        this.$emit('loadMore', { page, itemsPerPage, sortBy })

        // Update state after the change
        this.currentPage = page
        this.currentItemsPerPage = itemsPerPage
        this.currentSortBy = sortBy
      }
    },

    ///////////////////////////////// edit time log //////////////////////////////

    handleToggleAssignToMenu() {
      this.isItemAssignUpdate = !this.isItemAssignUpdate
    },

    handleToggleEditTimeLog(record?: CalendarItemsPresenter) {
      this.isEditTimeLog = !this.isEditTimeLog
      this.selectedRecord = deepCopy(record) || ({} as CalendarItemsPresenter)
    },

    handleUpdateStartDate(date: { date: string }) {
      this.selectedRecord.start_time = date.date
    },
    handleUpdateEndDate(date: { date: string }) {
      this.selectedRecord.end_time = date.date
    },

    handleUpdateTimeLog() {
      this.$emit('handleUpdateTimeLog', this.selectedRecord)
    },

    handleDeleteTimeLog(id: number) {
      this.$emit('handleDeleteTimeLog', id)
    },

    handleLinkToUpdate(data: {
      calendar_item_linked_items_attributes: calendar_item_linked_items_attributesInterface[]
      selectedItems: calendarGlobalSearchInterface[]
    }) {
      this.selectedRecord.calendar_item_linked_items_attributes =
        data.calendar_item_linked_items_attributes
      this.selectedRecord.calendar_item_linked_items = data.selectedItems
    },
    handleAssignType(type: CompanyConfigurationPresenter) {
      this.selectedRecord.item_type = type
      this.selectedRecord.item_type_id = type?.id

      this.isItemTypeSelect = !this.isItemTypeSelect
    },

    ///////////////////////////////////////////////////////////////////////////////////

    handleRangeTimeUpdateToSec() {
      let { hours, minutes } = this.timeRangeUpdate

      hours = Number(hours) || 0
      minutes = Number(minutes) || 0

      // both = 0 => minutes = 1
      const adjustedHours = hours >= 0 ? hours : 0
      const adjustedMinutes = minutes > 0 || hours > 0 ? minutes : 1

      //  time in seconds
      if (adjustedHours >= 0 && adjustedMinutes >= 0) {
        const totalSeconds = adjustedHours * 3600 + adjustedMinutes * 60

        this.selectedRecord.total_time_spent_seconds = totalSeconds
        this.selectedRecord.time_range = true

        // Set start_time to midnight UTC
        const today = new Date()
        today.setUTCHours(0, 0, 0, 0)
        this.selectedRecord.start_time = today.toISOString()

        this.selectedRecord.end_time = addSecondsToISOTime(
          this.selectedRecord.start_time,
          totalSeconds,
        )
      }
    },
  },
  watch: {
    selectedRows() {
      this.$emit('selectedRows', this.selectedRows)
    },
    updateTimeLogLoading: {
      handler(data) {
        if (!data) {
          this.isEditTimeLog = false
          this.selectedRecord = {} as CalendarItemsPresenter
        }
      },
    },
    deleteTimeLogLoading: {
      handler(data) {
        if (!data) {
          this.isEditTimeLog = false
          this.selectedRecord = {} as CalendarItemsPresenter
        }
      },
    },
  },
  components: {
    DatePickerModal,
    TypeSelectModal,
    TimeLinkModal,
  },
})
