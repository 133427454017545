import { defineComponent } from 'vue'
import SearchAndViews from '@/components/searchAndViews/index.vue'
import ActionsBar from '@/components/actionsBar/index.vue'
import { CompanyUserPresenter } from '@/presenters/CompanyUserPresenter'
import { addMember, deleteMember, getMembers } from '@/services/company'
import {
  getOptionName,
  validateInput,
  validateInputEmail,
  validateSelect,
} from '@/lib/utils'
import { useForm } from 'vuestic-ui/web-components'
import { RolePresenter } from '@/presenters/RolePresenter'
import { getRoles } from '@/services/roles'

const MoreVertIcon = new URL(
  '@/assets/icons/More-vert-icon.svg',
  import.meta.url,
).href

type addForm = {
  email: string
  roles: number[]
  title: string
  first_name: string
  middle_name: string
  last_name: string
}

export default defineComponent({
  name: '',
  data() {
    const { validate, reset } = useForm('form')
    return {
      validate,
      reset,

      MoreVertIcon,

      validateInput,
      validateInputEmail,
      validateSelect,
      getOptionName,

      isAddLoading: false,

      isAddMember: false,
      form: {} as addForm,

      membersLoading: false,
      addMemberLoading: false,

      teamMembers: [] as CompanyUserPresenter[],

      rolesOptions: ['admin'],

      membersItemPerPage: 6,
      memberPage: 1,
      memberSearchKeyword: '',
      memberTotalItems: 0,

      debounceTimeout: null as ReturnType<typeof setTimeout> | null,

      isDeleteMember: false,
      deleteMemberLoading: false,
      selectedMember: null as CompanyUserPresenter | null,

      rolesLoading: false,
      rolesOption: [] as RolePresenter[],
    }
  },
  methods: {
    handleToggleDeleteMember(member: CompanyUserPresenter | null = null) {
      this.selectedMember = member
      this.isDeleteMember = !this.isDeleteMember
    },

    async handleDeleteMember() {
      this.deleteMemberLoading = true

      const response = await deleteMember(
        this.selectedMember?.company_id as number,
        this.selectedMember?.id as number,
      )
      if (response.success) {
        this.$vaToast.init({
          message: 'Member deleted successfully!',
          color: 'success',
        })
        this.handleGetMembers()
        this.handleToggleDeleteMember()
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }

      this.deleteMemberLoading = false
    },

    ///////////////////////////////////////////////////////////////////////////////
    async handleGetMembers() {
      this.membersLoading = true
      const response = await getMembers({
        company_id: this.$stores.auth.company.company_id,
        as_company_user: true,
        keyword: this.memberSearchKeyword,
      })
      if (response.success) {
        this.teamMembers = response.data?.list
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.membersLoading = false
    },

    searchMembersData(search: string) {
      this.memberSearchKeyword = search

      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout)
      }

      this.debounceTimeout = setTimeout(() => {
        this.handleGetMembers()
      }, 500)
    },

    //////////////////////////////////////////////////////////////////////////////////
    toggleAddMember() {
      this.isAddMember = !this.isAddMember
      this.reset()
    },
    async handleAddMember() {
      this.addMemberLoading = true

      const response = await addMember(this.$stores.auth.company.company_id, {
        ...this.form,
        role_ids: this.form.roles?.length === 0 ? [0] : this.form.roles,
      })
      if (response.success) {
        this.$vaToast.init({
          message: 'Member Added successfully!',
          color: 'success',
        })
        this.handleGetMembers()
        this.toggleAddMember()
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }

      this.addMemberLoading = false
    },
    ///////////////////////////////////////// Roles /////////////////////////////////////////

    async handleGetRoles() {
      this.rolesLoading = true

      const response = await getRoles()

      // const itemsToExclude = this.member?.roles?.map((role) => role.id) || []
      //   this.rolesOption = response.data.list.filter(
      //     (item: RolePresenter) => !itemsToExclude.includes(item.id),
      //   )

      if (response.success) {
        this.rolesOption = response.data.list
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }

      this.rolesLoading = false
    },
  },
  mounted() {
    this.handleGetMembers()
  },
  watch: {
    isAddMember: {
      handler() {
        if (this.isAddMember) {
          this.handleGetRoles()
        }
      },
    },
    form: {
      handler(data) {
        console.log({ data })
      },
      deep: true,
    },
  },
  components: {
    SearchAndViews,
    ActionsBar,
  },
})
