import { defineComponent } from 'vue'
import 'vue-cal/dist/vuecal.css'
import VueCal from 'vue-cal'
import SharedHeader from '@/components/sharedHeader/index.vue'
import AddEventModal from '@/components/Calendar/AddEventModal/index.vue'
import UpdateEventModal from '@/components/Calendar/UpdateEventModal/index.vue'
import {
  addCalendarItem,
  deleteCalendarItem,
  getAllCalendarItems,
  updateCalendarItem,
} from '@/services/calendarItem'
import { CalendarItemsPresenter } from '@/presenters/calendarItemsPresenter'
import {
  convertToLocalISO,
  getOptionFullName,
  getOptionName,
  getContrastingTextColor,
  formatTimeSeconds,
  updateDatePart,
  getWeekStartAndEndUTC,
} from '@/lib/utils'
import { addUpdateCalendarItemInterface, ZoneType } from '@/Interfaces/Services'
import { calendarItemsFormInterface } from '@/Interfaces/layout'
import { GlobalSearchPresenter } from '@/presenters/GlobalSearchPresenter'
import { CompanyUserPresenter } from '@/presenters/CompanyUserPresenter'
import TimeZoneModal from '@/components/Calendar/TimeZoneModal/index.vue'

type TimeRow = {
  [key: string]: string
}

export default defineComponent({
  name: '',
  props: {},
  emits: [],
  data() {
    const typesOption = [
      { name: 'All Types', id: null, color: null },
      ...(this.$stores.generalData.companyConfigs.calendarTypesOption ?? []),
    ]
    const viewOptions = [
      { text: 'Month', value: 'month' },
      { text: 'Week', value: 'week' },
      { text: 'Day', value: 'day' },
    ]

    const userFiltrationOptions = [
      {
        id: this.$stores.auth.company.id,
        fullName: 'Me',
      },
      { id: 107070701, fullName: 'ALL Users' },
      ...(
        (this.$stores.usersData.teamMembers as CompanyUserPresenter[]) || []
      ).filter((member) => member.id !== this.$stores.auth.company.id),
    ]
    const userFiltration = {
      id: this.$stores.auth.company.id,
      fullName: 'Me',
    } as CompanyUserPresenter

    return {
      getOptionFullName,
      getOptionName,
      getContrastingTextColor,
      formatTimeSeconds,

      timeRows: [] as TimeRow[],
      showTable: true,

      now: new Date(),
      timeCellHeight: 50,
      selectedDate: new Date() as string | Date,

      dateValue: new Date(),

      typesOption,
      activeType: { name: 'All Types', id: null, color: null },

      selectedView: { text: 'Week', value: 'week' },
      viewOptions,

      addCalendarLoading: false,
      isAddCalendarForm: false,

      updateCalendarLoading: false,
      deleteCalendarLoading: false,
      isUpdateCalendarForm: false,

      isCalendarItemsLoading: false,

      itemsPerPage: 100,
      totalPages: 0,
      currentPage: 1,

      calendarItems: [] as CalendarItemsPresenter[],
      selectedEvent: {} as addUpdateCalendarItemInterface,
      newEventStart: '' as any,

      getWeekStartAndEndUTC,
      currentStartEndTime: getWeekStartAndEndUTC(),

      userFiltration,

      userFiltrationOptions,

      debounceTimeout: null as ReturnType<typeof setTimeout> | null,

      searchedEvent: '' as any,
      searchedEventOptions: [] as CalendarItemsPresenter[],
      isSearchedCalendarItemsLoading: false,

      isGlobalSearchLoading: false,
      globalSearchKeyword: '',
      globalSearchData: {} as GlobalSearchPresenter,

      isItemTypeSelect: false,

      isDragging: false,

      isTimeZoneModalOpen: false,
    }
  },

  methods: {
    handleToggleFilter() {
      this.$stores.ui.showCalendarSideDrawer =
        !this.$stores.ui.showCalendarSideDrawer
    },

    toggleAddCalendarForm() {
      this.isUpdateCalendarForm = false
      this.isAddCalendarForm = !this.isAddCalendarForm
    },

    toggleUpdateCalendarForm() {
      this.isAddCalendarForm = false
      this.isUpdateCalendarForm = !this.isUpdateCalendarForm
    },

    formatTime(time: string) {
      const date = new Date(time)
      const hours = date.getHours()
      const minutes = date.getMinutes()?.toString().padStart(2, '0')
      const period = hours >= 12 ? 'PM' : 'AM'
      const formattedHours = hours % 12 || 12 // Convert 0 to 12 for 12 AM
      return `${formattedHours}:${minutes} ${period}`
    },

    /////////////////////////////////////////////////////////

    onEventClick(event: addUpdateCalendarItemInterface) {
      this.selectedEvent = event
      this.toggleUpdateCalendarForm()
    },

    onEventCreate(event: { date?: Date } | Date) {
      // it becomes event.date when in week view we click on the day name
      let eventDate = event instanceof Date ? event : event?.date

      if (eventDate instanceof Date && !this.isDragging) {
        if (this.selectedView?.value === 'month') {
          eventDate = new Date(eventDate?.setHours(0, 0, 0, 0))
        }
        this.newEventStart = {
          start_time: eventDate,
          end_time: new Date(eventDate.getTime() + 30 * 60 * 1000),
        }
        this.toggleAddCalendarForm()
      }
    },

    async onEventDragCreate(event: { start: Date; end: Date }) {
      this.isDragging = true
      setTimeout(() => {
        this.isDragging = false
      }, 100)
      const res = await this.handleCreateEvent({
        start_time: event.start.toISOString(),
        end_time: event.end.toISOString(),
        item_type_id:
          this.activeType?.id ||
          this.$stores.generalData.companyConfigs.calendarTypesOption?.[0].id,
        color:
          this.activeType?.color ||
          this.$stores.generalData.companyConfigs.calendarTypesOption?.[0]
            .color,
      })
      if (!res) {
        this.revertOnError()
      }
    },

    // Also called when resizing an event
    async onEventDragDrop(event: {
      event: CalendarItemsPresenter
      oldDate: Date
      newDate: Date
    }) {
      let res
      const draggedEvent = this.calendarItems.find(
        (eventDragged) => eventDragged.id === event.event.id,
      )

      if (draggedEvent && draggedEvent.time_range) {
        const today = event.newDate

        const newDateOnly = convertToLocalISO(today).split('T')[0]

        draggedEvent.start = newDateOnly // Normalize start date
        draggedEvent.end = newDateOnly // Normalize end date

        res = await this.handleUpdateEvent({
          id: draggedEvent.id,
          start_time: updateDatePart(
            convertToLocalISO(draggedEvent.start_time),
            newDateOnly,
          ),
          end_time: updateDatePart(
            convertToLocalISO(draggedEvent.end_time),
            newDateOnly,
          ),
          total_time_spent_seconds: draggedEvent.total_time_spent_seconds,
        })
      } else {
        res = await this.handleUpdateEvent({
          id: event.event.id,
          start_time: event.event.start?.toString(),
          end_time: event.newDate // adding logic to handle when we are changing the end time by dragging for it to have a minimum of 15 mins
            ? event.event.end?.toString()
            : new Date(
                Math.max(
                  new Date(event.event.start).getTime() + 15 * 60 * 1000,
                  new Date(event.event.end).getTime(),
                ),
              ).toISOString(),
        })
      }

      if (!res) {
        this.revertOnError()
      }
    },

    onEventDelete(event: { id: number }) {
      this.handleDeleteEvent(event.id)
    },

    onViewChange(event: { startDate: string; endDate: string; view: string }) {
      this.currentStartEndTime = {
        start_after: new Date(event.startDate).toISOString(),
        end_before: new Date(event.endDate).toISOString(),
      }

      this.handleGetEvents({
        start_after: new Date(event.startDate).toISOString(),
        end_before: new Date(event.endDate).toISOString(),
      })

      this.selectedView = this.viewOptions.find(
        (e) => e.value === event.view,
      ) || { text: '', value: '' }
      this.scrollToCurrentTime()
    },

    scrollToCurrentTime() {
      const calendar = document.querySelector('#vuecal .vuecal__bg') as any

      const hours = this.now.getHours() + this.now.getMinutes() / 60
      calendar.scrollTo({
        top: hours * this.timeCellHeight,
        behavior: 'smooth',
      })
    },

    //////////////////////////////////////////////////////////

    revertOnError() {
      this.calendarItems = [...this.calendarItems]
    },

    /////////////////////////////////////////////////////////
    logEvents(eventType: string, event: any) {
      console.log(eventType, event)
    },

    handleOnCalendarReady() {
      this.scrollToCurrentTime()
    },

    //////////////////////////////////////////////////////////

    async handleGetEvents(body: { start_after: string; end_before: string }) {
      this.isCalendarItemsLoading = true

      const response = await getAllCalendarItems({
        skip_limit: true,
        ...(this.userFiltration.id === 107070701
          ? { for_whole_company: true }
          : { company_user_id: this.userFiltration.id }),
        item_type_id: this.activeType?.id || undefined,

        ...body,
      })

      if (response.success) {
        this.calendarItems = response.data?.list
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.isCalendarItemsLoading = false
    },

    // calendarItemsFormInterface
    async handleCreateEvent(form: calendarItemsFormInterface) {
      this.addCalendarLoading = true

      const response = await addCalendarItem(form)

      if (response.success) {
        this.handleGetEvents({
          ...this.currentStartEndTime,
        })
        this.$vaToast.init({
          message: 'Event Created Successfully',
          color: 'success',
          position: 'bottom-right',
        })
        this.isAddCalendarForm = false
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
        this.addCalendarLoading = false
        return false
      }
      this.addCalendarLoading = false
      return true
    },

    async handleUpdateEvent(form: addUpdateCalendarItemInterface) {
      this.updateCalendarLoading = true

      const response = await updateCalendarItem(form.id, form)

      if (response.success) {
        // Ensuring fast updates to calendarItems[index] for a better user experience
        const index = this.calendarItems.findIndex((cal) => cal.id === form.id)
        // Wrap in CalendarItemsPresenter to exclude extra form properties.
        this.calendarItems[index] = new CalendarItemsPresenter({
          ...this.calendarItems[index],
          ...form,
        })

        this.handleGetEvents({
          ...this.currentStartEndTime,
        })
        this.isUpdateCalendarForm = false
        this.$vaToast.init({
          message: 'Event Updated Successfully',
          color: 'success',
          position: 'bottom-right',
        })
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
        this.updateCalendarLoading = false
        return false
      }

      this.updateCalendarLoading = false
      return true
    },

    async handleDeleteEvent(id: number) {
      this.deleteCalendarLoading = true
      const response = await deleteCalendarItem(id)

      if (response.success) {
        this.handleGetEvents({
          ...this.currentStartEndTime,
        })
        this.$vaToast.init({
          message: 'Event Removed Successfully',
          color: 'success',
          position: 'bottom-right',
        })
        this.isUpdateCalendarForm = false
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
        this.revertOnError()
      }
      this.deleteCalendarLoading = false
    },

    /////////////////////////////////////////////////////////////////////////////////////////

    handleSelectUserFilter() {
      this.handleGetEvents({
        ...this.getWeekStartAndEndUTC(),
      })
    },

    ///////////////////////////////////////////////////////////////////////////

    async handleGetSearchedEvents(keyword: string) {
      this.isSearchedCalendarItemsLoading = true

      const response = await getAllCalendarItems({
        per_page: keyword ? this.itemsPerPage : 10,
        page: this.currentPage,
        sort_by_direction: 'desc',
        sort_by_field: 'start_time',
        ...(this.userFiltration.id === 107070701
          ? { for_whole_company: true }
          : { company_user_id: this.userFiltration.id }),
        keyword,
      })

      if (response.success) {
        this.searchedEventOptions = response.data?.list
        console.log({ searchedEventOptions: this.searchedEventOptions })
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.isSearchedCalendarItemsLoading = false
    },

    handleSearchEvent(search: string) {
      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout)
      }
      if (search.length > 0) {
        this.debounceTimeout = setTimeout(() => {
          this.handleGetSearchedEvents(search)
        }, 500)
      }
    },

    getEventTitle(option: CalendarItemsPresenter) {
      return option?.title ?? option?.item_type?.name
      // return `${option?.title} - ${option?.start?.split(' ')[0]}`
    },

    handleSelectSearchedEvent() {
      this.selectedDate = new Date(this.searchedEvent.start_time)
    },

    //////////////////////////////////////////////////////////////////

    handleSelectTypeFilter() {
      this.handleGetEvents({
        ...this.getWeekStartAndEndUTC(),
      })
    },

    /////////////////////////////// Time Zone /////////////////////////////

    toggleTable() {
      this.showTable = !this.showTable
    },

    generateTimeRows() {
      const startHour = 0 // Start at midnight (12:00 AM)
      const endHour = 23 // End at 11 PM
      const rows: TimeRow[] = []

      for (let hour = startHour; hour <= endHour; hour++) {
        const timeRow: TimeRow = {}
        this.$stores.auth.company.calendar_timezones_options.forEach((zone) => {
          timeRow[zone?.abbreviation] = this.formatTime2(hour, zone?.timezone)
        })
        rows.push(timeRow)
      }

      this.timeRows = rows
    },

    formatTime2(hour: number, timeZone: string): string {
      const now = new Date()
      // console.log({ hour })

      now.setHours(hour, 0, 0, 0) // Set time to the specific hour
      return new Intl.DateTimeFormat('en-US', {
        timeZone,
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
      }).format(now)
    },

    // generateTimeSlots(start: string, end: string, interval: number): string[] {
    //   const slots: string[] = []
    //   const [startHour, startMinute] = start.split(':').map(Number)
    //   const [endHour, endMinute] = end.split(':').map(Number)

    //   const currentDate = new Date()
    //   currentDate?.setHours(startHour, startMinute, 0, 0)

    //   const endDate = new Date()
    //   endDate?.setHours(endHour, endMinute, 0, 0)

    //   while (currentDate <= endDate) {
    //     slots.push(
    //       currentDate.toLocaleTimeString('en-US', {
    //         hour: '2-digit',
    //         minute: '2-digit',
    //         hour12: false,
    //       }),
    //     )
    //     currentDate?.setHours(currentDate?.getHours() + interval) // Increment by the interval
    //   }

    //   return slots
    // },

    convertTime(time: string, timezone: string): string {
      const [hours, minutes] = time.split(':').map(Number)
      const date = new Date()
      date.setUTCHours(hours, minutes, 0, 0)

      try {
        return date.toLocaleTimeString('en-US', {
          timeZone: timezone,
          hour: '2-digit',
          minute: '2-digit',
          hour12: true,
        })
      } catch (error) {
        console.error(`Error converting time for timezone ${timezone}:`, error)
        return 'Invalid Time'
      }
    },

    ////////////////////////////////////// Time Zone ///////////////////////////////////////////////////

    async handleSaveSelectedZones(zones: ZoneType[]) {
      this.$stores.calendar.handleSaveSelectedZones(zones)
    },

    handleSearchUpdate(search: string) {
      this.$stores.usersData.handleSearchMembers(search, {
        as_company_user: true,
      })
    },

    ////////////////////////////////////// Time Zone ///////////////////////////////////////////////////

    handleToggleTimeZone() {
      this.isTimeZoneModalOpen = !this.isTimeZoneModalOpen
    },
  },

  components: {
    VueCal,
    SharedHeader,
    AddEventModal,
    UpdateEventModal,
    TimeZoneModal,
  },

  watch: {
    '$stores.usersData.teamMembers': {
      handler(data) {
        this.userFiltrationOptions = [
          {
            id: this.$stores.auth.company.id,
            fullName: 'Me',
          },
          { id: 107070701, fullName: 'ALL Users' },
          ...data.filter(
            (member: CompanyUserPresenter) =>
              member.id !== this.$stores.auth.company.id,
          ),
        ]
      },
      deep: true,
      immediate: true,
    },
    '$stores.auth.company.calendar_timezones_options': {
      handler() {
        this.generateTimeRows()
      },
      deep: true,
      immediate: true,
    },
    '$stores.generalData.companyConfigs.calendarTypesOption': {
      handler(data) {
        this.typesOption = [
          { name: 'All Types', id: null, color: null },
          ...(data ?? []),
        ]
      },
      deep: true,
      immediate: true,
    },
  },

  mounted() {
    this.handleGetEvents({
      ...this.getWeekStartAndEndUTC(),
    })
    this.$stores.usersData.handleGetMembers({ as_company_user: true })
    this.handleGetSearchedEvents('')

    this.generateTimeRows()
  },
})
