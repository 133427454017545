import { TemplateInterface } from '@/Interfaces/Constants'

export const organizationFormFields: TemplateInterface[] = [
  // text
  {
    type: 'text',
    rules: 'validateInput',
    name: 'organization_name',
    label: 'Organization Name',
    placeholder: 'Organization Name',
    details: 'Text Field',
  },
  {
    type: 'text',
    rules: 'validateInput',
    name: 'organization_owner_email',
    label: 'Organization Owner Email',
    placeholder: 'Organization Owner Email',
    details: 'Text Field',
  },
  {
    type: 'text',
    rules: 'validateInput',
    name: 'organization_tax_identification_number',
    label: 'Tax Identification Number',
    placeholder: 'Tax Identification Number',
    details: 'Text Field',
  },
  {
    type: 'text',
    rules: 'validateInput',
    name: 'organization_registration_number',
    label: 'Registration Incorporation Number',
    placeholder: 'Registration Incorporation Number',
    details: 'Text Field',
  },
  {
    type: 'text',
    rules: 'validateInput',
    name: 'website_url',
    label: 'Organizational Website',
    placeholder: 'Organizational Website',
    details: 'Text Field',
  },
  {
    type: 'text',
    rules: 'validateInput',
    name: 'organization_jurisdiction_of_incorporation',
    label: 'Jurisdiction Of Incorporation',
    placeholder: 'Jurisdiction Of Incorporation',
    details: 'Text Field',
  },

  // radio
  {
    type: 'radio',
    name: 'individual_gender',
    label: 'Gender',
    options: [
      {
        text: 'Male',
        value: 'male',
      },
      {
        text: 'Female',
        value: 'female',
      },
    ],
    details: 'Radio buttons',
  },

  // date
  {
    type: 'date',
    rules: 'validateInput',
    name: 'organization_date_of_foundation',
    label: 'Date Of Foundation',
    placeholder: 'MM/DD/YYYY',
    details: 'Date Field - Select date + Manual Input',
  },

  // select
  {
    type: 'select',
    rules: 'validateSelect',
    name: 'organization_type',
    label: 'Organization Type',
    placeholder: 'Organization Type',
    options: 'organizationTypeOptions',
    textBy: 'getOptionFullName',
    multiple: false,
    searchable: true,
    details:
      'Dropdown : Select Organization type - fixed in the dynamic contact form',
  },
  {
    type: 'select',
    rules: 'validateSelect',
    name: 'linked_legal_matter_ids',
    label: 'Link To Matter',
    placeholder: 'Link To Matter',
    options: 'matters',
    textBy: 'getOptionName',
    multiple: true,
    searchable: true,
    details: 'Dropdown : Select Matter and link the contact to it',
  },
  {
    type: 'select',
    rules: 'validateSelect',
    name: 'organization_industry',
    label: 'Organization Industry',
    placeholder: 'Organization Industry',
    options: 'organizationIndustryOptions',
    textBy: 'getOptionFullName',
    multiple: false,
    searchable: false,
    details:
      'Dropdown : Select Organization Industry - fixed in the dynamic contact form',
  },
  {
    type: 'select',
    rules: 'validateSelect',
    name: 'designated_point_of_contact',
    label: 'Designed Point Of Contact',
    placeholder: 'Designed Point Of Contact',
    options: 'teamMembers',
    textBy: 'getOptionFullName',
    multiple: false,
    searchable: true,
    details:
      'Dropdown : Select Designed Point Of Contact - fixed in the dynamic contact form',
  },
  {
    type: 'select',
    rules: 'validateSelect',
    name: 'link_to_organization',
    label: 'Link To Organization',
    placeholder: 'Link To Organization',
    options: 'organizations',
    textBy: 'getOptionName',
    multiple: false,
    searchable: true,
    details:
      'Dropdown : Select Organization - ability to add a new organization',
  },
  {
    type: 'select',
    rules: 'validateSelect',
    name: 'relationship_owner',
    label: 'Relationship Owner',
    placeholder: 'Relationship Owner',
    options: 'teamMembers',
    textBy: 'getOptionFullName',
    multiple: false,
    searchable: true,
    details:
      'Dropdown : Select team member - ability to add a new organization',
  },
  {
    type: 'select',
    rules: 'validateSelect',
    name: 'assigned_to',
    label: 'Assigned To',
    placeholder: 'Assigned To',
    options: 'teamMembers',
    textBy: 'getOptionFullName',
    multiple: false,
    searchable: true,
    details:
      'Dropdown : Select team member - ability to add a new organization',
  },
  {
    type: 'select',
    rules: 'validateSelect',
    name: 'referred_by',
    label: 'Referred By',
    placeholder: 'Referred By',
    options: 'teamMembers',
    textBy: 'getOptionFullName',
    multiple: false,
    searchable: true,
    details:
      'Dropdown : Select team member - ability to add a new organization',
  },

  // text area
  {
    type: 'textarea',
    rules: 'validateInput',
    name: 'about',
    label: 'Notes',
    placeholder: 'Enter your notes here...',
    details: 'Text Area',
  },
]

export const organizationFormFieldsMostUsed: TemplateInterface[] = [
  {
    type: 'text',
    rules: 'validateInput',
    name: 'organization_name',
    label: 'Organization Name',
    placeholder: 'Organization Name',
    details: 'Text Field',
  },
  {
    type: 'text',
    rules: 'validateInput',
    name: 'organization_jurisdiction_of_incorporation',
    label: 'Jurisdiction Of Incorporation',
    placeholder: 'Jurisdiction Of Incorporation',
    details: 'Text Field',
  },
]
