import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SearchAndViews = _resolveComponent("SearchAndViews")!
  const _component_ActionsBar = _resolveComponent("ActionsBar")!
  const _component_AddTargetModal = _resolveComponent("AddTargetModal")!
  const _component_KPIAndTargetsTable = _resolveComponent("KPIAndTargetsTable")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_SearchAndViews, { onSearch: _ctx.searchTargetData }, null, 8, ["onSearch"]),
    _createVNode(_component_ActionsBar, {
      "second-add-button": "Add Target",
      onSecondButtonAction: _ctx.handleToggleAddTargetModal
    }, null, 8, ["onSecondButtonAction"]),
    _createVNode(_component_AddTargetModal, {
      "add-target-loading": _ctx.isAddLoading,
      "is-add-target-form-open": _ctx.isAddTargetModalOpen,
      onHandleToggleAddTargetModal: _ctx.handleToggleAddTargetModal,
      onHandleCreateTarget: _ctx.handleCreateTarget
    }, null, 8, ["add-target-loading", "is-add-target-form-open", "onHandleToggleAddTargetModal", "onHandleCreateTarget"]),
    _createVNode(_component_KPIAndTargetsTable, {
      loading: _ctx.tableLoading,
      "items-per-page": _ctx.targetsItemPerPage,
      "total-items": _ctx.targetTotalItems,
      items: _ctx.targets,
      headers: _ctx.getHeaders(),
      onLoadMore: _ctx.handlePaginationTargets
    }, null, 8, ["loading", "items-per-page", "total-items", "items", "headers", "onLoadMore"])
  ], 64))
}