<template>
  <template v-for="(section, sectionIndex) in templateData" :key="sectionIndex">
    <div class="column">
      <div class="row">
        <template v-for="(item, index) in section" :key="index">
          <!-- Images -->
          <!-- <div v-if="item.type === 'img'" class="square">{{ item.label }}</div> -->
          <img
            v-if="item.type === 'img'"
            :src="getImageSource(item.src)"
            alt="Logo"
          />
          <!-- Headings -->
          <h1 v-if="item.type === 'h1'">{{ item.text }}</h1>

          <!-- Text -->
          <p v-if="item.type === 'text'" v-html="formatText(item.text)"></p>

          <!-- Client Details -->
          <div class="client" v-if="item.type === 'client'">
            <span>{{ form?.invoiceable?.fullName }}</span>
            <span> {{ city }} - {{ country }} </span>
            <span> {{ building_name }} {{ floor }}</span>
          </div>

          <!-- Date & Invoice Number -->
          <div v-if="item.type === 'date|number'">
            <strong>Invoice Date: </strong>
            <span>
              {{ form?.invoice_date }}
            </span>
            <br />
            <strong>Invoice No.: </strong>
            <span>{{ form?.invoice_number }}</span>
          </div>

          <!-- Invoice Table -->
          <div v-if="item.type === 'table'" class="invoice-items">
            <table class="invoice-items">
              <thead>
                <tr>
                  <th>Description</th>
                  <th>Quantity</th>
                  <th>Price</th>
                  <th v-if="isDiscountItemsAmount">Discount</th>
                  <th>Tax</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(invoiceItem, invIndex) in form.invoice_items"
                  :key="invIndex"
                >
                  <td>{{ invoiceItem?.description }}</td>

                  <td v-if="!isCalendarItem(invoiceItem)">
                    {{ invoiceItem?.quantity }}
                  </td>
                  <td v-else>{{ getFormattedQuantity(invIndex) }}</td>
                  <td>{{ invoiceItem?.unit_price }}</td>
                  <td v-if="isDiscountItemsAmount">
                    {{ invoiceItem?.discount_type_amount }}
                  </td>
                  <td>{{ invoiceItem?.tax_percentage }}</td>
                  <td>{{ calculateTotalAmount(invoiceItem) }}</td>
                </tr>
              </tbody>
            </table>
            <div class="invoice-summary-container">
              <table class="invoice-summary-table">
                <tbody>
                  <tr>
                    <td><strong>Subtotal:</strong></td>
                    <td>
                      {{ subtotal }} <b>{{ form.currency?.code }}</b>
                    </td>
                  </tr>
                  <tr v-if="convertToSecondaryCurrencySubTotal">
                    <td></td>
                    <td>
                      {{ convertToSecondaryCurrencySubTotal }}
                      <b>
                        {{ form.secondary_currency?.code }}
                      </b>
                    </td>
                  </tr>
                  <tr v-if="Number(form.discount_type_amount) > 0">
                    <td><strong>Discount:</strong></td>
                    <td>
                      {{ form.discount_type == 'percentage' ? '%' : '' }}
                      {{ form.discount_type_amount }}
                    </td>
                  </tr>
                  <tr class="no-underline">
                    <td><strong>Taxes:</strong></td>
                    <td />
                  </tr>
                  <tr
                    v-for="(tax, index) in form.invoice_tax_items"
                    :key="index"
                    class="no-underline"
                  >
                    <td>&#9675; {{ tax.name }} :</td>
                    <td>
                      {{ tax.tax_type == 'percentage' ? '%' : '' }}
                      {{ tax.tax_type_amount }}
                    </td>
                  </tr>

                  <tr class="no-underline final-amount">
                    <td><strong>TOTAL AMOUNT:</strong></td>
                    <td>
                      <strong>{{ total }} {{ form.currency?.code }}</strong>
                    </td>
                  </tr>
                  <tr
                    v-if="convertToSecondaryCurrencyTotal != null"
                    class="no-underline final-amount"
                  >
                    <td></td>
                    <td>
                      <strong
                        >{{ convertToSecondaryCurrencyTotal }}
                        {{ form.secondary_currency?.code }}</strong
                      >
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <!-- Time Keepers -->
          <div v-if="item.type === 'timekeepers'">
            <div class="timekeepers-list">
              <h1>Timekeeper Summary</h1>
              <p v-for="(timekeeper, index) in form.timekeepers" :key="index">
                Timekeeper
                <b>{{
                  [
                    timekeeper?.company_user?.user?.first_name[0],
                    timekeeper?.company_user?.user?.last_name[0],
                  ]
                    .filter(Boolean)
                    .join('')
                }}</b>
                worked
                <b>{{
                  formatTimeSeconds({
                    totalSeconds: timekeeper.total_time_spent_seconds,
                    showOnlyHourMin: true,
                  })
                }}</b>
                at
                <b>{{
                  `${timekeeper.billable_hourly_rate_charging_amount} ${
                    timekeeper.billable_hourly_rate_charging_currency?.code ||
                    ''
                  }`
                }}</b>
                per hour, totaling
                <b>{{
                  `${timekeeper.total_billable_hourly_rate_charging_amount} ${
                    timekeeper.billable_hourly_rate_charging_currency?.code ||
                    ''
                  }`
                }}</b>
                .
              </p>
            </div>
          </div>
        </template>
      </div>
    </div>
  </template>
</template>

<script lang="ts" src="./script.ts" />
<style lang="scss" scoped src="../styles.scss" />
