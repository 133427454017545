<template>
  <div class="user-section">
    <h1 class="user-header">User Info</h1>
    <VaForm
      ref="form"
      class="update-user-form"
      @submit.prevent="handleUpdateUserPass"
    >
      <div class="circular-image-upload-container">
        <VaFileUpload
          hideFileList="true"
          v-model="userForm.profile_picture"
          :file-types="$stores.generalData.imagesFormats"
          @update:modelValue="handleFileUpload"
          type="single"
        >
          <div class="avatar-container">
            <img
              :src="userForm?.avatar?.medium_url || UploadAvatarIcon"
              alt="Avatar"
              class="avatar-preview"
            />
          </div>
          <div
            v-if="userForm?.avatar?.medium_url"
            class="close-icon press-button"
            @click.stop="handleRemoveImage"
          >
            ✕
          </div>
        </VaFileUpload>
      </div>

      <div class="form-inputs">
        <VaInput
          v-model="userForm.title"
          placeholder="Title"
          background="#ECF0F1"
          label="Title"
        />
        <VaInput
          v-model="userForm.first_name"
          placeholder="First Name"
          background="#ECF0F1"
          label="First Name"
          :rules="[validateInput]"
        />
      </div>
      <div class="form-inputs">
        <VaInput
          v-model="userForm.middle_name"
          placeholder="Middle Name"
          background="#ECF0F1"
          label="Middle Name"
        />
        <VaInput
          v-model="userForm.last_name"
          placeholder="Last Name"
          background="#ECF0F1"
          label="Last Name"
        />
      </div>
      <div>
        <VaButton
          class="save-info-Button"
          color="secondary"
          :loading="updateUserLoading"
          @click="validateInfo() && handleUpdateUserInfo()"
        >
          Save Changes
        </VaButton>
      </div>
    </VaForm>
  </div>
  <div class="password-section">
    <h1 class="pass-header">Password Change</h1>
    <VaForm
      ref="pass_form"
      class="update-pass-form"
      @submit.prevent="handleUpdateUserPass"
    >
      <!-- Current Password Input -->
      <div class="current-pass">
        <VaInput
          v-model="pass_form.old_password"
          placeholder="Enter Current Password"
          background="#ECF0F1"
          label="Current Password"
          :error="
            validateCurrentPassword() !== true
              ? validateCurrentPassword()
              : null
          "
        />
      </div>

      <!-- New Password Input -->
      <div class="new-pass">
        <VaInput
          v-model="pass_form.new_password"
          placeholder="Enter Your New Password"
          background="#ECF0F1"
          label="New Password"
          :error="validateNewPassword() !== true ? validateNewPassword() : null"
        />
        <div class="password-requirements">
          <p>
            <b>
              <VaIcon :color="lengthIconColor" name="circle" />
            </b>
            At least 8 characters long, 12-16 characters recommended
          </p>
          <p>
            <b>
              <VaIcon :color="caseIconColor" name="circle" />
            </b>
            Contains a combination of upper and lowercase letters
          </p>
        </div>
      </div>

      <!-- Confirm Password Input -->
      <div class="confirm-new-pass">
        <VaInput
          v-model="pass_form.confirm_new_password"
          placeholder="Confirm Your Password"
          background="#ECF0F1"
          :error="
            validateConfirmPassword() !== true
              ? validateConfirmPassword()
              : null
          "
        />
        <div class="password-requirements">
          <p>
            <b>
              <VaIcon :color="numberIconColor" name="circle" />
            </b>
            Includes at least one number and one special character (!@#$%^&*)
          </p>
        </div>
      </div>

      <!-- Save Changes Button -->
      <VaButton
        class="save-pass-Button"
        color="secondary"
        :disabled="!isFormValid"
        @click="handleUpdateUserPass"
      >
        Save Changes
      </VaButton>
    </VaForm>
  </div>
</template>

<script lang="ts" src="./script.ts"></script>
<style lang="scss" scoped src="./styles.scss"></style>
