<template>
  <TabsBar :tabs="tabs" :active-tab-id="activeTabId" @changeTab="changeTab" />

  <div class="scroll-container-wrapper">
    <!-- <Members /> -->
    <Members v-if="activeTab === 'MEMBERS'" />

    <Workspace v-else-if="activeTab === 'WORKSPACE'" />

    <!-- <Teams /> -->
    <Teams v-else-if="activeTab === 'TEAMS'" />

    <!-- <KPI & TARGETS /> -->
    <KPIAndTargets v-else-if="activeTab === 'KPI & TARGETS'" />

    <div v-else class="coming-soon-container">
      <ComingSoon />
    </div>
  </div>
</template>
<script lang="ts" src="./script.ts" />
<style lang="scss" scoped src="./styles.scss" />
