import { formatDate } from '@/lib/utils'
import { ExpensesPresenter } from '@/presenters/ExpensesPresenter'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'LinkExpense',
  props: {
    expenses: {
      type: Array as () => ExpensesPresenter[],
      required: true,
    },
    isExpensesLoading: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['handleLinkTo'],
  data() {
    return {
      formatDate,
    }
  },
  methods: {
    handleLinkTo(data: ExpensesPresenter) {
      this.$emit('handleLinkTo', data)
    },

    // billedToName(expense: ExpensesPresenter): string {
    //   if (!expense?.billed_to) return 'Unknown'
    //   return expense.billed_to.class_name === 'IndividualContact'
    //     ? expense.billed_to.fullName
    //     : expense.billed_to.name
    // },
  },
})
