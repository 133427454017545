import { defineComponent } from 'vue'
import DynamicContactForm from '@/components/DynamicForms/DynamicContactForm/index.vue'
import { ContactTemplatePresenter } from '@/presenters/TemplatePresenter/Contact'
import {
  contactFormFieldsMostUsed,
  contactFormFields,
} from '@/constants/Templates/contactTemplate'
import { TemplateInterface } from '@/Interfaces/Constants'
import draggable from 'vuedraggable'
import { formatTemplateFields, validateInput } from '@/lib/utils'
import { SectionsPresenter } from '@/presenters/SectionsPresenter'
import {
  organizationFormFields,
  organizationFormFieldsMostUsed,
} from '@/constants/Templates/organizationTemplate'

export default defineComponent({
  name: 'ContactTemplateModal',

  props: {
    addContactTempLoading: {
      type: Boolean,
      default: false,
    },
    isAddContactTemp: {
      type: Boolean,
      default: false,
    },
    isUpdateTemplate: {
      type: Boolean,
      default: false,
    },
    selectedTemplate: {
      type: Object as () => SectionsPresenter,
      required: true,
    },
  },
  emits: [
    'handleToggleAddModalTemp',
    'handleAddTemplate',
    'handleUpdateTemplate',
  ],
  data() {
    const templatesFound = [
      {
        id: 1,
        name: 'Most Used',
        temps: [
          {
            id: 1,
            name: 'Litigation Case',
          },
          {
            id: 2,
            name: 'Non-Litigation Case',
          },
          {
            id: 3,
            name: 'Divorce Case',
          },
        ],
      },
      {
        id: 1,
        name: 'Haqq Templates',
        temps: [
          {
            id: 1,
            name: 'Template 1',
          },
          {
            id: 2,
            name: 'Template 2',
          },
          {
            id: 3,
            name: 'Template 3',
          },
        ],
      },
      {
        id: 1,
        name: 'Your Saved Templates',
        temps: [
          {
            id: 1,
            name: 'Template 1',
          },
          {
            id: 2,
            name: 'Template 2',
          },
          {
            id: 3,
            name: 'Template 3',
          },
        ],
      },
      {
        id: 1,
        name: 'Marketplace Templates',
        temps: [
          {
            id: 1,
            name: 'Template 1',
          },
          {
            id: 2,
            name: 'Template 2',
          },
          {
            id: 3,
            name: 'Template 3',
          },
          {
            id: 4,
            name: 'Template 4',
          },
          {
            id: 5,
            name: 'Template 5',
          },
          {
            id: 6,
            name: 'Template 6',
          },
        ],
      },
    ]

    const formFields: Record<string, TemplateInterface[]> = {
      People: contactFormFields,
      Organizations: organizationFormFields,
    }
    const formFieldsMostUsed: Record<string, TemplateInterface[]> = {
      People: contactFormFieldsMostUsed,
      Organizations: organizationFormFieldsMostUsed,
    }

    return {
      form: {},
      selectedFields: [] as ContactTemplatePresenter[][],
      templateForm: {} as SectionsPresenter,

      isAddContactTempBool: false,
      isSelectTemplate: false,
      templatesFound,
      validateInput,

      isSelectFields: false,

      formFields,
      formFieldsMostUsed,

      selectedFieldIndex: null as number | null,
      vaCheckbox: true,
    }
  },

  methods: {
    handleToggleAddModalTemp() {
      this.isAddContactTempBool = !this.isAddContactTempBool
      this.$emit('handleToggleAddModalTemp')
    },

    handleToggleSelectTemplate() {
      this.isSelectTemplate = !this.isSelectTemplate
    },

    handleSubmitTemplate() {
      if (this.isUpdateTemplate) {
        const { table_columns, ...templateForm } = this.templateForm

        this.$emit('handleUpdateTemplate', {
          ...templateForm,
          form_columns: formatTemplateFields(
            this.selectedFields as TemplateInterface[][],
          ),
        })
      }
    },

    searchContactData(search: string) {
      console.log({ search })
    },

    ///////////////////////////// Add Field Modal /////////////////////////////
    handleToggleSelectFields(index?: number) {
      if (index !== null && index !== undefined) {
        this.selectedFieldIndex = index
      } else {
        this.selectedFieldIndex = null
      }
      this.isSelectFields = !this.isSelectFields
    },

    handleAddField(field: TemplateInterface) {
      if (this.selectedFieldIndex !== null) {
        this.selectedFields[this.selectedFieldIndex].push(
          field as ContactTemplatePresenter,
        )
      } else {
        this.selectedFields?.push([field as ContactTemplatePresenter])
      }
      this.handleToggleSelectFields()
    },

    handleRemoveRowField(index: number) {
      this.selectedFields.splice(index, 1)
    },

    handleRemoveField(row: number, field: number) {
      // this.selectedFields.splice(index, 1);
      this.selectedFields[row].splice(field, 1)
    },
  },
  computed: {
    modalTitle(): string {
      if (this.isUpdateTemplate) return 'Modify Template'
      const title = !this.isSelectTemplate
        ? 'Create Template From Scratch'
        : 'Select Template'
      return title
    },
    firstButton(): string {
      return this.isSelectTemplate ? 'Create From Scratch' : 'Select Template'
    },
    filteredFormFields(): TemplateInterface[] {
      console.log({
        name: this.selectedTemplate?.name,
        testy: this.formFields[this.selectedTemplate?.name],
      })

      return this.formFields[this.selectedTemplate?.name]?.filter((field) => {
        const isExist = this.selectedFields.some((selectedField) =>
          selectedField.some((selected) => selected.label === field.label),
        )
        return !isExist
      })
    },
    filteredFormFieldsMostUsed(): TemplateInterface[] {
      return this.formFieldsMostUsed[
        this.selectedTemplate?.name as string
      ]?.filter((field) => {
        const isExist = this.selectedFields.some((selectedField) =>
          selectedField.some((selected) => selected.label === field.label),
        )
        return !isExist
      })
    },
    fieldsTitle(): string {
      return this.filteredFormFields.length > 0
        ? 'All Fields'
        : 'All Fields are selected, none to view'
    },
    isFieldSelected(): (field: TemplateInterface) => boolean {
      return (field: TemplateInterface): boolean => {
        return !this.selectedFields.some((selectedField) =>
          selectedField.some((selected) => selected.label === field.label),
        )
      }
    },
    isFieldSelectedText(): (field: TemplateInterface) => string {
      return (field: TemplateInterface): string => {
        return this.selectedFields.some((selectedField) =>
          selectedField.some((selected) => selected.label === field.label),
        )
          ? 'Selected'
          : ''
      }
    },
  },
  watch: {
    isAddContactTemp: {
      handler(data) {
        this.isAddContactTempBool = data
        if (!data) {
          this.selectedFields = []
          this.templateForm = {} as SectionsPresenter
        }
      },
    },
    selectedTemplate: {
      handler(data) {
        if (data?.name) {
          this.templateForm = data
          this.selectedFields = data.form_columns
        }
      },
      immediate: true,
    },
  },
  components: {
    DynamicContactForm,
    draggable,
  },
})
