import { LeadPresenter } from '@/presenters/LeadPresenter'
import { defineComponent } from 'vue'

export default defineComponent({
  name: '',
  props: {
    lead: {
      type: Object as () => LeadPresenter,
      required: true,
    },
  },
  emits: [],
  data() {
    return {}
  },
  methods: {},
  mounted() {
    console.log({ lead: this.lead })
  },
})
