import { addExpenseFormInterface } from '@/Interfaces/Services'
import {
  deepCopy,
  getOptionCurrency,
  getOptionFullName,
  getOptionName,
  getOptionTruncateName50,
  validateInput,
  validateInputEmail,
  validateSelect,
} from '@/lib/utils'
import { CompanyPresenter } from '@/presenters/CompanyPresenter'
import { CompanyUserPresenter } from '@/presenters/CompanyUserPresenter'
import { IndividualContactPresenter } from '@/presenters/contactPresenter'
import { OrganizationContactPresenter } from '@/presenters/organizationPresenter'
import { TeamMemberPresenter } from '@/presenters/teamMember'
import { defineComponent } from 'vue'
import { useForm } from 'vuestic-ui/web-components'

export default defineComponent({
  name: 'AddExpenseFormInterface',
  props: {
    addExpenseLoading: {
      type: Boolean,
      default: false,
    },
    isAddExpense: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const { isValid, validate, reset, resetValidation } = useForm('form')

    const baseForm = {
      category: 'Administrative Law',
      due_date: new Date(),
      status: 'not_started',
      expense_type: 'utilities',
      quantity: 1,
      total_tax: 0,
      unit_price: 0,
      declared_amount: 0,
      total_amount: 0,
    } as addExpenseFormInterface

    return {
      validateInput,
      validateInputEmail,
      validateSelect,
      getOptionName,
      getOptionFullName,
      getOptionCurrency,
      getOptionTruncateName50,
      deepCopy,

      isAddExpenseBool: this.isAddExpense,
      isValid,
      validate,
      reset,
      resetValidation,
      files: [],
      baseForm,
      form: deepCopy(baseForm) as addExpenseFormInterface,

      secondaryCurrencyAmount: null as string | number | null,

      debounceTimeout: null as ReturnType<typeof setTimeout> | null, // Holds the debounce timeout ID
    }
  },
  methods: {
    handleToggleAddModal() {
      this.$emit('handleToggleAddModal')
      this.form = deepCopy(this.baseForm)
    },
    async handleCreateExpense() {
      const { currency, secondary_currency, paid_by, related_to, ...form } =
        this.form

      this.$emit('handleCreateExpense', {
        ...form,
        paid_by_id: paid_by?.id,
        paid_by_type: paid_by?.class_name,
        currency_id: currency?.id,
        secondary_currency_id: secondary_currency?.id,
        related_to_id: related_to?.id,
        related_to_type: related_to?.class_name,
        template_id: 4849, // fixed for now
      })
    },

    ///////////////////////////////////////////////////////////////////////
    calculateDeclaredAmount() {
      const unitPrice = this.form.unit_price || 0
      const quantity = this.form.quantity || 0
      this.form.declared_amount = unitPrice * quantity
    },

    calculateTaxAndTotalAmount() {
      const declaredAmount = this.form.declared_amount || 0
      const taxPercentage = this.form.total_tax || 0
      const calculatedTax = (declaredAmount * taxPercentage) / 100
      this.form.total_amount = declaredAmount + calculatedTax

      // Recalculate secondary currency amount
      this.calculateSecondaryCurrencyAmount()
    },

    ////////////////////////////// Currencies ///////////////////////////////////

    calculateSecondaryCurrencyAmount() {
      if (
        this.form.currency &&
        this.form.secondary_currency &&
        this.form.currency.conversion_rate_against_us_dollars &&
        this.form.secondary_currency.conversion_rate_against_us_dollars
      ) {
        const amountInUSD =
          this.form.total_amount /
          Number(this.form.currency.conversion_rate_against_us_dollars)

        this.secondaryCurrencyAmount = (
          amountInUSD *
          Number(
            this.form.secondary_currency.conversion_rate_against_us_dollars,
          )
        ).toFixed(4)
      } else {
        this.secondaryCurrencyAmount = null
      }
    },

    ////////////////////////////////// Clients /////////////////////////////////////

    handleSearchClients(search: string) {
      this.$stores.contacts.handleSearchContacts(search)
      this.$stores.contacts.handleSearchOrganizations(search)
    },
  },
  computed: {
    clients(): Array<
      IndividualContactPresenter | OrganizationContactPresenter
    > {
      return [
        ...this.$stores.contacts.organizations,
        ...this.$stores.contacts.contacts,
      ]
    },
    paidByOptions(): Array<
      TeamMemberPresenter | CompanyUserPresenter | CompanyPresenter
    > {
      return [
        this.$stores.auth.company.company,
        ...this.$stores.usersData.teamMembers,
      ]
    },
  },
  watch: {
    'form.unit_price': 'calculateDeclaredAmount',
    'form.quantity': 'calculateDeclaredAmount',
    'form.declared_amount': 'calculateTaxAndTotalAmount',
    'form.total_tax': 'calculateTaxAndTotalAmount',
    'form.currency': {
      handler() {
        this.calculateSecondaryCurrencyAmount()
      },
      deep: true,
    },
    'form.secondary_currency': {
      handler() {
        this.calculateSecondaryCurrencyAmount()
      },
      deep: true,
    },
    form: {
      handler(newForm) {
        console.log('Form updated:', newForm)
      },
      deep: true,
    },
    isAddExpense: {
      handler(data) {
        this.isAddExpenseBool = data
        if (data) {
          this.$stores.contacts.handleGetContacts()
          this.$stores.contacts.handleGetOrganizations()
          this.$stores.matter.handleGetMatters()

          this.$stores.usersData.handleGetMembers()
          this.$stores.currency.handleGetCompanyCurrencies()
        }
      },
    },
    addExpenseLoading: {
      handler(data) {
        !data && this.handleToggleAddModal()
      },
    },
  },
})
