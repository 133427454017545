import { RolePresenter } from '@/presenters/RolePresenter'
import {
  addRole,
  deleteRole,
  getRoleById,
  getRoles,
  updateRole,
} from '@/services/roles'
import { defineComponent } from 'vue'
import AddUpdateRoleModal from '@/components/AddUpdateRoleModal/index.vue'
import {
  AddUpdateRoleInterface,
  PermissionInterface,
  RoleResourceType,
} from '@/Interfaces/Services'

export default defineComponent({
  name: 'RolesPermissions',
  data() {
    return {
      roles: [] as RolePresenter[],
      selectedRole: {} as RolePresenter,

      permissions: {
        Task: { read: false, create: false, delete: false, update: false },
        Company: { read: false, create: false, delete: false, update: false },
        Contact: { read: false, create: false, delete: false, update: false },
        Currency: { read: false, create: false, delete: false, update: false },
        Expense: { read: false, create: false, delete: false, update: false },
        Hearing: { read: false, create: false, delete: false, update: false },
        Invoice: { read: false, create: false, delete: false, update: false },
        LegalMatter: {
          read: false,
          create: false,
          delete: false,
          update: false,
        },
        Milestone: { read: false, create: false, delete: false, update: false },
        Role: { read: false, create: false, delete: false, update: false },
        Section: { read: false, create: false, delete: false, update: false },
        StorageCategory: {
          read: false,
          create: false,
          delete: false,
          update: false,
        },
        StorageFolder: {
          read: false,
          create: false,
          delete: false,
          update: false,
        },
        StorageFile: {
          read: false,
          create: false,
          delete: false,
          update: false,
        },
        CompanyUser: {
          read: false,
          create: false,
          delete: false,
          update: false,
        },
        CompanyConfiguration: {
          read: false,
          create: false,
          delete: false,
          update: false,
        },
      } as Record<string, Record<string, boolean>>,

      addRoleLoading: false,
      updateRoleLoading: false,
      rolesLoading: false,
      roleLoading: false,

      isRoleModalOpen: false,

      isDeleteRoleModalOpen: false,
      selectedRoleId: null as null | number,
      isDeleteRoleLoading: false,

      isUpdateRole: false,
    }
  },
  methods: {
    handleToggleModal(bool?: boolean) {
      this.isRoleModalOpen =
        typeof bool === 'boolean' ? bool : !this.isRoleModalOpen

      if (!this.isRoleModalOpen) {
        this.isUpdateRole = false
        this.selectedRole = {} as RolePresenter
      }
    },
    handleToggleDeleteRoleModal(id: number) {
      this.selectedRoleId = id
      this.isDeleteRoleModalOpen = true
    },
    ///////////////////////////// roles /////////////////////////////

    async handleGetRoles() {
      this.rolesLoading = true

      const response = await getRoles()

      if (response.success) {
        this.roles = response.data.list
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }

      this.rolesLoading = false
    },

    async handleGetRole(role: RolePresenter) {
      this.isUpdateRole = true
      this.handleToggleModal()

      this.roleLoading = true

      const response = await getRoleById({ id: role.id })

      if (response.success) {
        this.selectedRole = response.data
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }

      this.roleLoading = false
    },

    async handleAddRole(body: AddUpdateRoleInterface) {
      this.addRoleLoading = true

      const response = await addRole({ body })

      if (response.success) {
        this.handleGetRoles()
        this.handleToggleModal(false)
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }

      this.addRoleLoading = false
    },

    async handleUpdateRole(body: AddUpdateRoleInterface) {
      this.updateRoleLoading = true

      const response = await updateRole({ id: Number(body.id), body })

      if (response.success) {
        this.handleGetRoles()
        this.handleToggleModal(false)
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }

      this.updateRoleLoading = false
    },

    async handleDuplicateRole(role: RolePresenter) {
      this.selectedRoleId = role.id

      this.roleLoading = true

      const response = await getRoleById({ id: role.id })

      if (response.success) {
        this.selectedRole = response.data

        const { name, role_permissions } = this.selectedRole

        const role_permissions_attributes: PermissionInterface[] =
          role_permissions?.map((item) => ({
            resource_type: item.resource_type as RoleResourceType,
            actions: item.actions,
          }))

        this.handleAddRole({
          name: `${name}-copy`,
          role_permissions_attributes,
        })
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }

      this.selectedRole = {} as RolePresenter
      this.roleLoading = false
    },

    async handleDeleteRole() {
      this.isDeleteRoleLoading = true

      const response = await deleteRole({
        id: Number(this.selectedRoleId),
      })

      if (response.success) {
        this.handleGetRoles()
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }

      this.isDeleteRoleLoading = false
    },
  },

  mounted() {
    this.handleGetRoles()
  },
  components: {
    AddUpdateRoleModal,
  },
})
