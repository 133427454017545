<template>
  <VaModal
    v-model="isAddNewOption"
    size="auto"
    hide-default-actions
    fixed-layout
  >
    <template #header>
      <div class="form-modal-header">
        <h1>Add {{ selectedField?.label }} Option</h1>
        <VaIcon
          class="header-icon press-button"
          name="close"
          size="30px"
          @click="handleToggleAddOption"
        />
      </div>
    </template>

    <VaForm
      v-if="selectedField.configuration_type"
      ref="optionForm"
      class="modal-container"
      @submit.prevent="handleToggleAddOption"
    >
      <VaInput
        v-model="optionForm.name"
        label="Option Name"
        placeholder="Enter option name"
        outlined
        background="#ECF0F1"
        class="input"
        required-mark
      />
    </VaForm>
    <VaForm
      v-else
      ref="contactForm"
      class="modal-container"
      @submit.prevent="handleToggleAddOption"
    >
      <VaInput
        v-model="optionForm.individual_first_name"
        label="First Name"
        placeholder="First Name"
        outlined
        background="#ECF0F1"
        class="input"
        required-mark
      />
      <VaInput
        v-model="optionForm.individual_last_name"
        label="Last Name"
        placeholder="Last Name"
        outlined
        background="#ECF0F1"
        class="input"
        required-mark
      />
    </VaForm>

    <template #footer>
      <div class="form-modal-buttons">
        <VaButton
          preset="secondary"
          border-color="primary"
          @click="handleToggleAddOption"
        >
          Cancel
        </VaButton>
        <VaButton
          :loading="
            $stores.contacts.addContactLoading ||
            $stores.generalData.isAddGlobalConfigLoading
          "
          @click="handleAddNewOption"
        >
          Save
        </VaButton>
      </div>
    </template>
  </VaModal>

  <VaForm ref="form" class="form-column" @submit.prevent="handleSubmit">
    <div
      v-for="(row, rowIndex) in fields"
      :key="`row-${rowIndex}`"
      class="form-row"
      :style="`--columns: ${Math.min(fields.length, 4)}`"
    >
      <div
        v-for="(field, index) in row"
        :key="index"
        class="form-field"
        :style="{ flex: field.flex || '1' }"
      >
        <!-- Scoped Slot for Custom Field Rendering -->
        <template v-if="$slots.field">
          <slot name="field" :field="field" :model="formData" />
        </template>

        <template v-else>
          <div v-if="field.type === 'text'">
            <VaInput
              v-model="formData[field.name]"
              :label="field.label"
              :placeholder="field.placeholder"
              :required-mark="field.required"
              :rules="[() => validateField(field)]"
              background="#ECF0F1"
              outlined
              class="input"
              clearable
            />
          </div>

          <div v-else-if="field.type === 'select'">
            <VaSelect
              ref="select"
              v-model="formData[field.name]"
              :label="field.label"
              :placeholder="field.placeholder"
              :options="handleOptions(field.options)"
              :text-by="(e) => handleTextBy(field.textBy, e)"
              :value-by="field.valueBy"
              :required-mark="field.required"
              background="#ECF0F1"
              outlined
              class="input"
              :multiple="field.multiple"
              :rules="[() => validateField(field)]"
              :searchable="field.searchable"
              :highlight-matched-text="false"
              :loading="getLoadingState(field.options)"
              allow-create
              :no-options-text="noOptionsText(field.options)"
              @update:search="handleSearch(field.options, $event)"
              @create-new="handleNewOption(field, $event)"
              @update:isOpen="onOpen"
              track-by="id"
              clearable
              @click="field.searchable && focusInputForSelect"
            />
          </div>

          <div v-else-if="field.type === 'textarea'" class="va-textarea">
            <VaTextarea
              v-model="formData[field.name]"
              :label="field.label"
              :placeholder="field.placeholder"
              :required-mark="field.required"
              autosize
              background="#ECF0F1"
              outlined
              max-rows="7"
              class="input"
              style="height: 10rem"
              :rules="[() => validateField(field)]"
            />
          </div>

          <div v-else-if="field.type === 'radio'">
            <p class="radio-label">
              {{ field.label }}
            </p>
            <VaRadio
              class="radio-label-input"
              v-model="formData[field.name]"
              :options="field.options"
              value-by="value"
            />
          </div>

          <div v-else-if="field.type === 'checklist'">
            <div :aria-labelledby="field.label">
              <VaCheckbox
                v-for="(option, idx) in Array.isArray(field.options)
                  ? field.options
                  : []"
                :key="idx"
                v-model="formData[field.name]"
                :label="option.label"
                :true-value="option.value"
                :false-value="null"
                class="input"
              />
            </div>
          </div>

          <div v-else-if="field.type === 'date'">
            <VaDateInput
              v-model="formData[field.name]"
              :label="field.label"
              :placeholder="field.placeholder"
              :required-mark="field.required"
              :rules="[() => validateField(field)]"
              background="#ECF0F1"
              outlined
              class="input"
              manual-input
              @update:modelValue="handleDateChange($event, field.name)"
            />
          </div>

          <!-- add the email  -->
          <!-- add the number -->
        </template>
      </div>
    </div>
  </VaForm>
</template>

<script lang="ts" src="./script.ts" />
<style lang="scss" scoped src="./styles.scss" />
