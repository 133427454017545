import { TeamMemberPresenter } from '../teamMember'

export class TeamPresenter {
  id!: number | undefined
  name!: string
  image!: File
  jurisdictions!: string[]
  description!: string | null
  parentWorkspace!: string | null
  parentTeam!: string | null
  manager!: TeamMemberPresenter
  members!: TeamMemberPresenter[]

  constructor(data: TeamPresenter) {
    Object.assign(this, data)

    if (data?.manager) {
      this.manager = new TeamMemberPresenter(data.manager)
    }

    if (data?.members) {
      this.members = data.members.map((item) => new TeamMemberPresenter(item))
    }
  }
}
