export const peopleTemplateHeaders = [
  {
    title: '#',
    align: 'start',
    key: 'img',
    sortable: false,
  },
  {
    title: 'First Name',
    align: 'start',
    key: 'firstName',
    sortable: true,
  },
  {
    title: 'Middle Name',
    align: 'start',
    key: 'middleName',
    sortable: false,
  },
  {
    title: 'Last Name',
    align: 'start',
    key: 'lastName',
    sortable: true,
  },
  {
    title: 'Title',
    align: 'start',
    key: 'title',
    sortable: false,
  },
  {
    title: 'Email',
    align: 'start',
    key: 'email_addresses',
    sortable: false,
  },
  {
    title: 'Phone Number',
    align: 'start',
    key: 'phone_numbers',
    sortable: false,
  },
  {
    title: 'Nationality',
    align: 'start',
    key: 'nationality',
    sortable: false,
  },
  {
    title: 'Relationship Owner',
    align: 'start',
    key: 'relationship_owner',
    sortable: false,
  },
  {
    title: 'Assigned To',
    align: 'start',
    key: 'assigned_to',
    sortable: false,
  },
  {
    title: 'Referred By',
    align: 'start',
    key: 'referred_by',
    sortable: false,
  },
  {
    title: 'Google Maps Link',
    align: 'start',
    key: 'google_maps_url',
    sortable: false,
  },
  {
    title: 'Tax Identification Number',
    align: 'start',
    key: 'taxIdentificationNumber',
    sortable: false,
  },
  {
    title: 'Personal Website',
    align: 'start',
    key: 'website_url',
    sortable: false,
  },
  {
    title: 'Organization',
    align: 'start',
    key: 'organization',
    sortable: false,
  },
  {
    title: 'KYC Complete',
    align: 'start',
    key: 'kyc_completed',
    sortable: false,
  },
  {
    title: 'KYC Note',
    align: 'start',
    key: 'kyc_notes',
    sortable: false,
  },
  { title: 'Note', align: 'start', key: 'notes', sortable: false },
  {
    title: 'opt',
    align: 'center',
    key: 'action',
    sortable: false,
    width: '3rem',
  },
]
