<template>
  <VaModal
    v-model="isAddContactTempBool"
    size="auto"
    hide-default-actions
    fixed-layout
    no-outside-dismiss
    @update:modelValue="handleToggleAddModalTemp"
    class="split-modal-form"
  >
    <template #header>
      <div class="modal-form-header">
        <h1>{{ modalTitle }}</h1>
        <VaIcon
          class="press-button"
          name="close"
          size="30px"
          @click="handleToggleAddModalTemp"
        />
      </div>
    </template>
    <div class="split-modal-body-wrapper">
      <div class="split-modal-scrollable-section left">
        <div v-if="isSelectTemplate" class="select-template">
          <div class="search">
            <VaInput
              placeholder="Search"
              icon="search"
              @update:modelValue="searchContactData"
            >
              <template #appendInner>
                <VaIcon name="search" color="secondary" />
                <VaIcon name="search" color="secondary" />
              </template>
            </VaInput>
          </div>

          <div
            v-for="temps in templatesFound"
            :key="temps.id"
            class="template-container"
          >
            <p>{{ temps?.name }}</p>
            <div class="view-templates">
              <div
                v-for="temp in temps.temps"
                :key="temp.id"
                class="template-card-container"
              >
                <div class="template-card press-button">
                  <div class="content">
                    <div class="line" />
                    <div class="line" />
                    <div class="line" />
                    <div class="subline" />
                  </div>
                </div>
                <p>{{ temp?.name }}</p>
              </div>
            </div>
          </div>
        </div>

        <div class="create-template" v-else>
          <VaInput
            background="#ECF0F1"
            v-model="templateForm.name"
            label="Template Name"
            placeholder="Name"
            requiredMark
            :rules="[validateInput]"
            :disabled="isUpdateTemplate"
          />
          <VaDivider />
          <p class="required-msg">
            Check the required fields*
            <VaCheckbox disabled v-model="vaCheckbox" />
          </p>
          <!-- <h1 class="create-template-title">Info</h1> -->
          <div class="all-fields">
            <draggable
              v-model="selectedFields"
              item-key="id"
              group="sections"
              class="draggable-sections"
            >
              <template #item="{ element, index }">
                <div class="AF-row">
                  <div class="AF-row">
                    <VaIcon
                      name="drag_handle"
                      size="1.5rem"
                      class="press-button"
                    />

                    <div
                      v-for="(field, subIndex) in element"
                      :key="subIndex"
                      class="AF-map"
                    >
                      <font-awesome-icon
                        :icon="field?.icon"
                        color="#52709b"
                        width="0.5rem"
                      />
                      <div>
                        <p class="AF-name">
                          <VaCheckbox v-model="field.required" />
                          {{ field?.label }}
                        </p>
                      </div>
                      <font-awesome-icon
                        icon="trash-can"
                        size="sm"
                        class="press-button"
                        color="#66023c"
                        @click="handleRemoveField(index, subIndex)"
                      />
                    </div>
                  </div>
                  <font-awesome-icon
                    icon="circle-plus"
                    size="lg"
                    class="press-button"
                    color="#66023c"
                    @click="handleToggleSelectFields(index)"
                  />
                  <font-awesome-icon
                    icon="trash-can"
                    size="lg"
                    class="press-button"
                    color="#66023c"
                    @click="handleRemoveRowField(index)"
                  />
                </div>
              </template>
            </draggable>
          </div>
          <div class="form-modal-buttons">
            <VaButton
              color="#ECF0F1"
              size="small"
              icon-right="add"
              @click="handleToggleSelectFields()"
            >
              Add Field
            </VaButton>
          </div>
        </div>
      </div>

      <VaDivider vertical />

      <div class="split-modal-scrollable-section right">
        <DynamicContactForm :fields="selectedFields" :initial-values="form" />
      </div>
    </div>

    <template #footer>
      <div class="form-modal-buttons">
        <VaButton
          preset="secondary"
          border-color="primary"
          @click="handleToggleSelectTemplate"
          disabled
        >
          {{ firstButton }}
        </VaButton>
        <VaButton
          @click="handleSubmitTemplate"
          :loading="addContactTempLoading"
        >
          Update Contact
        </VaButton>
      </div>
    </template>
  </VaModal>

  <!-- Nested modals using vuestic should be inside the same file -->
  <VaModal
    v-model="isSelectFields"
    size="30rem"
    hide-default-actions
    fixed-layout
    no-outside-dismiss
    @update:modelValue="handleToggleSelectFields()"
  >
    <template #header>
      <div class="modal-form-header">
        <h1>Add Field</h1>
        <VaIcon
          class="press-button"
          name="close"
          size="30px"
          @click="handleToggleSelectFields()"
        />
      </div>
      <div class="search">
        <VaInput
          placeholder="Search"
          icon="search"
          @update:modelValue="searchContactData"
        >
          <template #appendInner>
            <VaIcon name="search" color="secondary" />
          </template>
        </VaInput>
      </div>
    </template>

    <div class="add-field-container">
      <h1 v-if="filteredFormFieldsMostUsed?.length > 0" class="title">
        Most Used
      </h1>
      <div class="most-used-fields">
        <div
          v-for="(field, index) in filteredFormFieldsMostUsed"
          :key="index"
          class="MUF-map"
          @click="isFieldSelected(field) && handleAddField(field)"
        >
          <font-awesome-icon
            :icon="field.icon"
            color="#52709b"
            width="0.5rem"
          />
          <div>
            <p class="MUF-name">
              {{ field.label }} <span>{{ isFieldSelectedText(field) }}</span>
            </p>
            <p class="MUF-details">
              {{ field.details }}
            </p>
          </div>
        </div>
      </div>

      <h1 class="title">{{ fieldsTitle }}</h1>
      <div class="all-fields">
        <div
          v-for="(field, index) in filteredFormFields"
          :key="index"
          class="AF-map"
          @click="handleAddField(field)"
        >
          <font-awesome-icon
            :icon="field.icon"
            color="#52709b"
            width="0.5rem"
          />
          <div>
            <p class="AF-name">
              {{ field.label }}
            </p>
            <p class="AF-details">
              {{ field.details }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </VaModal>
</template>
<script lang="ts" src="./script.ts" />
<style lang="scss" scoped src="./styles.scss" />
