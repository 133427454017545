import { defineComponent } from 'vue'
import ComingSoon from '@/components/ComingSoon/index.vue'
import SharedHeader from '@/components/sharedHeader/index.vue'
import SideDrawer from '@/components/SideDrawers/SideDrawer/index.vue'

export default defineComponent({
  name: 'HomePage',

  components: {
    ComingSoon,
    SharedHeader,
    SideDrawer,
  },
  data() {
    return {
      invoiceText: '',
    }
  },

  mounted() {
    // console.log(this.$stores.auth.token)
  },
  methods: {
    submitInvoice() {
      console.log('Submitted Invoice Text:\n', {
        invoiceText: this.invoiceText,
      })
    },
  },
  computed: {
    formattedInvoiceText(): string {
      return this.invoiceText.replace(/\n/g, '<br>')
    },
  },
})
