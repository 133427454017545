import { paginationInterface } from '@/Interfaces/Services'
import { formatDate, getMonthAndYearFromDate } from '@/lib/utils'
import { MatterPresenter } from '@/presenters/matterPresenter'
import { defineComponent } from 'vue'

const MoreVertIcon = new URL(
  '@/assets/icons/More-vert-icon.svg',
  import.meta.url,
).href

export default defineComponent({
  name: 'MatterTable',
  props: {
    matters: {
      type: Array as () => MatterPresenter[],
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    itemsPerPage: {
      type: Number,
      default: 10,
    },
    totalItems: {
      type: Number,
      default: 10,
    },
    unLink: {
      type: Boolean,
      default: false,
    },
    headers: {
      type: Array,
      required: true,
    },
  },
  emits: ['selectedRows', 'loadMore', 'unLinkMatter'],
  data() {
    return {
      formatDate,
      getMonthAndYearFromDate,
      localTotalItems: this.totalItems,
      selectedRows: [],
      MoreVertIcon,
      deleteDialog: false,
      deleteItem: null,
      currentPage: 1,
      currentSortBy: [] as { key: string; order?: 'asc' | 'desc' }[],
      currentItemsPerPage: this.itemsPerPage,
    }
  },
  watch: {
    selectedRows() {
      this.$emit('selectedRows', this.selectedRows)
    },
    totalItems(newValue) {
      this.localTotalItems = newValue
    },
  },
  methods: {
    viewProfile(item: { id: number }) {
      this.$router.push(`/legal-matters/${item?.id}`)
    },
    handleUnLink(item: MatterPresenter) {
      this.$emit('unLinkMatter', item)
    },
    ////////////////////////////////////////////////////////////
    handleLoadMore({ page, itemsPerPage, sortBy }: paginationInterface) {
      const { currentPage, currentItemsPerPage, currentSortBy } = this

      // Check if there are any changes in pagination or sorting
      const hasPaginationChanged =
        page !== currentPage || itemsPerPage !== currentItemsPerPage
      const hasSortingChanged =
        JSON.stringify(sortBy) !== JSON.stringify(currentSortBy) // Compare sorting objects

      // Only emit if there's a change in pagination or sorting
      if (hasPaginationChanged || hasSortingChanged) {
        this.$emit('loadMore', { page, itemsPerPage, sortBy })

        // Update state after the change
        this.currentPage = page
        this.currentItemsPerPage = itemsPerPage
        this.currentSortBy = sortBy
      }
    },
    ////////////////////////////////////////////////////////////////////////
  },
})
