import { TeamMemberPresenter } from '@/presenters/teamMember'
import { setHeaders } from '@/services/API'
import { defineComponent } from 'vue'

const BATImage = new URL('@/assets/images/BAT.png', import.meta.url).href

const collapsedWorkspacesImage = new URL(
  '@/assets/icons/close-workspaces-icon.svg',
  import.meta.url,
).href
const expandedWorkspacesImage = new URL(
  '@/assets/icons/open-workspaces-icon.svg',
  import.meta.url,
).href
const collapseRightImage = new URL(
  '@/assets/icons/collapse-right-icon.svg',
  import.meta.url,
).href
const expandedLeftImage = new URL(
  '@/assets/icons/collapse-left-icon.svg',
  import.meta.url,
).href

type ViewInterface = {
  name: string
  path: string
  icon: string
  view?: { [key: string]: boolean }
}[]

export default defineComponent({
  name: 'SideBar',
  data() {
    const { user, company } = this.$stores.auth

    const isTLAFirm =
      this.$stores.auth.company?.company?.name.toUpperCase() ===
      'TRIBONIAN LAW ADVISORS'

    const roleId = localStorage.getItem('RoleId') as string

    return {
      user,
      company,
      isTLAFirm,
      roleId,
      BATImage,

      minimized: false,
      showWorkSpaces: false,

      collapsedWorkspacesImage,
      expandedWorkspacesImage,
      collapseRightImage,
      expandedLeftImage,
    }
  },
  computed: {
    currentWorkspaceImage(): string {
      return this.showWorkSpaces
        ? this.collapsedWorkspacesImage
        : this.expandedWorkspacesImage
    },
    currentSideBarImage(): string {
      const locale = localStorage.getItem('locale') || 'en'
      if (locale === 'ar') {
        if (this.minimized) {
          return this.expandedLeftImage
        }
        return this.collapseRightImage
      }
      if (this.minimized) {
        return this.collapseRightImage
      }
      return this.expandedLeftImage
    },
    views(): ViewInterface {
      const permissions = this.$stores.auth.company?.roles_extended || {}

      return [
        {
          name: `${this.$t('views.home')}`,
          path: '/',
          icon: 'home',
          active: true,
          view: true,
        },
        this.roleId !== '20' && {
          name: `${this.$t('views.firm')}`,
          path: '/firm',
          icon: 'business',
          view: true,
        },

        this.roleId === '20' && {
          name: 'Bar Association',
          path: '/firm',
          icon: 'business',
        },
        this.roleId === '20' && {
          name: 'Bar Services',
          path: '/home',
          icon: 'wifi_1_bar',
        },
        this.roleId === '20' && {
          name: 'Bar Exams',
          path: '/home',
          icon: 'edit',
        },
        this.roleId === '20' && {
          name: 'Public Announcements',
          path: '/home',
          icon: 'campaign',
        },
        this.roleId === '20' && {
          name: 'Inventory',
          path: '/home',
          icon: 'inventory_2',
        },
        this.roleId === '20' && {
          name: 'Insurance',
          path: '/home',
          icon: 'health_and_safety',
        },
        {
          name: 'Leads',
          path: '/leads',
          icon: 'contact_page',
          view: permissions['Lead']?.read ?? false,
          // view: true,
        },
        {
          name: `${this.$t('views.contacts')}`,
          path: '/contacts',
          icon: 'contacts',
          view: permissions['Contact']?.read ?? false,
        },
        this.roleId !== '20' && {
          name: `${this.$t('views.legalMatters')}`,
          path: '/legal-matters',
          icon: 'inventory_2',
          view: permissions['LegalMatter']?.read ?? false,
        },
        {
          name: `${this.$t('views.legalLibrary')}`,
          path: '/legal-library',
          icon: 'menu_book',
          view: true,
        },
        {
          name: `${this.$t('views.legalMarket')}`,
          path: '/legal-market',
          icon: 'storefront',
          view: true,
        },

        {
          name: `${this.$t('views.hearings')}`,
          path: '/hearings',
          icon: 'gavel',
          view: permissions['Hearing']?.read ?? false,
        },

        {
          name: `${this.$t('views.tasks')}`,
          path: '/tasks',
          icon: 'assignment',
          view: permissions['Task']?.read ?? false,
        },
        {
          name: `${this.$t('views.calendar')}`,
          path: '/calendar',
          icon: 'calendar_month',
          view: true,
        },
        {
          name: `${this.$t('views.files')}`,
          path: '/files',
          icon: 'folder',
          view: permissions['StorageCategory']?.read ?? false,
        },
        {
          name: `${this.$t('views.financial')}`,
          path: '/financial',
          icon: 'attach_money',
          view: permissions['Invoice']?.read ?? false,
        },
        {
          name: `${this.$t('views.emails')}`,
          path: '/emails',
          icon: 'mail',
          view: true,
        },
        {
          name: `${this.$t('views.recycleBin')}`,
          path: '/recycleBin',
          icon: 'delete',
          view: true,
        },
      ].filter(Boolean) as ViewInterface
    },
  },
  emits: ['toggleSidebar'],
  methods: {
    handleLogOut() {
      localStorage.clear()

      this.$stores.auth.user = {} as TeamMemberPresenter

      console.log('Logged out successfully')
      // this.$router.push({ name: 'LoginView' })
      window.location.reload()
    },
    handleSideBarToggle() {
      this.minimized = !this.minimized
      this.$emit('toggleSidebar', this.minimized)
    },

    //////////////////////////////// workspaces /////////////////////////

    handleChangeWorkspace(company: any) {
      localStorage.setItem('haqqCompany', JSON.stringify(company))
      this.$stores.auth.$patch((state) => {
        state.company = company
      })

      localStorage.setItem('RoleId', company?.id)
      setHeaders({ RoleId: company?.id })

      window.location.reload()
    },
  },
})
