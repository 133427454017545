<template>
  <VaModal
    v-model="isAddTaskFormBool"
    size="auto"
    hide-default-actions
    fixed-layout
    no-outside-dismiss
    @update:modelValue="handleToggleAddTaskModal"
  >
    <template #header>
      <div class="add-task-form-modal-header">
        <h1>Add a Task</h1>
        <div>
          <VaSelect
            v-model="subSection"
            background="#ECF0F1"
            :options="subSections"
            :text-by="getOptionName"
            placeholder="Select Stage"
          />
        </div>
        <VaIcon
          name="close"
          size="30px"
          class="icon"
          @click="handleToggleAddTaskModal"
        />
      </div>
    </template>
    <VaForm
      ref="form"
      class="add-task-form-modal"
      @submit.prevent="validate() && handleCreateTask()"
    >
      <div class="add-task-container">
        <VaInput
          v-model="form.name"
          placeholder="Title"
          background="#ECF0F1"
          style="width: 100%"
          label="title"
          required-mark
          :rules="[validateInput]"
        />

        <div class="icon-container">
          <VaSelect
            v-model="form.tag"
            background="#ECF0F1"
            placeholder="Select Multiple"
            style="width: 50%; height: 2rem; margin-bottom: 1.5rem"
            label="tag"
            :options="['Hot', 'Important', 'Cool', 'Icebox']"
            :highlight-matched-text="false"
            :rules="[validateSelect]"
            multiple
            selected-top-shown
            :max-visible-options="2"
          />

          <div class="icon-sub-container">
            <v-tooltip text="Time Range" location="top">
              <template #activator="{ props }">
                <TimeRange
                  v-bind="props"
                  :date="time_range"
                  shape="button-icon"
                  @update:date="handleSelectedRangeDate"
                />
              </template>
            </v-tooltip>

            <v-tooltip text="Due Date" location="top">
              <template #activator="{ props }">
                <DatePickerModal
                  v-bind="props"
                  :date="due_time"
                  shape="button-icon"
                  @update:date="handleSelectedDate"
                />
              </template>
            </v-tooltip>

            <v-tooltip text="Attach a file" location="top">
              <template #activator="{ props }">
                <VaFileUpload v-model="file" v-bind="props">
                  <VaButton color="#ECF0F1">
                    <img :src="AttachFileIcon" alt="" />
                  </VaButton>
                </VaFileUpload>
              </template>
            </v-tooltip>

            <v-tooltip text="Priority" location="top">
              <template #activator="{ props }">
                <VaMenu v-bind="props">
                  <template #anchor>
                    <VaButton color="#ECF0F1">
                      <!-- <img :src="FlagIcon" alt="" style="color: red" /> -->
                      <VaIcon
                        name="flag"
                        size="medium"
                        :color="form.priority?.color"
                      />
                    </VaButton>
                  </template>
                  <VaMenuItem
                    v-for="(flag, index) in $stores.generalData.companyConfigs
                      .taskPriorityOptions"
                    :key="index"
                    @click="handleFlagMenuItemClick(flag)"
                  >
                    <VaIcon name="flag" :color="flag?.color" />
                    {{ flag?.name }}
                  </VaMenuItem>
                </VaMenu>
              </template>
            </v-tooltip>

            <VaMenu class="add-task-options">
              <template #anchor>
                <VaButton preset="secondary">
                  <img
                    :src="MoreVertIcon"
                    alt="more-vert"
                    class="press-button-2"
                  />
                </VaButton>
              </template>

              <VaMenuItem @click="alert"> Add Watcher </VaMenuItem>
              <VaMenuItem @click="alert"> Add Time Estimate </VaMenuItem>
              <h1 class="vmenu-p">Dependencies</h1>
              <VaMenuItem @click="alert"> Waiting on </VaMenuItem>
              <VaMenuItem @click="alert"> Blocking </VaMenuItem>
            </VaMenu>
          </div>
        </div>

        <div style="width: 100%">
          <VaSelect
            v-model="form.section_stage_id"
            placeholder="Status"
            background="#ECF0F1"
            style="width: 50%; height: 2rem; margin-bottom: 1.5rem"
            label="Status"
            :options="subSection?.section_stages"
            :highlight-matched-text="false"
            :rules="[validateSelect]"
            :text-by="getOptionName"
            value-by="id"
          />
        </div>

        <div class="select-checkbox-container">
          <div style="width: 70%">
            <VaSelect
              v-model="form.owner_id"
              placeholder="Task Owner"
              background="#ECF0F1"
              style="width: 100%; height: 2rem; margin-bottom: 1.5rem"
              label="Task Owner"
              clearable
              searchable
              :loading="$stores.usersData.membersLoading"
              :options="$stores.usersData.teamMembers"
              :highlight-matched-text="false"
              :text-by="getOptionFullName"
              value-by="id"
              allow-create
              :no-options-text="noOptionsText"
              @update:search="$stores.usersData.handleSearchMembers"
              @create-new="addNewOption"
            />
          </div>

          <VaCheckbox
            v-model="form.isOwner_counsel"
            disabled
            style="height: 0.5rem"
            class="mb-6"
            label="Of Counsel"
          />
        </div>

        <div class="select-checkbox-container">
          <div style="width: 70%">
            <VaSelect
              v-model="form.assigned_to_id"
              placeholder="Assigned To"
              clearable
              background="#ECF0F1"
              style="width: 100%; height: 2rem; margin-bottom: 1.5rem"
              label="assigned to"
              searchable
              :loading="$stores.usersData.membersLoading"
              :options="$stores.usersData.teamMembers"
              :highlight-matched-text="false"
              :text-by="getOptionFullName"
              value-by="id"
              @update:search="$stores.usersData.handleSearchMembers"
            />
          </div>

          <VaCheckbox
            v-model="form.isAssigned_counsel"
            disabled
            style="height: 0.5rem"
            class="mb-6"
            label="Of Counsel"
          />
        </div>

        <VaTextarea
          v-model="form.description"
          placeholder="Add description..."
          max-rows="11"
          label="Description"
          style="width: 100%; min-height: 10rem"
          autosize
          background="#ECF0F1"
        />

        <VaSelect
          v-model="link_to"
          placeholder="Link To Matter"
          clearable
          background="#ECF0F1"
          label="Select Matters"
          searchable
          :loading="$stores.matter.mattersLoading"
          :options="$stores.matter.matters"
          :highlight-matched-text="false"
          :text-by="getOptionName"
          multiple
          :max-visible-options="3"
          value-by="id"
          @update:modelValue="handleLinkMatters"
          @update:search="$stores.usersData.handleSearchMembers"
        >
          <template #prepend>
            <VaIcon name="link" class="mr-2" color="secondary" />
          </template>
        </VaSelect>

        <VaInput
          v-model="form.location"
          placeholder="Location"
          background="#ECF0F1"
          style="width: 100%"
          label="location"
        >
          <template #prepend>
            <VaIcon name="location_on" class="mr-2" color="secondary" />
          </template>
        </VaInput>

        <VaInput
          v-model="form.notes"
          placeholder="Note"
          background="#ECF0F1"
          style="width: 100%"
          label="note"
        >
          <template #prepend>
            <VaIcon name="event_note" class="mr-2" color="secondary" />
          </template>
        </VaInput>

        <VaInput
          v-model="form.budget"
          placeholder="Budget"
          background="#ECF0F1"
          style="width: 100%"
          label="budget"
        >
          <template #prepend>
            <VaIcon name="attach_money" class="mr-2" color="secondary" />
          </template>
        </VaInput>

        <VaSwitch v-model="form.billable" label="bill task" size="small" />
        <!-- <VaCheckbox
          v-model="form.pre_charged"
          class="mb-6"
          label="pre-charge task"
          size="small"
        /> -->

        <!-- <div v-if="form.pre_charged" class="pre-charged-container">
          <VaInput
            background="#ECF0F1"
            v-model="form.value"
            style="width: 70%"
            label="title"

            requiredMark
            :rules="[(value) => (value && value.length > 0) ]"
          />

          <VaSelect
            background="#ECF0F1"
            v-model="form.currency"
            style="width: 19%; height: 2rem; margin-bottom: 1.5rem"
            label="currency"

            requiredMark
            :options="['USD', 'LBP', 'EUR', 'USDT']"
            :highlight-matched-text="false"
            :rules="[validateSelect ]"
          />
        </div> -->
      </div>
    </VaForm>
    <template #footer>
      <div class="add-task-form-modal-buttons">
        <VaButton preset="secondary" border-color="primary" disabled>
          Templates
        </VaButton>
        <VaButton
          style="width: 79%"
          :disabled="addTaskLoading"
          @click="validate() && handleCreateTask()"
        >
          Save
        </VaButton>
      </div>
    </template>
  </VaModal>
</template>

<script lang="ts" src="./script.ts" />
<style lang="scss" scoped src="./styles.scss" />
