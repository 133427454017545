<template>
  <ActionsBar
    second-add-button="Add Member"
    @secondButtonAction="toggleAddMember"
  />
  <VaModal
    v-model="isAddMember"
    size="small"
    hide-default-actions
    fixed-layout
    no-outside-dismiss
  >
    <template #header>
      <div class="create-hearing-form-header">
        <h1>Add Member</h1>
        <VaIcon
          class="header-icon press-button"
          name="close"
          size="30px"
          @click="toggleAddMember"
        />
      </div>
    </template>
    <VaForm ref="form" class="create-hearing-form" @submit.prevent="validate">
      <div class="form-inputs">
        <VaInput
          v-model="form.title"
          placeholder="Title"
          background="#ECF0F1"
          label="Title"
        />
        <VaInput
          v-model="form.first_name"
          placeholder="First Name"
          background="#ECF0F1"
          label="First Name"
        />
      </div>
      <div class="form-inputs">
        <VaInput
          v-model="form.middle_name"
          placeholder="Middle Name"
          background="#ECF0F1"
          label="Middle Name"
        />
        <VaInput
          v-model="form.last_name"
          placeholder="Last Name"
          background="#ECF0F1"
          label="Last Name"
        />
      </div>

      <VaInput
        v-model="form.email"
        placeholder="Primary Email"
        background="#ECF0F1"
        :rules="[validateInput, validateInputEmail]"
        label="Primary Email"
        required-mark
      />

      <VaSelect
        label="Select Roles"
        placeholder="Roles"
        v-model="form.roles"
        :options="rolesOption"
        :text-by="getOptionName"
        :loading="rolesLoading"
        class="EHR-input"
        background="#ECF0F1"
        multiple
        searchable
        required-mark
        :rules="[validateSelect]"
        value-by="id"
      />
    </VaForm>
    <template #footer>
      <div class="add-hearing-form-modal-buttons">
        <VaButton
          preset="secondary"
          border-color="primary"
          @click="toggleAddMember"
        >
          Cancel
        </VaButton>
        <VaButton
          :loading="addMemberLoading"
          @click="validate() && handleAddMember()"
        >
          Save
        </VaButton>
      </div>
    </template>
  </VaModal>

  <VaModal
    v-model="isDeleteMember"
    message="Delete this member?"
    ok-text="Delete"
    size="auto"
    @ok="handleDeleteMember"
    @cancel="handleToggleDeleteMember"
  />

  <SearchAndViews @search="searchMembersData" :search="memberSearchKeyword" />
  <VaInnerLoading
    v-if="membersLoading"
    :loading="membersLoading"
    class="loading-flex"
  />
  <div class="scroll-container-wrapper">
    <div class="scroll-container">
      <div
        v-for="(user, index) in teamMembers"
        :key="index"
        class="members-list"
      >
        <div class="user-container">
          <!-- <img :src="user.user.avatar" alt="user avatar" /> -->
          <router-link :to="`/firm/members/${user?.id}`" :userData="user">
            <VaAvatar color="#ECF0F1" :src="user?.user?.avatar?.medium_url">
              <span v-if="!user?.user?.avatar?.medium_url">{{
                user.user.avatar_letter
              }}</span>
            </VaAvatar>
          </router-link>
          <div class="details-container">
            <router-link :to="`/firm/members/${user?.id}`" :userData="user">
              <h1>{{ user.user.fullName }}</h1>
              <p class="title">{{ user.role }}</p>
              <p>
                {{ user.user.email }}
              </p>
            </router-link>
          </div>
          <div class="action-option">
            <va-dropdown :offset="[0, -70]" close-on-content-click>
              <template #anchor>
                <img
                  :src="MoreVertIcon"
                  class="matter-action-option-img press-button-2"
                  alt="MoreVertIcon"
                />
              </template>
              <va-dropdown-content class="dropdown">
                <router-link :to="`/firm/members/${user?.id}`" :userData="user">
                  <p>View Profile</p>
                </router-link>
                <p>Assign Matter</p>
                <p>KPI & Targets</p>
                <p @click="handleToggleDeleteMember(user)">Delete</p>
              </va-dropdown-content>
            </va-dropdown>
          </div>
        </div>
        <VaDivider />
      </div>
    </div>
  </div>
  <div class="pagination-container">
    <VaPagination
      v-model="memberPage"
      :visible-pages="7"
      :total="memberTotalItems"
      :page-size="membersItemPerPage"
      boundary-numbers
      class="mb-3 justify-center sm:justify-start"
    >
      <template #prevPageLink="{ onClick, disabled }">
        <VaButton
          preset="primary"
          :disabled="disabled"
          aria-label="go prev page"
          @click="onClick"
        >
          Previous
        </VaButton>
      </template>
      <template #nextPageLink="{ onClick, disabled }">
        <VaButton
          preset="primary"
          :disabled="disabled"
          aria-label="go next page"
          @click="onClick"
        >
          Next
        </VaButton>
      </template>
    </VaPagination>
  </div>
</template>
<script lang="ts" src="./script.ts" />
<style lang="scss" scoped src="./styles.scss" />
