<template>
  <div class="firm-time-sheet-container">
    <div class="filter-search-container">
      <div class="filter">
        <Button
          button-text="Filter"
          :text-color="'#66023c'"
          :icon-before="filterIcon"
          :custom-styles="{
            padding: '10px',
            width: 'auto',
            borderRadius: '5px',
            fontSize: '12px',
            fontWeight: '500',
            backgroundColor: '#fff',
            border: '1px solid #66023c',
          }"
          :hover-styles="{
            scale: '1.02',
          }"
          @click="openFilterModal"
        />
      </div>
      <VaInput
        size="large"
        background="#ECF0F1"
        type="search"
        placeholder="Search..."
      >
        <template #appendInner>
          <VaIcon name="search" color="primary" size="2rem" />
        </template>
      </VaInput>
    </div>

    <div class="breadCrumb-view-container">
      <div class="view-icons">
        <div
          class="view-icon"
          :class="{ active: activeView === 'Work Load' }"
          @click="changeView('Work Load')"
        >
          <img :src="WorkLoadIcon" alt="Work Load Icon" />
        </div>
        <div
          class="view-icon"
          :class="{ active: activeView === 'List' }"
          @click="changeView('List')"
        >
          <img :src="ListIcon" alt="List Icon" />
        </div>
        <div
          class="view-icon"
          :class="{ active: activeView === 'Pie Chart' }"
          @click="changeView('Pie Chart')"
        >
          <img :src="PieChartIcon" alt="Pie Chart Icon" />
        </div>
      </div>
      <VaButton
        preset="secondary"
        class="ml-6"
        border-color="primary"
        icon-right="arrow_drop_down"
      >
        Today
      </VaButton>
    </div>

    <div v-if="activeView == 'Work Load'" class="work-load-view">Work Load</div>

    <div
      v-else-if="activeView == 'List'"
      class="scroll-container-wrapper for-tables"
    >
      <div class="tasks-table-container">
        <v-data-table-server
          :loading="false"
          :headers="headers"
          :items="list"
          show-select
          class="custom-table"
          :items-length="list?.length"
          items-per-page="10"
          :fixed-header="true"
          :fixed-footer="true"
        >
          <template #[`item.img`]="{ item }">
            <VaAvatar
              color="#ECF0F1"
              :src="item.client_avatar"
              :alt="item.title"
              size="small"
            >
              <span v-if="!item.client_avatar" style="color: black">{{
                item.title?.charAt(0)
              }}</span>
            </VaAvatar>
          </template>

          <!-- Title and Task Details -->
          <template #[`item.task_title`]="{ item }">
            <div>
              <v-icon left color="primary"> mdi-clipboard-text </v-icon>
              <strong>{{ item.task_title }}</strong>
            </div>
          </template>

          <!-- Client Info with Avatar -->
          <template #[`item.client`]="{ item }">
            <div class="d-flex align-center">
              <v-avatar class="mr-3" size="40">
                <img
                  :src="item.client_avatar"
                  alt="client avatar"
                  width="100%"
                />
              </v-avatar>
              <div>
                <strong>{{ item.client_name }}</strong>
                <div class="text--secondary">
                  {{ item.client_title }}
                </div>
              </div>
            </div>
          </template>

          <!-- Billable Amount -->
          <template #[`item.billable`]="{ item }">
            <div class="text-right">
              <strong>{{ item.billable }}</strong>
              <div class="text--secondary">
                {{ item.currency }}
              </div>
            </div>
          </template>

          <template #[`item.task_description`]="{ item }">
            <div class="task-description">
              <p>{{ item.task_description }}</p>
            </div>
          </template>

          <template #[`item.action`]="{}">
            <td class="table-action-td">
              <va-dropdown :offset="[0, -70]" close-on-content-click>
                <template #anchor>
                  <img
                    :src="MoreVertIcon"
                    alt="MoreVertIcon"
                    class="press-button-2"
                  />
                </template>
                <va-dropdown-content class="dropdown">
                  <p><va-icon name="edit" /> Edit</p>
                  <p><va-icon name="delete" /> Delete</p>
                  <p><va-icon name="person" /> View Profile</p>
                </va-dropdown-content>
              </va-dropdown>
            </td>
          </template>

          <!-- Expanded Sub-row (Sub-rows) -->
          <template #expanded-item="{ item }">
            <tr>
              <td colspan="5">
                <v-card outlined class="sub-row-card">
                  <v-card-title>Additional Details</v-card-title>
                  <v-card-text>
                    <p><strong>Task ID:</strong> {{ item.task_id }}</p>
                    <p>
                      <strong>Client Email:</strong> {{ item.client_email }}
                    </p>
                    <p>
                      <strong>Project Deadline:</strong> {{ item.deadline }}
                    </p>
                    <!-- Add more detailed information for the sub-row here -->
                  </v-card-text>
                </v-card>
              </td>
            </tr>
          </template>
        </v-data-table-server>
      </div>
    </div>

    <div v-else-if="activeView == 'Pie Chart'" class="pie-chart-view">
      Pie Chart

      <v-data-table
        :group-by="groupBy"
        :headers="headers"
        :items="list"
        item-value="title"
      >
        <template #group-header="{ item, columns, toggleGroup, isGroupOpen }">
          <tr>
            <td :colspan="columns.length">
              <VBtn
                :icon="isGroupOpen(item) ? '$expand' : '$next'"
                size="small"
                variant="text"
                @click="toggleGroup(item)"
              />
              {{ item.value }}
            </td>
          </tr>
        </template>
        <template #[`item.img`]="{ item }">
          <VaAvatar
            color="#ECF0F1"
            :src="item.client_avatar"
            :alt="item.title"
            size="small"
          >
            <span v-if="!item.client_avatar" style="color: black">{{
              item.title.charAt(0)
            }}</span>
          </VaAvatar>
        </template>

        <!-- Title and Task Details -->
        <template #[`item.task_title`]="{ item }">
          <div>
            <v-icon left color="primary"> mdi-clipboard-text </v-icon>
            <strong>{{ item.task_title }}</strong>
          </div>
        </template>

        <!-- Client Info with Avatar -->
        <template #[`item.client`]="{ item }">
          <div class="d-flex align-center">
            <v-avatar class="mr-3" size="40">
              <img :src="item.client_avatar" alt="client avatar" width="100%" />
            </v-avatar>
            <div>
              <strong>{{ item.client_name }}</strong>
              <div class="text--secondary">
                {{ item.client_title }}
              </div>
            </div>
          </div>
        </template>

        <!-- Billable Amount -->
        <template #[`item.billable`]="{ item }">
          <div class="text-right">
            <strong>{{ item.billable }}</strong>
            <div class="text--secondary">
              {{ item.currency }}
            </div>
          </div>
        </template>

        <template #[`item.task_description`]="{ item }">
          <div class="task-description">
            <p>{{ item.task_description }}</p>
          </div>
        </template>

        <template #[`item.action`]="{}">
          <td class="task-action-td">
            <va-dropdown :offset="[0, -70]" close-on-content-click>
              <template #anchor>
                <img
                  :src="MoreVertIcon"
                  alt="MoreVertIcon"
                  class="press-button-2"
                />
              </template>
              <va-dropdown-content class="dropdown">
                <p><va-icon name="edit" /> Edit</p>
                <p><va-icon name="delete" /> Delete</p>
                <p><va-icon name="person" /> View Profile</p>
              </va-dropdown-content>
            </va-dropdown>
          </td>
        </template>

        <!-- Expanded Sub-row (Sub-rows) -->
        <template #expanded-item="{ item }">
          <tr>
            <td colspan="5">
              <v-card outlined class="sub-row-card">
                <v-card-title>Additional Details</v-card-title>
                <v-card-text>
                  <p><strong>Task ID:</strong> {{ item.task_id }}</p>
                  <p><strong>Client Email:</strong> {{ item.client_email }}</p>
                  <p><strong>Project Deadline:</strong> {{ item.deadline }}</p>
                  <!-- Add more detailed information for the sub-row here -->
                </v-card-text>
              </v-card>
            </td>
          </tr>
        </template>
      </v-data-table>
    </div>
  </div>
</template>
<script lang="ts" src="./script.ts" />
<style lang="scss" scoped src="./styles.scss" />

<!-- <div class="office offices">
        <div class="left-side">
          <div class="office">
            <h2>Company</h2>
            <p>All offices
              <v-menu v-model="showWorkSpaces" :close-on-content-click="false" location="bottom center">
                <template v-slot:activator="{ props }">
                  <div class="img-container">
                    <img v-bind="props" :src="currentWorkspaceImage" alt="Logo" class="open-offices-btn" />
                  </div>
                </template>

                <v-card>
                  <div class="workspaces-list">
                    <p>VIEW WORKPLACES</p>
                    <VaDivider />
                    <ul>
                      <li v-for="n in 5" :key="n" @click="showWorkSpaces = false">
                        <div class="workspace">
                          <img :src="`https://i.pravatar.cc/${n}00`" alt="Logo" />
                          <div class="workspace-data">
                            <h2>Workspace Name</h2>
                            <p>Swiss Verein</p>
                          </div>
                        </div>
                        <span class="online-members">69 0f 120 members online</span>
                      </li>
                    </ul>

                  </div>
                </v-card>
              </v-menu>
            </p>
            <p>5 offices • 128 members</p>
          </div>
          <div class="billable">
            <p>Billable</p>
            <span>
              <p>On firm</p>
              <h1>CUR<b>$,739.14</b></h1>
            </span>
          </div>
        </div>
        <div class="right-side">

          <div class="duration">
            <p>Duration</p>
            <p>25:25:46</p>
            <p>00:00 - Now</p>
          </div>

          <VaButton v-if="!breadCrumbChildView" style="visibility: hidden;"> See More </VaButton>
          <va-dropdown :offset="[0, -70]">
            <template #anchor>
              <img :src="MoreVertIcon" alt="MoreVertIcon" class="press-button-2" />
            </template>
            <VaDropdownContent>
              <p class="option">Un-Monitor User</p>
              <p class="option">Report</p>
            </VaDropdownContent>
          </va-dropdown>
          <img v-if="!breadCrumbParentView" style="visibility: hidden;" :src="DownIcon" alt="chevron down"
            class="view-member-btn" />
        </div>
      </div>

      <div class="bread-crumb-parent" v-if="breadCrumbParentView">
        <div class="office-container" v-for="(name, index) in offices" :key="index">
          <div class="office">
            <div class="left-side">
              <div class="office">
                <h2>Company</h2>
                <p class="office-name">{{ name }} </p>
                <p>5 offices • 128 members</p>
              </div>
              <div class="billable">
                <p>Billable</p>
                <span>
                  <p>On firm</p>
                  <h1>CUR<b>$,739.14</b></h1>
                </span>
              </div>
            </div>
            <div class="right-side">

              <div class="duration">
                <p>Duration</p>
                <p>25:25:46</p>
                <p>00:00 - Now</p>
              </div>

              <VaButton @click="changeCompanyPath(name)"> See More </VaButton>
              <va-dropdown :offset="[0, -70]">
                <template #anchor>
                  <img :src="MoreVertIcon" alt="MoreVertIcon" class="press-button-2" />
                </template>
                <VaDropdownContent>
                  <p class="option">Un-Monitor User</p>
                  <p class="option">Report</p>
                </VaDropdownContent>
              </va-dropdown>
            </div>
          </div>
          <VaDivider inset />
        </div>
      </div>

      <div class="bread-crumb-child" v-if="breadCrumbChildView">

        <div class="members-container" v-for="(member, index) in members" :key="index"
          :class="{ 'selected-member': activeIndices.includes(index) }">
          <div class="members">
            <div class="left-side">


              <div class="member-container">
                <img :src="member.img" alt="user">
                <div class="member-info">
                  <h1>{{ member.name }}</h1>
                  <p>Position </p>
                  <div>
                    <p>Company Name</p>
                    <VaIcon color="primary" size="22px" name="groups" />
                    <p>Team member: <br /> <b>Admin</b></p>
                  </div>

                </div>

              </div>


              <div :class="{ 'hide': activeIndices.includes(index) }" class="billable">
                <p>Billable</p>
                <span>
                  <p>On firm</p>
                  <h1>CUR<b>$,739.14</b></h1>
                </span>
              </div>
            </div>
            <div class="right-side">

              <div :class="{ 'hide': !activeIndices.includes(index) }">
                <VaButton preset="secondary" border-color="primary" @click="console.log({ name: member.name })"> See
                  More
                </VaButton>
              </div>


              <div class="duration" :class="{ 'hide': activeIndices.includes(index) }">
                <p>Duration</p>
                <p>25:25:46</p>
                <p>00:00 - Now</p>
              </div>

              <va-dropdown :offset="[0, -70]">
                <template #anchor>
                  <img :src="MoreVertIcon" alt="MoreVertIcon" class="press-button-2" />
                </template>
                <VaDropdownContent>
                  <p class="option">Un-Monitor User</p>
                  <p class="option">Report</p>
                </VaDropdownContent>
              </va-dropdown>

              <img :src="DownIcon" alt="chevron down" class="view-member-btn" @click="viewMemberDetails(index)"
                :class="{ 'rotated': activeIndices.includes(index) }" />

            </div>
          </div>
          <div class="accordion-content-wrapper" :class="{ 'selected-member': activeIndices.includes(index) }"
            ref="contentWrappers">
            <div class="accordion-content">

              <div class="left-side">
                <p class="title">
                  <img :src="ListViewIcon" alt="List View" />
                  Task
                </p>
                <h1>
                  Title : Lorum ipsum dolor sit amet
                </h1>
                <p>Description: Lorem ipsum dolor sit amet consectetur. Molestie erat gravida nullam vel lorem.
                  Condimentum
                  laoreet tristique proin accumsan amet gravida fusce.</p>
                <p class="sub-title">
                  Client
                </p>
                <div class="client-info">
                  <VaAvatar color="#ECF0F1" color="#D9D9D9"> FL </VaAvatar>
                  <h1>First Last Name <br />
                    <p>title</p>
                  </h1>
                </div>
                <p>legal Matter</p>
              </div>

              <div class="right-side">

                <p class="title">
                  Date & Time
                </p>
                <p>May 6, 2024 • 12:34pm</p>
                <p class="title">
                  Duration
                </p>
                <div class="duration">
                  <p>25:25:46</p>
                  <p>00:00 - Now</p>
                </div>
                <p class="title">
                  Billable
                </p>
                <div class="billable">
                  <span>
                    <p>On firm</p>
                    <h1>CUR<b>$,739.14</b></h1>
                  </span>
                </div>
              </div>

            </div>
          </div>
          <VaDivider inset />
        </div>



        <div class="team-container" :class="{ 'selected-member': isTeamOpen }">
          <div class="team">
            <div class="left-side">
              <div class="team-container">
                <h1>Legislation Team</h1>
              </div>

              <div class="billable">
                <p>Billable</p>
                <span>
                  <p>On firm</p>
                  <h1>CUR<b>$,739.14</b></h1>
                </span>
              </div>
            </div>

            <div class="right-side">

              <div class="duration">
                <p>Duration</p>
                <p>25:25:46</p>
                <p>00:00 - Now</p>
              </div>

              <va-dropdown :offset="[0, -70]">
                <template #anchor>
                  <img :src="MoreVertIcon" alt="MoreVertIcon" class="press-button-2" />
                </template>
                <VaDropdownContent>
                  <p class="option">Un-Monitor User</p>
                  <p class="option">Report</p>
                </VaDropdownContent>
              </va-dropdown>

              <img :src="DownIcon" alt="chevron down" class="view-team-btn" @click="toggleTeam"
                :class="{ 'rotated': isTeamOpen }" />
            </div>
          </div>

          <div class="accordion-content-wrapper" ref="contentWrapper">
            <VaDivider inset color="#fff" />
            <div class="team-accordion-content">
              <div class="members-container selected-member" v-for="(member, index) in members" :key="index">
                <div class="members">
                  <div class="left-side">
                    <div class="member-container">
                      <img :src="member.img" alt="user">
                      <div class="member-info">
                        <h1>{{ member.name }}</h1>
                        <p>Position </p>
                        <div>
                          <p>Company Name</p>
                          <VaIcon color="primary" size="22px" name="groups" />
                          <p>Team member: <br /> <b>Admin</b></p>
                        </div>

                      </div>

                    </div>



                  </div>
                  <div class="right-side">

                    <div >
                      <VaButton preset="secondary" border-color="primary" @click="console.log({ name: member.name })">
                        See
                        More
                      </VaButton>
                    </div>


                    <va-dropdown :offset="[0, -70]">
                      <template #anchor>
                        <img :src="MoreVertIcon" alt="MoreVertIcon" class="press-button-2" />
                      </template>
                      <VaDropdownContent>
                        <p class="option">Un-Monitor User</p>
                        <p class="option">Report</p>
                      </VaDropdownContent>
                    </va-dropdown>
                  </div>
                </div>

                <div class="accordion-content">
                   <div class="left-side">
                  <p class="title">
                    <img :src="ListViewIcon" alt="List View" />
                    Task
                  </p>
                  <h1>
                    Title : Lorum ipsum dolor sit amet
                  </h1>
                  <p>Description: Lorem ipsum dolor sit amet consectetur. Molestie erat gravida nullam vel lorem.
                    Condimentum
                    laoreet tristique proin accumsan amet gravida fusce.</p>
                  <p class="sub-title">
                    Client
                  </p>
                  <div class="client-info">
                    <VaAvatar color="#ECF0F1" color="#D9D9D9"> FL </VaAvatar>
                    <h1>First Last Name <br />
                      <p>title</p>
                    </h1>
                  </div>
                  <p>legal Matter</p>
                </div>

                <div class="right-side">

                  <p class="title">
                    Date & Time
                  </p>
                  <p>May 6, 2024 • 12:34pm</p>
                  <p class="title">
                    Duration
                  </p>
                  <div class="duration">
                    <p>25:25:46</p>
                    <p>00:00 - Now</p>
                  </div>
                  <p class="title">
                    Billable
                  </p>
                  <div class="billable">
                    <span>
                      <p>On firm</p>
                      <h1>CUR<b>$,739.14</b></h1>
                    </span>
                  </div>
                </div></div>


                <VaDivider inset color="#fff" />
              </div>
              <VaDivider inset />
            </div>
          </div>
        </div>

      </div> -->
