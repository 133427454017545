import { defineComponent } from 'vue'
import { useForm } from 'vuestic-ui/web-components'
import TimeRange from '@/components/TimeRange/index.vue'
import DatePickerModal from '@/components/DatePickerModal/index.vue'
import { camelCaseToNormal, getOptionFullName } from '@/lib/utils'
import { TargetPresenter } from '@/presenters/TargetPresenter'
import { calendarGlobalSearchInterface } from '@/Interfaces/Store'
import { calendar_item_linked_items_attributesInterface } from '@/Interfaces/layout'
import TimeLinkModal from '@/components/TimeRecordModal/TimeLinkModal/index.vue'

const MoreVertIcon = new URL(
  '@/assets/icons/More-vert-icon.svg',
  import.meta.url,
).href
const AttachFileIcon = new URL(
  '@/assets/icons/attach-file-icon.svg',
  import.meta.url,
).href

export default defineComponent({
  name: 'AddTargetForm',
  props: {
    addTargetLoading: {
      type: Boolean,
      default: false,
    },
    isAddTargetFormOpen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const alert = (...args: any) => console.log(args)
    const { isValid, validate, reset, resetValidation } = useForm('form')
    return {
      camelCaseToNormal,
      getOptionFullName,
      alert,

      isAddTargetFormOpenBool: this.isAddTargetFormOpen,

      isValid,
      validate,
      reset,
      resetValidation,

      MoreVertIcon,
      AttachFileIcon,

      flagMenuOpen: false,
      loadingUpdate: false,

      form: {} as TargetPresenter,

      file: [],
      time_range: {},
      due_time: '',

      debounceTimeout: null as ReturnType<typeof setTimeout> | null,

      activeType:
        this.$stores.generalData.companyConfigs.calendarTypesOption?.[0].id,

      isItemAssignUpdate: false,
    }
  },
  emits: ['handleToggleAddTargetModal', 'handleCreateTarget'],
  methods: {
    selectType(id: number) {
      this.activeType = id
      this.form.target_type = id.toString()
    },
    ////////////////////////////////////////////////////////////////////////

    handleToggleAssignToMenu() {
      this.isItemAssignUpdate = !this.isItemAssignUpdate
    },
    handleToggleAddTargetModal() {
      this.$emit('handleToggleAddTargetModal')
      this.reset()
    },
    handleCreateTarget() {
      this.handleToggleAddTargetModal()
      this.$emit('handleCreateTarget', {
        newTarget: { ...this.form },
      })
    },
    handleSelectedRangeDate(date: { start: string; end: string }) {
      this.form.start_date = date.start
      this.form.end_date = date.end
    },
    handleSelectedDate(date: { date: string }) {
      this.form.start_date = date.date
    },
    handleFlagMenuItemClick(flag: any) {
      this.form.priority = flag
    },
    /////////////////////////////////////////////////////////////////////
    async handleGetMembers(search?: string) {
      this.$stores.usersData.handleGetMembers({
        keyword: search,
      })
    },
    handleSearchMembers(search: string) {
      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout)
      }

      this.debounceTimeout = setTimeout(() => {
        this.handleGetMembers(search)
      }, 500)
    },

    handleLinkToUpdate(data: {
      selectedItems: calendarGlobalSearchInterface[]
      calendar_item_linked_items_attributes: calendar_item_linked_items_attributesInterface[]
    }) {
      this.form.target = data.selectedItems
      // this.form.target_attributes = data.calendar_item_linked_items_attributes
    },
  },
  watch: {
    isAddTargetFormOpen: {
      handler(data) {
        this.isAddTargetFormOpenBool = data
        if (data) {
          this.handleGetMembers()
        }
      },
    },
  },
  components: {
    TimeRange,
    DatePickerModal,
    TimeLinkModal,
  },
})
