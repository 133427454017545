import {
  CompanyUserRolesInterface,
  RolePermissionsInterface,
} from '../interface'
import { TeamMemberPresenter } from '../teamMember'

export class RolePresenter {
  id!: number
  class_name!: string
  created_at!: string
  created_by_user!: TeamMemberPresenter
  name!: string
  updated_at!: string
  company_user_roles!: CompanyUserRolesInterface[]
  company_user_roles_ids!: number[]
  role_permissions!: RolePermissionsInterface[]

  constructor(data: RolePresenter) {
    Object.assign(this, data)

    if (data.created_by_user) {
      this.created_by_user = new TeamMemberPresenter(data.created_by_user)
    }

    if (data.company_user_roles) {
      this.company_user_roles_ids = this.company_user_roles.map(
        (item) => item.id,
      )
    }
  }
}
