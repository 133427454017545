import { selectedOptionType } from '@/Interfaces/SettingsInterfaces'
import { validateInput, validateColorInput, deepCopy } from '@/lib/utils'
import { CompanyConfigurationPresenter } from '@/presenters/companyConfigPresenters'
import { addCompanyConfigType } from '@/services/companyConfigurations'
import { library } from '@fortawesome/fontawesome-svg-core'
import { defineComponent, PropType } from 'vue'
import { useForm } from 'vuestic-ui/web-components'

export default defineComponent({
  name: 'GlobalConfigModal',
  props: {
    isGlobalConfig: {
      type: Boolean,
      default: false,
    },
    selectedOption: {
      type: Object as PropType<selectedOptionType>,
      required: true,
    },
    globalConfig: {
      type: Array as PropType<CompanyConfigurationPresenter[]> | undefined,
      required: true,
    },
    iconName: {
      type: String,
      required: true,
    },
    showConfirmConfigId: {
      type: Number || null,
      required: true,
    },
    loadingConfigId: {
      type: Number || null,
      required: true,
    },
    newOptionForm: {
      type: Object as PropType<addCompanyConfigType>,
      required: true,
    },
    isAddGlobalConfigLoading: {
      type: Boolean,
      required: true,
    },
    isUpdateGlobalConfigLoading: {
      type: Boolean,
      required: true,
    },
  },
  emits: [
    'toggleGlobalConfig',
    'toggleConfirm',
    'confirmDelete',
    'cancelDelete',
    'handleAddGlobalConfig',
    'handleUpdateGlobalConfig',
  ],
  data() {
    const { validate, reset } = useForm('form')
    const { validate: updateValidate, reset: updateReset } =
      useForm('updateForm')

    const colorView = [
      'legal_matter_status',
      'hearing_status',
      'task_priority',
      'milestone_status',
      'calendar_item_type',
    ]

    const iconView = ['calendar_item_type']

    return {
      validate,
      reset,
      validateInput,
      validateColorInput,
      updateValidate,
      updateReset,

      iconView,
      colorView,
      globalConfigSearch: '',

      isGlobalConfigBool: this.isGlobalConfig,

      isSelectIcon: false,
      iconSearchKeyword: '',
      selectedIcon: 'house',
      icons: [], // it contains 1966 icon
      filteredIcons: [],

      isGlobalConfigUpdate: false,
      selectedConfig: {} as CompanyConfigurationPresenter,
      isSelectIconUpdate: false,
    }
  },
  computed: {
    isViewColorInput(): boolean {
      return this.colorView.includes(
        this.newOptionForm.configuration_type as string,
      )
    },
    isViewIconSelect(): boolean {
      return this.iconView.includes(
        this.newOptionForm.configuration_type as string,
      )
    },
    filteredGlobalConfig(): CompanyConfigurationPresenter[] {
      if (!this.globalConfig || this.globalConfigSearch === '') {
        return this.globalConfig || []
      }
      return this.globalConfig.filter((config) => {
        const name = config.name ? config.name.toLowerCase() : ''
        return name.includes(this.globalConfigSearch.toLowerCase())
      })
    },
  },
  watch: {
    isGlobalConfig: {
      handler(data) {
        this.isGlobalConfigBool = data
      },
    },
    isSelectIcon: {
      handler() {
        this.iconSearchKeyword = ''
        this.handleIconSearch()
      },
    },
    isSelectIconUpdate: {
      handler() {
        this.iconSearchKeyword = ''
        this.handleIconSearch()
      },
    },
    isUpdateGlobalConfigLoading: {
      handler(data) {
        if (!data) {
          this.toggleEdit()
        }
      },
    },
  },
  created() {
    const definitions = (library as any).definitions?.fas
    if (definitions) {
      this.icons = Object.keys(definitions) as any
      this.filteredIcons = [...this.icons]
    } else {
      console.error('No icons found in the Font Awesome library.')
    }
  },
  methods: {
    toggleGlobalConfig() {
      this.isGlobalConfigBool = !this.isGlobalConfigBool
      this.reset()
      this.$emit('toggleGlobalConfig')
    },

    handleSearchGlobalConfig(event: InputEvent) {
      this.globalConfigSearch = (event.target as HTMLInputElement).value
    },

    toggleConfirm(id: number) {
      this.$emit('toggleConfirm', id)
    },
    confirmDelete(id: number) {
      this.$emit('confirmDelete', id)
    },
    cancelDelete(id: number) {
      this.$emit('cancelDelete', id)
    },
    handleAddGlobalConfig() {
      if (this.validate()) {
        this.$emit(
          'handleAddGlobalConfig',
          this.isViewIconSelect && this.selectedIcon,
        )
      }
    },

    handleUpdateGlobalConfig() {
      if (this.updateValidate()) {
        const { data, ...selectedConfig } = this.selectedConfig
        this.$emit('handleUpdateGlobalConfig', selectedConfig)
      }
    },

    handleCheckDeletable(config: CompanyConfigurationPresenter) {
      if (config?.company_id === this.$stores.auth.company.company_id) {
        return true
      } else false
    },

    ////////////////////////////////// icon //////////////////////////////

    handleIconSearch() {
      const keyword = this.iconSearchKeyword.toLowerCase()
      this.filteredIcons = this.icons.filter((icon: string) =>
        icon.toLowerCase().includes(keyword),
      )
    },

    handleSelectIcon(icon: string, update?: boolean) {
      if (update) {
        this.selectedConfig.icon_name = icon
        this.isSelectIconUpdate = false
      } else {
        this.selectedIcon = icon
        this.isSelectIcon = false
      }
    },

    ///////////////////////////////// update ///////////////////////////////

    toggleEdit(config?: CompanyConfigurationPresenter) {
      this.selectedConfig = config
        ? deepCopy(config)
        : ({} as CompanyConfigurationPresenter)
      this.isGlobalConfigUpdate = !this.isGlobalConfigUpdate
      this.isSelectIconUpdate = false
    },
  },
})
