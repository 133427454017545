import { contactFormFields } from '../Templates/contactTemplate'

const contactFallbackData = [
  [
    {
      name: 'individual_title',
    },
  ],

  [
    {
      name: 'individual_first_name',
      required: true,
    },
  ],

  [
    {
      name: 'individual_middle_name',
    },
  ],

  [
    {
      name: 'individual_last_name',
      required: true,
    },
  ],

  [
    {
      name: 'individual_suffix',
    },
  ],

  [
    {
      name: 'individual_job_title',
    },
  ],

  [
    {
      name: 'individual_tax_identification_number',
    },
  ],

  [
    {
      name: 'individual_date_of_birth',
    },
  ],

  [
    {
      name: 'individual_nationality',
    },
  ],

  [
    {
      name: 'individual_gender',
    },
  ],

  [
    {
      name: 'website_url',
    },
  ],

  [
    {
      name: 'organization_contact',
    },
  ],

  [
    {
      name: 'relationship_owner',
    },
  ],

  [
    {
      name: 'assigned_to',
    },
  ],

  [
    {
      name: 'referred_by',
    },
  ],

  [
    {
      name: 'notes',
    },
  ],
]

export const contactFallbackForm = contactFallbackData.map((rowFields) => {
  return rowFields.map((field: { name: string | undefined }) => {
    const fieldData = contactFormFields.find((f) => f.name === field.name)

    return {
      ...fieldData,
      ...field,
    }
  })
})
