<template>
  <InvoiceTemplateModal
    :isTemplateModalOpen="isTemplateModalOpen"
    :isUpdateTemplate="isUpdateTemplate"
    :isAddTemplateLoading="isAddTemplateLoading"
    :isUpdateTemplateLoading="isUpdateTemplateLoading"
    :selectedTemplate="selectedTemplate"
    @handleToggleModal="handleToggleTemplateModal"
    @handleAddTemplate="handleAddTemplate"
    @handleUpdateTemplate="handleUpdateTemplate"
  />

  <VaModal
    v-model="isDeleteTemplateModalOpen"
    message="Do you want to delete this template?"
    ok-text="Delete"
    size="auto"
    @ok="handleDeleteTemplate"
  />

  <div class="templates-container">
    <div class="title-row">
      <h1 class="header">Invoice Templates</h1>
      <!-- <VaButton icon-right="add" @click="handleToggleTemplateModal()">
        Add Template
      </VaButton> -->
    </div>

    <div
      v-for="(template, index) in invoiceTemplates"
      :key="index"
      class="button-row"
    >
      <VaButton
        class="options"
        preset="secondary"
        border-color="primary"
        @click="handleToggleTemplateModal(template)"
      >
        {{ template.name }}
      </VaButton>

      <v-tooltip text="Delete" location="top">
        <template v-slot:activator="{ props }">
          <VaButton v-bind="props" preset="secondary" border-color="primary">
            <VaIcon
              class="press-button"
              name="delete_forever"
              :spin="
                template.id === selectedTemplate.id && isDeleteTemplateLoading
              "
              @click="handleToggleDeleteTemplateModal(template)"
            />
          </VaButton>
        </template>
      </v-tooltip>

      <v-tooltip text="Duplicate" location="top">
        <template v-slot:activator="{ props }">
          <VaButton v-bind="props" preset="secondary" border-color="primary">
            <VaIcon
              class="press-button"
              name="content_copy"
              :spin="
                template.id === selectedTemplate.id && isAddTemplateLoading
              "
            />
          </VaButton>
        </template>
      </v-tooltip>
    </div>
  </div>
</template>

<script lang="ts" src="./script.ts" />
<style lang="scss" scoped src="./styles.scss" />
