<template>
  <VaModal
    v-model="isAddMembersModalOpenBool"
    size="small"
    hide-default-actions
    fixed-layout
    noOutsideDismiss
    :before-close="beforeAddMembersModalClose"
  >
    <template #header>
      <div class="add-members-form-header">
        <h1>Add Member(s)</h1>
        <VaIcon
          class="header-icon"
          name="close"
          size="30px"
          @click="handleCancel"
        />
      </div>
      <div>
        You can send also an email to recipient.
        <span class="click-here-text">Click here</span>
      </div>
    </template>
    <VaForm ref="form" class="add-members-form" @submit.prevent="validate">
      <VaSelect
        placeholder="Team"
        background="#ECF0F1"
        v-model="form.team"
        label="Team"
        searchable
        :options="teamSearchOptions"
        :text-by="getOptionFullName"
        :highlight-matched-text="false"
      />

      <VaSelect
        placeholder="Members"
        background="#ECF0F1"
        v-model="form.members"
        label="Choose the members to add"
        searchable
        :options="$stores.usersData.teamMembers"
        :text-by="getOptionFullName"
        :highlight-matched-text="false"
        multiple
      />
      <VaTextarea
        v-model="form.note"
        max-rows="7"
        background="#ECF0F1"
        label="Note"
        placeholder="Add your note here..."
        autosize
        class="text-area"
      />
    </VaForm>
    <template #footer>
      <div class="add-members-form-modal-buttons">
        <VaButton
          preset="secondary"
          border-color="primary"
          @click="handleCancel"
          >Cancel</VaButton
        >
        <VaButton
          @click="validate() && handleAddMembers()"
          :loading="addMembersLoading"
          >Add Member(s)</VaButton
        >
      </div>
    </template>
  </VaModal>
</template>
<script lang="ts" src="./script.ts" />
<style lang="scss" scoped src="./styles.scss" />
