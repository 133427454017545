<template>
  <SharedHeader title-width="30rem" title="Legal Matters">
    <div v-if="activeTab !== 'All Matters'" class="sub-SharedHeader">
      <va-dropdown :offset="[0, -70]" close-on-content-click>
        <template #anchor>
          <img :src="MoreVertIcon" alt="MoreVertIcon" class="press-button-2" />
        </template>
        <va-dropdown-content class="dropdown">
          <p @click="handleToggleUpdateLayoutModal">Edit Page Layout</p>
        </va-dropdown-content>
      </va-dropdown>
    </div>
  </SharedHeader>

  <AddMatterModal
    :add-matter-loading="addMatterLoading"
    :is-add-matter="isAddMatter"
    :sub-sections="subSections"
    :section="section"
    @handleToggleAddModal="toggleAddMatter"
    @handleCreateMatter="handleCreateMatter"
  />

  <UpdateLayoutColumns
    :update-layout-loading="isUpdateLayoutLoading"
    :is-update-layout-form="isShowUpdateLayoutModal"
    :section_stages="stages"
    :is-delete-layout-loading="isDeleteLayoutLoading"
    @handleToggleUpdateLayoutModal="handleToggleUpdateLayoutModal"
    @handleUpdateStageData="handleUpdateLayout"
    @onDragEnd="handleUpdateLayout"
    @handleDeleteLayout="handleDeleteLayout"
  />

  <TabsBar
    :tabs="sections"
    :active-tab-id="activeTabId"
    :loading="isSectionsLoading"
    @changeTab="handleTabChange"
  />
  <ActionsBar
    v-if="!isSectionsLoading"
    :second-add-button="$t('actionsBar.addMatter')"
    @secondButtonAction="toggleAddMatter"
  />

  <!-- <div> -->
  <AllMatters
    v-if="activeTabId === 1717171"
    :sections="sections.filter((sec) => sec.id != 1717171)"
    :headers="headers"
    :refetchAllMattersTrigger="refetchAllMattersTrigger"
  />
  <!-- </div> -->

  <div class="scroll-container-wrapper" v-else>
    <!-- <DraggableColumns
        :isLegalMatters="true"
        :groups="groups"
        :list="list"
        :isSelect="isSelect"
        :isLoading="false"
      /> -->
    <KanbanInfiniteLoad
      v-if="!isSectionsLoading"
      :is-legal-matters="true"
      :stages="stages"
      :is-cards-loading="isMattersLoading"
      :is-columns-loading="isStagesLoading"
      @ticketMoved="handleTicketMoved"
      @handleAddStage="handleToggleUpdateLayoutModal"
      @handleAddItem="toggleAddMatter"
    />
  </div>
</template>

<script lang="ts" src="./script.ts"></script>
<style lang="scss" scoped src="./styles.scss"></style>
