import { defineComponent } from 'vue'
import AllMatters from './AllMatters/index.vue'
import AddMatterModal from '@/components/LegalMatter/AddMatterModal/index.vue'
import TabsBar from '@/components/tabsBar/index.vue'
import ActionsBar from '@/components/actionsBar/index.vue'
import SharedHeader from '@/components/sharedHeader/index.vue'
import DraggableColumns from '@/components/DraggableColumns/index.vue'
import KanbanInfiniteLoad from '@/components/KanbanInfiniteLoad/index.vue'
import ComingSoon from '@/components/ComingSoon/index.vue'
import UpdateLayoutColumns from '@/components/UpdateLayoutColumns/index.vue'
import { getAllSections } from '@/services/sections'
import {
  addStageItem,
  deleteStageItem,
  getAllStages,
  getStageItems,
  updateStageItem,
} from '@/services/stages'
import { addMatter, updateMatter } from '@/services/legalMatter'
import { useTabs } from '@/composables/useTabs'
import { StagesPresenter } from '@/presenters/StagesPresenter'
import { LocationQueryValue } from 'vue-router'
import { SectionsPresenter } from '@/presenters/SectionsPresenter'
import { TableColumnInterface } from '@/Interfaces/Constants'
import { TableDataExporter } from '@/constants/Tables'

const MoreVertIcon = new URL(
  '@/assets/icons/More-vert-icon.svg',
  import.meta.url,
).href

export default defineComponent({
  name: 'ContactsPage',

  data() {
    const sections = [] as SectionsPresenter[]
    const subSections = [] as SectionsPresenter[]

    const { activeTabId, activeTab } = useTabs(sections)
    return {
      activeTabId,
      activeTab,

      headers: [] as TableColumnInterface[],

      isSelect: false,
      isAddMatter: false,
      sections,
      subSections,
      section: null as StagesPresenter | null,
      stages: [] as StagesPresenter[],
      draggedTaskId: null,

      addMatterLoading: false,
      isSectionsLoading: false,
      isStagesLoading: false,
      isMattersLoading: false,
      loadingUpdate: false,
      matterTableLoading: false,

      isShowUpdateLayoutModal: false,
      isUpdateLayoutLoading: false,
      isDeleteLayoutLoading: false,
      MoreVertIcon,

      refetchAllMattersTrigger: false,
    }
  },

  methods: {
    handleTicketMoved(data: any) {
      this.handleUpdateMatterToStage(data.id, data)
    },

    toggleAddMatter(section?: StagesPresenter) {
      this.isAddMatter = !this.isAddMatter
      this.section = section ?? null
    },

    async handleTabChange({ tab: section }: { tab: SectionsPresenter }) {
      // Update active section data
      this.activeTabId = section.id as number
      this.activeTab = section.name

      // Update the query parameter in the route
      this.$router.push({ query: { tab: section.name } })
    },
    //////////////////////////////////////////////////////////////////////////////////

    async handleCreateMatter(form: any) {
      this.addMatterLoading = true
      const response = await addMatter(form)
      if (response.success) {
        this.$vaToast.init({
          message: 'Matter created successfully!',
          color: 'success',
        })
        if (this.activeTabId === 1717171) {
          this.refetchAllMattersTrigger = !this.refetchAllMattersTrigger
        } else {
          const stageId =
            response.data?.section_stage_items[0]?.section_stage_id
          this.handleGetMattersByStage(stageId)
        }

        this.isAddMatter = false
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.addMatterLoading = false
    },
    async handleUpdateMatterToStage(id: string, data: any) {
      this.loadingUpdate = true
      const response = await updateMatter(id, data)

      if (!response.success) {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.loadingUpdate = false
    },

    //////////////////////////////////////////////////////////////////////////////////

    async handleGetMattersByStage(id: number) {
      this.isMattersLoading = true
      const response = await getStageItems(this.activeTabId, id)
      const tickets = response.data?.list
      const stage = this.stages?.find((s: StagesPresenter) => s.id === id)
      if (stage) {
        stage.section_stage_items = tickets
      }
      this.isMattersLoading = false
    },

    async handleGetStagesSection(section_id: number) {
      this.isStagesLoading = true

      const response = await getAllStages(section_id)
      if (response.success) {
        this.stages = response.data?.list

        this.subSections = this.subSections.map((sub) =>
          sub.id === section_id ? { ...sub, section_stages: this.stages } : sub,
        )

        await Promise.all(
          this.stages.map((stage: StagesPresenter) =>
            this.handleGetMattersByStage(stage.id),
          ),
        )
        this.$stores.matter.stages = response.data?.list
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }

      this.isStagesLoading = false
    },

    async handleGetAllSections() {
      this.isSectionsLoading = true
      const response = await getAllSections('legal_matters')
      if (response.success) {
        this.sections = [...this.fixedSections, ...response.data?.list]

        this.subSections = response.data?.list

        this.activeTabId = this.sections[0]?.id
        this.activeTab = this.sections[0]?.name

        this.headers =
          this.sections[0]?.table_columns?.length > 0
            ? this.sections[0]?.table_columns
            : TableDataExporter.getTableColumns(
                this.sections[0]?.listing_filters?.contact_type as string,
              )

        // it should be in this function cuz its on mount
        const queryTab = this.$route.query.tab
        const foundTab = queryTab
          ? this.sections.find(
              (tab: { name: string | LocationQueryValue[] }) =>
                tab.name === queryTab,
            )
          : null

        if (foundTab) {
          this.activeTabId = foundTab.id
          this.activeTab = foundTab.name
        } else {
          this.activeTabId = this.sections[0]?.id as number
          this.activeTab = this.sections[0]?.name
        }

        this.activeTabId !== 1717171 &&
          this.handleGetStagesSection(this.activeTabId)
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.isSectionsLoading = false
    },

    ////////////////////////////// layout modal /////////////////////////////////////////

    handleToggleUpdateLayoutModal() {
      this.isShowUpdateLayoutModal = !this.isShowUpdateLayoutModal
    },

    async handleUpdateLayout(body: StagesPresenter) {
      this.isUpdateLayoutLoading = true
      const { id, name, sort_order, color } = body

      let response
      if (id) {
        response = await updateStageItem(this.activeTabId, id, {
          name,
          sort_order,
          color,
        })
      } else {
        response = await addStageItem(this.activeTabId, {
          name,
          sort_order,
          color,
        })
      }

      if (response.success) {
        this.handleGetStagesSection(this.activeTabId)
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.isUpdateLayoutLoading = false
    },

    async handleDeleteLayout(stageId: number) {
      this.isDeleteLayoutLoading = true

      const response = await deleteStageItem(this.activeTabId, stageId)

      if (response.success) {
        this.handleGetStagesSection(this.activeTabId)
        this.$vaToast.init({
          message: 'Stage Delete Successfully',
          color: 'success',
        })
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }

      this.isDeleteLayoutLoading = false
    },

    ///////////////////////////////////////////////////////////////////////////////
  },
  watch: {
    '$route.query.tab': async function (newTab) {
      const theFoundTab = this.sections.find((tab) => tab.name === newTab)

      if (theFoundTab?.id === 1717171) {
        this.activeTabId = theFoundTab.id as number
        this.activeTab = theFoundTab.name
        return
      }

      if (theFoundTab) {
        this.activeTabId = theFoundTab.id as number
        this.activeTab = theFoundTab.name
        this.stages = []
        this.handleGetStagesSection(this.activeTabId)
      }
    },
  },
  computed: {
    fixedSections() {
      return [
        {
          id: 1717171,
          name: 'All Matters',
          listing_filters: {
            contact_type: 'All Matters',
          },
          table_columns:
            this.$stores.generalData.companyConfigs
              .legal_matters_all_table_columns?.[0].data.columns || [],
        },
      ]
    },
  },
  mounted() {
    this.handleGetAllSections()
  },
  components: {
    TabsBar,
    ActionsBar,
    SharedHeader,
    DraggableColumns,
    KanbanInfiniteLoad,
    ComingSoon,
    AddMatterModal,
    UpdateLayoutColumns,
    AllMatters,
  },
})
