<template>
  <div class="file" @contextmenu.prevent="isOpenOptions = true">
    <div class="folder-header">
      <div class="info">
        <!-- <img :src="icon" alt="folder icon" class="file-type" /> -->
        <VaIcon name="description" size="1.7rem" color="primary" />

        <div class="file-details">
          <div class="name-container">
            <h3 v-if="!isEditingName" class="name" @click.stop="enableEditing">
              {{ file?.name }}
            </h3>
            <input
              v-else
              ref="nameInput"
              v-model="editableName"
              type="text"
              class="name-input"
              autofocus
              @keydown.enter="saveName"
              @blur="cancelEditing"
              @click.stop
            />
            <p class="size">{{ file?.file_size }} GB</p>
          </div>
        </div>
      </div>
      <div class="folder-options">
        <v-menu
          v-model="isOpenOptions"
          :close-on-content-click="true"
          location="bottom left"
        >
          <template #activator="{ props }">
            <img
              v-bind="props"
              :src="MoreVertIcon"
              alt="MoreVertIcon"
              class="press-button-2"
            />
          </template>
          <v-card class="menu-list">
            <ul class="menu">
              <li
                v-for="option in fileOptions"
                :key="option.id"
                :class="[
                  'menu-item',
                  { 'menu-item-disabled': option.deactivate },
                ]"
                @click="!option.deactivate && handleOptionClick(option)"
              >
                {{ option.option }}
              </li>
            </ul>
          </v-card>
        </v-menu>
      </div>
    </div>
    <!-- <div class="labels">
      <div class="label">
        <p class="size">Size : {{ file?.file_size }}</p>
      </div>
    </div> -->
  </div>
</template>

<script lang="ts" src="./script.ts"></script>
<style lang="scss" scoped src="./styles.scss"></style>
