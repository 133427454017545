import { calendarGlobalSearchInterface } from '@/Interfaces/Store'
import { camelCaseToNormal } from '@/lib/utils'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'CalendarLinkToModal',
  props: {
    linkedItems: {
      type: Array as () => calendarGlobalSearchInterface[],
    },
  },
  emits: ['handleLinkTo', 'handleToggleAssignToMenu'],
  data() {
    return {
      camelCaseToNormal,

      globalSearchKeyword: '',
      debounceTimeout: null as ReturnType<typeof setTimeout> | null,

      selectedItems: [
        ...(this.linkedItems ?? []),
      ] as calendarGlobalSearchInterface[],
    }
  },
  watch: {},
  methods: {
    handleSearchGlobally() {
      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout)
      }
      this.debounceTimeout = setTimeout(() => {
        this.$stores.globalSearch.handleGlobalSearch(this.globalSearchKeyword, {
          individual_contacts_enabled: false,
          organization_contacts_enabled: false,
        })
      }, 500)
    },

    handleLinkTo(data: calendarGlobalSearchInterface) {
      if (!this.selectedItems?.find((item) => item.id === data.id)) {
        this.selectedItems?.push(data)

        const calendar_item_linked_items_attributes = this.selectedItems.map(
          (item) => ({
            linked_item_id: item.id,
            linked_item_type: item.class_name,
          }),
        )

        this.$emit('handleLinkTo', {
          selectedItems: this.selectedItems,
          calendar_item_linked_items_attributes,
        })
      }
    },

    handleRemoveLinked(id: number): void {
      if (this.selectedItems) {
        this.selectedItems = this.selectedItems.filter(
          (item: calendarGlobalSearchInterface) => item.id !== id,
        )

        const calendar_item_linked_items_attributes = this.selectedItems.map(
          (item) => ({
            linked_item_id: item.id,
            linked_item_type: item.class_name,
          }),
        )

        this.$emit('handleLinkTo', {
          selectedItems: this.selectedItems,
          calendar_item_linked_items_attributes,
        })
      }
    },
  },
})
