import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7250366e"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  id: "print",
  ref: "componentRef"
}
const _hoisted_2 = { class: "invoice-container-header" }
const _hoisted_3 = { class: "invoice-container-body" }
const _hoisted_4 = { class: "container-bottom" }
const _hoisted_5 = { class: "logo-section" }
const _hoisted_6 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DynamicSections = _resolveComponent("DynamicSections")!
  const _component_VaButton = _resolveComponent("VaButton")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_DynamicSections, {
          form: _ctx.form,
          subtotal: _ctx.subtotal,
          convertToSecondaryCurrencyTotal: _ctx.convertToSecondaryCurrencyTotal,
          convertToSecondaryCurrencySubTotal: _ctx.convertToSecondaryCurrencySubTotal,
          total: _ctx.total,
          templateData: _ctx.templateDataHeader
        }, null, 8, ["form", "subtotal", "convertToSecondaryCurrencyTotal", "convertToSecondaryCurrencySubTotal", "total", "templateData"])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_DynamicSections, {
          form: _ctx.form,
          subtotal: _ctx.subtotal,
          convertToSecondaryCurrencyTotal: _ctx.convertToSecondaryCurrencyTotal,
          convertToSecondaryCurrencySubTotal: _ctx.convertToSecondaryCurrencySubTotal,
          total: _ctx.total,
          templateData: _ctx.templateDataBody
        }, null, 8, ["form", "subtotal", "convertToSecondaryCurrencyTotal", "convertToSecondaryCurrencySubTotal", "total", "templateData"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_DynamicSections, {
          form: _ctx.form,
          subtotal: _ctx.subtotal,
          convertToSecondaryCurrencyTotal: _ctx.convertToSecondaryCurrencyTotal,
          convertToSecondaryCurrencySubTotal: _ctx.convertToSecondaryCurrencySubTotal,
          total: _ctx.total,
          templateData: _ctx.templateDataFooter
        }, null, 8, ["form", "subtotal", "convertToSecondaryCurrencyTotal", "convertToSecondaryCurrencySubTotal", "total", "templateData"]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("img", {
            src: _ctx.logoImage,
            width: "30rem",
            alt: "LOGO"
          }, null, 8, _hoisted_6),
          _createTextVNode(" Billed through MyHAQQ ")
        ])
      ])
    ], 512),
    _createVNode(_component_VaButton, {
      preset: "primary",
      icon: "print",
      onClick: _ctx.handlePrint,
      loading: _ctx.loading
    }, {
      default: _withCtx(() => [
        _createTextVNode(" Preview Invoice ")
      ]),
      _: 1
    }, 8, ["onClick", "loading"])
  ], 64))
}