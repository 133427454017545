import { mockTeams } from '@/constants/mockTeams'
import { CompanyUserPresenter } from '@/presenters/CompanyUserPresenter'
import { TeamPresenter } from '@/presenters/TeamPresenter'

export const getAllTeams = () => {
  const response = {
    success: true,
    message: 'success',
    data: {
      extra_data: {},
      limit_value: 100,
      total_pages: 1,
      current_page: 1,
      next_page: null,
      prev_page: null,
      list: mockTeams,
    },
  }

  if (!response.success) return response

  const list = response.data?.list
  if (!Array.isArray(list)) return response

  return {
    ...response,
    data: {
      ...response.data,
      list: list.map((item: TeamPresenter) => {
        return new TeamPresenter(item)
      }),
    },
  }
}

export const addTeam = ({ newTeam }: { newTeam: TeamPresenter }) => {
  console.log(newTeam)
}

export const addTeamMember = ({
  members,
  team,
  note,
}: {
  members: CompanyUserPresenter[]
  team: number
  note: string
}) => {
  console.log({ members, team, note })
}
