import FileUploadHelper, {
  getFormColumns,
  getOptionFullName,
  getOptionName,
  getOptionTruncateName50,
  truncateText,
  validateInput,
  validateInputEmail,
  validateSelect,
} from '@/lib/utils'
import { Avatar } from '@/presenters/interface'
import { defineComponent } from 'vue'
import { useForm } from 'vuestic-ui/web-components'
import DynamicContactForm from '@/components/DynamicForms/DynamicContactForm/index.vue'
import { IndividualContactPresenter } from '@/presenters/contactPresenter'

import { AddressPresenter } from '@/presenters/AddressPresenter'

const UploadAvatarIcon = new URL(
  '@/assets/icons/upload-avatar-icon.svg',
  import.meta.url,
).href

export default defineComponent({
  name: 'AddContactForm',
  props: {
    addContactLoading: {
      type: Boolean,
      default: false,
    },
    isAddContactForm: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const { isValid, validate, reset, resetValidation } = useForm('form')

    const form = {
      contact_addresses: [
        {
          city: null,
          floor: '',
          zip_code: '',
          street_name: '',
          building_name: '',
          phone_number: '',
          google_maps_url: '',
        },
      ] as AddressPresenter[],
    } as IndividualContactPresenter

    return {
      validateInput,
      validateInputEmail,
      validateSelect,
      getOptionName,
      getOptionFullName,
      getOptionTruncateName50,

      formFields: [] as any[][],
      toggleSubmit: false,

      isAddContactFormBool: this.isAddContactForm,
      isValid,
      validate,
      reset,
      resetValidation,
      form,
      sexOptions: ['male', 'female'],
      kyc_documents: [],
      UploadAvatarIcon,
      isAddressAccordion: [false],

      debounceTimeout: null as ReturnType<typeof setTimeout> | null,
      truncateText,
    }
  },
  methods: {
    toggleAddContactForm() {
      this.isAddContactFormBool = !this.isAddContactFormBool
      this.$emit('toggleAddContactForm')
    },

    async handleToggleSubmit() {
      this.toggleSubmit = !this.toggleSubmit
    },

    handleCreateContact(form: IndividualContactPresenter) {
      const {
        referred_by,
        organization_contact,
        individual_profile_picture,
        avatar,
        email_addresses,
        phone_numbers,
        contact_addresses,
        relationship_owner,
        assigned_to,
        linked_legal_matter_ids,
        ...dynamicForm
      } = form

      this.$emit('handleCreateContact', {
        organization_contact_id: organization_contact?.id,
        relationship_owner_id: relationship_owner?.id,
        referred_by_id: referred_by?.id,
        assigned_to_id: assigned_to?.id,
        individual_profile_picture:
          this.form.individual_profile_picture ??
          new File([], 'empty.jpg', {
            type: 'image/jpeg',
          }),
        ...(email_addresses?.[0].email_address !== null && { email_addresses }),
        ...(phone_numbers?.[0].phone_number !== null && { phone_numbers }),
        ...(contact_addresses?.[0]?.city !== null && {
          contact_addresses_attributes: contact_addresses?.map((address) => ({
            ...address,
            city_id: address.city?.id,
          })),
        }),
        linked_legal_matter_ids: linked_legal_matter_ids?.map(
          (matter: { id: number }) => matter.id,
        ),
        ...dynamicForm,
      })
    },

    ///////////////////////////////////////////////////////////////////

    handleRemoveImage() {
      this.form.avatar = {} as Avatar
      this.form.individual_profile_picture = new File([], 'empty.jpg', {
        type: 'image/jpeg',
      })
    },

    handleFileUpload(event: File) {
      FileUploadHelper.handleFileUpload({
        event,
        form: this.form,
        key: 'individual_profile_picture',
      })
    },
  },

  watch: {
    isAddContactForm: {
      handler(data) {
        this.isAddContactFormBool = data

        if (data) {
          this.$stores.usersData.handleGetMembers()
          this.$stores.generalData.handleGetAllCountries()
          this.$stores.contacts.handleGetContacts()
          this.$stores.contacts.handleGetOrganizations()
          this.$stores.matter.handleGetMatters()

          this.formFields = getFormColumns(
            this.$stores.generalData.companyConfigs
              .individual_contact_form_fields?.[0].data.fields,
            'contacts',
          )
        } else {
          this.form = {
            contact_addresses: [
              {
                city: null,
                floor: '',
                zip_code: '',
                street_name: '',
                building_name: '',
                phone_number: '',
                google_maps_url: '',
              },
            ] as AddressPresenter[],
          } as IndividualContactPresenter
        }
      },
    },
  },
  components: {
    DynamicContactForm,
  },
})
