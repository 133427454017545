import { CityPresenter } from './CityPresenter'
import { CountryPresenter } from './CountryPresenter'
import { StatePresenter } from './StatePresenter'

export class AddressPresenter {
  id?: number
  building_name!: string
  city!: any
  class_name!: string
  country!: CountryPresenter
  created_at!: string
  floor!: string
  google_maps_url!: string
  phone_number!: string
  state!: StatePresenter
  street_name!: string
  updated_at!: string
  zip_code!: string

  constructor(data: AddressPresenter) {
    Object.assign(this, data)

    if (data.city) {
      this.city = new CityPresenter(data.city)
    }

    if (data.country) {
      this.country = new CountryPresenter(data.country)
    }

    if (data.state) {
      this.state = new StatePresenter(data.state)
    }
  }
}
