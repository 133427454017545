import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, withCtx as _withCtx, toDisplayString as _toDisplayString, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6c3f8f01"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "form-modal-header" }
const _hoisted_2 = { class: "fees" }
const _hoisted_3 = { class: "expenses" }
const _hoisted_4 = { class: "form-modal-buttons" }
const _hoisted_5 = { class: "scroll-container-wrapper for-tables" }
const _hoisted_6 = {
  key: 1,
  class: "description-text"
}
const _hoisted_7 = { class: "fees_amount" }
const _hoisted_8 = { class: "expenses_amount" }
const _hoisted_9 = { class: "table-action-td" }
const _hoisted_10 = ["src"]
const _hoisted_11 = ["onClick"]
const _hoisted_12 = ["onClick"]
const _hoisted_13 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ActionsBar = _resolveComponent("ActionsBar")!
  const _component_VaIcon = _resolveComponent("VaIcon")!
  const _component_VaInput = _resolveComponent("VaInput")!
  const _component_VaDateInput = _resolveComponent("VaDateInput")!
  const _component_VaSelect = _resolveComponent("VaSelect")!
  const _component_VaTextarea = _resolveComponent("VaTextarea")!
  const _component_VaForm = _resolveComponent("VaForm")!
  const _component_VaButton = _resolveComponent("VaButton")!
  const _component_VaModal = _resolveComponent("VaModal")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_va_dropdown_content = _resolveComponent("va-dropdown-content")!
  const _component_va_dropdown = _resolveComponent("va-dropdown")!
  const _component_v_data_table_server = _resolveComponent("v-data-table-server")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ActionsBar, {
      "second-add-button": "Add Milestone",
      onSecondButtonAction: _ctx.handleToggleAddMileStone
    }, null, 8, ["onSecondButtonAction"]),
    _createVNode(_component_VaModal, {
      modelValue: _ctx.isAddMileStone,
      "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.isAddMileStone) = $event)),
      size: "auto",
      "hide-default-actions": "",
      "fixed-layout": "",
      "no-outside-dismiss": ""
    }, {
      header: _withCtx(() => [
        _createElementVNode("h1", _hoisted_1, [
          _createTextVNode(" Add Milestone "),
          _createVNode(_component_VaIcon, {
            name: "close",
            size: "30px",
            class: "icon",
            onClick: _ctx.handleToggleAddMileStone
          }, null, 8, ["onClick"])
        ])
      ]),
      footer: _withCtx(() => [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_VaButton, {
            preset: "primary",
            onClick: _ctx.handleToggleAddMileStone
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Cancel ")
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_VaButton, {
            preset: "primary",
            loading: 
            _ctx.isAddFunction ? _ctx.addMileStoneLoading : _ctx.updateMileStoneLoading
          ,
            onClick: _cache[9] || (_cache[9] = ($event: any) => (
            _ctx.validate() && _ctx.isAddFunction
              ? _ctx.handleAddMileStone()
              : _ctx.handleUpdateMileStone()
          ))
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Save ")
            ]),
            _: 1
          }, 8, ["loading"])
        ])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_VaForm, {
          ref: "form",
          class: "add-milestone-form-modal",
          onSubmit: _cache[8] || (_cache[8] = _withModifiers(($event: any) => (
        _ctx.validate() && _ctx.isAddFunction
          ? _ctx.handleAddMileStone()
          : _ctx.handleUpdateMileStone()
      ), ["prevent"]))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_VaInput, {
              modelValue: _ctx.form.name,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.name) = $event)),
              placeholder: "Name",
              background: "#ECF0F1",
              style: {"width":"50%"},
              label: "Name",
              "required-mark": "",
              rules: [_ctx.validateInput]
            }, null, 8, ["modelValue", "rules"]),
            _createVNode(_component_VaDateInput, {
              modelValue: _ctx.form.due_date,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.due_date) = $event)),
              placeholder: "MM/DD/YYYY",
              "manual-input": "",
              label: "Due Date",
              clearable: "",
              background: "#ECF0F1",
              "required-mark": "",
              rules: [_ctx.validateInput]
            }, null, 8, ["modelValue", "rules"]),
            _createVNode(_component_VaSelect, {
              modelValue: _ctx.form.status,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.form.status) = $event)),
              label: "Status",
              placeholder: "Status",
              background: "#ECF0F1",
              options: _ctx.$stores.generalData.companyConfigs.milestonesStatusOption,
              "text-by": "name",
              rules: [_ctx.validateSelect],
              "required-mark": ""
            }, null, 8, ["modelValue", "options", "rules"]),
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_VaInput, {
                modelValue: _ctx.form.fees_amount,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.form.fees_amount) = $event)),
                label: "Fees",
                background: "#ECF0F1",
                type: "number",
                placeholder: "Fees",
                min: "0",
                "required-mark": "",
                rules: [_ctx.validateInput]
              }, null, 8, ["modelValue", "rules"]),
              _createVNode(_component_VaSelect, {
                modelValue: _ctx.form.fees_currency,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.form.fees_currency) = $event)),
                label: "currency",
                background: "#ECF0F1",
                options: _ctx.$stores.currency.currencies,
                "text-by": _ctx.getOptionCurrency,
                placeholder: "Currency",
                rules: [_ctx.validateSelect],
                "required-mark": ""
              }, null, 8, ["modelValue", "options", "text-by", "rules"])
            ]),
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_VaInput, {
                modelValue: _ctx.form.expenses_amount,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.form.expenses_amount) = $event)),
                label: "Expenses",
                background: "#ECF0F1",
                type: "number",
                placeholder: "Expenses",
                min: "0",
                "required-mark": "",
                rules: [_ctx.validateInput]
              }, null, 8, ["modelValue", "rules"]),
              _createVNode(_component_VaSelect, {
                modelValue: _ctx.form.expenses_currency,
                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.form.expenses_currency) = $event)),
                label: "currency",
                background: "#ECF0F1",
                options: _ctx.$stores.currency.currencies,
                "text-by": _ctx.getOptionCurrency,
                placeholder: "Currency",
                rules: [_ctx.validateSelect],
                "required-mark": ""
              }, null, 8, ["modelValue", "options", "text-by", "rules"])
            ]),
            _createVNode(_component_VaTextarea, {
              modelValue: _ctx.form.description,
              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.form.description) = $event)),
              "max-rows": "11",
              label: "description",
              placeholder: "Add your description here...",
              style: {"width":"100%","height":"10rem"},
              autosize: "",
              background: "#ECF0F1"
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }, 512)
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_v_data_table_server, {
        selection: _ctx.selectedRows,
        "onUpdate:selection": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.selectedRows) = $event)),
        loading: _ctx.milestoneTableLoading || _ctx.generateInvoiceLoading,
        headers: _ctx.headers,
        items: _ctx.milestones,
        "show-select": "",
        class: "custom-table",
        "items-length": _ctx.milestoneTotalItems,
        "items-per-page": _ctx.milestonesItemPerPage,
        "items-per-page-options": [10, 25, 50],
        "onUpdate:options": _ctx.handleLoadMore,
        "fixed-header": true,
        "fixed-footer": true
      }, {
        [`item.description`]: _withCtx(({ item }) => [
          (_ctx.isLongDescription(item?.description, 100))
            ? (_openBlock(), _createBlock(_component_v_tooltip, {
                key: 0,
                text: item?.description,
                location: "top"
              }, {
                activator: _withCtx(({ props }) => [
                  _createElementVNode("p", _mergeProps(props, { class: "description-text" }), _toDisplayString(_ctx.truncateText(item?.description, 100)), 17)
                ]),
                _: 2
              }, 1032, ["text"]))
            : (_openBlock(), _createElementBlock("p", _hoisted_6, _toDisplayString(_ctx.truncateText(item?.description, 100)), 1))
        ]),
        [`item.fees_amount`]: _withCtx(({ item }) => [
          _createElementVNode("p", _hoisted_7, [
            _createElementVNode("span", null, _toDisplayString(item?.fees_amount), 1),
            _createElementVNode("span", null, _toDisplayString(item?.fees_currency.code), 1)
          ])
        ]),
        [`item.expenses_amount`]: _withCtx(({ item }) => [
          _createElementVNode("p", _hoisted_8, [
            _createElementVNode("span", null, _toDisplayString(item?.expenses_amount), 1),
            _createElementVNode("span", null, _toDisplayString(item?.expenses_currency.code), 1)
          ])
        ]),
        [`item.action`]: _withCtx(({ item }) => [
          _createElementVNode("td", _hoisted_9, [
            _createVNode(_component_va_dropdown, {
              offset: [0, 50],
              "close-on-content-click": ""
            }, {
              anchor: _withCtx(() => [
                _createElementVNode("img", {
                  src: _ctx.moreVertIcon,
                  alt: "MoreVertIcon",
                  class: "press-button-2"
                }, null, 8, _hoisted_10)
              ]),
              default: _withCtx(() => [
                _createVNode(_component_va_dropdown_content, { class: "dropdown" }, {
                  default: _withCtx(() => [
                    _createElementVNode("p", {
                      onClick: ($event: any) => (_ctx.handleToggleAddMileStone(item))
                    }, [
                      _createVNode(_component_VaIcon, { name: "edit" }),
                      _createTextVNode(" Edit ")
                    ], 8, _hoisted_11),
                    (!item.related_invoice_id)
                      ? (_openBlock(), _createElementBlock("p", {
                          key: 0,
                          onClick: ($event: any) => (_ctx.handleGenerateInvoice(item.id))
                        }, [
                          _createVNode(_component_VaIcon, { name: "attach_money" }),
                          _createTextVNode(" Generate Invoice ")
                        ], 8, _hoisted_12))
                      : (_openBlock(), _createElementBlock("p", {
                          key: 1,
                          onClick: ($event: any) => (
                  _ctx.$router.push(`/financial/invoice/${item?.related_invoice_id}`)
                )
                        }, [
                          _createVNode(_component_VaIcon, { name: "person" }),
                          _createTextVNode(" View Invoice ")
                        ], 8, _hoisted_13))
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 2
            }, 1024)
          ])
        ]),
        _: 2
      }, 1032, ["selection", "loading", "headers", "items", "items-length", "items-per-page", "onUpdate:options"])
    ])
  ], 64))
}