<template>
  <div id="print" ref="componentRef">
    <div class="invoice-container-header">
      <DynamicSections
        :form="form"
        :subtotal="subtotal"
        :convertToSecondaryCurrencyTotal="convertToSecondaryCurrencyTotal"
        :convertToSecondaryCurrencySubTotal="convertToSecondaryCurrencySubTotal"
        :total="total"
        :templateData="templateDataHeader"
      />
    </div>

    <div class="invoice-container-body">
      <DynamicSections
        :form="form"
        :subtotal="subtotal"
        :convertToSecondaryCurrencyTotal="convertToSecondaryCurrencyTotal"
        :convertToSecondaryCurrencySubTotal="convertToSecondaryCurrencySubTotal"
        :total="total"
        :templateData="templateDataBody"
      />
    </div>

    <!-- Footer (Fixed at Bottom) -->
    <div class="container-bottom">
      <DynamicSections
        :form="form"
        :subtotal="subtotal"
        :convertToSecondaryCurrencyTotal="convertToSecondaryCurrencyTotal"
        :convertToSecondaryCurrencySubTotal="convertToSecondaryCurrencySubTotal"
        :total="total"
        :templateData="templateDataFooter"
      />
      <div class="logo-section">
        <img :src="logoImage" width="30rem" alt="LOGO" /> Billed through MyHAQQ
      </div>
    </div>
  </div>

  <VaButton
    preset="primary"
    icon="print"
    @click="handlePrint"
    :loading="loading"
  >
    Preview Invoice
  </VaButton>
</template>

<script lang="ts" src="./script.ts" />
<style lang="scss" scoped src="./styles.scss" />
