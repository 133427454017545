import { CompanyUserPresenter } from '@/presenters/CompanyUserPresenter'
import { TeamMemberPresenter } from '@/presenters/teamMember'
import { CurrenciesPresenter } from '@/presenters/CurrenciesPresenter'
import { timezoneOptions } from './timezones'
import { TimezonesInterface } from '@/Interfaces/Constants'

const mockCurrency = (code: string, name: string): CurrenciesPresenter => ({
  id: 0,
  code,
  name,
  class_name: '',
  company_id: 0,
  conversion_rate_against_us_dollars: '',
  created_at: '',
  updated_at: '',
})

const mockAvatar = () => ({
  url: null,
  id: null,
  large_url: null,
  medium_url: null,
  thumbnail_url: null,
})

const mockTeamMember = (
  id: number,
  firstName: string,
  lastName: string,
  email: string,
  title: string,
): TeamMemberPresenter => ({
  id,
  avatar: mockAvatar(),
  class_name: 'TeamMember',
  email,
  first_name: firstName,
  last_name: lastName,
  middle_name: null,
  title,
  company_users: [],
  fullName: `${firstName} ${lastName}`,
  avatar_letter: `${firstName?.charAt(0)}${lastName?.charAt(0)}`,
  avatar_first_letter: `${firstName?.charAt(0)}`,
})

const getTimezoneOptions = (timezones: string[]) =>
  timezones
    .map((tz) => timezoneOptions.find((option) => option.timezone === tz))
    .filter((tz): tz is TimezonesInterface => tz !== undefined)

const mockCompany = (id: number, name: string, userCount: number) => ({
  id,
  name,
  class_name: 'Company',
  company_users_count: userCount,
  created_at: '',
  guid: `team${id}`,
  updated_at: '',
  icon: 'source_environment',
})

const mockTimezones = ['America/New_York', 'Europe/London', 'Asia/Tokyo']

const mockCompanyUsersData = [
  {
    role: 'Manager',
    fullName: 'John Doe',
    id: 1,
    salaryAmount: 6000,
    timezoneIndexes: [0, 1],
    salaryCurrency: mockCurrency('USD', 'US Dollar'),
    billingRate: 80,
    billingCurrency: mockCurrency('USD', 'US Dollar'),
    companyId: 1,
    companyName: 'Example Inc.',
  },
  {
    billingRate: 100,
    role: 'Developer',
    salaryAmount: 4500,
    salaryCurrency: mockCurrency('EUR', 'Euro'),
    billingCurrency: mockCurrency('EUR', 'Euro'),
    timezoneIndexes: [1, 2],
    companyId: 2,
    id: 2,
    fullName: 'Jane Smith',
    companyName: 'Tech Solutions Ltd.',
  },
  {
    billingRate: 120,
    billingCurrency: mockCurrency('GBP', 'British Pound'),
    id: 3,
    fullName: 'Michael Brown',
    role: 'Project Manager',
    salaryAmount: 7000,
    salaryCurrency: mockCurrency('GBP', 'British Pound'),
    timezoneIndexes: [0],
    companyId: 3,
    companyName: 'Creative Agency',
  },
  {
    id: 4,
    timezoneIndexes: [2],
    role: 'Senior Consultant',
    salaryAmount: 8000,
    salaryCurrency: mockCurrency('USD', 'US Dollar'),
    fullName: 'Laura Wilson',
    companyName: 'Enterprise Solutions',
    billingRate: 150,
    billingCurrency: mockCurrency('USD', 'US Dollar'),
    companyId: 4,
  },
  {
    billingRate: 90,
    fullName: 'Emma Johnson',
    salaryCurrency: mockCurrency('CAD', 'Canadian Dollar'),
    billingCurrency: mockCurrency('CAD', 'Canadian Dollar'),
    timezoneIndexes: [1],
    role: 'Data Analyst',
    salaryAmount: 5000,
    companyId: 5,
    companyName: 'Analytics Hub',
    id: 5,
  },
]

const getFirstAndLastName = (fullName: string) => {
  const [firstName, lastName] = fullName.split(' ')
  return { firstName, lastName }
}

const createMockCompanyUser = ({
  id,
  fullName,
  role,
  salaryAmount,
  salaryCurrency,
  billingRate,
  billingCurrency,
  timezoneIndexes,
  companyId,
  companyName,
}: (typeof mockCompanyUsersData)[number]): any => {
  const { firstName, lastName } = getFirstAndLastName(fullName)
  const user = mockTeamMember(
    id,
    firstName,
    lastName,
    `${firstName.toLowerCase()}.${lastName.toLowerCase()}@example.com`,
    role,
  )

  return {
    id,
    avatar: mockAvatar(),
    basic_salary_amount: salaryAmount,
    basic_salary_currency: salaryCurrency,
    basic_salary_frequency: 'monthly',
    billable_hourly_rate_charging_amount: billingRate,
    billable_hourly_rate_charging_currency: billingCurrency,
    billable_hourly_rate_earning_amount: billingRate - 10,
    billable_hourly_rate_earning_currency: billingCurrency,
    calendar_timezones: timezoneIndexes.map((index) => mockTimezones[index]),
    calendar_timezones_options: getTimezoneOptions(
      timezoneIndexes.map((index) => mockTimezones[index]),
    ),
    class_name: 'CompanyUser',
    created_at: '2023-12-31T12:00:00Z',
    updated_at: '2024-01-10T12:00:00Z',
    role,
    company_id: companyId,
    company: mockCompany(companyId, companyName, 5),
    roleable_type: 'User',
    user_id: id,
    fullName,
    avatar_letter: firstName?.charAt(0) + lastName?.charAt(0),
    user,
  }
}

export const mockCompanyUsers: CompanyUserPresenter[] =
  mockCompanyUsersData.map(createMockCompanyUser)
