import {
  user_form,
  user_pass,
} from '@/views/Settings/GeneralSettings/interface'
import { apiRequest } from './apiUtils'
import { TeamMemberPresenter } from '@/presenters/teamMember'
import { objectToFormData } from '@/lib/utils'

export const getUserInfo = async () => {
  const response = await apiRequest('get', '/users')
  if (response.success) {
    return {
      ...response,
      data: new TeamMemberPresenter(response.data),
    }
  } else {
    return response
  }
}

export const updateUserInfo = async (body: user_form) => {
  const response = await apiRequest('put', '/users', objectToFormData(body))
  if (response.success) {
    return {
      ...response,
      data: new TeamMemberPresenter(response.data),
    }
  } else {
    return response
  }
}

export const updateUserPass = (body: user_pass) => {
  return apiRequest('put', '/users/change_password', body)
}

export const passwordResetRequest = (email: string) => {
  return apiRequest('post', '/users/password_reset_request', { email })
}
