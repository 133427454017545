import {
  invoiceItemAttributesInterface,
  invoiceTaxItemsAttributesInterface,
} from '@/Interfaces/Services'
import { CurrencyPresenter } from '../companyConfigPresenters'
import { IndividualContactPresenter } from '../contactPresenter'
import { OrganizationContactPresenter } from '../organizationPresenter'

const presenters = {
  IndividualContact: IndividualContactPresenter,
  OrganizationContact: OrganizationContactPresenter,
} as const

export class InvoicePresenter {
  id!: number
  class_name!: 'Invoice'

  invoiceable!: IndividualContactPresenter | OrganizationContactPresenter

  currency!: CurrencyPresenter
  secondary_currency!: CurrencyPresenter
  currency_conversion_rate_against_us_dollars!: string
  secondary_currency_conversion_rate_against_us_dollars!: string

  customer_email!: string
  customer_name!: string
  customer_amount!: string
  discount_type!: 'percentage' | 'amount'
  discount_type_amount!: string
  general_note!: string
  guid!: string
  invoice_date!: string
  invoice_items!: invoiceItemAttributesInterface[]
  invoice_number!: string
  invoice_tax_items!: invoiceTaxItemsAttributesInterface[]
  payment_details_note!: string
  payment_due_date!: string
  signature_note!: string
  status!: string
  tax_after_discount!: string
  tax_before_discount!: string
  terms_and_conditions_note!: string
  total_after_discount!: string
  total_before_discount!: string
  template!: any
  timekeepers!: any

  constructor(data: InvoicePresenter) {
    Object.assign(this, data)

    if (data.invoiceable) {
      const Presenter = presenters[data.invoiceable.class_name]
      this.invoiceable = new Presenter(data.invoiceable as never) || null
    }

    if (data.currency) {
      this.currency = new CurrencyPresenter(data.currency)
    }

    data.invoice_items?.forEach((e: invoiceItemAttributesInterface) => {
      e.quantity = Number(e.quantity)
    })

    this.timekeepers = data?.invoice_items
      ?.filter(
        (item: invoiceItemAttributesInterface) =>
          item?.billable?.class_name === 'CalendarItem',
      )
      ?.map((item: invoiceItemAttributesInterface) => item.billable)
  }
}
