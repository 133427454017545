import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ef79e7c6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "sub-SharedHeader" }
const _hoisted_2 = ["src"]
const _hoisted_3 = {
  key: 2,
  class: "scroll-container-wrapper"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_va_dropdown_content = _resolveComponent("va-dropdown-content")!
  const _component_va_dropdown = _resolveComponent("va-dropdown")!
  const _component_SharedHeader = _resolveComponent("SharedHeader")!
  const _component_AddTaskModal = _resolveComponent("AddTaskModal")!
  const _component_UpdateLayoutColumns = _resolveComponent("UpdateLayoutColumns")!
  const _component_TabsBar = _resolveComponent("TabsBar")!
  const _component_ActionsBar = _resolveComponent("ActionsBar")!
  const _component_AllTasks = _resolveComponent("AllTasks")!
  const _component_KanbanInfiniteLoad = _resolveComponent("KanbanInfiniteLoad")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_SharedHeader, {
      "title-width": "30rem",
      title: "Tasks"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_va_dropdown, {
            offset: [0, -70],
            "close-on-content-click": ""
          }, {
            anchor: _withCtx(() => [
              _createElementVNode("img", {
                src: _ctx.MoreVertIcon,
                alt: "MoreVertIcon",
                class: "press-button-2"
              }, null, 8, _hoisted_2)
            ]),
            default: _withCtx(() => [
              _createVNode(_component_va_dropdown_content, { class: "dropdown" }, {
                default: _withCtx(() => [
                  _createElementVNode("p", {
                    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleToggleUpdateLayoutModal && _ctx.handleToggleUpdateLayoutModal(...args)))
                  }, "Edit Page Layout")
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ])
      ]),
      _: 1
    }),
    _createVNode(_component_AddTaskModal, {
      "add-task-loading": _ctx.isAddLoading,
      "is-add-task-form": _ctx.isAddTask,
      "sub-sections": _ctx.subSections,
      section: _ctx.section,
      onHandleToggleAddTaskModal: _ctx.handleToggleAddTaskModal,
      onHandleCreateTask: _ctx.handleCreateTask
    }, null, 8, ["add-task-loading", "is-add-task-form", "sub-sections", "section", "onHandleToggleAddTaskModal", "onHandleCreateTask"]),
    _createVNode(_component_UpdateLayoutColumns, {
      "update-layout-loading": _ctx.isUpdateLayoutLoading,
      "is-update-layout-form": _ctx.isShowUpdateLayoutModal,
      section_stages: _ctx.stages,
      "is-delete-layout-loading": _ctx.isDeleteLayoutLoading,
      onHandleToggleUpdateLayoutModal: _ctx.handleToggleUpdateLayoutModal,
      onHandleUpdateStageData: _ctx.handleUpdateLayout,
      onOnDragEnd: _ctx.handleUpdateLayout,
      onHandleDeleteLayout: _ctx.handleDeleteLayout
    }, null, 8, ["update-layout-loading", "is-update-layout-form", "section_stages", "is-delete-layout-loading", "onHandleToggleUpdateLayoutModal", "onHandleUpdateStageData", "onOnDragEnd", "onHandleDeleteLayout"]),
    _createVNode(_component_TabsBar, {
      tabs: _ctx.sections,
      "active-tab-id": _ctx.activeTabId,
      loading: _ctx.isLoading,
      onChangeTab: _ctx.handleTabChange
    }, null, 8, ["tabs", "active-tab-id", "loading", "onChangeTab"]),
    (!_ctx.isLoading)
      ? (_openBlock(), _createBlock(_component_ActionsBar, {
          key: 0,
          "second-add-button": _ctx.$t('actionsBar.addTask'),
          onSecondButtonAction: _ctx.handleToggleAddTaskModal
        }, null, 8, ["second-add-button", "onSecondButtonAction"]))
      : _createCommentVNode("", true),
    (_ctx.activeTabId === 1717171)
      ? (_openBlock(), _createBlock(_component_AllTasks, {
          key: 1,
          headers: _ctx.headers,
          refetchAllTasksTrigger: _ctx.refetchAllTasksTrigger
        }, null, 8, ["headers", "refetchAllTasksTrigger"]))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, [
          (!_ctx.isLoading)
            ? (_openBlock(), _createBlock(_component_KanbanInfiniteLoad, {
                key: 0,
                "is-tasks": true,
                stages: _ctx.stages,
                "is-cards-loading": _ctx.isTasksLoading,
                "is-columns-loading": _ctx.isStageLoading,
                onTicketMoved: _ctx.handleTicketMoved,
                onHandleAddStage: _ctx.handleToggleUpdateLayoutModal
              }, null, 8, ["stages", "is-cards-loading", "is-columns-loading", "onTicketMoved", "onHandleAddStage"]))
            : _createCommentVNode("", true)
        ]))
  ], 64))
}