import { defineComponent } from 'vue'
import TabsBar from '@/components/tabsBar/index.vue'
import ComingSoon from '@/components/ComingSoon/index.vue'
import InfoSecurity from '@/views/Settings/GeneralSettings/InfoSecurity/index.vue'
import RolesPermissions from '@/views/Settings/GeneralSettings/RolesPermissions/index.vue'

export default defineComponent({
  name: 'GeneralSettings',
  // mounted() {},
  components: {
    TabsBar,
    InfoSecurity,
    ComingSoon,
    RolesPermissions,
  },
  data() {
    const tabs = [
      { id: 0, name: 'INFO & SECURITY' },
      { id: 1, name: 'ROLES & PERMISSIONS' },
      { id: 2, name: 'PLANS' },
      { id: 3, name: 'REFERRALS' },
      { id: 4, name: 'HELP CENTER' },
      { id: 5, name: 'INTEGRATIONS' },
      { id: 6, name: 'CHAT SETTINGS' },
      { id: 7, name: 'AVAILABLE LIST' },
      { id: 8, name: 'STORAGE' },
      { id: 9, name: 'BACKUP & RESTORE' },
    ]

    return {
      tabs,
      activeTabId: tabs[0].id,
      activeTab: tabs[0].name,
    }
  },
  computed: {},
  methods: {
    /////////////////////////////////////////////////////////////////////////////
    changeTab({ tab }: { tab: { title?: string; name: string; id: number } }) {
      console.log({ tab })

      this.activeTabId = tab.id
      this.activeTab = tab.name
    },
  },
})
