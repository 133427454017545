import { defineComponent } from 'vue'
import SearchAndViews from '@/components/searchAndViews/index.vue'
import ActionsBar from '@/components/actionsBar/index.vue'
import { addWorkspace } from '@/services/workspace'
import { WorkspacePresenter } from '@/presenters/WorkspacePresenter'
import AddWorkspaceModal from '@/components/AddWorkspaceModal/index.vue'
import { setHeaders } from '@/services/API'

const MoreVertIcon = new URL(
  '@/assets/icons/More-vert-icon.svg',
  import.meta.url,
).href

export default defineComponent({
  name: 'Workspaces',
  props: {},
  data() {
    const { user, company } = this.$stores.auth
    console.log({ user, company })
    return {
      user,

      MoreVertIcon,

      workspaces: user.company_users,

      isWorkspaceModalOpen: false,
    }
  },
  emits: [],
  methods: {
    toggleAddWorkspaceModal() {
      this.isWorkspaceModalOpen = !this.isWorkspaceModalOpen
    },
    handleAddWorkspace({ newWorkspace }: { newWorkspace: WorkspacePresenter }) {
      addWorkspace({ newWorkspace })
    },
    searchWorkspacesData(search: string) {
      this.workspaces = this.user.company_users.filter((company) =>
        company?.company?.name?.toLowerCase()?.includes(search.toLowerCase()),
      )
    },
    handleChangeWorkspace(company: any) {
      localStorage.setItem('haqqCompany', JSON.stringify(company))
      this.$stores.auth.$patch((state) => {
        state.company = company
      })

      localStorage.setItem('RoleId', company?.id)
      setHeaders({ RoleId: company?.id })

      window.location.reload()
    },
  },
  components: {
    SearchAndViews,
    ActionsBar,
    AddWorkspaceModal,
  },
})
