<template>
  <VaModal
    v-model="isWorkspaceModalOpenBool"
    size="small"
    hide-default-actions
    fixed-layout
    noOutsideDismiss
    :before-close="beforeAddWorkspaceModalClose"
  >
    <template #header>
      <div class="create-workspace-form-header">
        <h1>Create Workspace</h1>
        <VaIcon
          class="header-icon"
          name="close"
          size="30px"
          @click="handleCancel"
        />
      </div>
    </template>
    <VaForm ref="form" class="create-workspace-form" @submit.prevent="validate">
      <div class="circular-image-upload-modal-container">
        <VaFileUpload
          hideFileList="true"
          v-model="form.image"
          :file-types="$stores.generalData.imagesFormats"
          @update:modelValue="handleFileUpload"
          type="single"
        >
          <div>
            <img
              :src="form?.avatar?.medium_url || UploadAvatarIcon"
              alt="Avatar"
              class="avatar-preview"
            />
          </div>
          <div
            v-if="form?.avatar?.medium_url"
            class="close-icon press-button"
            @click.stop="handleRemoveImage"
          >
            ✕
          </div>
        </VaFileUpload>
      </div>

      <VaInput
        placeholder="Enter workspace's name"
        background="#ECF0F1"
        v-model="form.name"
        label="Workspace Name"
      />

      <VaSelect
        placeholder="Workspace"
        background="#ECF0F1"
        v-model="form.workspaceParent"
        label="Workspace Parent"
        searchable
        clearable
        :options="workspaceSearchOptions"
        :highlight-matched-text="false"
      />

      <VaSelect
        placeholder="Workspace Manager"
        background="#ECF0F1"
        v-model="form.manager"
        label="Workspace Manager"
        searchable
        :options="recipientsList"
        :text-by="getOptionFullName"
        :highlight-matched-text="false"
      />

      <VaSelect
        placeholder="Members"
        background="#ECF0F1"
        v-model="form.members"
        label="Workspace Members"
        searchable
        :options="recipientsList"
        :text-by="getOptionFullName"
        :highlight-matched-text="false"
        multiple
      />

      <VaTextarea
        v-model="form.biography"
        max-rows="5"
        background="#ECF0F1"
        label="Workspace Bio"
        placeholder="Write here the workspace's bio"
        autosize
        class="text-area"
      />
    </VaForm>
    <template #footer>
      <div class="add-workspace-form-modal-buttons">
        <VaButton
          preset="secondary"
          border-color="primary"
          @click="handleCancel"
          >Cancel</VaButton
        >
        <VaButton
          @click="validate() && handleAddWorkspace()"
          :loading="addWorkspaceLoading"
          >Create Workspace</VaButton
        >
      </div>
    </template>
  </VaModal>
</template>
<script lang="ts" src="./script.ts" />
<style lang="scss" scoped src="./styles.scss" />
