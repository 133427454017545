import { defineComponent } from 'vue'
import FileUploadHelper, { validateInput, getOptionFullName } from '@/lib/utils'
import { useForm } from 'vuestic-ui/web-components'
import { getMembers } from '@/services/company'
import { CompanyUserPresenter } from '@/presenters/CompanyUserPresenter'
import { WorkspacePresenter } from '@/presenters/WorkspacePresenter'
import { Avatar } from '@/presenters/interface'

const UploadAvatarIcon = new URL(
  '@/assets/icons/upload-avatar-icon.svg',
  import.meta.url,
).href

export default defineComponent({
  name: 'AddWorkspaceModal',
  props: {
    isWorkspaceModalOpen: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    const { validate, reset } = useForm('form')

    const workspaceSearchOptions = ['Workspace1', 'Workspace2']

    return {
      validate,
      reset,

      validateInput,
      getOptionFullName,

      isAddToDraftModalOpen: false,
      addWorkspaceLoading: false,

      workspaceSearchOptions,

      form: {} as WorkspacePresenter,
      recipientsList: [] as CompanyUserPresenter[],

      UploadAvatarIcon,
    }
  },
  emits: ['toggleAddWorkspaceModal', 'handleAddWorkspace'],
  methods: {
    beforeAddWorkspaceModalClose() {
      if (this.isWorkspaceModalOpenBool) {
        this.handleCancel()
      }
    },
    async handleGetMembers() {
      const response = await getMembers({
        company_id: this.$stores.auth.company.company_id,
        as_company_user: true,
      })
      if (response.success) {
        this.recipientsList = response.data?.list
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
    },
    handleAddWorkspace() {
      this.addWorkspaceLoading = true

      this.$emit('handleAddWorkspace', this.form)
      this.handleCancel()

      this.addWorkspaceLoading = false
    },

    handleCancel() {
      this.$emit('toggleAddWorkspaceModal')
      this.form = {} as WorkspacePresenter
      this.handleRemoveImage()
    },

    handleRemoveImage() {
      this.form.image = new File([], 'empty.jpg', {
        type: 'image/jpeg',
      })
      this.form.avatar = {} as Avatar
    },

    handleFileUpload(event: File) {
      FileUploadHelper.handleFileUpload({
        event,
        form: this.form,
        key: 'image',
      })
    },
  },
  computed: {
    isWorkspaceModalOpenBool: {
      get(): boolean {
        return this.isWorkspaceModalOpen
      },
      set() {
        this.handleCancel()
      },
    },
  },
  mounted() {
    this.handleGetMembers()
  },
  components: {},
})
