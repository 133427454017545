import { TableColumnInterface } from '@/Interfaces/Constants'
import { peopleTemplateHeaders } from './peopleTemplate'
import { organizationsTemplateHeaders } from './organizationsTemplate'
import { allTasksTemplateHeaders } from './allTasksTemplate'
import { leadsTemplateHeaders } from './leadsTableTemplate'
import { matterTemplateHeaders } from './matterTableTemplate'

export class TableDataExporter {
  // Map to store all table column arrays
  private static readonly tableColumnsMap: Map<string, TableColumnInterface[]> =
    new Map([
      ['IndividualContact', peopleTemplateHeaders as TableColumnInterface[]],
      [
        'OrganizationContact',
        organizationsTemplateHeaders as TableColumnInterface[],
      ],
      ['Leads', leadsTemplateHeaders as TableColumnInterface[]],
      ['All Tasks', allTasksTemplateHeaders as TableColumnInterface[]],
      ['All Matter', matterTemplateHeaders as TableColumnInterface[]],
    ])

  // Method to get table columns by name
  public static getTableColumns(name: string): TableColumnInterface[] {
    const columns = this.tableColumnsMap.get(name)
    if (!columns) {
      return peopleTemplateHeaders
    }
    return columns
  }

  // Optional: Get all available array names
  public static getAvailableTableNames(): string[] {
    return Array.from(this.tableColumnsMap.keys())
  }
}
