import { defineComponent } from 'vue'
import ComingSoon from '@/components/ComingSoon/index.vue'
import SharedHeader from '@/components/sharedHeader/index.vue'
import TabsBar from '@/components/tabsBar/index.vue'
import GeneralSettings from '@/views/Settings/GeneralSettings/index.vue'
import MattersSettings from '@/views/Settings/MattersSettings/index.vue'
import HearingsSettings from '@/views/Settings/HearingsSettings/index.vue'
import TasksSettings from '@/views/Settings/TasksSettings/index.vue'
import MilestonesSetting from '@/views/Settings/MilestonesSetting/index.vue'
import CalendarSettings from '@/views/Settings/CalendarSettings/index.vue'
import FinancialSettings from '@/views/Settings/FinancialSettings/index.vue'
import ContactsSettings from '@/views/Settings/ContactsSettings/index.vue'
import LeadsSettings from '@/views/Settings/LeadsSettings/index.vue'

import { useTabs } from '@/composables/useTabs'

export default defineComponent({
  name: 'Settings',
  components: {
    ComingSoon,
    SharedHeader,
    TabsBar,
    GeneralSettings,
    MattersSettings,
    HearingsSettings,
    TasksSettings,
    MilestonesSetting,
    CalendarSettings,
    FinancialSettings,
    ContactsSettings,
    LeadsSettings,
  },
  data() {
    const tabs = [
      { id: 0, name: 'General' },
      { id: 1, name: 'Home' },
      { id: 2, name: 'Leads' },
      { id: 3, name: 'Contacts' },
      { id: 4, name: 'Matters' },
      { id: 5, name: 'Milestones' },
      { id: 6, name: 'Hearings' },
      { id: 7, name: 'Library' },
      { id: 8, name: 'Market' },
      { id: 9, name: 'Tasks' },
      { id: 10, name: 'Calendar' },
      { id: 11, name: 'Files' },
      { id: 12, name: 'Financial' },
      { id: 13, name: 'Firm' },
    ]
    const { activeTabId, activeTab, changeTab } = useTabs(tabs)
    return {
      activeTabId,
      activeTab,
      changeTab,
      tabs,
    }
  },
  methods: {},
})
