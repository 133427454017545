<template>
  <SharedHeader :back-button="true" title-width="30rem" :title="form.title">
    <div class="sub-SharedHeader">
      <VaButton
        @click="form.won = true"
        :color="form.won ? 'success' : '#808080'"
        gradient
      >
        Won
      </VaButton>

      <VaButton
        @click="form.won = false"
        :color="!form.won ? 'danger' : '#808080'"
        gradient
      >
        Loss
      </VaButton>
    </div>
  </SharedHeader>
  <TabsBar :tabs="tabs" :active-tab-id="activeTabId" @changeTab="changeTab" />
  <div class="scroll-container inherit-height">
    <VaForm
      ref="form"
      class="form-modal"
      @submit.prevent="validate() && handleUpdateLead()"
    >
      <div class="form-modal-row">
        <VaSelect
          v-model="form.individual_contact"
          placeholder="Contact"
          background="#ECF0F1"
          label="Contact"
          searchable
          clearable
          :highlight-matched-text="false"
          :options="$stores.contacts.contacts"
          :text-by="getOptionFullName"
          :loading="$stores.contacts.contactsLoading"
          @update:search="$stores.contacts.handleSearchContacts"
          track-by="id"
        />
      </div>

      <div class="form-modal-row">
        <VaSelect
          v-model="form.organization_contact"
          placeholder="Organization"
          background="#ECF0F1"
          label="Organization"
          searchable
          clearable
          :highlight-matched-text="false"
          :options="$stores.contacts.organizations"
          :text-by="getOptionTruncateName50"
          :loading="$stores.contacts.organizationsLoading"
          @update:search="$stores.contacts.handleSearchOrganizations"
          track-by="id"
        />
      </div>
      <div class="form-modal-row">
        <VaInput
          v-model="form.title"
          placeholder="Title"
          background="#ECF0F1"
          label="Title"
        />
      </div>

      <div class="form-modal-row">
        <VaInput
          v-model="form.lead_value"
          placeholder="Value"
          background="#ECF0F1"
          label="Value"
          type="number"
        />
        <VaSelect
          v-model="form.currency"
          label="main currency"
          background="#ECF0F1"
          :options="$stores.currency.currencies"
          :text-by="getOptionCurrency"
          :loading="$stores.currency.currencyLoading"
          track-by="id"
        />
      </div>
      <div class="form-modal-row">
        <VaSelect
          v-model="form.stage"
          placeholder="Stage"
          background="#ECF0F1"
          label="Stage"
          :options="stages"
          :highlight-matched-text="false"
          :text-by="getOptionName"
          :loading="isStagesLoading"
          track-by="id"
        />
      </div>

      <div class="form-modal-row">
        <VaSelect
          v-model="form.label"
          label="Label"
          background="#ECF0F1"
          :options="$stores.generalData.companyConfigs.lead_label"
          :text-by="getOptionName"
          track-by="id"
        />
      </div>

      <div class="form-modal-row">
        <VaInput
          v-model="form.probability"
          placeholder="Probability"
          background="#ECF0F1"
          label="Probability"
          type="number"
          min="0"
          max="100"
          :rules="[validateProbability]"
        />
      </div>

      <div class="form-modal-row">
        <VaDateInput
          v-model="form.expected_close_date"
          placeholder="MM/DD/YYYY"
          label="expected close date"
          background="#ECF0F1"
          manual-input
        />
      </div>

      <div class="form-modal-row">
        <VaSelect
          v-model="form.relationship_owner"
          placeholder="Relationship Owner"
          background="#ECF0F1"
          label="Relationship Owner"
          searchable
          :options="$stores.usersData.teamMembers"
          :text-by="getOptionFullName"
          :highlight-matched-text="false"
          :loading="$stores.usersData.membersLoading"
          @update:search="$stores.usersData.handleSearchMembers"
          track-by="id"
        />
      </div>

      <div class="action-buttons">
        <VaButton
          :loading="isUpdateLoading"
          preset="primary"
          @click="validate() && handleUpdateLead()"
        >
          Update
        </VaButton>
      </div>
    </VaForm>
  </div>
</template>

<script lang="ts" src="./script.ts" />
<style lang="scss" scoped src="./styles.scss" />
