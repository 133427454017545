import { IndividualContactPresenter } from '../contactPresenter'
import { ExpensesPresenter } from '../ExpensesPresenter'
import { HearingsPresenter } from '../hearingsPresenter'
import { InvoicePresenter } from '../InvoicesPresenter'
import { MatterPresenter } from '../matterPresenter'
import { MilestonePresenter } from '../MilestonePresenter'
import { OrganizationContactPresenter } from '../organizationPresenter'
import { TaskPresenter } from '../taskPresenter'
import { VersionPresenter } from '../VersionPresenter'

type NotifiableMapping = {
  IndividualContact: IndividualContactPresenter
  OrganizationContact: OrganizationContactPresenter
  Expense: ExpensesPresenter
  Hearing: HearingsPresenter
  Invoice: InvoicePresenter
  LegalMatter: MatterPresenter
  Milestone: MilestonePresenter
  Task: TaskPresenter
}

const notifiableType = {
  IndividualContact: IndividualContactPresenter,
  OrganizationContact: OrganizationContactPresenter,
  Expense: ExpensesPresenter,
  Hearing: HearingsPresenter,
  Invoice: InvoicePresenter,
  LegalMatter: MatterPresenter,
  Milestone: MilestonePresenter,
  Task: TaskPresenter,
} as const

type NotifiableTypeKey = keyof typeof notifiableType

export class NotificationPresenter {
  id!: number
  class_name!: string
  created_at!: string

  notifiable!: NotifiableMapping[NotifiableTypeKey] // Dynamic mapping.

  notifiable_id!: number
  notifiable_type!: NotifiableTypeKey
  notification_group_type!: string
  updated_at!: string

  version!: VersionPresenter

  view_description!: string
  view_title!: string
  viewed_at!: string | null

  constructor(data: NotificationPresenter) {
    Object.assign(this, data)

    const PresenterClass = notifiableType[data.notifiable_type]
    if (PresenterClass && data.notifiable) {
      this.notifiable = new PresenterClass(data.notifiable as any)
    }

    if (data.version) {
      this.version = new VersionPresenter(data.version)
    }
  }
}
