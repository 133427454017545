export class MessagePresenter {
  id!: number
  content!: string
  date!: string
  isRead!: boolean
  attachments?: []
  isMe!: boolean

  constructor(data: MessagePresenter) {
    Object.assign(this, data)
  }
}
