import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomePage from '@/views/Home/index.vue'
import SettingsPage from '@/views/Settings/index.vue'
import ContactsPage from '@/views/Contacts/index.vue'
import ContactDetailsPage from '@/views/Contacts/ContactDetails/index.vue'
import OrganizationDetailsPage from '@/views/Contacts/OrganizationDetails/index.vue'
import LeadsPage from '@/views/Leads/index.vue'
import LeadsDetailsPage from '@/views/Leads/LeadsDetails/index.vue'
import CalendarPage from '@/views/Calendar/index.vue'
import LegalMattersPage from '@/views/legalMatters/index.vue'
import LegalMattersDetailsPage from '@/views/legalMatters/MatterDetails/index.vue'
import HearingsPage from '@/views/Hearings/index.vue'
import HearingsDetailsPage from '@/views/Hearings/Details/index.vue'
import LegalLibraryPage from '@/views/LegalLibrary/index.vue'
import FilesPage from '@/views/files/index.vue'
import FinancialPage from '@/views/financial/index.vue'
import InvoicePage from '@/views/financial/Invoice/index.vue'
import ExpensePage from '@/views/financial/Expense/index.vue'
import TasksPage from '@/views/Tasks/index.vue'
import TaskDetailsPage from '@/views/Tasks/Details/index.vue'
import FirmPage from '@/views/firm/index.vue'
import MemberProfilePage from '@/views/firm/MemberProfile/index.vue'
import LegalMarketPage from '@/views/LegalMarket/index.vue'
import EmailsPage from '@/views/Emails/index.vue'
import RecycleBinPage from '@/views/RecycleBin/index.vue'
import NotFound from '@/components/NotFound/index.vue'
import DefaultLayout from '../../layouts/Default/index.vue' // Import the layout component

const routes: Array<RouteRecordRaw> = [
  {
    path: '/login',
    name: 'LoginView',
    component: () => import('@/views/Login/index.vue'),
    meta: { layout: 'none' }, // No layout for login
  },
  {
    path: '/',
    component: DefaultLayout, // Wrap with the layout
    meta: { requiresAuth: true },
    children: [
      {
        path: '',
        name: 'HomeView',
        component: HomePage,
      },
      {
        path: 'settings',
        name: 'SettingsView',
        component: SettingsPage,
      },
      {
        path: 'contacts',
        name: 'ContactsView',
        component: ContactsPage,
        meta: { permission: 'Contact', action: 'read' },
      },
      {
        path: 'contacts/people/:id',
        name: 'ContactDetailsView',
        component: ContactDetailsPage,
        meta: { permission: 'Contact', action: 'read' },
      },
      {
        path: 'contacts/organization/:id',
        name: 'OrganizationDetailsView',
        component: OrganizationDetailsPage,
        meta: { permission: 'Contact', action: 'read' },
      },

      {
        path: 'leads',
        name: 'LeadsView',
        component: LeadsPage,
      },
      {
        path: 'contacts/leads/:id',
        name: 'LeadsDetailsView',
        component: LeadsDetailsPage,
      },

      {
        path: 'calendar',
        name: 'CalendarView',
        component: CalendarPage,
      },
      {
        path: 'legal-matters',
        name: 'LegalMattersView',
        component: LegalMattersPage,
        meta: { permission: 'LegalMatter', action: 'read' },
      },
      {
        path: 'legal-matters/:id',
        name: 'LegalMattersDetails',
        component: LegalMattersDetailsPage,
        meta: { permission: 'LegalMatter', action: 'read' },
      },
      {
        path: 'hearings',
        name: 'HearingsView',
        component: HearingsPage,
        meta: { permission: 'Hearing', action: 'read' },
      },
      {
        path: 'hearings/:id',
        name: 'HearingsDetailsView',
        component: HearingsDetailsPage,
        meta: { permission: 'Hearing', action: 'read' },
      },
      {
        path: 'legal-library',
        name: 'LegalLibrary',
        component: LegalLibraryPage,
      },
      {
        path: 'files',
        name: 'FilesView',
        component: FilesPage,
        meta: { permission: 'StorageFile', action: 'read' },
      },
      {
        path: 'financial',
        name: 'FinancialView',
        component: FinancialPage,
        meta: { permission: 'Invoice', action: 'read' },
      },
      {
        path: 'financial/invoice/:id',
        name: 'InvoiceView',
        component: InvoicePage,
        meta: { permission: 'Invoice', action: 'read' },
      },
      {
        path: 'financial/expense/:id',
        name: 'ExpenseView',
        component: ExpensePage,
        meta: { permission: 'Invoice', action: 'read' },
      },
      {
        path: 'tasks',
        name: 'Tasks',
        component: TasksPage,
        meta: { permission: 'Task', action: 'read' },
      },
      {
        path: 'tasks/:id',
        name: 'TaskDetails',
        component: TaskDetailsPage,
        meta: { permission: 'Task', action: 'read' },
      },
      {
        path: 'firm',
        name: 'Firm',
        component: FirmPage,
      },
      {
        path: 'firm/members/:id',
        name: 'Member',
        component: MemberProfilePage,
      },
      {
        path: 'legal-market',
        name: 'LegalMarket',
        component: LegalMarketPage,
      },
      {
        path: 'emails',
        name: 'Emails',
        component: EmailsPage,
      },
      {
        path: 'recycleBin',
        name: 'RecycleBin',
        component: RecycleBinPage,
      },
    ],
  },
  {
    path: '/:pathMatch(.*)*', // This catches all undefined routes
    name: 'NotFound',
    component: NotFound,
    meta: { requiresAuth: true },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

// Add authentication guard
router.beforeEach((to, from, next) => {
  const isAuthenticated = !!localStorage.getItem('authaqq') // Example of auth check (customize this to your needs)
  const company = JSON.parse(localStorage.getItem('haqqCompany') || '{}')
  const permissions = company?.roles_extended || {}

  // if (
  //   to.matched.some((record) => record.meta.requiresAuth) &&
  //   !isAuthenticated
  // ) {
  //   next({ name: 'LoginView' }) // Redirect to login page if not authenticated
  // } else {
  //   next() // Proceed to the route
  // }

  if (!isAuthenticated && to.meta.requiresAuth) {
    console.warn('User not authenticated, redirecting to LoginView')

    next({ name: 'LoginView' })
  } else if (to.meta.permission) {
    const permissionKey = to.meta.permission as string
    const action = (to.meta.action as string) || 'read'

    if (!permissions[permissionKey] || !permissions[permissionKey][action]) {
      next({ name: 'HomeView' }) // Redirect unauthorized users
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
