import { defineComponent } from 'vue'
import Button from '@/components/ui/button/index.vue'

const filterIcon = new URL('@/assets/icons/filter.svg', import.meta.url).href

const addIcon = new URL('../../assets/icons/add-no-border.svg', import.meta.url)
  .href

const whiteAddIcon = new URL(
  '../../assets/icons/add-no-border-white.svg',
  import.meta.url,
).href

const listViewIcon = new URL(
  '../../assets/icons/List-view-icon.svg',
  import.meta.url,
).href

const kanbanView = new URL(
  '../../assets/icons/kanban-view.svg',
  import.meta.url,
).href

export default defineComponent({
  name: 'SearchAndViews',
  components: {
    Button,
  },
  props: {
    activeView: {
      type: String,
      required: true,
    },
    withViews: {
      type: Boolean,
      required: true,
    },
    withAddButton: {
      type: Object as () => {
        buttonText: string
        buttonAction: () => void
        isSecondary: boolean
      },
      required: false,
    },
    search: {
      type: String,
      default: '', // Default to empty string if not provided
    },
  },
  data() {
    return {
      internalSearch: this.search, // Initialize with the prop value
      filterIcon,

      addIcon,
      whiteAddIcon,
      listViewIcon,
      kanbanView,
    }
  },
  computed: {
    showViews(): boolean {
      return this.withViews
    },
  },
  watch: {
    // Watch for changes to the search prop and update internalSearch accordingly
    search(newValue) {
      this.internalSearch = newValue
    },
    // If internalSearch changes, emit the updated value to the parent
    internalSearch(newValue) {
      this.$emit('search', newValue)
    },
  },
  methods: {
    // Handle view change and emit event
    changeView(view: string) {
      this.$emit('changeView', view)
    },
    // Emit event to open the filter modal
    openFilterModal() {
      this.$emit('openFilterModal')
    },
  },
})
