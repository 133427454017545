import { paginationInterface } from '@/Interfaces/Services'
import { defineComponent } from 'vue'
import { getAllLeads } from '@/services/leads'
import { LeadPresenter } from '@/presenters/LeadPresenter'
import LeadTable from '@/components/Contacts/Leads/LeadTable/index.vue'
import SearchAndViews from '@/components/searchAndViews/index.vue'
import { StagesPresenter } from '@/presenters/StagesPresenter'

export default defineComponent({
  name: '',
  props: {
    refetchAllLeadsTrigger: {
      type: Boolean,
      required: false,
    },
    sections: {
      type: Array as () => StagesPresenter[],
      required: true,
    },
    headers: {
      type: Array,
      required: true,
    },
  },
  emits: [],
  data() {
    return {
      leadTableLoading: false,

      selectedRows: [] as LeadPresenter[],

      leads: [] as LeadPresenter[],
      leadsItemPerPage: 10,
      leadPage: 1,
      leadSortField: '' as any,
      leadSortDirection: '' as 'asc' | 'desc' | undefined,
      leadSearchKeyword: '',
      leadTotalItems: 0,

      debounceTimeout: null as ReturnType<typeof setTimeout> | null,
    }
  },
  mounted() {
    this.handleGetLeads()
  },
  methods: {
    getSelectedRows(rows: LeadPresenter[]) {
      this.selectedRows = rows
    },
    ////////////////////////////////////////////////////////////////////////////
    async handleGetLeads() {
      this.leadTableLoading = true

      const response = await getAllLeads({
        per_page: this.leadsItemPerPage,
        page: this.leadPage,
        keyword: this.leadSearchKeyword,
      })
      if (response.success) {
        this.leads = response.data?.list
        this.leadTotalItems = response.data.total_pages * this.leadsItemPerPage
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.leadTableLoading = false
    },

    async handlePaginationLeads({
      page,
      itemsPerPage,
      sortBy,
    }: paginationInterface) {
      this.leadsItemPerPage = itemsPerPage
      this.leadPage = page
      if (sortBy.length > 0) {
        this.leadSortField = sortBy[0]?.key.split('.')[1]
        this.leadSortDirection = sortBy[0]?.order
      } else {
        this.leadSortField = undefined
        this.leadSortDirection = undefined
      }

      await this.handleGetLeads()
    },

    searchLeadData(search: string) {
      this.leadSearchKeyword = search

      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout)
      }

      this.debounceTimeout = setTimeout(() => {
        this.handleGetLeads()
      }, 500)
    },

    ////////////////////////////////////////////////////////////////////////////
  },
  watch: {
    refetchAllLeadsTrigger: {
      handler(data) {
        this.handleGetLeads()
      },
    },
  },
  components: {
    LeadTable,
    SearchAndViews,
  },
})
