<template>
  <div class="time-record-container">
    <VaButton
      class="time-record-container-button"
      preset="primary"
      @click="showRecord = !showRecord"
    >
      <div class="timer-container">
        <img
          :src="isTimeRecord ? playImage : stopImage"
          alt="LOGO"
          style="width: 1.8rem; height: 1.8rem; margin-right: 5px"
        />
        <span v-if="!isTimeRecord">Running tasks...</span>
        <div
          v-if="timerStore.elapsedTime > 0"
          style="flex-shrink: 0; width: 3.3rem"
        >
          {{ timerStore.formattedTime }}
        </div>

        <v-menu
          v-model="showRecord"
          :close-on-content-click="false"
          location="bottom right"
        >
          <template #activator="{ props }">
            <img
              v-bind="props"
              :src="openWorkspaces"
              alt="Record icon"
              style="width: 1.8rem; height: 1.8rem; margin-left: 5px"
            />
          </template>

          <v-card width="24rem" height="70vh" class="time-log-card">
            <VaTabs
              v-model="timerTab"
              stateful
              grow
              color="#fff"
              class="time-log-header-tabs"
            >
              <template #tabs>
                <VaTab
                  v-for="title in timerTabs"
                  :key="title"
                  :name="title"
                  color="#fff"
                >
                  {{ title }}
                </VaTab>
              </template>
            </VaTabs>
            <VaForm
              @submit.prevent=""
              v-if="timerTab === 'Timer'"
              ref="form"
              class="time-log-header"
            >
              <v-menu
                v-model="isItemAssignNew"
                :close-on-content-click="false"
                location="bottom right"
              >
                <template #activator="{ props }">
                  <div v-bind="props" class="link-to-section">
                    <VaIcon name="link" color="#fff" />
                    <div
                      v-if="
                        calendarItemsForm.calendar_item_linked_items &&
                        calendarItemsForm.calendar_item_linked_items.length > 0
                      "
                      class="linked-items-container press-button"
                    >
                      <div
                        v-for="(
                          item, index
                        ) in calendarItemsForm.calendar_item_linked_items"
                        :key="index"
                        class="linked-item"
                      >
                        <v-tooltip
                          :text="camelCaseToNormal(item.class_name || '')"
                          location="top"
                        >
                          <template #activator="{ props }">
                            <VaIcon
                              v-bind="props"
                              :name="item.icon"
                              color="primary"
                              size="26px"
                            />
                          </template>
                        </v-tooltip>
                        <span class="linked-item-name">
                          {{ item.name || item.fullName }}
                        </span>
                      </div>
                    </div>

                    <p v-else class="press-button">Link to</p>
                  </div>
                </template>
                <TimeLinkModal
                  :linked-items="calendarItemsForm.calendar_item_linked_items"
                  @handleLinkTo="handleLinkToNew"
                  @handleToggleAssignToMenu="handleToggleAssignToMenu"
                />
              </v-menu>

              <VaInput
                v-model="calendarItemsForm.description"
                background="#97537a"
                placeholder="Description..."
              >
                <template #prependInner>
                  <img :src="descriptionImage" alt="description icon" />
                </template>
              </VaInput>

              <div class="time-log-header-bottom">
                <div class="circular-background-start-image">
                  <div v-if="formRecordLoading" class="play-image">
                    <VaIcon
                      class="loading-icon"
                      name="autorenew"
                      color="primary"
                    />
                  </div>
                  <img
                    v-else
                    :src="timerStore.isPlaying ? stopImage : playImage"
                    class="play-image"
                    @click="handleStartFormTimer"
                  />
                </div>
                <!-- {{ timerStore.formattedTime }} -->
                <VaButton
                  round
                  icon="attach_money"
                  :color="calendarItemsForm.billable ? '#38A169' : '#b8c4d6'"
                  class="dollar-button"
                  @click="
                    calendarItemsForm.billable = !calendarItemsForm.billable
                  "
                />
              </div>
            </VaForm>

            <div v-else-if="timerTab === 'Manual'" class="time-log-Manual">
              <div class="option-assign-manual">
                <VaIcon name="link" color="primary" size="18px" />
                <v-menu
                  v-model="isItemAssignManual"
                  :close-on-content-click="false"
                  location="bottom left"
                >
                  <template #activator="{ props }">
                    <div v-bind="props" class="linked-items-container">
                      <div
                        v-if="
                          manualForm.calendar_item_linked_items &&
                          manualForm.calendar_item_linked_items.length > 0
                        "
                        class="linked-items-container press-button"
                      >
                        <div
                          v-for="(
                            item, index
                          ) in manualForm.calendar_item_linked_items"
                          :key="index"
                          class="linked-item"
                        >
                          <v-tooltip
                            :text="camelCaseToNormal(item.class_name || '')"
                            location="top"
                          >
                            <template #activator="{ props }">
                              <VaIcon
                                v-bind="props"
                                :name="item.icon"
                                color="primary"
                                size="20px"
                              />
                            </template>
                          </v-tooltip>
                          <span class="linked-item-name">
                            {{ item.name || item.fullName }}
                          </span>
                        </div>
                      </div>
                      <p v-else class="press-button">Link to</p>
                    </div>
                  </template>

                  <TimeLinkModal
                    :linked-items="manualForm.calendar_item_linked_items"
                    @handleLinkTo="handleLinkToManual"
                    @handleToggleAssignToMenu="handleToggleAssignToMenu"
                  />
                </v-menu>
                <!-- <p>Task/Matter name</p> -->
              </div>
              <VaDivider />
              <div class="option-update">
                <div class="option-update-time">
                  <VaIcon name="manage_history" color="primary" size="18px" />
                  <DatePickerModal
                    :date="manualForm.start_time"
                    shape="time-record-small"
                    @update:date="handleUpdateStartDateManual"
                  />
                  -
                  <DatePickerModal
                    :date="manualForm.end_time"
                    shape="time-record-small"
                    @update:date="handleUpdateEndDateManual"
                  />
                </div>
                <VaDivider />

                <div class="option-update-note">
                  <VaIcon name="subject" color="primary" size="18px" />
                  <VaInput
                    v-model="manualForm.description"
                    placeholder="Description..."
                    background="#fff"
                  />
                </div>
              </div>
              <div class="time-log-Manual-footer">
                <div class="option-footer-btn">
                  <VaButton
                    round
                    icon="attach_money"
                    class="dollar-button"
                    :color="manualForm.billable ? '#38A169' : '#b8c4d6'"
                    @click="manualForm.billable = !manualForm.billable"
                  />
                  <VaButton
                    preset="primary"
                    :loading="manualFormLoading"
                    @click="handleCreateManual"
                  >
                    Add
                  </VaButton>
                </div>
              </div>
            </div>

            <VaForm
              @submit.prevent=""
              v-if="timerTab === 'Range'"
              ref="rangeForm"
              class="time-log-header"
            >
              <v-menu
                v-model="isItemAssignNew"
                :close-on-content-click="false"
                location="bottom right"
              >
                <template #activator="{ props }">
                  <div v-bind="props" class="link-to-section">
                    <VaIcon name="link" color="#fff" />
                    <div
                      v-if="
                        calendarItemsRangeForm.calendar_item_linked_items &&
                        calendarItemsRangeForm.calendar_item_linked_items
                          .length > 0
                      "
                      class="linked-items-container press-button"
                    >
                      <div
                        v-for="(
                          item, index
                        ) in calendarItemsRangeForm.calendar_item_linked_items"
                        :key="index"
                        class="linked-item"
                      >
                        <v-tooltip
                          :text="camelCaseToNormal(item.class_name || '')"
                          location="top"
                        >
                          <template #activator="{ props }">
                            <VaIcon
                              v-bind="props"
                              :name="item.icon"
                              color="primary"
                              size="26px"
                            />
                          </template>
                        </v-tooltip>
                        <span class="linked-item-name">
                          {{ item.name || item.fullName }}
                        </span>
                      </div>
                    </div>

                    <p v-else class="press-button">Link to</p>
                  </div>
                </template>
                <TimeLinkModal
                  :linked-items="
                    calendarItemsRangeForm.calendar_item_linked_items
                  "
                  @handleLinkTo="handleLinkToRange"
                  @handleToggleAssignToMenu="handleToggleAssignToMenu"
                />
              </v-menu>

              <VaInput
                v-model="calendarItemsRangeForm.description"
                background="#97537a"
                placeholder="Description..."
              >
                <template #prependInner>
                  <img :src="descriptionImage" alt="description icon" />
                </template>
              </VaInput>

              <div class="time-log-header-bottom range">
                <VaButton
                  round
                  icon="attach_money"
                  :color="
                    calendarItemsRangeForm.billable ? '#38A169' : '#b8c4d6'
                  "
                  width="2.5rem"
                  @click="
                    calendarItemsRangeForm.billable =
                      !calendarItemsRangeForm.billable
                  "
                />

                <div class="range-container">
                  <VaInput
                    v-model="timeRange.hours"
                    background="#97537a"
                    type="number"
                    min="0"
                    step="1"
                    @input="handleRangeTimeToSec"
                  >
                    <template #appendInner>
                      <span>hr</span>
                    </template>
                  </VaInput>
                  <p>:</p>
                  <VaInput
                    v-model="timeRange.minutes"
                    background="#97537a"
                    type="number"
                    min="0"
                    step="1"
                    @input="handleRangeTimeToSec"
                  >
                    <template #appendInner>
                      <span>min</span>
                    </template>
                  </VaInput>
                </div>

                <VaButton
                  color="#97537a"
                  border-color="#b8c4d6"
                  :loading="calendarItemsRangeFormLoading"
                  :disabled="timeRange.hours === '' || timeRange.minutes === ''"
                  @click="handleAddTimeRange()"
                >
                  Add
                </VaButton>
              </div>
            </VaForm>

            <div class="time-log-section">
              <VaTabs stateful grow model-value="Me">
                <template #tabs>
                  <VaTab
                    v-for="title in ['Me', 'Global']"
                    :key="title"
                    :name="title"
                  >
                    {{ title }}
                  </VaTab>
                </template>
              </VaTabs>

              <div
                class="time-log-section-details-container"
                @scroll="onScrollTimeRecord"
              >
                <div
                  v-for="(record, index) in $stores.mockTimer.calendarItems"
                  :key="index"
                  class="time-log-section-details"
                >
                  <div
                    v-if="!record.time_range"
                    class="circular-background-stop-image"
                  >
                    <div v-if="loadingStates[index]" class="play-image">
                      <VaIcon
                        class="loading-icon"
                        name="autorenew"
                        color="primary"
                      />
                    </div>

                    <img
                      v-else
                      :src="record.end_time ? playImage : stopImage"
                      alt="LOGO"
                      class="play-image"
                      @click="handleStartEndRecord(record, index)"
                    />
                  </div>

                  <div
                    v-else-if="record.time_range"
                    class="circular-background-stop-image"
                  >
                    <div class="play-image">
                      <font-awesome-icon
                        icon="stopwatch"
                        style="width: 1rem; height: 1rem"
                      />
                    </div>
                  </div>

                  <div class="linked-items-view">
                    <div class="linked-items-container">
                      <div
                        v-for="(
                          item, index
                        ) in record.calendar_item_linked_items"
                        :key="index"
                        class="linked-item"
                      >
                        <v-tooltip
                          :text="camelCaseToNormal(item.class_name || '')"
                          location="top"
                        >
                          <template #activator="{ props }">
                            <VaIcon
                              v-bind="props"
                              :name="item.icon"
                              color="primary"
                              size="16px"
                            />
                          </template>
                        </v-tooltip>
                        <span class="linked-item-name">
                          {{ item.name || item.fullName }}
                        </span>
                      </div>
                      <p
                        v-if="record?.calendar_item_linked_items.length < 1"
                        class="record-details"
                      >
                        Nothing selected
                      </p>
                    </div>

                    <p v-if="record.description" class="record-details">
                      <VaIcon name="subject" size="15px" />
                      {{ record?.description }}
                    </p>

                    <p v-if="!record.time_range" class="record-time">
                      {{
                        formatDateRange(
                          record.start_time,
                          record.end_time,
                          true,
                        )
                      }}

                      {{ runningTimes[record.id] }}
                    </p>

                    <p v-if="record.time_range" class="record-time">
                      {{
                        new Date(record.start_time).toLocaleDateString(
                          'en-US',
                          {
                            weekday: 'short',
                            month: 'short',
                            day: 'numeric',
                          },
                        )
                      }}
                      ,
                      {{
                        formatTimeSeconds({
                          totalSeconds: record.total_time_spent_seconds,
                        })
                      }}
                    </p>
                  </div>

                  <v-menu
                    v-model="showRecordOptionAsObject[index]"
                    :close-on-content-click="false"
                    location="end"
                    @click:outside.stop="closeAllMenus"
                  >
                    <template #activator="{ props }">
                      <VaIcon
                        name="more_vert"
                        v-bind="props"
                        class="vert-icon press-button-2"
                        @click="toggleMenu(index, record)"
                      />
                    </template>

                    <v-card width="22rem" class="option-card">
                      <div class="option-header">
                        <p>
                          <VaAvatar
                            v-if="
                              user.avatar_first_letter ||
                              user?.avatar?.medium_url
                            "
                            :src="user?.avatar?.medium_url"
                            class="option-header-avatar"
                            color="#B7C3D5"
                            size="18px"
                          >
                            <span v-if="!user?.avatar?.medium_url">
                              {{ user.avatar_first_letter }}
                            </span> </VaAvatar
                          >{{ user.fullName }}
                        </p>
                        <div class="option-header-buttons">
                          <VaButton
                            round
                            :icon="deleteRecordLoading ? 'sync' : 'delete'"
                            size="small"
                            preset="secondary"
                            hover-behavior="opacity"
                            :hover-opacity="0.4"
                            :class="[deleteRecordLoading && 'spin-animation']"
                            @click="handleDeleteRecord"
                          />

                          <VaButton
                            round
                            icon="close"
                            size="small"
                            preset="secondary"
                            hover-behavior="opacity"
                            :hover-opacity="0.1"
                            @click="closeAllMenus"
                          />
                        </div>
                      </div>

                      <div class="option-spent-time">
                        {{
                          formatTimeSeconds({
                            totalSeconds:
                              selectedRecord.total_time_spent_seconds,
                          })
                        }}
                        <VaIcon name="timer" color="primary" size="16px" />
                      </div>

                      <VaDivider />
                      <div class="option-assign">
                        <VaIcon name="link" color="primary" size="16px" />
                        <v-menu
                          v-model="isItemAssignUpdate"
                          :close-on-content-click="false"
                          location="top left"
                        >
                          <template #activator="{ props }">
                            <div v-bind="props" class="linked-items-container">
                              <div
                                v-if="
                                  selectedRecord.calendar_item_linked_items &&
                                  selectedRecord.calendar_item_linked_items
                                    .length > 0
                                "
                                class="linked-items-container press-button"
                              >
                                <div
                                  v-for="(
                                    item, index
                                  ) in selectedRecord.calendar_item_linked_items"
                                  :key="index"
                                  class="linked-item"
                                >
                                  <v-tooltip
                                    :text="
                                      camelCaseToNormal(item.class_name || '')
                                    "
                                    location="top"
                                  >
                                    <template #activator="{ props }">
                                      <VaIcon
                                        v-bind="props"
                                        :name="item.icon"
                                        color="primary"
                                        size="18px"
                                      />
                                    </template>
                                  </v-tooltip>
                                  <span class="linked-item-name">
                                    {{ item.name || item.fullName }}
                                  </span>
                                </div>
                              </div>
                              <p v-else class="press-button">Link to</p>
                            </div>
                          </template>
                          <TimeLinkModal
                            :linked-items="
                              selectedRecord.calendar_item_linked_items
                            "
                            @handleLinkTo="handleLinkToUpdate"
                            @handleToggleAssignToMenu="
                              handleToggleAssignUpdateToMenu
                            "
                          />
                        </v-menu>
                      </div>
                      <VaDivider />
                      <div class="option-assign">
                        <v-menu
                          v-model="isItemTypeSelect"
                          :close-on-content-click="false"
                          location="bottom left"
                        >
                          <template #activator="{ props }">
                            <div v-bind="props" class="press-button">
                              <div
                                v-if="selectedRecord.item_type_id"
                                class="item-type-view"
                                :style="{
                                  color: selectedRecord.item_type.color,
                                }"
                              >
                                <font-awesome-icon
                                  :icon="selectedRecord.item_type.icon_name"
                                />
                                {{ selectedRecord.item_type.name }}
                              </div>
                              <div v-else class="item-type-no-view">
                                <VaIcon
                                  name="arrow_right_alt"
                                  color="primary"
                                  size="16px"
                                />
                                <p>Select Type</p>
                              </div>
                            </div>
                          </template>
                          <TypeSelectModal
                            @handleAssignType="handleAssignType"
                          />
                          <p>{{ selectedRecord.item_type_id }}</p>
                        </v-menu>
                      </div>
                      <VaDivider />

                      <div class="option-update">
                        <div
                          v-if="!record.time_range"
                          class="option-update-time"
                        >
                          <VaIcon
                            name="manage_history"
                            color="primary"
                            size="16px"
                          />

                          <div class="option-update-time">
                            <DatePickerModal
                              :date="selectedRecord.start_time"
                              shape="time-record-small"
                              @update:date="handleUpdateStartDate"
                            />
                            -
                            <DatePickerModal
                              :date="selectedRecord.end_time"
                              shape="time-record-small"
                              @update:date="handleUpdateEndDate"
                            />
                          </div>
                        </div>

                        <div v-else class="option-range-container">
                          <VaIcon
                            name="manage_history"
                            color="primary"
                            size="16px"
                          />
                          <div class="option-range">
                            <VaInput
                              v-model="timeRangeUpdate.hours"
                              type="number"
                              min="0"
                              step="1"
                              @input="handleRangeTimeUpdateToSec"
                            >
                              <template #appendInner>
                                <span>hr</span>
                              </template>
                            </VaInput>
                            <p>:</p>
                            <VaInput
                              v-model="timeRangeUpdate.minutes"
                              type="number"
                              min="0"
                              step="1"
                              @input="handleRangeTimeUpdateToSec"
                            >
                              <template #appendInner>
                                <span>min</span>
                              </template>
                            </VaInput>
                          </div>
                        </div>

                        <div class="option-update-note">
                          <VaIcon name="subject" color="primary" size="16px" />
                          <VaInput
                            v-model="selectedRecord.description"
                            placeholder="Description..."
                          />
                        </div>
                      </div>

                      <VaCardActions class="option-footer">
                        <div class="option-footer-btns">
                          <VaButton
                            round
                            icon="attach_money"
                            class="dollar-button"
                            size="small"
                            :color="
                              selectedRecord.billable ? '#38A169' : '#b8c4d6'
                            "
                            @click="
                              selectedRecord.billable = !selectedRecord.billable
                            "
                          />
                          <VaButton
                            preset="primary"
                            size="small"
                            :loading="updateRecordLoading"
                            @click="handleUpdateRecord"
                          >
                            Save
                          </VaButton>
                        </div>
                      </VaCardActions>
                    </v-card>
                  </v-menu>
                </div>
                <div
                  v-if="$stores.mockTimer.isCalendarItemsLoading"
                  class="spinner-container"
                >
                  <VaButton
                    round
                    icon="sync"
                    size="small"
                    preset="secondary"
                    class="spin-animation"
                  />
                </div>
              </div>
            </div>
          </v-card>
        </v-menu>
      </div>
    </VaButton>
  </div>
</template>
<script lang="ts" src="./script.ts" />
<style lang="scss" scoped src="./styles.scss" />
