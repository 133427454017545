import { TargetPresenter } from '@/presenters/TargetPresenter'
import { CompanyConfigurationPresenter } from '@/presenters/companyConfigPresenters'
import { mockCompanyUsers } from './mockCompanyUsers'

const mockPriority = (
  id: number,
  className: string,
  color: string,
  name: string,
): CompanyConfigurationPresenter => ({
  id,
  class_name: className,
  color,
  company_id: 123,
  configuration_type: 'Priority',
  created_at: `2023-12-0${id}`,
  name,
  updated_at: `2023-12-0${id + 4}`,
  icon_name: `${className}-icon`,
  data: {},
})

const mockTargetsData = [
  {
    start_date: '2024-01-15',
    end_date: '2024-02-15',
    id: 1,
    target: [
      {
        id: 101,
        icon: 'project-icon',
        name: 'Alpha Project Completion',
        class_name: 'project',
        path_to: '/projects/alpha',
      },
    ],
    memberIndex: 0, // John Doe
    title: 'Complete Project Alpha',
    tag: 'Project Tag',
    action_note: 'Review and approve milestones',
    status: 'In Progress',
    priority: mockPriority(1, 'important', '#FF0000', 'Important'),
    note: 'Ensure all modules are tested before final review',
    target_type: 'Project',
  },
  {
    target_type: 'Task',
    memberIndex: 1, // Jane Smith
    start_date: '2024-01-10',
    end_date: '2024-01-25',
    title: 'UI/UX Update for Mobile App',
    target: [
      {
        id: 102,
        icon: 'design-icon',
        name: 'Mobile App Redesign',
        class_name: 'task',
        path_to: '/tasks/mobile-app-redesign',
      },
    ],
    id: 2,
    tag: 'Task Tag',
    action_note: 'Submit initial designs for review',
    status: 'Not Started',
    priority: mockPriority(2, 'normal', '#00FF00', 'Normal'),
    note: 'Designs were well-received; next step is development',
  },
  {
    target_type: 'Planning',
    priority: mockPriority(3, 'low', '#0000FF', 'Low'),
    id: 3,
    end_date: '2024-01-31',
    target: [
      {
        id: 103,
        icon: 'planning-icon',
        name: 'Q1 Planning and Strategy',
        class_name: 'planning',
        path_to: '/planning/q1-strategy',
      },
    ],
    tag: 'Planning Tag',
    action_note: 'Finalize project timelines and budgets',
    start_date: '2024-01-01',
    title: 'Quarterly Project Planning',
    note: 'Awaiting feedback from stakeholders',
    status: 'Not Retained',
    memberIndex: 2, // Michael Brown
  },
  {
    id: 4,
    target_type: 'Consulting',
    memberIndex: 3, // Laura Wilson
    title: 'Consultation on Enterprise System',
    target: [
      {
        id: 104,
        icon: 'consulting-icon',
        name: 'Enterprise Resource Planning (ERP)',
        class_name: 'consulting',
        path_to: '/consulting/erp',
      },
    ],
    tag: 'Consulting Tag',
    action_note: 'Prepare system audit report',
    start_date: '2023-12-20',
    end_date: '2024-01-20',
    status: 'In Progress',
    priority: mockPriority(4, 'critical', '#FFA500', 'Critical'),
    note: 'Initial findings indicate significant optimization potential',
  },
  {
    title: 'Data Analysis for Marketing Campaign',
    target_type: 'Analysis',
    note: 'Insights have been provided to the marketing team',
    end_date: '2024-01-18',
    status: 'Transferred',
    id: 5,
    target: [
      {
        id: 105,
        icon: 'analysis-icon',
        name: 'Marketing Campaign Performance',
        class_name: 'analysis',
        path_to: '/analysis/marketing-campaign',
      },
    ],
    tag: 'Analysis Tag',
    action_note: 'Deliver detailed analysis report',
    start_date: '2024-01-05',
    memberIndex: 4, // Emma Johnson
    priority: mockPriority(5, 'medium', '#FFFF00', 'Medium'),
  },
]

export const mockTargets: TargetPresenter[] = mockTargetsData.map(
  ({ memberIndex, ...target }) => ({
    ...target,
    member: mockCompanyUsers[memberIndex],
  }),
)
