import { TableDataExporter } from '@/constants/Tables'
import { TableColumnInterface } from '@/Interfaces/Constants'
import { SectionsPresenter } from '@/presenters/SectionsPresenter'
import { defineComponent, PropType } from 'vue'
import draggable from 'vuedraggable'

export default defineComponent({
  name: 'TablesConfigModal',
  props: {
    tableConfigLoading: {
      type: Boolean,
      default: false,
    },
    isTableConfigUpdate: {
      type: Boolean,
      default: false,
    },
    isTableConfigUpdateLoading: {
      type: Boolean,
      default: false,
    },
    selectedSection: {
      type: Object as PropType<SectionsPresenter>,
      default: () => ({} as SectionsPresenter),
    },
  },
  emits: ['handleToggleTableConfig', 'handleSubmitTableConfig'],
  data() {
    return {
      isTableConfigUpdateBool: false,
      tableColumns:
        this.selectedSection?.table_columns?.map((column) => ({
          ...column,
          selected: true,
        })) ?? [],
    }
  },
  methods: {
    handleToggleTableConfig() {
      this.isTableConfigUpdateBool = !this.isTableConfigUpdateBool
      this.$emit('handleToggleTableConfig')
    },

    handleSubmitTableConfig() {
      if (this.isNotAllowedToSave) {
        this.$vaToast.init({
          message: 'The table should at least have one column selected',
          color: 'danger',
        })
        return
      }
      const headersTemplate = this.tableColumns
        ?.map((column) => {
          if (column.selected) {
            return { key: column.key, title: column.title }
          }
          return null
        })
        .filter(Boolean)
      this.$emit('handleSubmitTableConfig', headersTemplate)
    },
  },
  computed: {
    isAllSelected: {
      get(): boolean {
        // Check if all table column titles are selected
        return this.tableColumns.every((header) => header.selected)
      },
      set(value: boolean): void {
        if (value) {
          // select all headers
          this.tableColumns = this.tableColumns.map((column) => ({
            ...column,
            selected: true,
          }))
        } else {
          // unselect all headers
          this.tableColumns = this.tableColumns.map((column) => ({
            ...column,
            selected: false,
          }))
        }
      },
    },
    isNotAllowedToSave(): boolean {
      return this.tableColumns.every((column) => !column.selected)
    },
  },
  watch: {
    isTableConfigUpdate: {
      handler(data) {
        this.isTableConfigUpdateBool = data
      },
    },
    selectedSection: {
      immediate: true,
      handler(newSection) {
        if (newSection && newSection.table_columns) {
          this.tableColumns = [
            ...newSection?.table_columns.map(
              (column: TableColumnInterface) => ({
                ...column,
                selected: true,
              }),
            ),
            ...TableDataExporter.getTableColumns(
              this.selectedSection?.listing_filters?.contact_type as string,
            )
              .filter(
                (column) =>
                  !newSection?.table_columns.some(
                    (newColumn: TableColumnInterface) =>
                      newColumn.key === column.key,
                  ),
              )
              .map((column: TableColumnInterface) => ({
                ...column,
                selected: false,
              })),
          ]
        }
      },
    },
  },
  components: {
    draggable,
  },
})
