import { getExpenseById, updateExpense } from '@/services/expenses'
import { defineComponent } from 'vue'
import { useForm } from 'vuestic-ui/web-components'
import {
  getOptionFullName,
  getOptionName,
  getOptionTruncateName50,
  validateInput,
  validateInputEmail,
  validateSelect,
  getOptionCurrency,
} from '@/lib/utils'
import SharedHeader from '@/components/sharedHeader/index.vue'
import { ExpensesPresenter } from '@/presenters/ExpensesPresenter'
import { IndividualContactPresenter } from '@/presenters/contactPresenter'
import { OrganizationContactPresenter } from '@/presenters/organizationPresenter'
import { TeamMemberPresenter } from '@/presenters/teamMember'
import { CompanyPresenter } from '@/presenters/CompanyPresenter'
import { CompanyUserPresenter } from '@/presenters/CompanyUserPresenter'

export default defineComponent({
  name: 'AddExpenseFormInterface',
  components: {
    SharedHeader,
  },
  data() {
    const { isValid, validate, reset, resetValidation } = useForm('form')

    return {
      validateInput,
      validateInputEmail,
      validateSelect,
      getOptionName,
      getOptionFullName,
      getOptionCurrency,
      getOptionTruncateName50,

      isValid,
      validate,
      reset,
      resetValidation,

      expenseDetails: [] as any,
      loading: false,
      files: [],

      form: {} as ExpensesPresenter,
      updateLoading: false,

      debounceTimeout: null as ReturnType<typeof setTimeout> | null, // Holds the debounce timeout ID
    }
  },
  methods: {
    handleToggleAddModal() {
      this.$emit('handleToggleAddModal')
    },

    async handleGetExpenseByID() {
      this.loading = true
      const response = await getExpenseById(this.$route.params.id as string)
      if (response.success) {
        this.form = response.data
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }

      this.loading = false
    },

    ///////////////////////////////////////////////////////////////////////
    async handleUpdateExpense() {
      this.updateLoading = true

      const { billed_to, paid_by, related_to, currency, ...updatedForm } =
        this.form

      const response = await updateExpense(this.$route.params.id as string, {
        ...updatedForm,
        billed_to_id: billed_to?.id,

        paid_by_id: paid_by?.id,
        paid_by_type: paid_by?.class_name,
        currency_id: currency?.id,
        related_to_id: related_to?.id as number,
        related_to_type: related_to?.class_name as string,
        template_id: 4849, // fixed for now
      })
      if (response.success) {
        this.$vaToast.init({
          message: 'Expense updated successfully!',
          color: 'success',
        })
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }

      this.updateLoading = false
    },
    ///////////////////////////////////////////////////////////////////////
    calculateDeclaredAmount() {
      const unitPrice = Number(this.form.unit_price) || 0
      const quantity = this.form.quantity || 0
      this.form.declared_amount = unitPrice * quantity
    },

    calculateTaxAndTotalAmount() {
      const declaredAmount = Number(this.form.declared_amount) || 0
      const taxPercentage = Number(this.form.total_tax) || 0
      const calculatedTax = (declaredAmount * taxPercentage) / 100
      this.form.total_amount = declaredAmount + calculatedTax
    },
    ////////////////////////////////// Clients /////////////////////////////////////

    handleSearchClients(search: string) {
      this.$stores.contacts.handleSearchContacts(search)
      this.$stores.contacts.handleSearchOrganizations(search)
    },
  },
  computed: {
    clients(): Array<
      IndividualContactPresenter | OrganizationContactPresenter
    > {
      return [
        ...this.$stores.contacts.organizations,
        ...this.$stores.contacts.contacts,
      ]
    },
    paidByOptions(): Array<
      TeamMemberPresenter | CompanyUserPresenter | CompanyPresenter
    > {
      return [
        this.$stores.auth.company.company,
        ...this.$stores.usersData.teamMembers,
      ]
    },
  },
  watch: {
    'form.unit_price': 'calculateDeclaredAmount',
    'form.quantity': 'calculateDeclaredAmount',
    'form.declared_amount': 'calculateTaxAndTotalAmount',
    'form.total_tax': 'calculateTaxAndTotalAmount',
    form: {
      handler(newForm) {
        console.log('Form updated:', newForm)
      },
      deep: true,
    },
  },
  mounted() {
    this.handleGetExpenseByID()
    this.$stores.contacts.handleGetContacts()
    this.$stores.contacts.handleGetOrganizations()
    this.$stores.matter.handleGetMatters()

    this.$stores.usersData.handleGetMembers()
    this.$stores.currency.handleGetCompanyCurrencies()
  },
})
