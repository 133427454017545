<template>
  <VaModal
    v-model="isUpdateTeamModalOpenBool"
    size="small"
    hide-default-actions
    fixed-layout
    noOutsideDismiss
    :before-close="beforeUpdateTeamModalClose"
  >
    <template #header>
      <div class="create-team-form-header">
        <h1>{{ teamActionTitle }}</h1>
        <VaIcon
          class="header-icon"
          name="close"
          size="30px"
          @click="handleCancel"
        />
      </div>
    </template>
    <VaForm ref="form" class="create-team-form" @submit.prevent="validate">
      <div class="circular-image-upload-modal-container">
        <VaFileUpload
          hideFileList="true"
          v-model="form.image"
          :file-types="$stores.generalData.imagesFormats"
          @update:modelValue="handleFileUpload"
          type="single"
        >
          <div>
            <img
              :src="form?.avatar?.medium_url || UploadAvatarIcon"
              alt="Avatar"
              class="avatar-preview"
            />
          </div>
          <div
            v-if="form?.avatar?.medium_url"
            class="close-icon press-button"
            @click.stop="handleRemoveImage"
          >
            ✕
          </div>
        </VaFileUpload>
      </div>

      <VaInput
        placeholder="Enter team's name"
        background="#ECF0F1"
        v-model="form.name"
        label="Team Name"
      />

      <VaSelect
        placeholder="Workspace"
        background="#ECF0F1"
        v-model="form.parentWorkspace"
        label="Team Parent"
        searchable
        clearable
        :options="workspaceSearchOptions"
        :highlight-matched-text="false"
      />

      <VaSelect
        placeholder="Team"
        background="#ECF0F1"
        v-model="form.parentTeam"
        searchable
        clearable
        :options="teamSearchOptions"
        :highlight-matched-text="false"
      />

      <VaSelect
        placeholder="Team Manager"
        background="#ECF0F1"
        v-model="form.manager"
        label="Team Manager"
        searchable
        :options="$stores.usersData.teamMembers"
        :text-by="getOptionFullName"
        :highlight-matched-text="false"
      />

      <VaSelect
        placeholder="Members"
        background="#ECF0F1"
        v-model="form.members"
        label="Team Members"
        searchable
        :options="$stores.usersData.teamMembers"
        :text-by="getOptionFullName"
        :highlight-matched-text="false"
        multiple
      />
      <VaTextarea
        v-model="form.description"
        max-rows="5"
        background="#ECF0F1"
        label="Team Bio"
        placeholder="Write here the team's bio"
        autosize
        class="text-area"
      />
    </VaForm>
    <template #footer>
      <div class="add-team-form-modal-buttons">
        <VaButton
          preset="secondary"
          border-color="primary"
          @click="handleCancel"
          >Cancel</VaButton
        >
        <VaButton
          @click="validate() && handleUpdateTeam()"
          :loading="updateTeamLoading"
        >
          {{ teamActionTitle }}
        </VaButton>
      </div>
    </template>
  </VaModal>
</template>
<script lang="ts" src="./script.ts" />
<style lang="scss" scoped src="./styles.scss" />
