<template>
  <SharedHeader title-width="30rem" title="Workspace: Organizational structure">
    <div class="sub-SharedHeader">
      <div class="sub-SharedHeader-left" />
      <div class="sub-SharedHeader-right" />
    </div>
  </SharedHeader>
  <TabsBar :tabs="tabs" :active-tab-id="activeTabId" @changeTab="changeTab" />

  <!-- <TimeSheet v-if="activeTabId === 0" /> -->
  <div v-if="activeTabId === 0" class="scroll-container-wrapper">
    <div class="time-log-filter-section">
      <div>
        <VaSelect
          v-model="activeType"
          class="types-view"
          :options="typesOption"
          :text-by="getOptionName"
          searchable
          :highlight-matched-text="false"
          track-by="id"
          @update:modelValue="handleSelectTypeFilter"
        />
      </div>

      <div>
        <VaSelect
          v-model="userFiltration"
          class="user-filter"
          :options="userFiltrationOptions"
          :text-by="getOptionFullName"
          :loading="$stores.usersData.membersLoading"
          searchable
          :highlight-matched-text="false"
          track-by="id"
          @update:search="
            $stores.usersData.handleSearchMembers($event, {
              as_company_user: true,
            })
          "
          @update:modelValue="handleSelectUserFilter"
        />
      </div>
    </div>

    <DetailsTimeLogs
      :time-log="timeLogs"
      :loading="timeLogTableLoading"
      :items-per-page="timeLogsItemPerPage"
      :total-items="timeLogTotalItems"
      :update-time-log-loading="updateRecordLoading"
      :delete-time-log-loading="deleteRecordLoading"
      :time-sheet="true"
      @loadMore="handlePaginationTimeLog"
      @handleUpdateTimeLog="handleUpdateRecord"
      @handleDeleteTimeLog="handleDeleteRecord"
    />
  </div>

  <Tree v-else-if="activeTabId === 1" />
  <!-- <TimeSheet v-else-if="activeTabId === 1" /> -->

  <!-- <Accordion v-else-if="activeTabId === 2" :user="user" :users="users" /> -->

  <!-- <div> -->
  <WorkspaceTeamsMembers v-else-if="activeTabId === 2" />
  <!-- </div> -->
  <Announcements
    v-else-if="activeTabId === 7"
    class="scroll-container-wrapper"
  />

  <div v-else class="coming-soon-container">
    <ComingSoon />
  </div>
</template>

<script lang="ts" src="./script.ts"></script>

<style lang="scss" scoped src="./styles.scss"></style>

<!--

put them in the script

user: {
  id: 1,
  name: 'John Doe',
  avatar: 'https://randomuser.me/api/portraits/men/1.jpg',
  company: 'Company Name',
  title: 'CEO',
},
users: [
  {
    id: 1,
    name: 'John Doe',
    avatar: 'https://randomuser.me/api/portraits/men/2.jpg',
    company: 'Company Name',
    title: 'Accountant',
  },
  {
    id: 2,
    name: 'Jane Doe',
    avatar: 'https://randomuser.me/api/portraits/women/2.jpg',
    company: 'Company Name',
    title: 'Designer',
  },
  {
    id: 3,
    name: 'Mike Doe',
    avatar: 'https://randomuser.me/api/portraits/men/3.jpg',
    company: 'Company Name',
    title: 'Developer',
  },
], -->
