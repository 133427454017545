import { defineComponent } from 'vue'
import { useForm } from 'vuestic-ui/web-components'
import {
  getOptionFullName,
  getOptionCurrency,
  validateInput,
  validateInputEmail,
  validateSelect,
} from '@/lib/utils'
import { CompanyUserPresenter } from '@/presenters/CompanyUserPresenter'

type addMembersForm = {
  team: string
  members: CompanyUserPresenter[]
  note: string
}

export default defineComponent({
  name: 'AddMembers',
  props: {
    isAddMembersModalOpen: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    const { validate, reset } = useForm('form')

    const teamSearchOptions = ['team1', 'team2']

    return {
      validate,
      reset,

      form: {} as addMembersForm,

      getOptionFullName,
      getOptionCurrency,
      validateInput,
      validateInputEmail,
      validateSelect,

      teamSearchOptions,

      addMembersLoading: false,
    }
  },
  emits: ['handleAddMembers', 'toggleAddMembersModal'],
  methods: {
    beforeAddMembersModalClose() {
      if (this.isAddMembersModalOpenBool) {
        this.handleCancel()
      }
    },
    handleAddMembers() {
      this.addMembersLoading = true

      this.$emit('handleAddMembers', this.form)
      this.handleCancel()

      this.addMembersLoading = false
    },

    handleCancel() {
      this.$emit('toggleAddMembersModal')
      this.reset()
    },
  },
  computed: {
    isAddMembersModalOpenBool: {
      get(): boolean {
        return this.isAddMembersModalOpen
      },
      set() {
        this.handleCancel()
      },
    },
  },
  mounted() {
    this.$stores.usersData.handleGetMembers({
      as_company_user: true,
    })
  },
})
