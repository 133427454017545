import { ContactType, getContactsInterface } from '@/Interfaces/Services'
import { IndividualContactPresenter } from '@/presenters/contactPresenter'
import { OrganizationContactPresenter } from '@/presenters/organizationPresenter'
import {
  addIndividualContact,
  addOrganizationContact,
  getContacts,
} from '@/services/contacts'
import { defineStore } from 'pinia'
import { useToast } from 'vuestic-ui/web-components'

export const useContactsStore = defineStore({
  id: 'contacts',
  state: () => ({
    vaToast: useToast(),
    allContacts: [] as IndividualContactPresenter[],
    allOrganizations: [] as OrganizationContactPresenter[],
    clients: [] as IndividualContactPresenter[],
    contactsActiveMatters: [] as IndividualContactPresenter[],

    contacts: [] as IndividualContactPresenter[],
    contactsLoading: false,
    addContactLoading: false,

    organizations: [] as OrganizationContactPresenter[],
    organizationsLoading: false,
    addOrganizationLoading: false,

    debounceTimeout: null as ReturnType<typeof setTimeout> | null,
  }),
  actions: {
    async handleGetContacts(
      body: getContactsInterface = {},
      contactsPerPage = 20,
    ) {
      this.contactsLoading = true
      const response = await getContacts({
        ...body,
        contact_type: ContactType.IndividualContact,
        per_page: contactsPerPage,
      })
      if (response.success) {
        this.contacts = response.data?.list
      } else {
        this.vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.contactsLoading = false
    },

    async handleGetOrganizations(body: getContactsInterface = {}) {
      this.organizationsLoading = true
      const response = await getContacts({
        ...body,
        contact_type: ContactType.OrganizationContact,
        per_page: 20,
      })
      if (response.success) {
        this.organizations = response.data?.list
      } else {
        this.vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.organizationsLoading = false
    },

    /////////////////////////////////// SEARCH ///////////////////////////////////////////

    handleSearchContacts(search: string) {
      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout)
      }
      this.debounceTimeout = setTimeout(() => {
        this.handleGetContacts({ keyword: search })
      }, 500)
    },
    handleSearchOrganizations(search: string) {
      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout)
      }
      this.debounceTimeout = setTimeout(() => {
        this.handleGetOrganizations({ keyword: search })
      }, 500)
    },

    /////////////////////////////////// ADD ///////////////////////////////////////////

    async handleAddContact(body: any) {
      this.addContactLoading = true
      const response = await addIndividualContact(body)
      if (!response.success) {
        this.vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.addContactLoading = false
    },

    async handleAddOrganization(body: any) {
      this.addOrganizationLoading = true
      const response = await addOrganizationContact(body)
      if (!response.success) {
        this.vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.addOrganizationLoading = false
    },
  },
})
