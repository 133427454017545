import { IndividualContactPresenter } from '@/presenters/contactPresenter'
import { apiRequest } from './apiUtils'
import { OrganizationContactPresenter } from '@/presenters/organizationPresenter'
import { getContactsInterface } from '@/Interfaces/Services'
import { objectToFormData } from '@/lib/utils'

const presenters = {
  IndividualContact: IndividualContactPresenter,
  OrganizationContact: OrganizationContactPresenter,
} as const

export const getContacts = async ({
  contact_type,
  per_page,
  page,
  sort_by_field,
  sort_by_direction,
  keyword,
  client,
  has_active_legal_matters,
}: getContactsInterface) => {
  const response = await apiRequest('get', '/contacts', null, {
    contact_type,
    per_page,
    page,
    sort_by_field,
    sort_by_direction,
    keyword,
    client,
    has_active_legal_matters,
  })
  if (response?.success && Array.isArray(response.data?.list)) {
    return {
      ...response,
      data: {
        ...response.data,
        list: response.data?.list?.map(
          (item: IndividualContactPresenter | OrganizationContactPresenter) => {
            return new presenters[item.class_name](item as never)
          },
        ),
      },
    }
  } else {
    return response
  }
}

export const getContactById = async (id: string) => {
  const response = await apiRequest('get', `/contacts/${id}`)
  if (response.success) {
    const className: 'IndividualContact' | 'OrganizationContact' =
      response.data.class_name
    return {
      ...response,
      data: new presenters[className](response.data),
    }
  } else {
    return response
  }
}

export const addIndividualContact = (body: any) => {
  return apiRequest('post', '/contacts/individuals', objectToFormData(body))
}

export const addOrganizationContact = (body: any) => {
  return apiRequest('post', '/contacts/organizations', objectToFormData(body))
}

export const updateIndividualContact = ({ id, body }: any) => {
  return apiRequest(
    'put',
    `/contacts/individuals/${id}`,
    objectToFormData(body),
  )
}

export const updateOrganizationContact = ({ id, body }: any) => {
  return apiRequest(
    'put',
    `/contacts/organizations/${id}`,
    objectToFormData(body),
  )
}

export const deleteContact = (id: string) => {
  return apiRequest('delete', `/contacts/${id}`)
}
