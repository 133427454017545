export const leadsTemplateHeaders = [
  { title: 'id', key: 'id', align: 'start', sortable: false },
  {
    title: 'TITLE',
    key: 'title',
    align: 'start',
    sortable: false,
  },

  {
    title: 'Contact',
    key: 'individual_contact',
    align: 'start',
    sortable: false,
  },
  {
    title: 'ORGANIZATION',
    key: 'organization_contact',
    align: 'start',
    sortable: false,
  },
  {
    title: 'CURRENCY',
    key: 'currency',
    align: 'center',
    sortable: false,
  },
  { title: 'VALUE', key: 'lead_value', align: 'center', sortable: false },
  {
    title: 'STAGE',
    key: 'section_stage_items',
    align: 'start',
    sortable: false,
  },
  { title: 'LABEL', key: 'label', align: 'start', sortable: false },
  {
    title: 'PROBABILITY',
    key: 'probability',
    align: 'center',
    sortable: false,
  },
  {
    title: 'EXPECTED CLOSE DATE',
    key: 'expected_close_date',
    align: 'start',
    sortable: false,
  },
  {
    title: 'RENEWAL DATE',
    key: 'renewal_date',
    align: 'start',
    sortable: false,
  },
  {
    title: 'OWNER',
    key: 'relationship_owner',
    align: 'start',
    sortable: false,
  },
  {
    title: 'WON',
    key: 'won',
    align: 'start',
    sortable: false,
  },
  { title: 'OPT', key: 'action', align: 'start', sortable: false },
]
