import { TeamPresenter } from '@/presenters/TeamPresenter'
import { TeamMemberPresenter } from '@/presenters/teamMember'
import { mockCompanyUsers } from './mockCompanyUsers'

function createTeamMemberFromCompanyUser(index: number): TeamMemberPresenter {
  const userData = mockCompanyUsers[index]
  return {
    id: userData.user_id,
    email: userData.user.email,
    first_name: userData.user.first_name,
    last_name: userData.user.last_name,
    middle_name: userData.user.middle_name,
    title: userData.user.title,
    class_name: 'TeamMemberPresenter',
    fullName: userData.user.fullName || '',
    avatar_letter: userData.user.avatar_letter || '',
    avatar_first_letter: userData.user.avatar_first_letter || '',
    avatar: {
      id: null,
      large_url: null,
      medium_url: null,
      thumbnail_url: null,
      url: null,
    },
    company_users: [{ ...userData }],
  }
}

const mockTeamsData = [
  {
    id: 1,
    name: 'Team Alpha',
    jurisdictions: ['US', 'CA', 'GB'],
    description: 'Focused on product innovation and design.',
    managerIndex: 0,
    memberIndices: [1, 2, 4],
  },
  {
    id: 2,
    name: 'Team Beta',
    jurisdictions: ['AU', 'NZ'],
    description: 'Specializes in cross-platform development.',
    managerIndex: 2,
    memberIndices: [0, 3, 4],
  },
  {
    id: 3,
    name: 'Team Gamma',
    jurisdictions: ['DE', 'FR', 'US'],
    description: 'Handles global marketing and client relations.',
    managerIndex: 1,
    memberIndices: [0, 3, 4],
  },
  {
    id: 4,
    name: 'Team Delta',
    jurisdictions: ['SG', 'HK'],
    description: 'Focuses on enterprise solutions and consulting.',
    managerIndex: 4,
    memberIndices: [0, 1, 2],
  },
]

function createMockTeam({
  id,
  name,
  jurisdictions,
  description,
  managerIndex,
  memberIndices,
}: (typeof mockTeamsData)[number]): TeamPresenter {
  return {
    id,
    name,
    image: new File([], 'empty.jpg', {
      type: 'image/jpeg',
    }),
    jurisdictions,
    description,
    manager: createTeamMemberFromCompanyUser(managerIndex),
    members: memberIndices.map(createTeamMemberFromCompanyUser),
    parentWorkspace: null,
    parentTeam: null,
  }
}

export const mockTeams: TeamPresenter[] = mockTeamsData.map(createMockTeam)
