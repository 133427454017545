import { contactFormFields } from '@/constants/Templates/contactTemplate'
import { leadFormFields } from '@/constants/Templates/leadTemplate'
import { matterFormFields } from '@/constants/Templates/matterTemplates'
import { organizationFormFields } from '@/constants/Templates/organizationTemplate'
import {
  FileUploadHelperInterface,
  TemplateInterface,
} from '@/Interfaces/Constants'
import { TeamMemberPresenter } from '@/presenters/teamMember'
import { setAuthToken, setHeaders } from '@/services/API'
import { useAuthStore } from '@/stores/auth'
import { nextTick } from 'vue'

export function getMonthAndYearFromDate(dateString: string) {
  const date = new Date(dateString)
  const month = date.toLocaleString('default', { month: 'long' })
  const year = date.getFullYear()
  return { month, year }
}

export function removeNullableFields(obj: any) {
  // Iterate through the object entries
  for (const key in obj) {
    // If the value is an object or array, recursively clean it
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      removeNullableFields(obj[key])

      // If it's an array, filter out empty objects or empty strings
      if (Array.isArray(obj[key])) {
        obj[key] = obj[key].filter(
          (item: any) =>
            (typeof item === 'object' && Object.keys(item).length > 0) ||
            item !== '',
        )
      }
      // If the object is now empty after cleaning, delete the property
      if (Object.keys(obj[key]).length === 0) {
        delete obj[key]
      }
    }
    // Remove if the value is null, undefined, or an empty string
    if (obj[key] === '' || obj[key] === null || obj[key] === undefined) {
      delete obj[key]
    }
  }
  return obj
}

export function formatTime(isoDateString: string): string {
  const date = new Date(isoDateString)
  const options: Intl.DateTimeFormatOptions = {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  }
  return new Intl.DateTimeFormat('en-US', options).format(date)
}

export function formatTimeSeconds({
  totalSeconds,
  showOnlyHourMin = false,
}: {
  totalSeconds: number
  showOnlyHourMin?: boolean
}) {
  const hours = Math.floor(totalSeconds / 3600)
  const minutes = Math.floor((totalSeconds % 3600) / 60)
  const seconds = totalSeconds % 60

  if (totalSeconds === 0) {
    return 'still running'
  } else if (hours > 0) {
    return showOnlyHourMin
      ? `${String(hours).padStart(2)}h ${String(minutes).padStart(2)}m`
      : `${String(hours).padStart(2)}h ${String(minutes).padStart(2)}m ${String(
          seconds,
        ).padStart(2)}s`
  } else if (minutes > 0) {
    return showOnlyHourMin
      ? `${String(minutes)}m`
      : `${String(minutes)}m ${String(seconds).padStart(2)}s`
  } else {
    return showOnlyHourMin ? '0m' : `${String(seconds).padStart(2)}s`
  }
}

export function formatDate(
  date: string | Date | null,
  showDay = false,
): string | null {
  if (date) {
    const dateObj = typeof date === 'string' ? new Date(date) : date

    if (dateObj instanceof Date && !isNaN(dateObj.getTime())) {
      const options: Intl.DateTimeFormatOptions = {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
        ...(showDay && { weekday: 'long' }), // Include weekday if showDay is true
      }
      return dateObj.toLocaleDateString('en-US', options)
    }
  }
  return null
}

export function formatDateRange(
  startString: Date | string,
  endString: Date | string,
  withTimeDifference = false,
) {
  const start = new Date(startString)
  const end = new Date(endString)

  const isSameDay =
    start.getFullYear() === end.getFullYear() &&
    start.getMonth() === end.getMonth() &&
    start.getDate() === end.getDate()

  const startOptions: Intl.DateTimeFormatOptions = {
    weekday: 'short',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  }

  const endOptions: Intl.DateTimeFormatOptions = {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  }

  // Adjust end options if on different days
  if (!isSameDay) {
    endOptions.weekday = 'short'
    endOptions.month = 'short'
    endOptions.day = 'numeric'
  }

  const startDateFormatted = new Intl.DateTimeFormat(
    'en-US',
    startOptions,
  ).format(start)
  const endDateFormatted = endString
    ? new Intl.DateTimeFormat('en-US', endOptions).format(end)
    : 'now'

  let result = `${startDateFormatted} - ${endDateFormatted}`

  if (withTimeDifference && endString) {
    const diffInSeconds = Math.floor((end.getTime() - start.getTime()) / 1000)
    result += ` (${formatTimeSeconds({ totalSeconds: diffInSeconds }).trim()})`
  }

  return result
}

export function convertToLocalISO(dateString: string | Date) {
  const date = new Date(dateString)
  const newDate = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
    2,
    '0',
  )}-${String(date.getDate()).padStart(2, '0')}T${String(
    date.getHours(),
  ).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}:${String(
    date.getSeconds(),
  ).padStart(2, '0')}.000`
  return newDate
}

export function convertToCalendarFormat(dateString: string | Date) {
  const date = new Date(dateString)
  const newDate = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
    2,
    '0',
  )}-${String(date.getDate()).padStart(2, '0')}T${String(
    date.getHours(),
  ).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}:${String(
    date.getSeconds(),
  ).padStart(2, '0')}.000`
  return newDate.slice(0, 16).replace('T', ' ')
}

export function truncateText(text: string, charLimit: number) {
  if (!text) {
    return ''
  }
  return text.length > charLimit ? text.substring(0, charLimit) + '...' : text
}

export function isLongDescription(text: string, length: number): boolean {
  if (!text) {
    return false
  }
  return text?.length > length
}

export function validateInput(value: string) {
  return value !== null && value !== undefined && value !== ''
}

export function validateInputEmail(value: string) {
  if (!value) {
    return true
  }
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)
}

export function validateSelect(value: any) {
  return !!value
}

export function validateColorInput(value: string) {
  return /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(value)
}

export function getOptionName(option: any) {
  return option?.name
}

export function getOptionFullName(option: any) {
  return option?.fullName
}

// export function getOptionAllNames(option: any) {
//   return [getOptionName(option), getOptionFullName(option)]
//     .filter(Boolean)
//     .join(' ')
// }

export function getOptionCurrency(option: any) {
  return option?.code
}

export function getOptionTruncateName50(option: any) {
  return truncateText(option?.name, 50)
}

export function getOptionTruncateName110(option: any) {
  return truncateText(option?.name, 110)
}

export const capitalizeFirst = (str: string) =>
  str?.charAt(0).toUpperCase() + str?.slice(1)

export const toSnakeCase = (str: string) =>
  str.toLowerCase().replace(/\s+/g, '_')

export const camelCaseToNormal = (str: string) => {
  return str
    .replace(/([a-z])([A-Z])/g, '$1 $2') // Add space before uppercase letters
    .replace(/([A-Z])([A-Z][a-z])/g, '$1 $2') // Handle consecutive uppercase letters
    .replace(/^./, (match) => match.toUpperCase()) // Capitalize the first letter
}

export const deepCopy = (obj: any) => {
  return JSON.parse(JSON.stringify(obj)) // Perform a deep copy
}

export const getContrastingTextColor = (hexColor: string) => {
  if (!hexColor) {
    return '#000000'
  }

  const color = hexColor.charAt(0) === '#' ? hexColor.slice(1) : hexColor

  // Convert RGB to decimal
  const r = parseInt(color.substr(0, 2), 16)
  const g = parseInt(color.substr(2, 2), 16)
  const b = parseInt(color.substr(4, 2), 16)

  // Calculate relative luminance
  const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b

  // Return white for dark backgrounds, black for light backgrounds
  return luminance > 128 ? '#000000' : '#FFFFFF'
}

export const addSecondsToISOTime = (
  isoTime: string,
  secondsToAdd: number,
): string => {
  // Parse the ISO string into a Date object
  const date = new Date(isoTime)

  // Add seconds by converting to milliseconds (1 second = 1000 ms)
  date.setSeconds(date.getSeconds() + secondsToAdd)

  // Convert the updated Date back to an ISO string
  return date.toISOString()
}

export const updateDatePart = (
  dateStr: string,
  newDatePart: string,
): string => {
  const [datePart, timePart] = dateStr.split('T')
  console.log(datePart)
  return `${newDatePart}T${timePart}`
}

export const formatTemplateFields = (selectedFields: TemplateInterface[][]) => {
  return selectedFields?.map((columns, index: number) => ({
    index,
    columns: columns?.map((field) => ({
      name: field?.name,
      required: field?.required,
    })),
  }))
}

export const getDueDateByDays = (days = 0) => {
  const currentDate = new Date()
  const dueDate = new Date(currentDate.setDate(currentDate.getDate() + days))
  return dueDate.toLocaleDateString()
}

export const isEmptyObject = (obj: object | undefined) => {
  return (
    obj && Object.keys(obj || {}).length === 0 && obj.constructor === Object
  )
}

export const storeUserData = (userData: TeamMemberPresenter) => {
  const authStore = useAuthStore()

  const roleId = localStorage.getItem('RoleId')

  const userToken = userData?.jwt_token || ''

  const userCompany =
    userData?.company_users?.find(
      (company) => company?.id === Number(roleId),
    ) || userData?.company_users?.[0]

  // Set the authentication token for API requests
  setAuthToken(userToken)

  // Update the auth store with user and company details
  authStore.$patch((state) => {
    state.user = { ...userData }
    state.company = userCompany
  })

  localStorage.setItem('haqqUser', JSON.stringify(userData))
  localStorage.setItem('haqqCompany', JSON.stringify(userCompany))

  // Set headers for subsequent API requests
  setHeaders({ RoleId: userCompany?.id })

  // Store the authentication token and role ID in localStorage
  localStorage.setItem('authaqq', userToken)
  localStorage.setItem('RoleId', userCompany?.id.toString())
}

export const objectToFormData = (
  object: Record<string, any>,
  form: FormData = new FormData(),
  parentKey: string | null = null,
): FormData => {
  Object.keys(object).forEach((key) => {
    const value = object[key]
    const formKey = parentKey ? `${parentKey}[${key}]` : key
    if (value !== undefined && value !== null) {
      if (value instanceof File) {
        form.append(formKey, value)
      } else if (Array.isArray(value)) {
        value.forEach((item) => {
          if (typeof item === 'object' && item !== null) {
            Object.keys(item).forEach((nestedKey) => {
              form.append(`${key}[][${nestedKey}]`, item[nestedKey])
            })
          }
        })
      } else {
        form.append(formKey, value)
      }
    }
  })

  // for (const [key, value] of form.entries()) {
  //   console.log(`Key: ${key}, Value: ${value}`)
  // }

  return form
}

export default class FileUploadHelper {
  static handleFileUpload({
    event,
    form,
    key = 'image',
  }: FileUploadHelperInterface) {
    if (!event) return
    const file = event
    this.setImage(file, form, key)
    this.loadAvatarImage(file, form)
  }

  static setImage(file: File, form: Record<string, any>, key: string) {
    form[key] = file
  }

  static loadAvatarImage(file: File, form: Record<string, any>) {
    const reader = new FileReader()
    reader.onload = (e: ProgressEvent<FileReader>) => {
      if (e.target?.result) {
        this.setAvatar(e.target.result as string, form)
      }
    }
    reader.readAsDataURL(file)
  }

  static setAvatar(imageUrl: string, form: Record<string, any>) {
    if (!form.avatar) {
      form.avatar = {
        id: null,
        large_url: null,
        medium_url: null,
        thumbnail_url: null,
        url: null,
      }
    }
    form.avatar.medium_url = imageUrl
  }
}

export const handleDateChange = (
  date: Date,
  dueOptions: { label: string; value: string }[],
  key: string,
  form: Record<string, any>,
  hideDueDateCallback: () => void,
) => {
  const formattedDate = date.toLocaleDateString()

  const exists = dueOptions.some((option) => option.label === formattedDate)
  if (!exists) {
    dueOptions.push({ label: formattedDate, value: formattedDate })
  }

  form[key] = formattedDate
  hideDueDateCallback()
}

// console.log(numberToTime(1.75)); // Output: "1:45"
export const calendarNumberToTime = (decimalHours: number): string => {
  const hours = Math.floor(decimalHours) // Get whole hours
  const minutes = Math.round((decimalHours - hours) * 60) // Convert decimal part to minutes

  return `${hours}:${minutes.toString().padStart(2, '0')}` // Ensure two-digit minutes
}

// console.log(timeToNumber("1:45")); // Output: 1.75
export const calendarTimeToNumber = (time: string): number => {
  const [hours, minutes] = time.split(':').map(Number)
  return parseFloat((hours + minutes / 60).toFixed(3)) // Convert back to decimal, rounded to 3 decimal places
}

export const getWeekStartAndEndUTC = (): {
  start_after: string
  end_before: string
} => {
  const today = new Date()
  const dayOfWeek = today.getDay()
  const mondayOffset = dayOfWeek === 0 ? -6 : 1 - dayOfWeek

  // Get the start of the week (Monday) in local time
  const startOfWeek = new Date(today)
  startOfWeek.setDate(today.getDate() + mondayOffset)
  startOfWeek.setHours(0, 0, 0, 0)

  // Get the end of the week (Sunday) in local time
  const endOfWeek = new Date(startOfWeek)
  endOfWeek.setDate(startOfWeek.getDate() + 6)
  endOfWeek.setHours(23, 59, 59, 999)

  // Return the full ISO string in UTC format
  return {
    start_after: startOfWeek.toISOString(),
    end_before: endOfWeek.toISOString(),
  }
}

export const focusNameInput = async (input: HTMLInputElement) => {
  if (input) {
    input.focus()
    input.setSelectionRange(input.value.length, input.value.length) // Move cursor to end
  }
}

export const focusInputForSelect = () => {
  nextTick(() => {
    focusNameInput(
      document.querySelector(
        '.va-input-wrapper.va-input-wrapper--focused input',
      ) as HTMLInputElement,
    )
  })
}

export const sectionType: Record<string, any> = {
  contacts: [...contactFormFields, ...organizationFormFields],
  tasks: matterFormFields,
  legal_matters: matterFormFields,
  leads: leadFormFields,
}

export const getFormColumns = (
  form_columns: { columns: { name: string }[] }[],
  section_type: string,
): { name: string }[][] => {
  const formColumns = form_columns.map((column) => column.columns)

  return formColumns?.map((rowFields) => {
    return rowFields?.map((field) => {
      const fieldData = sectionType[section_type]?.find(
        (f: { name: string }) => f.name === field.name,
      )

      return {
        ...fieldData,
        ...field,
      }
    })
  })
}

// Example usage
// const exampleFormColumns = [
//   { columns: [{ name: 'field1' }, { name: 'field2' }] },
// ]
// const exampleSectionType = 'contacts'
// const result = getFormColumns(exampleFormColumns, exampleSectionType)
// console.log(result)

export const getRunningTimeFormatted = (
  startTime: string | Date,
  timestamp: Date,
  // currentRunningTime: string,
): string => {
  const start = new Date(startTime)
  const now = timestamp || new Date()

  if (isNaN(start.getTime())) {
    return 'Invalid time'
  }

  const diffInSeconds = Math.floor((now.getTime() - start.getTime()) / 1000)

  if (diffInSeconds < 0) {
    return '00:00:00'
  }

  const hours = Math.floor(diffInSeconds / 3600) % 24
  const minutes = Math.floor((diffInSeconds % 3600) / 60)
  const seconds = diffInSeconds % 60

  const formattedTime = `${String(hours).padStart(2, '0')}:${String(
    minutes,
  ).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`

  return formattedTime
}
