<template>
  <VaModal
    v-model="isAddCalendarFormBool"
    size="auto"
    hide-default-actions
    fixed-layout
    no-outside-dismiss
    @update:modelValue="toggleAddCalendarForm"
  >
    <template #header>
      <div class="create-form-header">
        <h1>Add a Meeting</h1>
        <VaIcon
          class="header-icon press-button"
          name="close"
          size="30px"
          @click="toggleAddCalendarForm"
        />
      </div>
    </template>

    <VaForm
      ref="form"
      class="add-form-modal"
      @submit.prevent="validate() && handleCreateEvent()"
    >
      <VaInput v-model="form.title" placeholder="Title" background="#ECF0F1" />

      <div class="icon-sub-container">
        <div
          v-for="(type, index) in $stores.generalData.companyConfigs
            .calendarTypesOption"
          :key="index"
        >
          <v-tooltip :text="type.name" location="top">
            <template #activator="{ props }">
              <VaButton
                v-bind="props"
                :color="activeType === type.id ? 'primary' : '#ECF0F1'"
                @click="selectType(type.id)"
              >
                <font-awesome-icon :icon="type.icon_name" />
              </VaButton>
            </template>
          </v-tooltip>
        </div>
      </div>

      <div class="option-choose-time">
        <VaIcon name="manage_history" color="primary" size="16px" />
        <DatePickerModal
          class="Date-Picker-Modal-1"
          :date="form.start_time"
          shape="time-record"
          @update:date="handleUpdateStartDate"
        />
        -
        <DatePickerModal
          :date="form.end_time"
          shape="time-record"
          @update:date="handleUpdateEndDate"
        />
      </div>

      <div class="all-day-sec">
        <VaCheckbox v-model="form.all_day" label="All Day" />
        <p>Time Zone</p>
      </div>

      <div class="all-day-sec-repeat">
        <VaSelect background="#ECF0F1" placeholder="Does Not Repeat" disabled />
      </div>

      <div class="reminder-section">
        <VaIcon name="notifications" color="primary" size="16px" />
        <div>
          <VaSelect
            v-model="form.send_reminder_at"
            background="#ECF0F1"
            placeholder="Add Reminder"
            :options="reminderOptions"
            disabled
          />
        </div>
      </div>

      <div>
        <VaCheckbox v-model="form.private" label="Mark as private" />
      </div>

      <VaInput
        placeholder="Add Participants or email"
        background="#ECF0F1"
        disabled
      />

      <div class="location-section">
        <VaIcon name="location_on" color="primary" size="16px" />
        <VaInput
          v-model="form.location"
          placeholder="Add Location"
          background="#ECF0F1"
        />
      </div>

      <div class="option-assign">
        <VaIcon name="link" color="primary" size="16px" />
        <v-menu
          v-model="isItemAssignUpdate"
          :close-on-content-click="false"
          location="top left"
        >
          <template #activator="{ props }">
            <div v-bind="props" class="linked-items-container">
              <div
                v-if="
                  form.calendar_item_linked_items &&
                  form.calendar_item_linked_items.length > 0
                "
                class="linked-items-container press-button"
              >
                <div
                  v-for="(item, index) in form.calendar_item_linked_items"
                  :key="index"
                  class="linked-item"
                >
                  <v-tooltip
                    :text="camelCaseToNormal(item.class_name || '')"
                    location="top"
                  >
                    <template #activator="{ props }">
                      <VaIcon
                        v-bind="props"
                        :name="item.icon"
                        color="primary"
                        size="26px"
                      />
                    </template>
                  </v-tooltip>
                  <span class="linked-item-name">
                    {{ item.name || item.fullName }}
                  </span>
                </div>
              </div>
              <p v-else class="press-button">Link to</p>
            </div>
          </template>
          <TimeLinkModal
            :linked-items="form.calendar_item_linked_items"
            @handleLinkTo="handleLinkToUpdate"
            @handleToggleAssignToMenu="handleToggleAssignToMenu"
          />
        </v-menu>
      </div>

      <div class="attachment-section">
        <VaIcon name="attach_file" color="primary" size="16px" />
        <p class="press-button">Add attachment</p>
      </div>

      <div class="note-section">
        <VaIcon name="subject" color="primary" size="16px" />
        <VaInput
          v-model="form.description"
          placeholder="Add note"
          background="#ECF0F1"
        />
      </div>

      <div class="bill-meeting-section">
        <VaSwitch
          v-model="form.billable"
          label="Billable Activity"
          size="small"
        />

        <VaCheckbox label="Pre-Charge Meeting" size="small" disabled />
      </div>
    </VaForm>

    <template #footer>
      <div class="add-form-modal-buttons">
        <VaButton
          preset="secondary"
          border-color="primary"
          @click="toggleAddCalendarForm"
        >
          Cancel
        </VaButton>
        <VaButton
          :loading="addCalendarLoading"
          @click="validate() && handleCreateEvent()"
        >
          Save
        </VaButton>
      </div>
    </template>
  </VaModal>
</template>
<script lang="ts" src="./script.ts" />
<style lang="scss" scoped src="./styles.scss" />
