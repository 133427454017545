import { calendarGlobalSearchInterface } from '@/Interfaces/Store'
import { globalSearch } from '@/services/globalSearch'
import { defineStore } from 'pinia'
import { useToast } from 'vuestic-ui/web-components'

export const useGlobalSearch = defineStore({
  id: 'globalSearch',
  state: () => ({
    vaToast: useToast(),
    calendarGlobalSearch: [] as calendarGlobalSearchInterface[],
    calendarSearch: [] as calendarGlobalSearchInterface[],
    calendarGlobalSearchLoading: false,
  }),
  actions: {
    async handleGlobalSearch(
      keyword?: string,
      {
        hearings_enabled,
        legal_matters_enabled = true,
        individual_contacts_enabled = true,
        organization_contacts_enabled = true,
        tasks_enabled = true,
        calendar_items_enabled = false,
        number_of_records = 20,
      }: {
        hearings_enabled?: boolean
        legal_matters_enabled?: boolean
        individual_contacts_enabled?: boolean
        organization_contacts_enabled?: boolean
        tasks_enabled?: boolean
        calendar_items_enabled?: boolean

        number_of_records?: number
      } = {},
    ) {
      this.calendarGlobalSearchLoading = true

      const response = await globalSearch({
        keyword: keyword?.trim(),
        number_of_records,

        ...(hearings_enabled && { hearings_enabled }),
        ...(hearings_enabled && { hearings_order_by_column: 'created_at' }),
        ...(hearings_enabled && { hearings_order_by_direction: 'asc' }),

        ...(legal_matters_enabled && { legal_matters_enabled }),
        ...(legal_matters_enabled && {
          legal_matters_order_by_column: 'created_at',
        }),
        ...(legal_matters_enabled && {
          legal_matters_order_by_direction: 'asc',
        }),

        ...(individual_contacts_enabled && { individual_contacts_enabled }),
        ...(individual_contacts_enabled && {
          individual_contacts_order_by_column: 'created_at',
        }),
        ...(individual_contacts_enabled && {
          individual_contacts_order_by_direction: 'asc',
        }),

        ...(organization_contacts_enabled && { organization_contacts_enabled }),
        ...(organization_contacts_enabled && {
          organization_contacts_order_by_column: 'created_at',
        }),
        ...(organization_contacts_enabled && {
          organization_contacts_order_by_direction: 'asc',
        }),

        ...(tasks_enabled && { tasks_enabled }),
        ...(tasks_enabled && {
          tasks_order_by_column: 'created_at',
        }),
        ...(tasks_enabled && {
          tasks_order_by_direction: 'asc',
        }),

        ...(calendar_items_enabled && { calendar_items_enabled }),
        ...(calendar_items_enabled && {
          calendar_items_order_by_column: 'created_at',
        }),
        ...(calendar_items_enabled && {
          calendar_items_order_by_direction: 'asc',
        }),
      })

      if (response.success) {
        if (calendar_items_enabled) {
          this.calendarSearch = response.data?.allItemsSorted
        } else {
          this.calendarGlobalSearch = response.data?.allItemsSorted
        }
      } else {
        this.vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.calendarGlobalSearchLoading = false
    },
  },
})
