export class CompanyPresenter {
  id!: number
  class_name!: string
  company_users_count!: number
  created_at!: string
  guid!: string
  name!: string
  updated_at!: string
  icon!: string

  constructor(data: CompanyPresenter) {
    Object.assign(this, data)

    this.icon = 'store'
  }
}
