import { defineComponent } from 'vue'
import { CompanyUserPresenter } from '@/presenters/CompanyUserPresenter'
import { CurrenciesPresenter } from '@/presenters/CurrenciesPresenter'
import { deleteMember, updateMember } from '@/services/company'
import { useForm } from 'vuestic-ui/web-components'
import { deepCopy, getOptionCurrency, getOptionName } from '@/lib/utils'
import { getAllCurrencies } from '@/services/currencies'
import { getRoles } from '@/services/roles'
import { RolePresenter } from '@/presenters/RolePresenter'

const salaryFrequencyOptions = [
  {
    text: 'Daily',
    value: 'daily',
  },
  {
    text: 'Weekly',
    value: 'weekly',
  },
  {
    text: 'Bi Weekly',
    value: 'biweekly',
  },
  {
    text: 'Monthly',
    value: 'monthly',
  },
  {
    text: 'Quarterly',
    value: 'quarterly',
  },
  {
    text: 'Annually',
    value: 'annually',
  },
]

export default defineComponent({
  name: 'MemberProfilePage',
  props: {
    currentMember: {
      type: CompanyUserPresenter,
      required: true,
    },
  },
  data() {
    const { validate, reset } = useForm('update-salary-form')

    return {
      validate,
      reset,
      getOptionCurrency,
      getOptionName,

      salaryFrequencyOptions,

      member: deepCopy(this.currentMember) as CompanyUserPresenter,

      isDeleteMember: false,
      deleteMemberLoading: false,

      updateMemberLoading: false,
      rolesLoading: false,
      rolesOption: [] as RolePresenter[],
    }
  },
  emits: ['handleGetMember'],
  methods: {
    ///////////////////////////////////////// Update /////////////////////////////////////////

    async handleUpdateMember() {
      this.updateMemberLoading = true
      const { company_id } = this.$stores.auth.company
      const {
        id,
        basic_salary_amount,
        basic_salary_frequency,
        billable_hourly_rate_earning_amount,
        billable_hourly_rate_charging_amount,
        roles,
        ...member
      } = this.member

      const response = await updateMember(company_id, id, {
        basic_salary_amount,
        basic_salary_currency_id: member.basic_salary_currency?.id,
        basic_salary_frequency,
        billable_hourly_rate_earning_amount,
        billable_hourly_rate_earning_currency_id:
          member.billable_hourly_rate_earning_currency?.id,
        billable_hourly_rate_charging_amount,
        billable_hourly_rate_charging_currency_id:
          member.billable_hourly_rate_charging_currency?.id,
        role_ids: roles?.length === 0 ? [0] : roles?.map((role) => role.id),
      })

      if (response.success) {
        this.$vaToast.init({
          message: 'Member Updated successfully!',
          color: 'success',
        })
        this.$emit('handleGetMember', id)
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.updateMemberLoading = false
    },

    ///////////////////////////////////////// delete /////////////////////////////////////////

    handleToggleDeleteMember() {
      this.isDeleteMember = !this.isDeleteMember
    },

    async handleDeleteMember() {
      this.deleteMemberLoading = true

      const response = await deleteMember(
        this.$stores.auth.company.company_id,
        this.member.id,
      )
      if (response.success) {
        this.$vaToast.init({
          message: 'Member Removed successfully!',
          color: 'success',
        })

        this.handleToggleDeleteMember()
        this.$router.replace('/firm?tab=Workspaces,+Teams+%26+Members')
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.deleteMemberLoading = false
    },

    ///////////////////////////////////////// Roles /////////////////////////////////////////

    async handleGetRoles() {
      this.rolesLoading = true

      const response = await getRoles()

      // const itemsToExclude = this.member?.roles?.map((role) => role.id) || []
      //   this.rolesOption = response.data.list.filter(
      //     (item: RolePresenter) => !itemsToExclude.includes(item.id),
      //   )

      if (response.success) {
        this.rolesOption = response.data.list
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }

      this.rolesLoading = false
    },
  },
  computed: {},
  mounted() {
    this.$stores.currency.handleGetCompanyCurrencies()
    this.handleGetRoles()
  },
  watch: {
    currentMember: {
      handler(data) {
        this.member = deepCopy(data)
      },
    },
    member: {
      deep: true,
      handler(data) {
        console.log({ data })
      },
    },
  },
})
