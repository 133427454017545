import DynamicSections from '@/components/financial/PrintInvoice/DynamicSection/DynamicSections.vue'
import mockInvoiceData from '@/constants/mockInvoiceTemplate'
import { deepCopy, validateInput } from '@/lib/utils'
import { SectionsPresenter } from '@/presenters/SectionsPresenter'
import { defineComponent, PropType } from 'vue'
import draggable from 'vuedraggable'
import { useForm } from 'vuestic-ui/web-components'

interface Field {
  label?: string
  type: string
  key: string
  src?: string
  text?: string
  required?: boolean
}

type SectionKey = 'header' | 'body' | 'footer'

const logoImage = new URL('@/assets/icons/logo.svg', import.meta.url).href

export default defineComponent({
  name: 'InvoiceTemplateModal',
  props: {
    isTemplateModalOpen: Boolean,
    isAddTemplateLoading: Boolean,
    isUpdateTemplateLoading: Boolean,
    isUpdateTemplate: Boolean,
    selectedTemplate: SectionsPresenter,
  },
  emits: ['handleToggleModal', 'handleUpdateTemplate', 'handleAddTemplate'],
  data() {
    const { validate } = useForm('form')
    const defaultData = {
      header: [
        [
          { label: 'Logo 1', type: 'img', key: 'header', src: 'logo' },
          { label: 'Logo 2', type: 'img', key: 'header', src: 'logo1' },
        ],
        [{ label: 'Text Field', type: 'text', key: 'header', text: '' }],
        [{ label: 'Header', type: 'h1', key: 'header', text: '' }],
        [
          { label: 'Client Data', type: 'client', key: 'body' },
          { label: 'Date|Number', type: 'date|number', key: 'body' },
        ],
      ],
      body: [
        [{ label: 'Invoice Table', type: 'table', key: 'body' }],
        [{ label: 'Timekeepers', type: 'timekeepers', key: 'body' }],
      ],
      footer: [
        [{ label: 'Text Field', type: 'text', key: 'footer', text: '' }],
      ],
    }

    return {
      validate,
      validateInput,
      templateForm: this.selectedTemplate as SectionsPresenter,
      isTemplateModalOpenBool: this.isTemplateModalOpen,
      isSelectFields: false,
      selectedSection: '' as SectionKey,
      selectedRowIndex: null as number | null,

      selectedData: {} as Record<SectionKey, Field[][]>,
      defaultData,

      formFields: [
        { label: 'Text Field', type: 'text', text: '' },
        { label: 'Logo 1', type: 'img', src: 'logo' },
        { label: 'Logo 2', type: 'img', src: 'logo1' },
        { label: 'Header', type: 'h1', text: '' },
        { label: 'Client Data', type: 'client' },
        { label: 'Date|Number', type: 'date|number' },
        { label: 'Invoice Table', type: 'table' },
        { label: 'Timekeepers', type: 'timekeepers' },
      ] as Field[],

      mockInvoiceData,
      logoImage,

      subtotal: '200',
      convertToSecondaryCurrencySubTotal: '250',
      convertToSecondaryCurrencyTotal: '300',
      total: '250',
    }
  },
  methods: {
    handleToggleModal(): void {
      this.isTemplateModalOpenBool = !this.isTemplateModalOpenBool
      this.$emit('handleToggleModal')
    },

    handleToggleSubmit(): void {
      if (this.isUpdateTemplate) {
        this.$emit('handleUpdateTemplate', {
          ...this.templateForm,
          data: this.selectedData,
          configuration_type: 'invoice_template',
        })
      } else {
        this.$emit('handleAddTemplate', {
          name: this.templateForm?.name,
          data: this.selectedData,
          configuration_type: 'invoice_template',
        })
      }
    },

    handleToggleSelectFields(
      section: SectionKey = 'header',
      index: number | null = null,
    ): void {
      this.isSelectFields = !this.isSelectFields
      this.selectedSection = section
      this.selectedRowIndex = index
    },

    handleAddField(field: Field): void {
      if (this.selectedRowIndex !== null) {
        this.selectedData[this.selectedSection][this.selectedRowIndex].push({
          ...field,
          key: this.selectedSection,
        })
      } else {
        this.selectedData[this.selectedSection].push([
          { ...field, key: this.selectedSection },
        ])
      }
      this.isSelectFields = false
    },

    handleRemoveRow(section: SectionKey, rowIndex: number): void {
      this.selectedData[section].splice(rowIndex, 1)
    },

    handleRemoveField(
      section: SectionKey,
      rowIndex: number,
      fieldIndex: number,
    ): void {
      this.selectedData[section][rowIndex].splice(fieldIndex, 1)
      if (this.selectedData[section][rowIndex].length === 0) {
        this.handleRemoveRow(section, rowIndex)
      }
    },

    capitalizeFirstLetter(str: string): string {
      return str?.charAt(0).toUpperCase() + str.slice(1)
    },
  },
  computed: {
    submitButton(): string {
      return this.isUpdateTemplate ? 'Update Template' : 'Add Template'
    },
    headerTitle(): string {
      return this.isUpdateTemplate ? 'Update Template' : 'Add Template'
    },
    orderedSelectedData(): Record<SectionKey, Field[][]> {
      return {
        header: this.selectedData.header,
        body: this.selectedData.body,
        footer: this.selectedData.footer,
      }
    },
  },
  watch: {
    isTemplateModalOpen(data: boolean) {
      this.isTemplateModalOpenBool = data
    },
    selectedTemplate(data: SectionsPresenter) {
      this.templateForm = deepCopy(data)
      this.selectedData = Object.keys(data.data || {}).length
        ? deepCopy(data.data)
        : deepCopy(this.defaultData)
    },
  },
  components: {
    draggable,
    DynamicSections,
  },
})
