<template>
  <div class="buttons" id="menu-activator">
    <VaButton class="top-button" icon="chat_bubble" @click="handleSetTab(0)" />
    <VaButton class="bottom-button" icon="article" @click="handleSetTab(1)" />
  </div>
  <v-menu
    activator="#menu-activator"
    v-model="isChatActivityPanelOpen"
    :close-on-content-click="false"
  >
    <v-card class="menu-container" width="25rem" height="70vh">
      <div class="top-bar">
        <TabsBar
          :tabs="tabs"
          :full-tab="true"
          gap-size="1rem"
          :active-tab-id="activeTabId"
          @changeTab="changeTabHandler"
        />
        <div class="button-icons">
          <VaButton v-if="activeTabId === 0" icon="info" preset="secondary" />
          <VaButton icon="search" preset="secondary" />
          <VaButton
            icon="view_sidebar"
            preset="secondary"
            @click="toggleChatActivityPanel()"
          />
        </div>
      </div>

      <VaInnerLoading v-if="versionsLoading" :loading="versionsLoading">
        <div class="loader"></div>
      </VaInnerLoading>

      <div v-else class="menu-content-wrapper">
        <div class="menu-content" v-if="activeTabId === 0">
          <VaButton preset="secondary">START A NEW CONVERSATION</VaButton>
          <p class="underline-hover press-button">Invite Members</p>
          <div class="content-area"></div>
          <VaInput
            v-model="newMessage"
            placeholder="Send Message"
            class="task-name"
            preset="bordered"
          >
            <template #prependInner>
              <VaIcon name="add" color="primary" size="1.5rem" />
            </template>
            <template #appendInner>
              <VaIcon name="send" color="primary" size="1.5rem" />
            </template>
          </VaInput>
        </div>

        <div class="menu-content" v-else>
          <div class="content-area" v-if="versions.length > 0">
            <div
              v-for="(version, index) in versions"
              :key="index"
              class="activity"
            >
              <div class="activity-details">
                <div class="activity-item">
                  <va-avatar
                    :src="version?.whodunnit_user?.avatar?.medium_url"
                    color="#ECF0F1"
                  >
                    <span v-if="!version?.whodunnit_user?.avatar?.medium_url">
                      {{ version?.whodunnit_user?.avatar_letter }}
                    </span>
                  </va-avatar>
                  <span class="activity-text">{{
                    version?.whodunnit_user?.fullName
                  }}</span>
                </div>
                <div class="gray-text">
                  {{ new Date(version?.created_at).toLocaleString() }}
                </div>
                <div v-for="message in version.messages" :key="message">
                  {{ message }}
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <span class="activity-text">No versions available</span>
          </div>
        </div>
      </div>
    </v-card>
  </v-menu>
</template>
<script lang="ts" src="./script.ts" />
<style lang="scss" scoped src="./styles.scss" />
