import { defineComponent } from 'vue'
import TabsBar from '@/components/tabsBar/index.vue'
import ComingSoon from '@/components/ComingSoon/index.vue'
import FinancialGlobal from '@/views/Settings/FinancialSettings/FinancialGlobal/index.vue'
import InvoiceTemplates from '@/views/Settings/FinancialSettings/InvoiceTemplates/index.vue'
export default defineComponent({
  name: 'FinancialSettings',

  components: {
    TabsBar,
    ComingSoon,
    FinancialGlobal,
    InvoiceTemplates,
  },
  data() {
    const tabs = [
      { id: 0, name: 'TOOLBAR' },
      { id: 1, name: 'GLOBAL FINANCIAL' },
      { id: 2, name: 'FINANCIAL' },
      { id: 3, name: 'INVOICE TEMPLATES' },
    ]

    return {
      tabs,
      activeTabId: tabs[3].id,
      activeTab: tabs[3].name,
    }
  },
  methods: {
    /////////////////////////////////////////////////////////////////////////////
    changeTab({ tab }: { tab: { title?: string; name: string; id: number } }) {
      this.activeTabId = tab.id
      this.activeTab = tab.name
    },
  },
  computed: {},
})
