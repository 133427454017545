import { LeadPresenter } from '@/presenters/LeadPresenter'
import { apiRequest } from './apiUtils'
import { AddUpdateLeadFormInterface } from '@/Interfaces/Services'

export const getAllLeads = async ({
  per_page,
  page,
  keyword,
  won,
}: {
  per_page: number
  page?: number
  keyword?: string
  won?: boolean
}) => {
  const response = await apiRequest('get', '/leads', null, {
    per_page,
    page,
    keyword,
    won,
  })

  if (!response.success) {
    return response
  }

  const list = response.data?.list
  if (!Array.isArray(list)) {
    return response
  }

  return {
    ...response,
    data: {
      ...response.data,
      list: list.map((item: LeadPresenter) => new LeadPresenter(item)),
    },
  }
}

export const getLeadById = async (id: number) => {
  const response = await apiRequest('get', `/leads/${id}`)
  if (response.success) {
    return {
      ...response,
      data: new LeadPresenter(response.data),
    }
  } else {
    return response
  }
}

export const addLead = (body: AddUpdateLeadFormInterface) => {
  return apiRequest('post', '/leads', body)
}

export const updateLead = (id: number, body: AddUpdateLeadFormInterface) => {
  return apiRequest('put', `/leads/${id}`, body)
}

export const deleteLead = (id: number) => {
  return apiRequest('delete', `/leads/${id}`)
}
