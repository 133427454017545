import { defineComponent, reactive, ref, watchEffect } from 'vue'
import draggable from 'vuedraggable'
import ContactCard from '@/components/Contacts/contactCard/index.vue'
import LegalMatterCard from '@/components/LegalMatter/legalMatterCard/index.vue'
import FinancialCard from '@/components/financial/card/index.vue'
import TaskCard from '@/components/Task/Card/index.vue'

export default defineComponent({
  name: 'KanbanBoard',
  components: {
    draggable,
    ContactCard,
    LegalMatterCard,
    TaskCard,
    FinancialCard,
  },
  props: {
    groups: {
      type: Array as any,
      required: true,
    },
    list: {
      type: Array,
      required: true,
    },
    isSelect: {
      type: Boolean,
      required: false,
      default: false,
    },
    isContacts: {
      type: Boolean,
      required: false,
      default: false,
    },
    isLegalMatters: {
      type: Boolean,
      required: false,
      default: false,
    },
    isTasks: {
      type: Boolean,
      required: false,
      default: false,
    },
    isFinancial: {
      type: Boolean,
      required: false,
      default: false,
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  setup(props, { emit }) {
    const isLoadingLocal = ref(props.isLoading)
    watchEffect(() => {
      isLoadingLocal.value = props.isLoading
    })

    const localGroups = reactive(
      [...props.groups].sort(
        (a, b) => Number(a.sort_order) - Number(b.sort_order),
      ),
    )

    const groupList = reactive<any>({})

    const newGroupTitle = reactive({ title: '' })
    const selectedCards = ref<number[]>([])

    const handleSelectedCards = (updatedCards: number[]) => {
      selectedCards.value = updatedCards // getting the selected cards
      console.log({ updatedCards })
    }

    const initializeGroupList = (list: any) => {
      localGroups?.forEach((group) => {
        groupList[group.id] = list.filter(
          (event: any) =>
            event.status.toLowerCase() === group.title.toLowerCase(),
        )
      })
      console.log({ localGroups, groupList })
    }

    const onEventDrop = (evt: any) => {
      const { item, to } = evt
      const newGroupId = Number(
        Object.keys(groupList).find((key: any) => groupList[key] === to),
      )
      item.group = String(newGroupId)
      emit('update:list', { evt }) // Emit updated list
    }

    const addGroup = () => {
      const newGroupId = localGroups.length
      const newGroup = {
        id: newGroupId,
        title: newGroupTitle.title,
        index: '12',
        color: 'red',
      }
      localGroups.push(newGroup)
      groupList[newGroupId] = []
      newGroupTitle.title = ''
      emit('update:groups', [...localGroups]) // Emit updated groups
    }

    return {
      localGroups,
      groupList,
      newGroupTitle,
      initializeGroupList,
      onEventDrop,
      addGroup,
      selectedCards,
      handleSelectedCards,
      isLoadingLocal,
    }
  },
  watch: {
    list(newValue) {
      this.initializeGroupList(this.list)
      console.log('List changed to:', newValue)
    },
    groups(newGroups) {
      console.log('Groups changed to:', newGroups)
    },
  },
  mounted() {
    console.log('Initial Groups:', this.groups)
    console.log('Initial List:', this.list)
    this.list && this.initializeGroupList(this.list)
  },
})
