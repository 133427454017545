import { addUpdateSectionType } from '@/Interfaces/Services'
import { SectionsPresenter } from '@/presenters/SectionsPresenter'
import { StorageFoldersPresenter } from '@/presenters/StorageFoldersPresenter'
import {
  addSection,
  deleteSection,
  getAllSections,
  updateSection,
} from '@/services/sections'
import { defineComponent } from 'vue'
import draggable from 'vuedraggable'
import MatterTemplateModal from '@/views/Settings/MattersSettings/MatterToolbar/MatterTemplateModal/index.vue'
import { formatTemplateFields } from '@/lib/utils'
import { TemplateInterface } from '@/Interfaces/Constants'

export default defineComponent({
  name: 'MatterToolbar',

  props: {},
  emits: [],
  data() {
    const nonUpdatableSections = [
      'Dashboard',
      'People',
      'Organizations',
      'Leads',
      'Clients',
      'Active Clients',
      'Retainers',
      'Suppliers',
      'KYC',
    ]
    return {
      isSectionsLoading: false,
      nonUpdatableSections,

      sections: [] as SectionsPresenter[],

      deleteDialog: false,
      deleteSectionId: null as number | null,

      isSectionLoading: {} as Record<
        number,
        { updating: boolean; deleting: boolean }
      >,

      addMatterTempLoading: false,
      isAddMatterTemp: false,

      selectedTemplate: {} as SectionsPresenter,
      isUpdateTemplate: false,
    }
  },
  methods: {
    handleToggleDeleteDialog(id: number) {
      this.deleteDialog = !this.deleteDialog
      this.deleteSectionId = id
    },

    ////////////////////////////////////////////////////////////////////////////////
    async handleGetAllSections() {
      this.isSectionsLoading = true
      const response = await getAllSections('legal_matters')
      if (response.success) {
        this.sections = response.data?.list

        console.log({ sections: this.sections })
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.isSectionsLoading = false
    },

    async handleUpdateSection({
      id,
      body,
      closeModal = true,
    }: {
      id: number
      body: addUpdateSectionType
      closeModal?: boolean
    }) {
      this.addMatterTempLoading = true
      if (!this.isSectionLoading[id]) {
        this.isSectionLoading[id] = { updating: false, deleting: false }
      }
      this.isSectionLoading[id].updating = true

      const { form_columns, listing_filters, ...updateBody } = body

      const response = await updateSection(id, {
        ...updateBody,
        ...(listing_filters?.contact_type && listing_filters),
        form_columns: formatTemplateFields(
          form_columns as TemplateInterface[][],
        ),
      })

      if (!response.success) {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }

      this.handleGetAllSections()
      closeModal && this.handleToggleAddModalTemp()

      this.isSectionLoading[id].updating = false
      this.addMatterTempLoading = false
    },

    async handleAddSection(section: SectionsPresenter) {
      this.addMatterTempLoading = true

      const {
        name,
        section_type,
        allow_multiple_stages_per_item,
        sort_order,
        form_columns,
      } = section

      const response = await addSection({
        name,
        ...(section_type
          ? { section_type }
          : { section_type: 'legal_matters' }),
        ...(allow_multiple_stages_per_item
          ? { allow_multiple_stages_per_item }
          : { allow_multiple_stages_per_item: true }),
        ...(sort_order && { sort_order: Number(sort_order) + 1 }),
        form_columns: formatTemplateFields(form_columns),
      })

      if (response.success) {
        this.handleGetAllSections()
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }

      this.addMatterTempLoading = false
    },

    async handleDeleteSection() {
      if (this.deleteSectionId !== null) {
        if (!this.isSectionLoading[this.deleteSectionId]) {
          this.isSectionLoading[this.deleteSectionId] = {
            updating: false,
            deleting: false,
          }
        }
        this.isSectionLoading[this.deleteSectionId].deleting = true

        const response = await deleteSection(this.deleteSectionId)

        if (response.success) {
          this.deleteSectionId = null
          this.handleGetAllSections()
        } else {
          this.$vaToast.init({
            message: `${response.message}`,
            color: 'danger',
          })
        }

        if (this.deleteSectionId !== null) {
          this.isSectionLoading[this.deleteSectionId].deleting = false
        }
      }
    },

    ////////////////////////////////////////////////////////////////////

    onSectionDragEnd(event: {
      newIndex: number
      oldIndex: number
      item: { _underlying_vm_: StorageFoldersPresenter }
    }) {
      const {
        newIndex,
        oldIndex,
        item: { _underlying_vm_ },
      } = event

      const sort_order = newIndex >= oldIndex ? newIndex + 1 : newIndex

      this.handleUpdateSection({
        id: _underlying_vm_.id as number,
        body: {
          sort_order,
        },
        closeModal: false,
      })
    },

    ////////////////////////////////////////////////////////////////////

    handleToggleAddModalTemp(template?: SectionsPresenter) {
      this.isAddMatterTemp = !this.isAddMatterTemp
      if (template) {
        this.selectedTemplate = { ...template }
        this.isUpdateTemplate = true
      } else {
        this.selectedTemplate = {} as SectionsPresenter
        this.isUpdateTemplate = false
      }
    },
  },
  mounted() {
    this.handleGetAllSections()
  },
  components: {
    draggable,
    MatterTemplateModal,
  },
})
