<template>
  <VaModal
    v-model="isAddTargetFormOpenBool"
    size="small"
    hide-default-actions
    fixed-layout
    no-outside-dismiss
    @update:modelValue="handleToggleAddTargetModal"
  >
    <template #header>
      <h1 class="add-target-form-modal-header">
        Add a Target
        <VaIcon
          name="close"
          size="30px"
          class="icon"
          @click="handleToggleAddTargetModal"
        />
      </h1>
    </template>
    <VaForm ref="form" @submit.prevent="validate() && handleCreateTarget()">
      <div class="add-target-container">
        <VaSelect
          v-model="form.id"
          placeholder="Assigned To"
          clearable
          background="#ECF0F1"
          label="assigned to"
          searchable
          :loading="$stores.usersData.membersLoading"
          :options="$stores.usersData.teamMembers"
          :highlight-matched-text="false"
          :text-by="getOptionFullName"
          value-by="id"
          @update:search="handleSearchMembers"
        />

        <VaInput
          v-model="form.title"
          placeholder="Title"
          background="#ECF0F1"
        />

        <div>
          <div class="label">
            <p>TARGET TYPE</p>
          </div>
          <div class="icon-sub-container">
            <div
              v-for="(type, index) in $stores.generalData.companyConfigs
                .calendarTypesOption"
              :key="index"
            >
              <v-tooltip :text="type.name" location="top">
                <template #activator="{ props }">
                  <VaButton
                    v-bind="props"
                    :color="activeType === type.id ? 'primary' : '#ECF0F1'"
                    @click="selectType(type.id)"
                  >
                    <font-awesome-icon :icon="type.icon_name" />
                  </VaButton>
                </template>
              </v-tooltip>
            </div>
          </div>
        </div>

        <div>
          <div class="label">
            <p>TARGET</p>
          </div>
          <div class="option-assign">
            <VaIcon name="link" color="primary" size="16px" />
            <v-menu
              v-model="isItemAssignUpdate"
              :close-on-content-click="false"
              location="top left"
            >
              <template #activator="{ props }">
                <div v-bind="props" class="linked-items-container">
                  <div
                    v-if="form.target && form.target.length > 0"
                    class="linked-items-container"
                  >
                    <div
                      v-for="(item, index) in form.target"
                      :key="index"
                      class="linked-item"
                    >
                      <v-tooltip
                        :text="camelCaseToNormal(item.class_name || '')"
                        location="top"
                      >
                        <template #activator="{ props }">
                          <VaIcon
                            v-bind="props"
                            :name="item.icon"
                            color="primary"
                            size="26px"
                          />
                        </template>
                      </v-tooltip>
                      <span class="linked-item-name">
                        {{ item.name || item.fullName }}
                      </span>
                    </div>
                  </div>
                  <p v-else class="press-button">Link to</p>
                </div>
              </template>
              <TimeLinkModal
                :linked-items="form.target"
                @handleLinkTo="handleLinkToUpdate"
                @handleToggleAssignToMenu="handleToggleAssignToMenu"
              />
            </v-menu>
          </div>
        </div>

        <div class="icon-container">
          <VaSelect
            class="tags-select"
            v-model="form.tag"
            background="#ECF0F1"
            placeholder="Add Tag"
            :options="['Hot', 'Important', 'Cool', 'Icebox']"
            :highlight-matched-text="false"
            multiple
            selected-top-shown
            :max-visible-options="2"
          />

          <div class="icon-sub-container">
            <v-tooltip text="Time Range" location="top">
              <template #activator="{ props }">
                <TimeRange
                  v-bind="props"
                  :date="time_range"
                  shape="button-icon"
                  @update:date="handleSelectedRangeDate"
                />
              </template>
            </v-tooltip>

            <v-tooltip text="Add Start Date" location="top">
              <template #activator="{ props }">
                <DatePickerModal
                  v-bind="props"
                  :date="due_time"
                  shape="button-icon"
                  @update:date="handleSelectedDate"
                />
              </template>
            </v-tooltip>

            <v-tooltip text="Priority" location="top">
              <template #activator="{ props }">
                <VaMenu v-bind="props">
                  <template #anchor>
                    <VaButton color="#ECF0F1">
                      <VaIcon
                        name="flag"
                        size="medium"
                        :color="form.priority?.color"
                      />
                    </VaButton>
                  </template>
                  <VaMenuItem
                    v-for="(flag, index) in $stores.generalData.companyConfigs
                      .taskPriorityOptions"
                    :key="index"
                    @click="handleFlagMenuItemClick(flag)"
                  >
                    <VaIcon name="flag" :color="flag?.color" />
                    {{ flag?.name }}
                  </VaMenuItem>
                </VaMenu>
              </template>
            </v-tooltip>
          </div>
        </div>

        <VaTextarea
          v-model="form.action_note"
          placeholder="Describe the target and the action that needs to be taken"
          max-rows="5"
          label="Action"
          autosize
          background="#ECF0F1"
        />

        <VaInput
          v-model="form.note"
          placeholder="Add note"
          background="#ECF0F1"
          label="note"
        >
          <template #prepend>
            <VaIcon name="event_note" class="mr-2" color="secondary" />
          </template>
        </VaInput>
      </div>
    </VaForm>
    <template #footer>
      <div class="add-target-form-modal-buttons">
        <VaButton preset="secondary" border-color="primary" disabled>
          Templates
        </VaButton>
        <VaButton
          class="save-button"
          :disabled="addTargetLoading"
          @click="validate() && handleCreateTarget()"
        >
          Save
        </VaButton>
      </div>
    </template>
  </VaModal>
</template>

<script lang="ts" src="./script.ts" />
<style lang="scss" scoped src="./styles.scss" />
