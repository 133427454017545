import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-57908cfc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "scroll-container-wrapper for-tables" }
const _hoisted_2 = { class: "title" }
const _hoisted_3 = { class: "currency" }
const _hoisted_4 = {
  key: 0,
  class: "social-media-icons"
}
const _hoisted_5 = { class: "organization" }
const _hoisted_6 = {
  key: 0,
  style: {"color":"black"}
}
const _hoisted_7 = {
  key: 0,
  class: "social-media-icons"
}
const _hoisted_8 = { class: "organization" }
const _hoisted_9 = {
  key: 0,
  style: {"color":"black"}
}
const _hoisted_10 = { class: "assigned-to" }
const _hoisted_11 = {
  key: 0,
  style: {"color":"black"}
}
const _hoisted_12 = { class: "table-action-td" }
const _hoisted_13 = ["src"]
const _hoisted_14 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_VaAvatar = _resolveComponent("VaAvatar")!
  const _component_VaIcon = _resolveComponent("VaIcon")!
  const _component_va_dropdown_content = _resolveComponent("va-dropdown-content")!
  const _component_va_dropdown = _resolveComponent("va-dropdown")!
  const _component_v_data_table_server = _resolveComponent("v-data-table-server")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_data_table_server, {
      selection: _ctx.selectedRows,
      "onUpdate:selection": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedRows) = $event)),
      loading: _ctx.loading,
      headers: _ctx.headers,
      items: _ctx.items,
      "show-select": "",
      class: "custom-table",
      "items-length": _ctx.totalItems,
      "items-per-page": _ctx.itemsPerPage,
      "items-per-page-options": [10, 25, 50],
      "onUpdate:options": _ctx.handleLoadMore,
      "fixed-header": true,
      "fixed-footer": true
    }, {
      [`item.title`]: _withCtx(({ item }) => [
        (item?.path_to)
          ? (_openBlock(), _createBlock(_component_router_link, {
              key: 0,
              to: item?.path_to
            }, {
              default: _withCtx(() => [
                _createElementVNode("p", _hoisted_2, _toDisplayString(item.title), 1)
              ]),
              _: 2
            }, 1032, ["to"]))
          : _createCommentVNode("", true)
      ]),
      [`item.currency`]: _withCtx(({ item }) => [
        _createElementVNode("p", _hoisted_3, _toDisplayString(item.currency?.code), 1)
      ]),
      [`item.section_stage_items`]: _withCtx(({ item }) => [
        (item.section_stage_items?.[0]?.section_stage?.name)
          ? (_openBlock(), _createElementBlock("td", {
              key: 0,
              class: "stage-td",
              style: _normalizeStyle({
            backgroundColor:
              item.section_stage_items?.[0]?.section_stage?.color || '#babfc0',
          })
            }, _toDisplayString(item.section_stage_items?.[0]?.section_stage?.name), 5))
          : _createCommentVNode("", true)
      ]),
      [`item.label`]: _withCtx(({ item }) => [
        (item.label?.name)
          ? (_openBlock(), _createElementBlock("td", {
              key: 0,
              class: "stage-td",
              style: _normalizeStyle({
            backgroundColor: item.label?.color || '#babfc0',
          })
            }, _toDisplayString(item.label?.name), 5))
          : _createCommentVNode("", true)
      ]),
      [`item.organization_contact`]: _withCtx(({ item }) => [
        (item?.organization_contact)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createVNode(_component_router_link, {
                to: item?.organization_contact?.path_to
              }, {
                default: _withCtx(() => [
                  _createElementVNode("p", _hoisted_5, [
                    (
                  item.organization_contact?.fullName ||
                  item?.organization_contact?.avatar?.medium_url
                )
                      ? (_openBlock(), _createBlock(_component_VaAvatar, {
                          key: 0,
                          color: "#ECF0F1",
                          src: item?.organization_contact?.avatar?.medium_url,
                          alt: item?.referred_by?.fullName,
                          size: "small"
                        }, {
                          default: _withCtx(() => [
                            (!item?.organization_contact?.avatar?.medium_url)
                              ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(item.organization_contact?.avatar_letter), 1))
                              : _createCommentVNode("", true)
                          ]),
                          _: 2
                        }, 1032, ["src", "alt"]))
                      : _createCommentVNode("", true),
                    _createTextVNode(" " + _toDisplayString(item?.organization_contact?.fullName), 1)
                  ])
                ]),
                _: 2
              }, 1032, ["to"])
            ]))
          : _createCommentVNode("", true)
      ]),
      [`item.individual_contact`]: _withCtx(({ item }) => [
        (item?.individual_contact)
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              _createVNode(_component_router_link, {
                to: item?.individual_contact?.path_to
              }, {
                default: _withCtx(() => [
                  _createElementVNode("p", _hoisted_8, [
                    (
                  item.individual_contact?.fullName ||
                  item?.individual_contact?.avatar?.medium_url
                )
                      ? (_openBlock(), _createBlock(_component_VaAvatar, {
                          key: 0,
                          color: "#ECF0F1",
                          src: item?.individual_contact?.avatar?.medium_url,
                          alt: item?.referred_by?.fullName,
                          size: "small"
                        }, {
                          default: _withCtx(() => [
                            (!item?.individual_contact?.avatar?.medium_url)
                              ? (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(item.individual_contact?.fullName[0]), 1))
                              : _createCommentVNode("", true)
                          ]),
                          _: 2
                        }, 1032, ["src", "alt"]))
                      : _createCommentVNode("", true),
                    _createTextVNode(" " + _toDisplayString(item?.individual_contact?.fullName), 1)
                  ])
                ]),
                _: 2
              }, 1032, ["to"])
            ]))
          : _createCommentVNode("", true)
      ]),
      [`item.relationship_owner`]: _withCtx(({ item }) => [
        _createElementVNode("p", _hoisted_10, [
          (
              item.relationship_owner?.avatar_letter ||
              item?.relationship_owner?.avatar?.medium_url
            )
            ? (_openBlock(), _createBlock(_component_VaAvatar, {
                key: 0,
                color: "#ECF0F1",
                src: item?.relationship_owner?.avatar?.medium_url,
                alt: item?.relationship_owner?.fullName,
                size: "small"
              }, {
                default: _withCtx(() => [
                  (!item?.relationship_owner?.avatar?.medium_url)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_11, _toDisplayString(item.relationship_owner?.avatar_letter), 1))
                    : _createCommentVNode("", true)
                ]),
                _: 2
              }, 1032, ["src", "alt"]))
            : _createCommentVNode("", true),
          _createTextVNode(" " + _toDisplayString(item.relationship_owner?.fullName), 1)
        ])
      ]),
      [`item.action`]: _withCtx(({ item }) => [
        _createElementVNode("td", _hoisted_12, [
          _createVNode(_component_va_dropdown, {
            offset: [0, -70],
            "close-on-content-click": ""
          }, {
            anchor: _withCtx(() => [
              _createElementVNode("img", {
                src: _ctx.MoreVertIcon,
                alt: "MoreVertIcon",
                class: "press-button-2"
              }, null, 8, _hoisted_13)
            ]),
            default: _withCtx(() => [
              _createVNode(_component_va_dropdown_content, { class: "dropdown" }, {
                default: _withCtx(() => [
                  _createElementVNode("p", {
                    onClick: ($event: any) => (_ctx.viewProfile(item))
                  }, [
                    _createVNode(_component_VaIcon, { name: "person" }),
                    _createTextVNode(" View Lead ")
                  ], 8, _hoisted_14)
                ]),
                _: 2
              }, 1024)
            ]),
            _: 2
          }, 1024)
        ])
      ]),
      _: 2
    }, 1032, ["selection", "loading", "headers", "items", "items-length", "items-per-page", "onUpdate:options"])
  ]))
}