<template>
  <v-card>
    <v-layout>
      <div class="container">
        <SideBar />
        <div class="progress-bar" v-if="generalDataStores.global_loading">
          <va-progress-bar color="primary" :size="3" indeterminate />
        </div>

        <div class="main">
          <div class="header">
            <div class="header-search">
              <router-link to="/">
                <VaButton
                  color="#fff"
                  border-color="#000"
                  style="
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    white-space: nowrap;
                    gap: 0.3rem;
                    text-overflow: ellipsis;
                    overflow: hidden;
                  "
                >
                  <p class="header-text-logo">HAQQI AI</p>
                  <img :src="logoImage" alt="LOGO" />
                </VaButton>
              </router-link>

              <v-autocomplete
                v-model="searchQuery"
                :items="globalSearchOptions"
                append-inner-icon="mdi-magnify"
                class="global-search"
                density="comfortable"
                placeholder="Global Search..."
                theme="light"
                menu-icon="icon"
                @update:search="handleSearchGlobal"
                :item-props="itemProps"
                @update:model-value="handleChooseOption"
                @click="handleOpenGlobalSearch"
                :loading="$stores.globalSearch.calendarGlobalSearchLoading"
                :no-data-text="noDataText"
              >
                <template v-slot:item="{ item, props }">
                  <v-list-item v-bind="props">
                    <template v-slot:prepend>
                      <p class="auto-complete-options">
                        <v-tooltip
                          :text="
                            camelCaseToNormal(item.value?.class_name || '')
                          "
                          location="top"
                        >
                          <template v-slot:activator="{ props }">
                            <VaIcon
                              v-bind="props"
                              :name="item.value?.icon"
                              color="primary"
                              size="26px"
                            />
                          </template>
                        </v-tooltip>
                      </p>
                    </template>
                  </v-list-item>
                </template>
              </v-autocomplete>

              <div class="left"></div>
              <TimeRecordModal />

              <div class="meet-icons">
                <img :src="teamsImage" alt="TEAMS" />
                <img :src="zoomImage" alt="ZOOM" />
              </div>

              <div class="get-verified">
                <img :src="verifiedImage" alt="VERIFIED" />
                <VaButton>GET VERIFIED</VaButton>
              </div>

              <NotificationModal
                :notificationNumber="notificationNumber"
                :notificationNumberClassification="
                  notificationNumberClassification
                "
                :notifications="notifications"
                :notificationLoading="notificationLoading"
                @handleGetNotification="handleGetNotification"
                @handleGetNotificationsCount="handleGetNotificationsCount"
              />

              <div class="user">
                <div class="user-info">
                  <h3>{{ user.fullName }}</h3>
                  <p>{{ user.title }}</p>
                </div>
                <VaAvatar
                  class="user-avatar"
                  color="#ECF0F1"
                  :src="user?.avatar?.medium_url"
                  size="3.5rem"
                  font-size="1rem"
                >
                  <div v-if="!user?.avatar?.medium_url">
                    <span>
                      {{ user.avatar_letter }}
                    </span>
                  </div>
                </VaAvatar>
                <!-- <img :src="tlaImage" alt="User" /> -->
                <!-- <va-icon name="settings" color="primary" size="2rem" class="user-info-icon" /> -->
              </div>
            </div>

            <!-- <div class="languages">
                <VaMenu :keepAnchorWidth="true">
                  <template #anchor>
                    <div class="selected-language" @click="changeLanguage = !changeLanguage">
                      <img :src="currentFlag" alt="Language Flag" />
                      <span>{{ currentLanguage }}</span>
                      <img v-if="!changeLanguage" :src="require('../../src/assets/icons/open-workspaces-icon.svg')
                        " alt="Language Flag" />
                      <img v-if="changeLanguage" :src="require('../../src/assets/icons/close-workspaces-icon.svg')
                        " alt="Language Flag" />
                    </div>
                  </template>

                  <VaMenuItem v-if="lang !== 'ar'" @click="selectLanguage('ar')">
                    <div class="language-dropdown-item">
                      <img :src="arabicImage" alt="Arabic Flag" />
                      <p>
                        {{ $t('lang.ar') }}
                      </p>
                    </div>
                  </VaMenuItem>
                  <VaMenuItem v-if="lang !== 'en'" @click="selectLanguage('en')">
                    <div class="language-dropdown-item">
                      <img :src="englishImage" alt="English Flag" />
                      <p>
                        {{ $t('lang.en') }}
                      </p>
                    </div>
                  </VaMenuItem>
                  <VaMenuItem v-if="lang !== 'fr'" @click="selectLanguage('fr')">
                    <div class="language-dropdown-item">
                      <img :src="frenchImage" alt="French Flag" />
                      <p>
                        {{ $t('lang.fr') }}
                      </p>
                    </div>
                  </VaMenuItem>
                </VaMenu>
              </div> -->
          </div>

          <div class="page-content">
            <div class="content">
              <router-view />
              <div v-if="shouldShowButtons" class="chat-activity-panel">
                <ChatActivityPanel />
              </div>
            </div>
            <div class="chats">
              <ChatComponent />
            </div>
          </div>
        </div>
      </div>
    </v-layout>
  </v-card>
</template>

<script lang="ts" src="./script.ts"></script>
<style lang="scss" scoped src="./styles.scss"></style>
