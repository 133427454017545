<template>
  <VaModal
    v-model="isAddMatterBool"
    size="medium"
    hide-default-actions
    fixed-layout
    no-outside-dismiss
    @update:modelValue="handleToggleAddModal"
  >
    <template #header>
      <div class="form-modal-header">
        <h1>Create Matter</h1>
        <div>
          <VaSelect
            v-model="subSection"
            background="#ECF0F1"
            :options="subSections"
            :text-by="getOptionName"
            placeholder="Select Stage"
          />
        </div>
        <VaIcon
          class="press-button"
          name="close"
          size="30px"
          @click="handleToggleAddModal"
        />
      </div>
    </template>

    <DynamicForm
      :fields="formFields"
      :initial-values="form"
      :toggle-submit="toggleSubmit"
      @submit="handleCreateMatter"
    />

    <template #footer>
      <div class="form-modal-buttons">
        <VaButton
          preset="secondary"
          border-color="primary"
          @click="handleToggleAddModal"
        >
          Cancel
        </VaButton>
        <VaButton :loading="addMatterLoading" @click="handleToggleSubmit">
          Save
        </VaButton>
      </div>
    </template>
  </VaModal>
</template>
<script lang="ts" src="./script.ts" />
<style lang="scss" scoped src="./styles.scss" />
