import { TeamMemberPresenter } from '@/presenters/teamMember'
import { CompanyUserPresenter } from '@/presenters/CompanyUserPresenter'
import { getMembers } from '@/services/company'
import { defineStore } from 'pinia'
import { useAuthStore } from './auth'
import { useToast } from 'vuestic-ui/web-components'
import { getMembersInterfaces } from '@/Interfaces/Services'

export const useUsersStore = defineStore({
  id: 'users',
  state: () => ({
    vaToast: useToast(),
    authStore: useAuthStore(),
    membersLoading: false,
    teamMembers: [] as TeamMemberPresenter[] | CompanyUserPresenter[],

    debounceTimeout: null as ReturnType<typeof setTimeout> | null,
  }),
  actions: {
    async handleGetMembers(body: getMembersInterfaces = {}) {
      this.membersLoading = true

      const response = await getMembers({
        ...body,
        company_id: this.authStore.company.company_id,

        per_page: 20,
      })
      if (response.success) {
        this.teamMembers = response.data?.list
      } else {
        this.vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.membersLoading = false
    },

    /////////////////////////////////// SEARCH ///////////////////////////////////////////

    handleSearchMembers(search: string, body: getMembersInterfaces = {}) {
      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout)
      }
      this.debounceTimeout = setTimeout(() => {
        this.handleGetMembers({ keyword: search, ...body })
      }, 500)
    },
  },
})
