<template>
  <div class="notification-button">
    <v-menu
      v-model="showNotifications"
      :close-on-content-click="false"
      location="bottom center"
      @update:model-value="handleCloseNotification()"
    >
      <template #activator="{ props }">
        <VaBadge overlap :text="notificationNumber">
          <div
            class="notification-header-logo"
            v-bind="props"
            @click="handleGetNotificationsCount"
          >
            <VaIcon name="notifications" color="#ffffff" size="1.5rem" />
          </div>
        </VaBadge>
      </template>

      <v-card class="notification-container pa-4" width="25rem" height="90vh">
        <div class="notification-header">
          <p>
            Notification
            <VaButton size="small" round>
              {{ notificationNumber }}
            </VaButton>
          </p>
          <span>
            <VaButton
              preset="secondary"
              round
              icon="open_with"
              @click="handleCloseNotification"
            />
            <VaButton
              preset="secondary"
              round
              icon="close"
              @click="handleCloseNotification"
            />
          </span>
        </div>
        <div class="notification-sub-header">
          <span class="notification-sub-header-left">
            <VaButton size="small"> Unread </VaButton>
            <VaButton preset="primary" size="small"> Show all </VaButton>
          </span>
          <span>
            <VaButton preset="secondary" round icon="filter_list" />
            <VaButton preset="secondary" round icon="settings" />
          </span>
        </div>
        <VaDivider />
        <!-- <div class="notification-details">
          <div>
            <va-icon name="error" size="2rem" />
          </div>
          <div>
            <p class="detail">
              "Lawyers, listen up! Your firm's website needs your attention - an
              important alert notification has just been issued."
            </p>
          </div>
          <div>
            <p class="time">
              12 min ago
              <VaButton preset="secondary" round icon="close" />
            </p>
          </div>
        </div> -->
        <div class="notification-accordion">
          <VaAccordion
            v-model="accordionModel"
            @update:model-value="accordionModel = $event"
            class="max-w-sm"
            multiple
          >
            <VaCollapse
              v-for="(notification, index) in notificationCollection"
              :key="index"
              v-model="notification.value"
              :header="notification.name"
              solid
              @update:model-value="
                handleGetNotification(notification, $event, index)
              "
            >
              <template #header="{ value, attrs, iconAttrs }">
                <div v-bind="attrs" class="collapse-header">
                  <h2 class="collapse-title">
                    {{ notification.name }}
                    <VaButton v-if="notification.number" size="small" round>
                      {{ notification.number }}
                    </VaButton>
                  </h2>

                  <VaIcon
                    v-if="notificationLoading && selectedLoading === index"
                    name="loop"
                    spin
                  />

                  <VaIcon
                    v-else
                    :name="!value ? 'va-arrow-down' : 'va-arrow-up'"
                    v-bind="iconAttrs"
                  />
                </div>
                <VaDivider class="divider" />
              </template>

              <template #body>
                <div class="collapse-body-container">
                  <router-link
                    v-for="(data, index) in notification.data"
                    :key="index"
                    :v-if="data"
                    class="collapse-body"
                    :class="{
                      'collapse-body-bc-read': data?.viewed_at !== null,
                    }"
                    :to="
                      data?.notifiable && 'path_to' in data.notifiable
                        ? data.notifiable.path_to
                        : ''
                    "
                  >
                    <!-- {{ data?.notifiable && 'path_to' in data.notifiable ? data.notifiable.path_to : '' }} -->

                    <span>
                      <VaAvatar
                        v-if="
                          data?.version?.whodunnit_user?.avatar_letter ||
                          data?.version?.whodunnit_user?.avatar?.medium_url
                        "
                        :src="data?.version?.whodunnit_user?.avatar?.medium_url"
                        color="#ECF0F1"
                        size="1.5rem"
                      >
                        <span
                          v-if="
                            !data?.version?.whodunnit_user?.avatar?.medium_url
                          "
                          class="avatar-letter"
                        >
                          {{ data?.version?.whodunnit_user?.avatar_letter }}
                        </span>
                      </VaAvatar>
                      <va-icon v-else name="account_circle" size="2rem" />
                    </span>
                    <div class="collapse-body-content">
                      <p class="name">
                        {{ data?.version?.whodunnit_user?.fullName }}
                      </p>
                      <p class="position">
                        {{ data?.version?.whodunnit_user?.title }}
                      </p>
                      <div class="company">
                        <p class="company-name">
                          {{ notificationType[data?.notifiable_type] }}
                        </p>
                        <!-- <div class="company-team">
                        <VaIcon name="groups" color="primary" />
                        <div>
                          {{ data?.version?.whodunnit_user?.fullName }}
                          <p>Team member:</p>
                          <p>{{ data.team }}</p>
                        </div>
                      </div> -->
                      </div>
                      <p class="context">
                        {{ data?.view_description }}
                      </p>
                    </div>
                    <div class="menu-time">
                      <img
                        :src="MoreVertIcon"
                        alt="MoreVertIcon"
                        class="press-button-2"
                      />
                      <!-- <p>{{ data.time }}</p> -->
                    </div>
                  </router-link>
                </div>
              </template>
            </VaCollapse>
          </VaAccordion>
        </div>
      </v-card>
    </v-menu>
  </div>
</template>
<script lang="ts" src="./script.ts" />
<style lang="scss" scoped src="./styles.scss" />
