import { VersionPresenter } from '@/presenters/VersionPresenter'
import { apiRequest } from './apiUtils'

export const chatActivityRoutesMap: Record<string, string> = {
  ContactDetailsView: 'Contact',
  ExpenseView: 'Expense',
  HearingsDetailsView: 'Hearing',
  InvoiceView: 'Invoice',
  LegalMattersDetails: 'LegalMatter',
  MilestoneDetails: 'Milestone',
  TaskDetails: 'Task',
}

import { RouteLocationNormalized } from 'vue-router'

export const getVersions = async (route: RouteLocationNormalized) => {
  const params = {
    object_type: chatActivityRoutesMap[String(route.name)],
    object_id: Number(route.params.id),
  }

  const response = await apiRequest('get', '/versions', null, params)

  if (response?.success && Array.isArray(response.data?.list)) {
    return {
      ...response,
      data: {
        ...response.data,
        list: response.data?.list
          .filter(
            (item: VersionPresenter) =>
              Object.keys(item.changeset || {}).length > 0,
          )
          .map((item: VersionPresenter) => new VersionPresenter(item)),
      },
    }
  } else {
    return response
  }
}
