<template>
  <div class="scroll-container-wrapper for-tables">
    <v-data-table-server
      v-model:selection="selectedRows"
      :loading="loading"
      :headers="headers"
      :items="invoices?.list"
      show-select
      class="custom-table scroll-container"
      :items-length="localTotalItems"
      :items-per-page="itemsPerPage"
      :items-per-page-options="[10, 25, 50]"
      @update:options="handleLoadMore"
      :fixed-header="true"
      :fixed-footer="true"
    >
      <template #[`item.invoice_number`]="{ item }">
        <router-link :to="`/financial/invoice/${item?.id}`">
          <p>{{ item?.invoice_number }}</p>
        </router-link>
      </template>

      <template #[`item.customer_name`]="{ item }">
        <router-link :to="`/financial/invoice/${item?.id}`">
          <p>{{ item?.customer_name }}</p>
        </router-link>
      </template>

      <template #[`item.status`]="{ item }">
        <p class="status" :class="item?.status.toLowerCase()">
          {{ item.status }}
        </p>
      </template>

      <template #[`item.currency`]="{ item }">
        <p class="currency">
          {{ item.currency.code }}
        </p>
      </template>

      <template #[`item.tax_amount`]="{ item }">
        <p class="tax_amount">
          {{ item.tax_amount || '-' }}
        </p>
      </template>

      <template #[`item.created_at`]="{ item }">
        <p>
          {{ item.created_at.split('T')[0] }}
        </p>
      </template>

      <template #[`item.approved`]="{ item }">
        <span v-if="item.approved">
          <VaIcon name="task_alt" color="#28A745" />
        </span>
        <span v-else>
          <VaIcon name="cancel" color="#FD7E14" />
        </span>
      </template>

      <template #[`item.action`]="{ item }">
        <td class="invoice-action-td">
          <va-dropdown :offset="[0, -70]" close-on-content-click>
            <template #anchor>
              <img
                :src="MoreVertIcon"
                alt="MoreVertIcon"
                class="press-button-2"
              />
            </template>
            <va-dropdown-content class="dropdown">
              <p @click="viewProfile(item)">
                <va-icon name="person" /> View Invoice
              </p>
              <p v-if="!item.approved" @click="handleApproveInvoice(item?.id)">
                <va-icon name="task_alt" /> Approve Invoice
              </p>
            </va-dropdown-content>
          </va-dropdown>
        </td>
      </template>
    </v-data-table-server>
  </div>
</template>

<script lang="ts" src="./script.ts"></script>
<style scoped lang="scss" src="./styles.scss"></style>
