<template>
  <div>
    <VaModal
      v-model="isDeleteMember"
      message="Would you like to Delete?"
      size="auto"
      hide-default-actions
    >
      <template #footer>
        <div class="add-delete-form-modal-buttons">
          <VaButton
            preset="secondary"
            border-color="primary"
            @click="handleToggleDeleteMember()"
            >Cancel</VaButton
          >
          <VaButton @click="handleDeleteMember" :loading="deleteMemberLoading"
            >Delete</VaButton
          >
        </div>
      </template>
    </VaModal>
    <div class="main-container">
      <div class="delete-button-container">
        <VaButton @click="handleToggleDeleteMember()" icon-right="delete">
          DELETE
        </VaButton>
      </div>

      <div>
        <VaForm
          ref="update-salary-form"
          class="update-form"
          @submit.prevent="validate"
        >
          <VaSelect
            label="Select Roles"
            placeholder="Roles"
            v-model="member.roles"
            :options="rolesOption"
            :text-by="getOptionName"
            :loading="rolesLoading"
            class="EHR-input"
            background="#ECF0F1"
            multiple
            clearable
            searchable
            track-by="id"
          />
          <div class="user-salary">
            <VaInput
              label="Basic Salary"
              v-model="member.basic_salary_amount"
              type="number"
              min="0"
              placeholder="Salary"
              class="BS-input"
              background="#ECF0F1"
            />
            <div class="SCF-container">
              <VaSelect
                label="salary currency"
                placeholder="Currency"
                v-model="member.basic_salary_currency"
                :options="$stores.currency.currencies"
                :text-by="getOptionCurrency"
                :loading="$stores.currency.currencyLoading"
                class="SC-input"
                background="#ECF0F1"
              />

              <VaSelect
                label="Salary Frequency"
                placeholder="Frequency"
                v-model="member.basic_salary_frequency"
                :options="salaryFrequencyOptions"
                text-by="text"
                value-by="value"
                class="SF-input"
                background="#ECF0F1"
              />
            </div>
          </div>
          <div class="billable-earning">
            <VaInput
              label="Earning Hourly Rate"
              v-model="member.billable_hourly_rate_earning_amount"
              type="number"
              min="0"
              placeholder="Earning Hourly Rate"
              class="EHR-input"
              background="#ECF0F1"
            />

            <VaSelect
              label="Earning Hourly Rate Currency"
              placeholder="Currency"
              v-model="member.billable_hourly_rate_earning_currency"
              :options="$stores.currency.currencies"
              :text-by="getOptionCurrency"
              :loading="$stores.currency.currencyLoading"
              class="EHR-input"
              background="#ECF0F1"
            />
          </div>

          <div class="billable-charging">
            <VaInput
              label="Charging Hourly Rate"
              v-model="member.billable_hourly_rate_charging_amount"
              type="number"
              min="0"
              placeholder="Charging Hourly Rate"
              class="CHR-input"
              background="#ECF0F1"
            />

            <VaSelect
              label="Charging Hourly Rate Currency"
              placeholder="Currency"
              v-model="member.billable_hourly_rate_charging_currency"
              :options="$stores.currency.currencies"
              :text-by="getOptionCurrency"
              :loading="$stores.currency.currencyLoading"
              class="CHR-input"
              background="#ECF0F1"
            />
          </div>
          <div class="action-buttons">
            <VaButton
              :loading="updateMemberLoading"
              @click="validate() && handleUpdateMember()"
              >Save</VaButton
            >
          </div>
        </VaForm>
      </div>
    </div>
  </div>
</template>
<script lang="ts" src="./script.ts" />
<style lang="scss" scoped src="./styles.scss" />
