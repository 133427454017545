<template>
  <div>
    <AddUpdateRoleModal
      :isRoleModalOpen="isRoleModalOpen"
      :addRoleLoading="addRoleLoading"
      :updateRoleLoading="updateRoleLoading"
      :permissions="permissions"
      :isUpdateRole="isUpdateRole"
      :selectedRole="selectedRole"
      :getRoleLoading="roleLoading"
      @handleToggleModal="handleToggleModal"
      @handleToggleSubmitAdd="handleAddRole"
      @handleToggleSubmitUpdate="handleUpdateRole"
    />

    <VaModal
      v-model="isDeleteRoleModalOpen"
      message="Do you want to delete this section?"
      ok-text="Delete"
      size="auto"
      @ok="handleDeleteRole"
    />

    <div class="roles">
      <div class="title-row">
        <h1 class="header">Roles & Permissions</h1>
        <VaButton @click="handleToggleModal" icon-right="add">
          Add Role
        </VaButton>
      </div>

      <div v-for="(role, index) in roles" :key="index" class="button-row">
        <VaButton
          class="options"
          preset="secondary"
          border-color="primary"
          @click="handleGetRole(role)"
        >
          {{ role.name }}
        </VaButton>

        <v-tooltip text="Delete" location="top">
          <template v-slot:activator="{ props }">
            <VaButton
              v-bind="props"
              preset="secondary"
              border-color="primary"
              @click="handleToggleDeleteRoleModal(role.id)"
            >
              <VaIcon
                class="press-button"
                name="delete_forever"
                :spin="role.id === selectedRoleId && isDeleteRoleLoading"
              />
            </VaButton>
          </template>
        </v-tooltip>

        <v-tooltip text="Duplicate" location="top">
          <template v-slot:activator="{ props }">
            <VaButton
              v-bind="props"
              preset="secondary"
              border-color="primary"
              @click="handleDuplicateRole(role)"
            >
              <VaIcon
                class="press-button"
                name="content_copy"
                :spin="
                  role.id === selectedRoleId && (addRoleLoading || roleLoading)
                "
              />
            </VaButton>
          </template>
        </v-tooltip>
      </div>
    </div>
  </div>
</template>

<script lang="ts" src="./script.ts" />
<style lang="scss" scoped src="./styles.scss" />
