import { resolveComponent as _resolveComponent, createVNode as _createVNode, mergeProps as _mergeProps, createElementVNode as _createElementVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a233465a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "notification-button" }
const _hoisted_2 = { class: "notification-header" }
const _hoisted_3 = { class: "notification-sub-header" }
const _hoisted_4 = { class: "notification-sub-header-left" }
const _hoisted_5 = { class: "notification-accordion" }
const _hoisted_6 = { class: "collapse-title" }
const _hoisted_7 = { class: "collapse-body-container" }
const _hoisted_8 = {
  key: 0,
  class: "avatar-letter"
}
const _hoisted_9 = { class: "collapse-body-content" }
const _hoisted_10 = { class: "name" }
const _hoisted_11 = { class: "position" }
const _hoisted_12 = { class: "company" }
const _hoisted_13 = { class: "company-name" }
const _hoisted_14 = { class: "context" }
const _hoisted_15 = { class: "menu-time" }
const _hoisted_16 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VaIcon = _resolveComponent("VaIcon")!
  const _component_VaBadge = _resolveComponent("VaBadge")!
  const _component_VaButton = _resolveComponent("VaButton")!
  const _component_VaDivider = _resolveComponent("VaDivider")!
  const _component_VaAvatar = _resolveComponent("VaAvatar")!
  const _component_va_icon = _resolveComponent("va-icon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_VaCollapse = _resolveComponent("VaCollapse")!
  const _component_VaAccordion = _resolveComponent("VaAccordion")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_menu = _resolveComponent("v-menu")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_menu, {
      modelValue: _ctx.showNotifications,
      "onUpdate:modelValue": [
        _cache[3] || (_cache[3] = ($event: any) => ((_ctx.showNotifications) = $event)),
        _cache[4] || (_cache[4] = ($event: any) => (_ctx.handleCloseNotification()))
      ],
      "close-on-content-click": false,
      location: "bottom center"
    }, {
      activator: _withCtx(({ props }) => [
        _createVNode(_component_VaBadge, {
          overlap: "",
          text: _ctx.notificationNumber
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _mergeProps({ class: "notification-header-logo" }, props, {
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleGetNotificationsCount && _ctx.handleGetNotificationsCount(...args)))
            }), [
              _createVNode(_component_VaIcon, {
                name: "notifications",
                color: "#ffffff",
                size: "1.5rem"
              })
            ], 16)
          ]),
          _: 2
        }, 1032, ["text"])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_v_card, {
          class: "notification-container pa-4",
          width: "25rem",
          height: "90vh"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("p", null, [
                _createTextVNode(" Notification "),
                _createVNode(_component_VaButton, {
                  size: "small",
                  round: ""
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.notificationNumber), 1)
                  ]),
                  _: 1
                })
              ]),
              _createElementVNode("span", null, [
                _createVNode(_component_VaButton, {
                  preset: "secondary",
                  round: "",
                  icon: "open_with",
                  onClick: _ctx.handleCloseNotification
                }, null, 8, ["onClick"]),
                _createVNode(_component_VaButton, {
                  preset: "secondary",
                  round: "",
                  icon: "close",
                  onClick: _ctx.handleCloseNotification
                }, null, 8, ["onClick"])
              ])
            ]),
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("span", _hoisted_4, [
                _createVNode(_component_VaButton, { size: "small" }, {
                  default: _withCtx(() => [
                    _createTextVNode(" Unread ")
                  ]),
                  _: 1
                }),
                _createVNode(_component_VaButton, {
                  preset: "primary",
                  size: "small"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(" Show all ")
                  ]),
                  _: 1
                })
              ]),
              _createElementVNode("span", null, [
                _createVNode(_component_VaButton, {
                  preset: "secondary",
                  round: "",
                  icon: "filter_list"
                }),
                _createVNode(_component_VaButton, {
                  preset: "secondary",
                  round: "",
                  icon: "settings"
                })
              ])
            ]),
            _createVNode(_component_VaDivider),
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_VaAccordion, {
                modelValue: _ctx.accordionModel,
                "onUpdate:modelValue": [
                  _cache[1] || (_cache[1] = ($event: any) => ((_ctx.accordionModel) = $event)),
                  _cache[2] || (_cache[2] = ($event: any) => (_ctx.accordionModel = $event))
                ],
                class: "max-w-sm",
                multiple: ""
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.notificationCollection, (notification, index) => {
                    return (_openBlock(), _createBlock(_component_VaCollapse, {
                      key: index,
                      modelValue: notification.value,
                      "onUpdate:modelValue": [($event: any) => ((notification.value) = $event), ($event: any) => (
                _ctx.handleGetNotification(notification, $event, index)
              )],
                      header: notification.name,
                      solid: ""
                    }, {
                      header: _withCtx(({ value, attrs, iconAttrs }) => [
                        _createElementVNode("div", _mergeProps(attrs, { class: "collapse-header" }), [
                          _createElementVNode("h2", _hoisted_6, [
                            _createTextVNode(_toDisplayString(notification.name) + " ", 1),
                            (notification.number)
                              ? (_openBlock(), _createBlock(_component_VaButton, {
                                  key: 0,
                                  size: "small",
                                  round: ""
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(notification.number), 1)
                                  ]),
                                  _: 2
                                }, 1024))
                              : _createCommentVNode("", true)
                          ]),
                          (_ctx.notificationLoading && _ctx.selectedLoading === index)
                            ? (_openBlock(), _createBlock(_component_VaIcon, {
                                key: 0,
                                name: "loop",
                                spin: ""
                              }))
                            : (_openBlock(), _createBlock(_component_VaIcon, _mergeProps({
                                key: 1,
                                name: !value ? 'va-arrow-down' : 'va-arrow-up'
                              }, iconAttrs), null, 16, ["name"]))
                        ], 16),
                        _createVNode(_component_VaDivider, { class: "divider" })
                      ]),
                      body: _withCtx(() => [
                        _createElementVNode("div", _hoisted_7, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(notification.data, (data, index) => {
                            return (_openBlock(), _createBlock(_component_router_link, {
                              key: index,
                              "v-if": data,
                              class: _normalizeClass(["collapse-body", {
                      'collapse-body-bc-read': data?.viewed_at !== null,
                    }]),
                              to: 
                      data?.notifiable && 'path_to' in data.notifiable
                        ? data.notifiable.path_to
                        : ''
                    
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("span", null, [
                                  (
                          data?.version?.whodunnit_user?.avatar_letter ||
                          data?.version?.whodunnit_user?.avatar?.medium_url
                        )
                                    ? (_openBlock(), _createBlock(_component_VaAvatar, {
                                        key: 0,
                                        src: data?.version?.whodunnit_user?.avatar?.medium_url,
                                        color: "#ECF0F1",
                                        size: "1.5rem"
                                      }, {
                                        default: _withCtx(() => [
                                          (
                            !data?.version?.whodunnit_user?.avatar?.medium_url
                          )
                                            ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(data?.version?.whodunnit_user?.avatar_letter), 1))
                                            : _createCommentVNode("", true)
                                        ]),
                                        _: 2
                                      }, 1032, ["src"]))
                                    : (_openBlock(), _createBlock(_component_va_icon, {
                                        key: 1,
                                        name: "account_circle",
                                        size: "2rem"
                                      }))
                                ]),
                                _createElementVNode("div", _hoisted_9, [
                                  _createElementVNode("p", _hoisted_10, _toDisplayString(data?.version?.whodunnit_user?.fullName), 1),
                                  _createElementVNode("p", _hoisted_11, _toDisplayString(data?.version?.whodunnit_user?.title), 1),
                                  _createElementVNode("div", _hoisted_12, [
                                    _createElementVNode("p", _hoisted_13, _toDisplayString(_ctx.notificationType[data?.notifiable_type]), 1)
                                  ]),
                                  _createElementVNode("p", _hoisted_14, _toDisplayString(data?.view_description), 1)
                                ]),
                                _createElementVNode("div", _hoisted_15, [
                                  _createElementVNode("img", {
                                    src: _ctx.MoreVertIcon,
                                    alt: "MoreVertIcon",
                                    class: "press-button-2"
                                  }, null, 8, _hoisted_16)
                                ])
                              ]),
                              _: 2
                            }, 1032, ["v-if", "class", "to"]))
                          }), 128))
                        ])
                      ]),
                      _: 2
                    }, 1032, ["modelValue", "onUpdate:modelValue", "header"]))
                  }), 128))
                ]),
                _: 1
              }, 8, ["modelValue"])
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}