<template>
  <!-- <div class="main"> -->
  <SharedHeader title-width="30rem" title="Home">
    <div class="sub-SharedHeader">
      <div class="sub-SharedHeader-left" />
      <div class="sub-SharedHeader-right" />
    </div>
  </SharedHeader>

  <div class="coming-soon-container">
    <ComingSoon />
  </div>
  <div>
    <!-- <h2>Invoice Editor</h2>

    <label>Invoice Text:</label>
    <textarea v-model="invoiceText"></textarea>

    <button @click="submitInvoice">Submit</button>

    <h3>Preview:</h3>
    <div class="preview" v-html="formattedInvoiceText"></div> -->
    <!-- <VaButton @click="$stores.ui.showSideDrawer = !$stores.ui.showSideDrawer">
        Open Sidebar
      </VaButton>
      <SideDrawer /> -->
  </div>
  <!-- </div> -->
</template>

<script lang="ts" src="./script.ts" />
<style lang="scss" scoped src="./styles.scss" />
