import { defineComponent } from 'vue'
import ActionsBar from '@/components/actionsBar/index.vue'
import { useForm } from 'vuestic-ui/web-components'
import {
  getOptionCurrency,
  isLongDescription,
  truncateText,
  validateInput,
  validateSelect,
} from '@/lib/utils'
import { paginationInterface } from '@/Interfaces/Services'
import { MilestonePresenter } from '@/presenters/MilestonePresenter'
import {
  addMilestone,
  generateMilestoneInvoice,
  getAllMilestones,
  updateMilestone,
} from '@/services/milestones'

const moreVertIcon = new URL(
  '@/assets/icons/More-vert-icon.svg',
  import.meta.url,
).href

export default defineComponent({
  name: 'Milestones',
  components: {
    ActionsBar,
  },
  data() {
    const { validate } = useForm('form')
    return {
      validate,
      validateInput,
      getOptionCurrency,
      validateSelect,
      truncateText,
      isLongDescription,

      moreVertIcon,

      selectedRows: [''],

      headers: [
        {
          title: '#',
          align: 'start',
          key: 'id',
          sortable: false,
          width: '2rem',
        },
        {
          title: 'Name',
          align: 'start',
          key: 'name',
          sortable: false,
          width: '10rem',
        },
        {
          title: 'Due Date',
          align: 'start',
          key: 'due_date',
          sortable: false,
          width: '10rem',
        },
        {
          title: 'Status',
          align: 'start',
          key: 'status.name',
          sortable: false,
          width: '10rem',
        },
        {
          title: 'Fees',
          align: 'start',
          key: 'fees_amount',
          sortable: false,
          width: '7rem',
        },
        {
          title: 'Expenses',
          align: 'start',
          key: 'expenses_amount',
          sortable: false,
          width: '7rem',
        },
        {
          title: 'Description',
          align: 'start',
          key: 'description',
          sortable: false,
        },
        {
          title: '',
          align: 'center',
          key: 'action',
          sortable: false,
          width: '3rem',
        },
      ],

      currentPage: 1,
      currentSortBy: [] as {
        key: string
        order?: 'asc' | 'desc'
      }[],

      isAddMileStone: false,

      form: {} as MilestonePresenter,
      isAddFunction: true,

      milestoneTableLoading: false,
      addMileStoneLoading: false,
      updateMileStoneLoading: false,
      generateInvoiceLoading: false,

      milestones: [] as MilestonePresenter[],
      milestonesItemPerPage: 10,
      milestonePage: 1,
      milestoneSortField: '' as string | undefined,
      milestoneSortDirection: '' as 'asc' | 'desc' | undefined,
      milestoneSearchKeyword: '',
      milestoneTotalItems: 0,

      debounceTimeout: null as ReturnType<typeof setTimeout> | null,
    }
  },
  watch: {
    updateMileStoneLoading: {
      handler(data) {
        !data && this.handleToggleAddMileStone()
      },
    },
    addMileStoneLoading: {
      handler(data) {
        !data && this.handleToggleAddMileStone()
      },
    },
    '$route.query.tab': {
      handler(newVal) {
        if (newVal === 'MILESTONES') {
          console.log('The tab is MILESTONES')
          this.handleGetMilestones()
        }
      },
      immediate: true, // This will trigger the handler immediately on component load
    },
    isAddMileStone: {
      handler(data) {
        if (data) {
          this.$stores.currency.handleGetCompanyCurrencies()
        } else {
          this.form = {} as MilestonePresenter
        }
      },
    },
  },
  methods: {
    async handleGetMilestones() {
      this.milestoneTableLoading = true

      const response = await getAllMilestones({
        per_page: this.milestonesItemPerPage,
        page: this.milestonePage,
        keyword: this.milestoneSearchKeyword,
        related_to_type: 'LegalMatter',
        related_to_id: Number(this.$route.params.id),
      })
      if (response.success) {
        this.milestones = response.data?.list

        this.milestoneTotalItems =
          response.data.total_pages * this.milestonesItemPerPage
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.milestoneTableLoading = false
    },

    async handlePaginationMilestones({
      page,
      itemsPerPage,
      sortBy,
    }: paginationInterface) {
      this.milestonesItemPerPage = itemsPerPage
      this.milestonePage = page
      if (sortBy.length > 0) {
        this.milestoneSortField = sortBy[0].key.split('.')[1]
        this.milestoneSortDirection = sortBy[0].order
      } else {
        this.milestoneSortField = undefined
        this.milestoneSortDirection = undefined
      }

      await this.handleGetMilestones()
    },

    searchMilestoneData(search: string) {
      this.milestoneSearchKeyword = search

      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout)
      }

      this.debounceTimeout = setTimeout(() => {
        this.handleGetMilestones()
      }, 500)
    },

    handleLoadMore({ page, itemsPerPage, sortBy }: paginationInterface) {
      const { currentPage, milestonesItemPerPage, currentSortBy } = this

      const hasPaginationChanged =
        page !== currentPage || itemsPerPage !== milestonesItemPerPage
      const hasSortingChanged =
        JSON.stringify(sortBy) !== JSON.stringify(currentSortBy)

      if (hasPaginationChanged || hasSortingChanged) {
        this.handlePaginationMilestones({ page, itemsPerPage, sortBy })

        // Update state after the change
        this.currentPage = page
        this.milestonesItemPerPage = itemsPerPage
        this.currentSortBy = sortBy
      }
    },

    ///////////////////////////////////////////////////////////////////////

    handleToggleAddMileStone(record?: MilestonePresenter) {
      this.isAddMileStone = !this.isAddMileStone
      this.isAddFunction = true
      if (record) {
        this.form = record
        this.isAddFunction = false
      }
    },

    async handleAddMileStone() {
      this.addMileStoneLoading = true

      const { status, fees_currency, expenses_currency, due_date, ...form } =
        this.form

      const response = await addMilestone({
        related_to_type: 'LegalMatter',
        related_to_id: Number(this.$route.params.id),

        due_date: new Date(due_date).toISOString(),
        status_id: status.id,
        fees_currency_id: fees_currency.id,
        expenses_currency_id: expenses_currency.id,
        ...form,
      })

      if (response.success) {
        this.$vaToast.init({
          message: 'Milestone created successfully!',
          color: 'success',
        })
        this.handleGetMilestones()
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.addMileStoneLoading = false
    },

    async handleUpdateMileStone() {
      this.updateMileStoneLoading = true

      const {
        id,
        status,
        fees_currency,
        expenses_currency,
        due_date,
        ...form
      } = this.form

      const response = await updateMilestone(id, {
        related_to_type: 'LegalMatter',
        related_to_id: Number(this.$route.params.id),

        due_date: new Date(due_date).toISOString(),
        status_id: status.id,
        fees_currency_id: fees_currency.id,
        expenses_currency_id: expenses_currency.id,
        ...form,
      })

      if (response.success) {
        this.$vaToast.init({
          message: 'Milestone created successfully!',
          color: 'success',
        })
        this.handleGetMilestones()
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }

      this.updateMileStoneLoading = false
    },

    async handleGenerateInvoice(id: number) {
      this.generateInvoiceLoading = true

      const response = await generateMilestoneInvoice(id)

      if (response.success) {
        this.$vaToast.init({
          message: 'Invoices generated successfully!',
          color: 'success',
        })

        this.handleGetMilestones()
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.generateInvoiceLoading = false
    },
  },
})
