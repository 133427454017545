import { TemplateInterface } from '@/Interfaces/Constants'

export const contactFormFields: TemplateInterface[] = [
  // upload
  // {
  //   type: 'upload',
  //   name: 'individual_profile_picture',
  //   details: 'Upload Image',
  // },

  // text
  {
    type: 'text',
    rules: 'validateInput',
    name: 'individual_title',
    label: 'Title',
    placeholder: 'Title',
    details: 'Text Field',
  },
  {
    type: 'text',
    rules: 'validateInput',
    name: 'individual_first_name',
    label: 'First Name',
    placeholder: 'First Name',
    details: 'Text Field',
    required: true,
  },
  {
    type: 'text',
    rules: 'validateInput',
    name: 'individual_middle_name',
    label: 'Middle Name',
    placeholder: 'Middle Name',
    details: 'Text Field',
  },
  {
    type: 'text',
    rules: 'validateInput',
    name: 'individual_last_name',
    label: 'Last Name',
    placeholder: 'Last Name',
    details: 'Text Field',
    required: true,
  },
  {
    type: 'text',
    rules: 'validateInput',
    name: 'individual_suffix',
    label: 'Suffix',
    placeholder: 'Suffix',
    details: 'Text Field',
  },
  {
    type: 'text',
    rules: 'validateInput',
    name: 'individual_job_title',
    label: 'Job Title',
    placeholder: 'Job Title',
    details: 'Text Field',
  },
  {
    type: 'text',
    rules: 'validateInput',
    name: 'individual_tax_identification_number',
    label: 'Tax Identification Number',
    placeholder: 'Tax Identification Number',
    details: 'Text Field',
  },
  {
    type: 'text',
    rules: 'validateInput',
    name: 'website_url',
    label: 'Personal Website',
    placeholder: 'Personal Website',
    details: 'Text Field',
  },
  {
    type: 'text',
    rules: 'validateInput',
    name: 'individual_nationality',
    label: 'Nationality',
    placeholder: 'Nationality',
    details: 'Text Field',
  },

  // radio
  {
    type: 'radio',
    name: 'individual_gender',
    label: 'Gender',
    options: [
      {
        text: 'Male',
        value: 'male',
      },
      {
        text: 'Female',
        value: 'female',
      },
    ],
    details: 'Radio buttons',
  },

  // date
  {
    type: 'date',
    rules: 'validateInput',
    name: 'individual_date_of_birth',
    label: 'Date Of Birth',
    placeholder: 'MM/DD/YYYY',
    details: 'Date Field - Select date + Manual Input',
  },

  // select
  {
    type: 'select',
    rules: 'validateSelect',
    name: 'linked_legal_matter_ids',
    label: 'Link To Matter',
    placeholder: 'Link To Matter',
    options: 'matters',
    textBy: 'getOptionName',
    multiple: true,
    searchable: true,
    details: 'Dropdown : Select Matter and link the contact to it',
  },
  {
    type: 'select',
    rules: 'validateSelect',
    name: 'organization_contact',
    label: 'Link To Organization',
    placeholder: 'Link To Organization',
    options: 'organizations',
    textBy: 'getOptionFullName',
    multiple: false,
    searchable: true,
    details: 'Dropdown : Select Organization and possibility to add new one',
  },
  {
    type: 'select',
    rules: 'validateSelect',
    name: 'relationship_owner',
    label: 'Relationship Owner',
    placeholder: 'Relationship Owner',
    options: 'teamMembers',
    textBy: 'getOptionFullName',
    multiple: false,
    searchable: true,
    details: 'Dropdown : Select Case Manager',
  },
  {
    type: 'select',
    rules: 'validateSelect',
    name: 'assigned_to',
    label: 'Assigned To',
    placeholder: 'Assigned To',
    options: 'teamMembers',
    textBy: 'getOptionFullName',
    multiple: false,
    searchable: true,
    details: 'Dropdown : Select Case Manager',
  },
  {
    type: 'select',
    rules: 'validateSelect',
    name: 'referred_by',
    label: 'Referred by',
    placeholder: 'Referred by',
    options: 'contacts',
    textBy: 'getOptionFullName',
    multiple: false,
    searchable: true,
    details: 'Dropdown : Select Referred by and possibility to add new one',
  },

  // text area
  {
    type: 'textarea',
    rules: 'validateInput',
    name: 'notes',
    label: 'Notes',
    placeholder: 'Enter your notes here...',
    details: 'Text Area',
  },

  // Address Accordion
]

export const contactFormFieldsMostUsed: TemplateInterface[] = [
  {
    type: 'text',
    rules: 'validateInput',
    name: 'individual_first_name',
    label: 'First Name',
    placeholder: 'First Name',
    details: 'Text Field',
  },

  {
    type: 'text',
    rules: 'validateInput',
    name: 'individual_last_name',
    label: 'Last Name',
    placeholder: 'Last Name',
    details: 'Text Field',
  },

  {
    type: 'text',
    rules: 'validateInput',
    name: 'individual_nationality',
    label: 'Nationality',
    placeholder: 'Nationality',
    details: 'Text Field',
  },

  // text area
  {
    type: 'textarea',
    rules: 'validateInput',
    name: 'notes',
    label: 'Notes',
    placeholder: 'Enter your notes here...',
    details: 'Text Area',
  },

  // Address Accordion
]
