import { TemplateInterface } from '@/Interfaces/Constants'
export const leadFormFields: TemplateInterface[] = [
  // text

  {
    type: 'text',
    rules: 'validateInput',
    name: 'title',
    label: 'Title',
    placeholder: 'Title',
    details: 'Text Field',
  },
  {
    type: 'text',
    rules: 'validateInput',
    name: 'lead_value',
    label: 'Value',
    placeholder: 'Value',
    details: 'Value',
  },
  {
    type: 'text',
    rules: 'validateInput',
    name: 'probability',
    label: 'Probability',
    placeholder: 'Probability',
    details: 'Text Field',
  },

  // select

  {
    type: 'select',
    rules: 'validateSelect',
    name: 'individual_contact',
    label: 'Contact',
    placeholder: 'Select Contact',
    options: 'contacts',
    textBy: 'getOptionName|getOptionFullName',
    searchable: true,
    details: 'Dropdown : Select Contact and possibility to add new one',
  },
  {
    type: 'select',
    rules: 'validateSelect',
    name: 'organization_contact',
    label: 'Organization',
    placeholder: 'Select Organization',
    options: 'organizations',
    textBy: 'getOptionName|getOptionFullName',
    searchable: true,
    details: 'Dropdown : Select Organization and possibility to add new one',
  },
  {
    type: 'select',
    rules: 'validateSelect',
    name: 'currency',
    label: 'Main Currency',
    placeholder: 'Select Main Currency',
    options: 'currencies',
    textBy: 'getOptionCurrency',
    searchable: true,
    details: 'Dropdown : Select Main Currency',
    valueBy: 'id',
  },

  {
    type: 'select',
    rules: 'validateSelect',
    name: 'section_stage',
    label: 'Stage',
    placeholder: 'Select Stage',
    options: 'leadSectionStages',
    textBy: 'getOptionName',
    searchable: true,
    details: 'Dropdown : Select Section Stage',
  },
  {
    type: 'select',
    rules: 'validateSelect',
    name: 'label',
    label: 'Label',
    placeholder: 'Select Label',
    options: 'leadLabels',
    textBy: 'getOptionName',
    searchable: true,
    details: 'Dropdown : Select Section Label',
  },
  {
    type: 'select',
    rules: 'validateSelect',
    name: 'relationship_owner',
    label: 'Relationship Owner',
    placeholder: 'Select Relationship Owner',
    options: 'teamMembers',
    textBy: 'getOptionFullName',
    searchable: true,
    details: 'Dropdown : Select Relationship Owner',
  },

  // date

  {
    type: 'date',
    rules: 'validateInput',
    name: 'expected_close_date',
    label: 'Expected Close Date',
    placeholder: 'MM/DD/YYYY',
    details: 'Date Field - Select date + Manual Input',
  },
]

const mostUsedFieldNames = [
  'title',
  'section_stage',
  'label',
  'expected_close_date',
]

export const leadFormFieldsMostUsed: TemplateInterface[] =
  leadFormFields.filter(
    (field) => field.name && mostUsedFieldNames.includes(field.name),
  )
