import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SearchAndViews = _resolveComponent("SearchAndViews")!
  const _component_TasksTable = _resolveComponent("TasksTable")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_SearchAndViews, {
      search: _ctx.taskSearchKeyword,
      onSearch: _ctx.searchTaskData
    }, null, 8, ["search", "onSearch"]),
    _createVNode(_component_TasksTable, {
      loading: _ctx.taskTableLoading,
      "items-per-page": _ctx.tasksItemPerPage,
      "total-items": _ctx.taskTotalItems,
      tasks: _ctx.tasks,
      headers: _ctx.headers,
      onSelectedRows: _ctx.getSelectedRows,
      onLoadMore: _ctx.handlePaginationTasks
    }, null, 8, ["loading", "items-per-page", "total-items", "tasks", "headers", "onSelectedRows", "onLoadMore"])
  ], 64))
}