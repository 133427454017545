<template>
  <SearchAndViews :search="matterSearchKeyword" @search="searchMatterData" />

  <!--
  :withViews="true"
  :activeView="activeView"
    @changeView="changeView" -->

  <MatterTable
    v-if="activeView === 'list'"
    :loading="matterTableLoading"
    :items-per-page="mattersItemPerPage"
    :total-items="matterTotalItems"
    :matters="matters"
    :headers="headers"
    @selectedRows="getSelectedRows"
    @loadMore="handlePaginationMatters"
  />

  <div v-else-if="activeView === 'board'" class="columns-container">
    <div v-for="(sec, index) in sections" :key="index" class="matter-column">
      <div
        class="matter-column-header"
        :style="{
          color: sec?.color || 'black',
          borderBottom: '0.5rem solid ' + (sec?.color || 'red'),
        }"
      >
        <h3>
          {{ sec.name }}
        </h3>
      </div>
      <LegalMatterCard :matter="{}" />
    </div>
  </div>
</template>

<script lang="ts" src="./script.ts" />
<style lang="scss" scoped src="./styles.scss" />
