import { AddressPresenter } from '../AddressPresenter'
import { CityPresenter } from '../AddressPresenter/CityPresenter'
import { CountryPresenter } from '../AddressPresenter/CountryPresenter'
import { StatePresenter } from '../AddressPresenter/StatePresenter'
import {
  Avatar,
  EmailAddress,
  IndividualSpecificData,
  KycDocument,
  PhoneNumber,
} from '../interface'
import { OrganizationContactPresenter } from '../organizationPresenter'
import { StagesPresenter } from '../StagesPresenter'
import { TeamMemberPresenter } from '../teamMember'
import { ContactTemplatePresenter } from '../TemplatePresenter/Contact'

export class IndividualContactPresenter {
  id!: number
  assigned_to!: TeamMemberPresenter
  class_name!: 'IndividualContact'
  company_id!: number
  contact_type!: string
  created_at!: string
  designated_point_of_contact!: TeamMemberPresenter
  email_addresses!: EmailAddress[]
  extra_notes!: string
  facebook_url!: string
  google_plus_url!: string
  instagram_url!: string
  kyc_completed!: boolean
  kyc_document!: KycDocument
  kyc_notes!: string
  linkedin_url!: string
  notes!: string
  organization_contact!: OrganizationContactPresenter
  phone_numbers!: PhoneNumber[]
  referred_by!: IndividualContactPresenter
  relationship_owner!: TeamMemberPresenter
  specific_data!: IndividualSpecificData
  tiktok_url!: string | null
  twitter_url!: string | null
  updated_at!: string
  website_url!: string
  youtube_url!: string | null
  contact_addresses!: AddressPresenter[]
  client!: boolean

  fullName!: string
  client_avatar_letter!: string
  firstName!: string
  middleName!: string
  lastName!: string
  title!: string
  nationality!: string
  taxIdentificationNumber!: string

  icon?: string
  path_to?: string

  individual_profile_picture!: File
  avatar!: Avatar

  section_stage_items!: StagesPresenter[]
  section_stage!: any
  form_columns!: ContactTemplatePresenter[][]
  linked_legal_matter_ids!: any

  constructor(data: IndividualContactPresenter) {
    Object.assign(this, data)

    if (data.specific_data) {
      Object.assign(this, data.specific_data) // Spread specific_data properties directly
    }

    if (data.section_stage_items) {
      this.section_stage_items = data.section_stage_items.map(
        (item) => new StagesPresenter(item),
      )

      this.form_columns = this.section_stage_items[0]?.section?.form_columns
    }

    if (data.assigned_to) {
      this.assigned_to = new TeamMemberPresenter(data.assigned_to)
    }

    if (data.organization_contact) {
      this.organization_contact = new OrganizationContactPresenter(
        data.organization_contact,
      )
    }

    if (data.referred_by) {
      this.referred_by = new IndividualContactPresenter(data.referred_by)
    }

    if (data.relationship_owner) {
      this.relationship_owner = new TeamMemberPresenter(data.relationship_owner)
    }

    if (data.designated_point_of_contact) {
      this.designated_point_of_contact = new TeamMemberPresenter(
        data.designated_point_of_contact,
      )
    }

    if (this.contact_addresses?.length === 0) {
      this.contact_addresses.push({
        building_name: '',
        city: {} as CityPresenter,
        country: {} as CountryPresenter,
        floor: '',
        google_maps_url: '',
        phone_number: '',
        state: {} as StatePresenter,
        street_name: '',
        updated_at: '',
        zip_code: '',
        class_name: '',
        created_at: '',
      })
    } else if (this.contact_addresses) {
      this.contact_addresses = this.contact_addresses.map(
        (item: AddressPresenter) => new AddressPresenter(item),
      )
    }

    this.fullName = [
      this.specific_data?.individual_first_name,
      this.specific_data?.individual_last_name,
    ]
      .filter(Boolean)
      .join(' ')

    this.client_avatar_letter = [
      this.specific_data?.individual_first_name?.charAt(0),
      this.specific_data?.individual_last_name?.charAt(0),
    ]
      .filter(Boolean)
      .join(' ')

    this.firstName = this.specific_data?.individual_first_name || ''
    this.middleName = this.specific_data?.individual_middle_name || ''
    this.lastName = this.specific_data?.individual_last_name || ''
    this.title = this.specific_data?.individual_title || ''
    this.nationality = this.specific_data?.individual_nationality || ''
    this.taxIdentificationNumber =
      this.specific_data?.individual_tax_identification_number || ''

    this.path_to = `/contacts/people/${this.id}`
    this.icon = 'contacts'
    this.avatar =
      this.specific_data?.individual_profile_picture || ({} as Avatar)
  }
}
