import { defineComponent } from 'vue'
import SearchAndViews from '@/components/searchAndViews/index.vue'
import ActionsBar from '@/components/actionsBar/index.vue'
import { getAllTeams, addTeam, addTeamMember } from '@/services/teams'
import { TeamPresenter } from '@/presenters/TeamPresenter'
import AddTeamModal from '@/components/AddTeamModal/index.vue'
import AddMembers from '@/components/AddTeamModal/AddTeamMemberModal/index.vue'
import { CompanyUserPresenter } from '@/presenters/CompanyUserPresenter'
import { deepCopy } from '@/lib/utils'

const MoreVertIcon = new URL(
  '@/assets/icons/More-vert-icon.svg',
  import.meta.url,
).href

export default defineComponent({
  name: 'Teams',
  data() {
    return {
      teams: [] as TeamPresenter[],

      MoreVertIcon,

      isUpdateTeamModalOpen: false,

      teamsAccordionCollapseValues: [false],

      currentTeam: {} as TeamPresenter,

      isAddMembersModalOpen: false,
    }
  },
  methods: {
    ///////////////////////////////////////////////////////////////////////////////
    getTeams(search?: string) {
      const response = getAllTeams()
      if (response.success) {
        this.teams = search
          ? response.data?.list.filter((team) =>
              team.name.toLowerCase().includes(search.toLowerCase()),
            )
          : response.data?.list
        this.teamsAccordionCollapseValues = [false]
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
    },

    searchTeamsData(search: string) {
      this.getTeams(search)
    },

    // //////////////////////////////////////////////////////////////////////////////////
    toggleUpdateTeamModal(id?: number) {
      if (id) {
        const team = this.teams.find((team) => team.id === id)
        this.currentTeam = deepCopy(team) ?? ({} as TeamPresenter)
      } else {
        this.currentTeam = {} as TeamPresenter
      }
      this.isUpdateTeamModalOpen = !this.isUpdateTeamModalOpen
    },

    handleUpdateTeam(newTeam: TeamPresenter) {
      addTeam({ newTeam })
    },

    toggleAddMembersModal() {
      this.isAddMembersModalOpen = !this.isAddMembersModalOpen
    },

    handleAddMembers({
      members,
      team,
      note,
    }: {
      members: CompanyUserPresenter[]
      team: number
      note: string
    }) {
      addTeamMember({ members, team, note })
    },
  },
  mounted() {
    this.getTeams()
  },
  components: {
    SearchAndViews,
    ActionsBar,
    AddTeamModal,
    AddMembers,
  },
})
