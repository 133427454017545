import { defineComponent } from 'vue'
import TabsBar from '@/components/tabsBar/index.vue'
import ComingSoon from '@/components/ComingSoon/index.vue'
import TaskGlobal from '@/views/Settings/TasksSettings/TaskGlobal/index.vue'
import TasksTables from './TasksTables/index.vue'
import TaskToolbar from './TaskToolbar/index.vue'
export default defineComponent({
  name: 'TasksSettings',

  components: {
    TabsBar,
    ComingSoon,
    TaskGlobal,
    TasksTables,
    TaskToolbar,
  },
  data() {
    const tabs = [
      { id: 0, name: 'TOOLBAR' },
      { id: 1, name: 'GLOBAL TASKS' },
      { id: 2, name: 'TASKS TABLES' },
    ]

    return {
      tabs,
      activeTabId: 0,
      activeTab: 'TOOLBAR',
    }
  },
  computed: {},
  methods: {
    /////////////////////////////////////////////////////////////////////////////
    changeTab({ tab }: { tab: { title?: string; name: string; id: number } }) {
      this.activeTabId = tab.id
      this.activeTab = tab.name
    },
  },
})
