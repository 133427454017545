import {
  mockDraftAnnouncements,
  mockSentAnnouncements,
} from '@/constants/mockAnnouncements'
import { AnnouncementPresenter } from '@/presenters/AnnouncementPresenter'

export const getAllAnnouncements = ({
  activeTabId,
}: {
  activeTabId: number
}) => {
  const announcements =
    activeTabId === 0 ? mockSentAnnouncements : mockDraftAnnouncements

  const response = {
    success: true,
    message: 'success',
    data: {
      extra_data: {},
      limit_value: 100,
      total_pages: 1,
      current_page: 1,
      next_page: null,
      prev_page: null,
      list: announcements,
    },
  }

  if (!response.success) return response

  const list = response.data?.list
  if (!Array.isArray(list)) return response

  return {
    ...response,
    data: {
      ...response.data,
      list: list.map((item: AnnouncementPresenter) => {
        return new AnnouncementPresenter(item)
      }),
    },
  }
}

export const addAnnouncement = ({
  isSent,
  newAnnouncement,
}: {
  isSent: boolean | undefined
  newAnnouncement: AnnouncementPresenter
}) => {
  if (isSent) {
    mockSentAnnouncements.push(newAnnouncement)
  } else {
    mockDraftAnnouncements.push(newAnnouncement)
  }
}
