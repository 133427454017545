import { CompanyConfigurationPresenter } from '../companyConfigPresenters'
import { IndividualContactPresenter } from '../contactPresenter'
import { CurrenciesPresenter } from '../CurrenciesPresenter'

import { OrganizationContactPresenter } from '../organizationPresenter'
import { SectionsPresenter } from '../SectionsPresenter'
import { StagesPresenter } from '../StagesPresenter'
import { TeamMemberPresenter } from '../teamMember'

export class LeadPresenter {
  id!: number
  title!: string
  class_name!: 'Lead'
  created_at!: string
  currency!: CurrenciesPresenter
  expected_close_date!: string
  individual_contact!: IndividualContactPresenter
  label!: CompanyConfigurationPresenter
  lead_value!: number
  organization_contact!: OrganizationContactPresenter
  probability!: number
  relationship_owner!: TeamMemberPresenter
  section_stage_items!: StagesPresenter[]
  updated_at!: string
  won!: boolean

  path_to!: string
  stage!: SectionsPresenter

  section_stage!: any

  constructor(data: LeadPresenter) {
    Object.assign(this, data)

    this.path_to = `/contacts/leads/${this.id}`

    if (data.currency) {
      this.currency = new CurrenciesPresenter(data.currency)
    }

    if (data.individual_contact) {
      this.individual_contact = new IndividualContactPresenter(
        data.individual_contact,
      )
    }

    if (data.label) {
      this.label = new CompanyConfigurationPresenter(data.label)
    }

    if (data.organization_contact) {
      this.organization_contact = new OrganizationContactPresenter(
        data.organization_contact,
      )
    }

    if (data.relationship_owner) {
      this.relationship_owner = new TeamMemberPresenter(data.relationship_owner)
    }

    if (data.section_stage_items) {
      this.section_stage_items = data.section_stage_items.map(
        (item) => new StagesPresenter(item),
      )
      this.stage = this.section_stage_items?.[0]?.section_stage
    }
  }
}
